import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Card, Nav, Tab } from "react-bootstrap";
import { useTheme } from "@mui/material/styles";
import { useURLSearchParams } from "components";
import { useTranslation } from "react-i18next";
import { IUrlParams } from "types";
import AssetForm from "./AssetForm";
import AssetUpload from "./AssetUpload";
import AssetsBreadcrumbs from "../ListView/AssetsBreadcrumbs";

export default function CreateOrEditAsset() {
  const { t } = useTranslation();
  const { type } = useParams<IUrlParams>();
  const location = useLocation();
  const theme = useTheme();
  const isAdd = location.pathname.includes("/add");
  const query = useURLSearchParams();

  return (
    <Card
      className="border-0 h-100 d-flex flex-column overflow-auto"
      style={{
        backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
      }}
    >
      <Tab.Container activeKey={query.get("formType") ?? "/"}>
        {isAdd ? (
          <Nav variant="tabs" className="mb-n1">
            <Nav.Item>
              <Nav.Link eventKey={"/"} as={Link} to={`./add`}>
                {t("common.form")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="upload"
                as={Link}
                to={`./add?formType=upload`}
              >
                {t("common.upload")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        ) : null}

        <Tab.Content
          className={`d-flex flex-column border-0 rounded overflow-auto ${
            theme.palette.mode === "light" ? "bg-light" : "bg-dark"
          }`}
        >
          <div className="px-3 pt-3">
            {!isAdd ? (
              <AssetsBreadcrumbs />
            ) : (
              <h3>
                <Link to="./">
                  {type} {t("inventory.assets.title")}
                </Link>
              </h3>
            )}
          </div>
          <Tab.Pane
            eventKey="/"
            className="flex-grow-1 overflow-auto px-3 pb-3"
          >
            {!query.get("formType") ? <AssetForm /> : null}
          </Tab.Pane>
          <Tab.Pane
            eventKey="upload"
            className="flex-column flex-grow-1 overflow-auto px-3 pb-3"
            style={
              query.get("formType") === "upload" ? { display: "flex" } : {}
            }
          >
            {query.get("formType") === "upload" ? <AssetUpload /> : null}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Card>
  );
}
