import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useConservationState } from "providers/ConservationProvider";
import MapThemeItem from "components/MapThemeItem";

export default function ConservationMapThemesView({ onThemeChange }) {
  const { t } = useTranslation();
  const [themeType, setThemeType] = useState("conservationType");
  const { themeRollup } = useConservationState();
  const typeTheme = themeRollup?.conservationType;
  const statusTheme = themeRollup?.conservationStatus;
  return (
    <Container className="h-100">
      <Row className="h-100">
        {typeTheme && typeTheme.length ? (
          <Col>
            <MapThemeItem
              id="conservationType"
              title={t("common.type")}
              isActive={themeType === "conservationType"}
              onClick={() => {
                setThemeType("conservationType");
                onThemeChange("conservationType");
              }}
              data={typeTheme}
            />
          </Col>
        ) : null}
        {statusTheme && statusTheme.length ? (
          <Col>
            <MapThemeItem
              id="conservationStatus"
              title={t("common.status")}
              isActive={themeType === "conservationStatus"}
              onClick={() => {
                setThemeType("conservationStatus");
                onThemeChange("conservationStatus");
              }}
              data={statusTheme}
            />
          </Col>
        ) : null}
        {!statusTheme?.length && !typeTheme?.length ? (
          <Col>
            <h6 className="text-center small mt-4">{t("common.noData")}</h6>
          </Col>
        ) : null}
      </Row>
    </Container>
  );
}
ConservationMapThemesView.defaultProps = {};
ConservationMapThemesView.propTypes = {
  onThemeChange: PropTypes.func.isRequired,
};
