import React, { useCallback, useEffect } from "react";
import { Card } from "react-bootstrap";
import useFetchWithToken from "components/useFetchWithToken";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { DownloadCloud, UploadCloud } from "react-feather";
import { useOrgState, useOrgDispatch } from "providers";
import { DataTable } from "components";
import jdImg from "./deere-logo.png";
import { LinearProgress } from "lib";

interface ISharedData {
  field: string;
  farm?: string;
  applied?: boolean;
  planted?: boolean;
  harvested?: boolean;
  tilled?: boolean;
}
interface ISharedDataByTenant {
  tenant: string;
  data: ISharedData[];
  darkLogo?: HTMLImageElement;
  lightLogo?: HTMLImageElement;
}

function BooleanCell({ value }: { value: string }) {
  if (value) {
    return <input readOnly type="checkbox" checked style={{ opacity: 0.5 }} />;
  }
  return (
    <input readOnly type="checkbox" checked={false} style={{ opacity: 0.5 }} />
  );
}

export default function DataConnections() {
  const { t } = useTranslation();
  const { org, seasonId } = useOrgState();
  const { getEnterpriseLogos } = useOrgDispatch();
  const theme = useTheme();
  const {
    fetchData: fetchDataSharing,
    state: sharedOperationsState,
  } = useFetchWithToken<ISharedDataByTenant[]>();

  // fetch dark/light photos for each tenant
  // and store it with the other data
  const getLogos = useCallback(
    async (d: ISharedDataByTenant) => {
      const [dark, light] = await getEnterpriseLogos(d.tenant);
      return { ...d, darkLogo: dark.data, lightLogo: light.data };
    },
    [getEnterpriseLogos]
  );

  const sharedCellProps = {
    Cell: BooleanCell,
    disableGlobalFilter: true,
  };
  // NOTE: added this to show that we ingest data from JD
  // TODO: need to remove this
  const receivingCols = ["kff", "templetonff", "rowcrop"].includes(org)
    ? [
        {
          Header: "",
          styles: { width: "150px" },
          isSortable: false,
          accessor: "img",
          Cell: () => {
            return <img src={jdImg} />;
          },
        },
        {
          Header: "",
          accessor: "description",
          isSortable: false,
          Cell: () => {
            return (
              <div>
                <dl className="mb-0">
                  <div className="d-flex">
                    <dt>Inventory:</dt>
                    <dd className="mb-0 ml-2">Field boundaries</dd>
                  </div>
                  <div className="d-flex">
                    <dt>Operations:</dt>
                    <dd className="mb-0 ml-2">
                      Planted, Applied, Harvested, Tilled
                    </dd>
                  </div>
                  <div className="d-flex">
                    <dt>Subfields:</dt>
                    <dd className="mb-0 ml-2">RX</dd>
                  </div>
                </dl>
              </div>
            );
          },
        },
      ]
    : [];

  const receivingData = ["kff", "templetonff", "rowcrop"].includes(org)
    ? [
        {
          img: "",
          description: "",
        },
      ]
    : [];

  useEffect(() => {
    fetchDataSharing(`/${org}/sharing/season/${seasonId}`, {
      transformResponse: async (data: ISharedDataByTenant[]) => {
        const withIcons = data.map((d) => {
          return getLogos(d);
        });
        return Promise.all(withIcons);
      },
    });
  }, [fetchDataSharing, getLogos, org, seasonId]);

  return (
    <Card className="h-100 p-3 data-connections">
      <h3>{t("settings.dataConnections")}</h3>
      <div className="mb-3 receiving">
        <h4>
          {t("settings.receiving")}{" "}
          <span className="text-primary">
            <DownloadCloud />
          </span>
        </h4>
        <DataTable
          tableProps={{ size: "sm", bordered: true }}
          isFilterable={false}
          columns={receivingCols}
          data={receivingData}
        />
      </div>
      <div className="d-flex flex-column overflow-auto">
        <h4>
          {t("settings.sending")}{" "}
          <span className="text-primary">
            <UploadCloud />
          </span>
        </h4>
        <p>{t("settings.contactMgrDataShare")}</p>
        <div className="overflow-auto">
          {sharedOperationsState.data && !sharedOperationsState.data?.length ? (
            <DataTable
              tableProps={{ bordered: true, size: "sm" }}
              isFilterable={false}
              data={[]}
            />
          ) : null}
          {sharedOperationsState.isLoading ? (
            <div style={{ minHeight: "300px" }} className="w-100 border">
              <LinearProgress />
            </div>
          ) : null}
          {sharedOperationsState.data?.map((y: ISharedDataByTenant) => (
            <div
              key={y.tenant}
              style={{ minHeight: "300px", maxHeight: "100%" }}
              className="w-100 overflow-hidden border p-3 d-flex flex-column"
            >
              <DataTable
                components={{
                  HeaderLeft: (
                    <div style={{ width: "170px" }}>
                      <img
                        className="img-fluid"
                        src={
                          theme.palette.mode === "light"
                            ? y.lightLogo?.src
                            : y.darkLogo?.src
                        }
                      />
                    </div>
                  ),
                }}
                tableProps={{ size: "sm" }}
                columns={[
                  {
                    Header: t("common.field"),
                    accessor: "field",
                    Cell: function FarmField({
                      value,
                      row,
                    }: {
                      value: string;
                      row: { original: ISharedData };
                    }) {
                      return (
                        <div className="float-left d-flex flex-column mr-1">
                          <span>{`${value}`}</span>
                          <small>{row?.original?.farm}</small>
                        </div>
                      );
                    },
                  },
                  {
                    Header: t("common.planted"),
                    accessor: "planted",
                    ...sharedCellProps,
                  },
                  {
                    Header: t("common.applied"),
                    accessor: "applied",
                    ...sharedCellProps,
                  },
                  {
                    Header: t("common.harvested"),
                    accessor: "harvested",
                    ...sharedCellProps,
                  },
                  {
                    Header: t("common.tilled"),
                    accessor: "tilled",
                    ...sharedCellProps,
                  },
                  {
                    Header: t("inventory.damage.title"),
                    accessor: "damage",
                    ...sharedCellProps,
                  },
                  {
                    Header: t("common.carbon"),
                    accessor: "carbon",
                    ...sharedCellProps,
                  },
                ]}
                data={y.data || []}
              />
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
}
