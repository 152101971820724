import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Link,
  Route,
  Switch,
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { usePrevious, IconButton } from "components";
import { useOrgState } from "providers/OrgProvider";
import { AssetsByTypeTable } from "./AssetsByTypeTable";
import { AssetsBySubtypeTable } from "./AssetsBySubtypeTable";
import { AssetsBySubtypeAndFieldAndGroupTable } from "./AssetsBySubtypeAndFieldAndGroupTable";
import AssetsBreadcrumbs from "./AssetsBreadcrumbs";

export default function AssetsListView({ expanded, toggleExpanded }) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  // const query = useURLSearchParams();
  const isAdd = location.pathname.includes("/add");
  const isEdit =
    location.pathname.includes("/edit") &&
    !location.pathname.includes("/records");
  const { rootUrl, seasonId } = useOrgState();
  const previousSeasonId = usePrevious(seasonId);
  // const { currentEditFtr } = useAssetsState();
  const { subtype, itemId, type } = useParams();

  // const groupId = query.get('groupId');

  // const outOfSeason =
  //   currentEditFtr?.properties.seasonId &&
  //   currentEditFtr?.properties.seasonId !== seasonId;

  // if season changes, redirect back to asset by type summary
  useEffect(() => {
    if (seasonId !== previousSeasonId && previousSeasonId) {
      history.replace({
        pathname: `${rootUrl}/inventory/assets/${type}`,
      });
    }
  }, [seasonId, type, rootUrl, history, previousSeasonId]);

  return (
    <>
      <Row>
        <Col>
          <AssetsBreadcrumbs />
        </Col>
        <Col xs="auto">
          <div className="d-flex ml-auto">
            {!itemId && !isEdit && !isAdd ? (
              <IconButton
                title={
                  expanded ? t("common.collapseTable") : t("common.expandTable")
                }
                icon={expanded ? "chevronDown" : "chevronUp"}
                className="mr-2 d-flex"
                onClick={toggleExpanded}
              />
            ) : null}

            {type && type !== "equipment" && !subtype && !isAdd ? (
              <IconButton
                as={Link}
                icon={"add"}
                variant="outline-success"
                to={`${rootUrl}/inventory/assets/${type}/add`}
                className="d-flex"
              />
            ) : null}
          </div>
        </Col>
      </Row>
      <Switch>
        <Route path="/:org/:season/inventory/assets/:type?" exact>
          <Row className="flex-grow-1 overflow-auto h-100">
            <Col className="h-100">
              <AssetsByTypeTable />
            </Col>
          </Row>
        </Route>
        <Route
          path="/:org/:season/inventory/assets/:type/:subtype/:fieldId/items"
          exact
        >
          <div className="inventory-list overflow-auto">
            <AssetsBySubtypeAndFieldAndGroupTable />
          </div>
        </Route>
        <Route path="/:org/:season/inventory/assets/:type/:subtype/:fieldId?">
          <AssetsBySubtypeTable />
        </Route>
      </Switch>
    </>
  );
}
AssetsListView.defaultProps = {
  expanded: false,
};
AssetsListView.propTypes = {
  expanded: PropTypes.bool,
  toggleExpanded: PropTypes.func.isRequired,
};
