import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ImageComponent from "components/Images/ImageComponent";
import { useAssetsDispatch, useAssetsState } from "providers/AssetsProvider";

export default function AssetImages() {
  const { itemId } = useParams();
  const { assetPhotos } = useAssetsState();
  const [images, setImages] = useState(assetPhotos || []);
  const {
    saveAssetPhoto,
    deleteAssetPhoto,
    fetchPhotosByAssetId,
  } = useAssetsDispatch();
  const saveImages = async (imgs) => {
    // resetDeleteRecordPhoto();
    // resetSaveRecordPhoto();
    const reqs = [];
    imgs.forEach(async (img) => {
      if (itemId && !img.id) {
        const req = saveAssetPhoto(img.file, itemId);
        reqs.push(req);
        req.then((res) => {
          img.isLoading = false;
          if (res?.data?.id) {
            img.id = res.data.id;
          } else {
            img.failed = true;
            img.errorMessage = res?.errorMessage;
          }
        });
      }
    });
    Promise.all(reqs)
      .then(() => {
        setImages(images.concat([...imgs]));
      })
      .finally(() => {
        fetchPhotosByAssetId(itemId);
      });
  };

  const deleteImage = async (img) => {
    try {
      const photoId = img.id;
      img.isLoading = true;
      setImages([...images]);
      const res = await deleteAssetPhoto(itemId, photoId);
      img.isLoading = false;
      if (res.isError) {
        img.failed = true;
        img.errorMessage = res.errorMessage;
        setImages([...images]);
      }
      fetchPhotosByAssetId(itemId);
      return res;
    } catch (e) {
      img.isLoading = false;
      img.failed = true;
      img.errorMessage = "Failed to delete";
      setImages([...images]);
      console.error(e);
    }
  };

  useEffect(() => {
    if (assetPhotos?.length > 0) {
      setImages(assetPhotos);
    }
  }, [assetPhotos]);
  return (
    <>
      <span className="h6 text-uppercase">(Max 4)</span>
      <ImageComponent
        id="asset-photo-upload"
        // disabled={fetchingRecordPhotos || saveRecordPhotoState?.isLoading}
        images={images}
        maxImages={4}
        onImageChange={(imgs) => {
          const all = images?.concat(imgs);
          imgs.forEach((i) => {
            i.isLoading = true;
          });
          saveImages(imgs);
          setImages(all);
        }}
        onImageDelete={async (img, idx) => {
          if (img.id) {
            // ensure delete is successful before removing
            const res = await deleteImage(img);
            if (!res?.isError) {
              const newImages = [...images];
              newImages.splice(idx, 1);
              setImages(newImages);
            }
          } else {
            // no id, hasnt been uploaded yet
            // so we just remove it from the array
            const newImages = [...images];
            newImages.splice(idx, 1);
            setImages(newImages);
          }
        }}
        accept=".gif, .jpeg, .jpg, .png"
      />
    </>
  );
}
