import { useObservationPhotoDelete } from "api/observations/useObservationPhotoDelete";
import { useParams } from "react-router-dom";
import { IUrlParams } from "types";
import ImageCard from "./ImageCard";
import { ParsedImage } from "api/observations/useObservationPhotos";

export default function ObservationPhotoCard({
  image,
  onClick,
}: {
  image: ParsedImage;
  onClick: (_e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const { org, observationId } = useParams<IUrlParams>();
  const mutation = useObservationPhotoDelete({
    org,
    observationId,
  });
  return (
    <ImageCard
      isLoading={mutation.isLoading || !image?.id}
      image={image}
      onDelete={() => {
        mutation.mutate(image.id);
      }}
      onClick={onClick}
    />
  );
}
