import React, { useRef } from "react";
import { Plus } from "react-feather";
import { ParsedImage } from "./ImageComponent";

function ImageUpload({
  id,
  images,
  onChange,
  className,
  disabled,
  accept,
}: {
  id: string;
  images: ParsedImage[];
  onChange: (_imgs: ParsedImage[]) => void;
  className: string;
  disabled: boolean;
  accept: string;
}) {
  const fileInputRef = useRef<HTMLInputElement>();

  function getImgSrc(input: HTMLInputElement) {
    return new Promise<ParsedImage[]>((resolve) => {
      const imgs: ParsedImage[] = [];

      let count = 0;
      const len = input.files.length;
      function handleOnload(e: ProgressEvent<FileReader>, file: File) {
        if (
          !imgs.find((i) => e.target.result === i.src) &&
          !images?.find((i) => e.target.result === i.src)
        ) {
          const image = new window.Image();
          image.src = e.target.result as string;
          image.onload = () => {
            imgs.push({
              src: e.target.result as string,
              file,
              w: image.width,
              h: image.height,
            });
            count += 1;
            if (count === len) {
              resolve(imgs);
              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
            }
          };
        } else {
          count += 1;
        }
      }
      if (input.files && len) {
        for (let i = 0; i < len; i += 1) {
          const reader = new window.FileReader();
          reader.onload = (e) => {
            handleOnload(e, input.files[i]);
          };
          reader.readAsDataURL(input.files[i]);
        }
      }
    });
  }
  function fileChange(e: React.ChangeEvent<HTMLInputElement>) {
    getImgSrc(e.currentTarget).then((imgs) => {
      onChange(imgs);
    });
  }
  return (
    <>
      <label
        style={{
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          marginBottom: 0,
          cursor: disabled ? "not-allowed" : "pointer",
          textAlign: "center",
        }}
        className={`${className ? ` ${className} ` : ""}btn btn-success btn-sm${
          disabled ? " disabled" : ""
        }`}
        htmlFor={id}
      >
        <Plus />
        <input
          disabled={disabled}
          ref={fileInputRef}
          multiple
          id={id}
          style={{
            opacity: 0,
            width: 0,
          }}
          hidden
          type="file"
          accept={accept}
          onChange={fileChange}
        />
      </label>
    </>
  );
}

// ImageUpload.defaultProps = {
//   id: "file-input",
//   className: "",
//   images: [],
//   disabled: false,
//   accept: "image/*",
// };

// ImageUpload.propTypes = {
//   id: PropTypes.string,
//   className: PropTypes.string,
//   images: PropTypes.arrayOf(ImagePropType),
//   onChange: PropTypes.func.isRequired,
//   disabled: PropTypes.bool,
//   accept: PropTypes.string,
// };

export default ImageUpload;
