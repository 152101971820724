import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab2 from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DataTable } from "components";
import { useFieldsState } from "providers/FieldsProvider";
import { useSubfieldColumns } from "./Subfields/SubfieldsListView/useSubfieldColumns";
import { useSubfields } from "api/useSubfields";
import { useParams } from "react-router-dom";
import { IUrlParams } from "types";
import { useOrgState } from "providers";
import { ISubfield } from "types/ISubfield";
import { ConfirmAlert } from "lib";

function a11yProps(index: number) {
  return {
    id: `conservation-tab-${index}`,
    "aria-controls": `conservation-tabpanel-${index}`,
  };
}

export function FieldSubfieldTableTabs({
  onChange,
}: {
  onChange: (_d: { fieldIds: string[]; subfieldIds: string[] }) => void;
}) {
  const { org } = useParams<IUrlParams>();
  const { t } = useTranslation();
  const { fieldListState } = useFieldsState();
  const { season } = useOrgState();
  const subfieldsQ = useSubfields(org, season?.id);
  const { subfieldTypeHeader, SubfieldsNonRxCols } = useSubfieldColumns();
  const [selectedFieldIds, setSelectedFieldIds] = useState<string[]>();
  const [selectedSubfieldIds, setSelectedSubfieldIds] = useState<string[]>();
  const [rowRef, setRowRef] = useState<HTMLElement>();
  const [showAlert, setShowAlert] = useState<string>();
  const [activeTabKey, setActiveTabKey] = useState(0);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTabKey}
          onChange={(_e, newVal) => {
            setActiveTabKey(newVal);
          }}
          aria-label={`${t("inventory.fields.title")} / ${t(
            "inventory.subfields.title"
          )} tabs`}
        >
          <Tab2 label={t("inventory.fields.title")} {...a11yProps(0)} />
          <Tab2 label={t("inventory.subfields.title")} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Tab.Container defaultActiveKey="0" activeKey={activeTabKey}>
        <Tab.Content>
          <Tab.Pane eventKey="0">
            <DataTable
              isSelectable
              disableGroupSelect
              data={fieldListState?.data}
              columns={[
                { Header: t("common.farm"), accessor: "farm" },
                { Header: t("common.field"), accessor: "name" },
              ]}
              onSelectionChange={(items: { original: { id: string } }[]) => {
                const ids = items.map((d) => d?.original?.id);
                setSelectedFieldIds(ids);
                onChange &&
                  onChange({
                    fieldIds: ids,
                    subfieldIds: selectedSubfieldIds,
                  });
              }}
              validateRowSelect={(
                row: { original: { id: string; name: string } },
                target: HTMLElement
              ) => {
                if (subfieldsQ.data) {
                  const fieldIds = subfieldsQ.data
                    .filter((d) => selectedSubfieldIds?.includes(d.id))
                    .map((d) => d.fieldId);
                  if (fieldIds.includes(row.original.id)) {
                    setRowRef(target);
                    setShowAlert(
                      t("inventory.operations.cannotSelectField", {
                        fieldName: row?.original?.name || t("common.field"),
                      })
                    );
                    return false;
                  }
                  return true;
                }
                return true;
              }}
            />
          </Tab.Pane>
          <Tab.Pane eventKey={"1"}>
            <DataTable
              isSelectable
              disableGroupSelect
              onRowContract={() => {
                setShowAlert("");
              }}
              onRowExpand={() => {
                setShowAlert("");
              }}
              validateRowSelect={(
                row: { original: { fieldId: string; field: string } },
                target: HTMLElement
              ) => {
                if (selectedFieldIds?.includes(row?.original?.fieldId)) {
                  setRowRef(target);
                  setShowAlert(
                    t("inventory.operations.cannotSelectSubfield", {
                      fieldName: row?.original?.field || t("common.field"),
                    })
                  );
                  return false;
                }
                return true;
              }}
              onSelectionChange={(data: { original: ISubfield }[]) => {
                setShowAlert("");
                const ids = data.reduce((acc, curr) => {
                  if (curr?.original?.id) {
                    acc.push(curr?.original?.id);
                  }
                  return acc;
                }, []);
                setSelectedSubfieldIds(ids);
                onChange &&
                  onChange({
                    fieldIds: selectedFieldIds,
                    subfieldIds: ids,
                  });
              }}
              data={subfieldsQ.data}
              columns={SubfieldsNonRxCols.concat([subfieldTypeHeader])}
              groupBy={["subfieldGroupKey"]}
            />
          </Tab.Pane>
        </Tab.Content>
        <ConfirmAlert
          title={t("common.invalidSelection")}
          message={showAlert}
          cancelText=""
          confirmText="OK"
          onConfirm={() => {
            setShowAlert("");
          }}
          target={rowRef}
          show={Boolean(showAlert)}
        />
      </Tab.Container>
    </>
  );
}
