import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import SummaryCardView from "components/SummaryCardView";

export default function FieldsSummaryView() {
  const { fieldsSummary } = useOrgState();
  const { t } = useTranslation();

  const count =
    fieldsSummary?.fieldCount || fieldsSummary?.fieldCount === 0
      ? fieldsSummary?.fieldCount
      : "--";
  const area =
    fieldsSummary?.totalArea || fieldsSummary?.totalArea === 0
      ? fieldsSummary?.totalArea
      : "--";

  return (
    <>
      <Row className="row align-items-center m-3">
        <Col className="d-flex flex-column h-100">
          <SummaryCardView
            value={count?.toLocaleString()}
            label={t("inventory.fields.totalFields").toUpperCase()}
            backgroundColor="primary"
          />
        </Col>
        <Col className="d-flex flex-column h-100">
          <SummaryCardView
            value={area?.toLocaleString()}
            label={`${
              fieldsSummary?.totalAreaUom?.toUpperCase() || t("common.area")
            }`}
            backgroundColor="success"
          />
        </Col>
      </Row>
    </>
  );
}
