import React from "react";
import { Form } from "react-bootstrap";
import { Control, Controller } from "react-hook-form";

export default function FormField({
  wrapper: Wrapper = Form.Label,
  label,
  className,
  inputClassName,
  control,
  rules,
  render,
  name,
  children,
  defaultValue,
  htmlFor,
  ...rest
}: {
  wrapper?: React.ElementType;
  label?: React.ReactNode;
  className?: string;
  inputClassName?: string;
  control?: Control;
  rules?: unknown;
  // TODO: fix this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: any;
  name?: string;
  children?: React.ReactNode;
  defaultValue?: unknown;
  htmlFor?: string;
}) {
  return (
    <>
      <Wrapper
        htmlFor={htmlFor}
        className={`input-label mt-3${className ? ` ${className}` : ""}`}
        {...rest}
      >
        <div className="label">{label}</div>
        <div
          className={`input-container${
            inputClassName ? ` ${inputClassName}` : ""
          }`}
        >
          {/* if no control from useForm, but render method - 
          call render with noop methods since it needs to be handled manually
          */}
          {render && !control
            ? render({
                onChange: () => {},
                onBlur: () => {},
              })
            : null}
          {/* pass it to the useForm Controller */}
          {control ? (
            <>
              <Controller
                rules={rules}
                name={name}
                control={control}
                render={render}
                defaultValue={defaultValue}
              />
              {children}
            </>
          ) : null}
          {/* no control or render method, just render children */}
          {!render && !control ? children : null}
        </div>
      </Wrapper>
    </>
  );
}
