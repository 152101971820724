import { useTranslation } from "react-i18next";

interface RowValue {
  area?: number;
  areaUom?: string;
  original?: { area?: number; areaUom?: string };
}

function AreaCell({
  value,
  row,
  data,
}: {
  value: number;
  row: RowValue;
  data: RowValue[];
}) {
  // check for areaUom
  const uom =
    row?.areaUom ??
    row?.original?.areaUom ??
    (data?.length && data[0]?.areaUom) ??
    "";
  return `${value?.toLocaleString?.() ?? value} ${uom ?? ""}`;
}

export default function useAreaColumn({
  aggregate,
  disableGlobalFilter,
}: { aggregate?: boolean; disableGlobalFilter?: boolean } = {}) {
  const { t } = useTranslation();
  return {
    accessor: "area",
    disableGlobalFilter,
    Header: t("common.area"),
    ...(aggregate
      ? {
          aggregate: "sum",
        }
      : {}),
    Cell: AreaCell,
  };
}
