import React from "react";
import PropTypes from "prop-types";

export default function NestedLegendItem({ item }) {
  return (
    <div>
      <h4 style={{ textTransform: "none" }}>{item.title}</h4>
      <table className="w-100 ml-1">
        <tbody>
          {item.data.map((d) => {
            return (
              <tr
                key={`${d.name}-${d.legendSymbol}`}
                className="d-flex align-items-center"
              >
                <td className="pb-1">{d.name}</td>
                <td className="pb-1 ml-auto">{d.legendSymbol}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

NestedLegendItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        legendSymbol: PropTypes.node,
      })
    ).isRequired,
  }).isRequired,
};
