import React, { useEffect } from "react";
import { Link, Route, useLocation, useParams } from "react-router-dom";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import { useOrgState } from "providers";
import { useTranslation } from "react-i18next";
import { IconButton } from "components";
import { useOperationsDispatch, useOperationsState } from "providers";
import { useInventoryDispatch } from "providers";
import HarvestedOperationDetails from "./HarvestedOperationDetails";
import PlantedOperationDetails from "./PlantedOperationDetails";
import CreateOrEditOperation from "views/InventoryView/Operations/CreateOrEditOperation";
import AppliedOperationDetails from "./AppliedOperationDetails";
import TilledOperationDetails from "./TilledOperationDetails";
import OperationEvents from "./OperationEvents";

export default function OperationDetails() {
  const { t } = useTranslation();
  const { rootUrl } = useOrgState();
  const { fitInventoryBounds } = useInventoryDispatch();
  const {
    fetchEvents,
    fetchOperationById,
    fetchEventTemplates,
  } = useOperationsDispatch();
  const { currentOperations, currentEditFtr } = useOperationsState();

  const { itemId, type }: { itemId: string; type: string } = useParams();
  const location = useLocation();
  const isEdit =
    location.pathname.includes("/edit") &&
    !location.pathname.includes("/records/");
  const isAdd =
    location.pathname.includes("/add") &&
    !location.pathname.includes("/records/");
  function getOpTypeText() {
    switch (type) {
      case "planted": {
        return t("inventory.operations.plantedOperations");
      }
      case "applied": {
        return t("inventory.operations.appliedOperations");
      }
      case "harvested": {
        return t("inventory.operations.harvestedOperations");
      }
      case "tilled": {
        return t("inventory.operations.tilledOperations");
      }
      default: {
        return t("inventory.operations.title");
      }
    }
  }
  // get parent operationId for working with events
  const op = currentOperations?.find((co: { id: string }) => co.id === itemId);
  const operationId = op?.operationId;

  useEffect(() => {
    if (operationId) {
      fetchEvents(operationId);
    }
  }, [fetchEvents, operationId]);

  useEffect(() => {
    if (currentEditFtr) {
      fitInventoryBounds({ geojson: currentEditFtr });
    }
  }, [currentEditFtr, fitInventoryBounds]);

  useEffect(() => {
    if (itemId) {
      // NOTE: fires several times on deep link
      // but this should be resolved when big data updates
      // are completed
      fetchOperationById(itemId);
    }
  }, [fetchOperationById, itemId]);

  useEffect(() => {
    fetchEventTemplates();
  }, [fetchEventTemplates]);

  return (
    <div className="p-3 h-100 d-flex flex-column">
      <Breadcrumb listProps={{ className: "p-0" }}>
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{
            to: `${rootUrl}/inventory/operations/${type}`,
          }}
        >
          {getOpTypeText()}
        </Breadcrumb.Item>
        {!isAdd ? (
          <Breadcrumb.Item
            active={itemId && !isEdit}
            linkAs={Link}
            linkProps={{
              to: `${rootUrl}/inventory/operations/${type}/items/${itemId}`,
            }}
          >
            {t("common.details")}
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item active>{t("common.add")}</Breadcrumb.Item>
        )}

        {itemId && isEdit ? (
          <Breadcrumb.Item active>{t("common.edit")}</Breadcrumb.Item>
        ) : null}
        {itemId && !isEdit ? (
          <Link
            className="ml-auto"
            to={`${rootUrl}/inventory/operations/${type}/items/${itemId}/edit`}
          >
            <IconButton title={t("common.edit")} size="lg" icon="edit" />
          </Link>
        ) : null}
      </Breadcrumb>
      <Route
        exact
        path={[
          `/:org/:season/inventory/operations/harvested/items/:itemId`,
          `/:org/:season/inventory/operations/harvested/items/:itemId/records/add`,
          `/:org/:season/inventory/operations/harvested/items/:itemId/records/:eventId/edit`,
        ]}
      >
        <HarvestedOperationDetails />
        <Row className="overflow-auto h-100 mt-5">
          <Col
            className="h-100"
            style={{
              overflow: "auto",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <OperationEvents type={type} />
          </Col>
        </Row>
      </Route>
      <Route
        exact
        path={[`/:org/:season/inventory/operations/planted/items/:itemId`]}
      >
        <PlantedOperationDetails />
      </Route>
      <Route
        exact
        path={[`/:org/:season/inventory/operations/applied/items/:itemId`]}
      >
        <AppliedOperationDetails />
      </Route>
      <Route
        exact
        path={[`/:org/:season/inventory/operations/tilled/items/:itemId`]}
      >
        <TilledOperationDetails />
      </Route>
      <Route
        exact
        path={[
          `/:org/:season/inventory/operations/:type/items/:itemId/edit`,
          `/:org/:season/inventory/operations/:type/add`,
        ]}
      >
        <CreateOrEditOperation />
      </Route>
    </div>
  );
}
