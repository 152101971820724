import React, { useCallback } from "react";
import useFetchWithToken from "components/useFetchWithToken";
import { useOrgLookup } from "components/useOrgLookup";
import { IFetchResponse, ISubfield } from "types";
import { Feature, FeatureCollection } from "geojson";
import {
  MutateOptions,
  UseMutationResult,
  UseQueryResult,
} from "@tanstack/react-query";
import { useSubfields } from "api/useSubfields";
import {
  ISubfieldMutateParams,
  useMutateSubfield,
} from "api/useSubfieldsGeodata";

type MutateMethod = (
  _d: ISubfield,
  _opts?: MutateOptions<IFetchResponse<ISubfield>, Error, ISubfieldMutateParams>
) => void;

interface ISubfieldsProviderState {
  subfields: ISubfield[];
  subfieldsState: UseQueryResult;
  subfieldsGeodataState: IFetchResponse<FeatureCollection>;
  subfieldTypes: {
    id: string;
    nameEn: string;
    name: string;
  }[];
  subfieldMutation: UseMutationResult<IFetchResponse<ISubfield>, Error>;
  currentEditFtr: Feature;
  isFetchingData: boolean;
}

interface ISubfieldsProviderDispatch {
  saveSubfield: MutateMethod;
  deleteSubfield: MutateMethod;
  resetGeodata: (_d: unknown) => void;
}

const SubfieldsStateContext = React.createContext<ISubfieldsProviderState>({
  subfields: null,
  subfieldsState: null,
  subfieldsGeodataState: null,
  subfieldTypes: null,
  subfieldMutation: null,
  currentEditFtr: null,
  isFetchingData: false,
});
const SubfieldsDispatchContext = React.createContext<
  ISubfieldsProviderDispatch
>({
  saveSubfield: null,
  deleteSubfield: null,
  resetGeodata: null,
});

function SubfieldsProvider({
  children,
  org,
  seasonId,
  subfieldType,
  editId,
}: {
  children: React.ReactNode;
  org: string;
  seasonId: string;
  subfieldType: string;
  editId?: string;
}) {
  const subfieldsQuery = useSubfields(org, seasonId, subfieldType);

  const subfieldMutation = useMutateSubfield({
    org,
    seasonId,
    type: subfieldType,
  });

  const { items: subfieldTypes } = useOrgLookup({
    org,
    url: `${org}/lookups/subfieldtypes`,
  });

  const {
    state: subfieldsGeodataState,
    setDataState: resetGeodata,
  } = useFetchWithToken<FeatureCollection>();

  const currentEditSubfield = editId
    ? // TODO: fix types
      subfieldsQuery.data?.find((f: { id: string }) => f.id === editId)
    : null;
  const currentEditFtr = editId
    ? subfieldsGeodataState?.data?.features?.find(
        (f: Feature) => f.id === editId
      )
    : null;
  if (currentEditFtr && currentEditSubfield) {
    Object.assign(currentEditFtr.properties, currentEditSubfield);
  }

  return (
    <SubfieldsStateContext.Provider
      value={{
        subfields: subfieldsQuery.data,
        subfieldsState: subfieldsQuery,
        subfieldMutation,
        subfieldsGeodataState,
        subfieldTypes,
        currentEditFtr,
        isFetchingData: subfieldsQuery.isLoading,
      }}
    >
      <SubfieldsDispatchContext.Provider
        value={{
          saveSubfield: useCallback(
            (d, opts) => {
              subfieldMutation.mutate({ body: d }, opts);
            },
            [subfieldMutation]
          ),
          deleteSubfield: useCallback(
            (body, opts) => {
              subfieldMutation.mutate(
                {
                  method: "DELETE",
                  body,
                },
                opts
              );
            },
            [subfieldMutation]
          ),
          resetGeodata,
        }}
      >
        {children}
      </SubfieldsDispatchContext.Provider>
    </SubfieldsStateContext.Provider>
  );
}

function useSubfieldsState() {
  const context = React.useContext(SubfieldsStateContext);
  if (context === undefined) {
    throw new Error(
      "useSubfieldsState must be used within a SubfieldsProvider"
    );
  }
  return context;
}

function useSubfieldsDispatch() {
  const context = React.useContext(SubfieldsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useSubfieldsDispatch must be used within a SubfieldsProvider"
    );
  }
  return context;
}

export { SubfieldsProvider, useSubfieldsState, useSubfieldsDispatch };
