import React, { useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch } from "providers/InventoryProvider";
import FieldForm from "./FieldForm";
import { useFieldsDispatch } from "../../../../providers/FieldsProvider";

export default function CreateOrEditField() {
  const { editId } = useParams();
  const { t } = useTranslation();
  const { rootUrl } = useOrgState();
  const { resetSaveField, resetDeleteField } = useFieldsDispatch();
  const {
    enableDraw,
    disableDraw,
    setDrawOptions,
    highlightLayerFeatures,
  } = useInventoryDispatch();

  useEffect(() => {
    // remove highlighted/selected features on edit
    highlightLayerFeatures([]);
    enableDraw();
    return disableDraw;
  }, [enableDraw, disableDraw, highlightLayerFeatures]);

  useEffect(() => {
    setDrawOptions({
      controls: {
        trash: true,
        polygon: true,
        rectangle: true,
      },
      defaultMode: "draw_rectangle",
    });
  }, [setDrawOptions]);

  // when leaving form, reset the fetch state
  useEffect(() => {
    return () => {
      resetSaveField();
      resetDeleteField();
    };
  }, [resetSaveField, resetDeleteField]);

  return (
    <div>
      <Breadcrumb listProps={{ className: "p-0 mb-0" }}>
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: `${rootUrl}/inventory/fields` }}
        >
          {t("inventory.fields.fieldList")}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {!editId
            ? t("inventory.fields.createField")
            : t("inventory.fields.editField")}
        </Breadcrumb.Item>
      </Breadcrumb>
      <FieldForm />
    </div>
  );
}
