import React from "react";
import PropTypes from "prop-types";
// import { useTranslation } from 'react-i18next';

export const TotalMassTooltip = ({ active, payload }) => {
  // const { t } = useTranslation();
  if (active && payload?.length) {
    const { name, totalMass, totalMassUom } = payload[0]?.payload;
    return (
      <div
        className="bg-light text-dark p-2"
        style={{ border: "solid 1px", opacity: 0.9, minWidth: "175px" }}
      >
        <h6
          className="text-center mb-0"
          style={{ fontWeight: "bold" }}
        >{`${name}`}</h6>
        {totalMass ? (
          <>
            <div>{`${Number(totalMass).toLocaleString()} ${totalMassUom}`}</div>
          </>
        ) : null}
      </div>
    );
  }

  return null;
};
export default TotalMassTooltip;

TotalMassTooltip.defaultProps = {
  active: false,
  payload: null,
};

TotalMassTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({}),
    })
  ),
};
