import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import { OperationsProvider } from "providers/OperationsProvider";
import { SubfieldsProvider } from "providers/SubfieldsProvider";
import { OperationsView } from "./OperationsView";
import { useInventoryDispatch } from "../../../providers/InventoryProvider";

export function Operations() {
  const { type, itemId } = useParams();
  const { org, season } = useOrgState();
  const { setInventoryFtrs } = useInventoryDispatch();

  // clear inventory features on unmount
  useEffect(() => {
    return () => {
      setInventoryFtrs(null);
    };
  }, [setInventoryFtrs]);

  return (
    <SubfieldsProvider org={org} seasonId={season?.id}>
      <OperationsProvider
        org={org}
        seasonId={season?.id}
        type={type}
        itemId={itemId}
      >
        <OperationsView />
      </OperationsProvider>
    </SubfieldsProvider>
  );
}
