import React from "react";
import SVGIconWrapper from "./IconWrapper";

export default function EquipmentIcon(props) {
  return (
    <SVGIconWrapper {...props}>
      <svg viewBox="-10 0 431 416">
        <path d="M291,232.25a35.14,35.14,0,1,0,35.15,35.14A35.15,35.15,0,0,0,291,232.25Zm0,56.22a21.09,21.09,0,1,1,21.08-21.08A21.1,21.1,0,0,1,291,288.47Z" />
        <path d="M350.39,207.47,335,84.65h5.25a7,7,0,1,0,0-14.07H185.6a7,7,0,0,0,0,14.07h9.69l-30.65,98.69s-1,0-3,0c-6.2,0-21.46.21-39.33,2V169.11H136.4V112.75H122.34v-14h-.07a21.09,21.09,0,0,0-21.08-21.09,7,7,0,1,0,0,14.06,7,7,0,0,1,7,7h.07v14h-14v56.36h14V187c-29.2,4.17-60.73,13-70.28,31.62v69.84a63.26,63.26,0,0,0,126.12,7h47.36a84.36,84.36,0,1,0,138.91-88ZM108.28,126.82h14.06v28.24H108.28ZM150,295.61A48.72,48.72,0,0,1,140.6,318a7,7,0,0,0-9.93,9.89,48.59,48.59,0,0,1-22.41,9.26,7,7,0,0,0-14,0,48.58,48.58,0,0,1-22.42-9.26A7,7,0,0,0,61.92,318a48.72,48.72,0,0,1-9.35-22.42,7,7,0,0,0,0-14,48.52,48.52,0,0,1,9.26-22.52,7,7,0,0,0,9.91-9.94,48.68,48.68,0,0,1,22.53-9.36,7,7,0,0,0,14,0,48.68,48.68,0,0,1,22.53,9.36,7,7,0,0,0,9.91,9.94A48.59,48.59,0,0,1,150,281.58a7,7,0,0,0,0,14Zm57.91-14.17H164.12A63.25,63.25,0,0,0,52.21,248.52l-.13-25.92c6.67-8.62,24.6-16,50.1-20.4,25.77-4.48,52-4.83,59.49-4.83,1.73,0,2.69,0,2.7,0l10.56.19,3.13-10.08L210,84.65H320.8L334.68,195.2A83.4,83.4,0,0,0,319.61,188L308.4,98.7h-88L185.44,211.16h42.72a84.47,84.47,0,0,0-20.3,70.28ZM305,184.2a84.35,84.35,0,0,0-60.57,12.9H204.52l26.2-84.35H296Zm50.16,112.1a7,7,0,0,0-7,12.14,72.55,72.55,0,0,1-7.37,8.64,71.77,71.77,0,0,1-8.66,7.38,7,7,0,0,0-12.13,7,69.89,69.89,0,0,1-21.9,5.84,7,7,0,0,0-14,0,69.82,69.82,0,0,1-21.9-5.84,7,7,0,0,0-12.13-7,71.11,71.11,0,0,1-16-16,7,7,0,0,0-7-12.14,69.9,69.9,0,0,1-5.85-21.9,7,7,0,0,0,0-14,69.84,69.84,0,0,1,5.85-21.89,7,7,0,0,0,7-12.14,70.74,70.74,0,0,1,7.36-8.65,71.68,71.68,0,0,1,8.66-7.37,7,7,0,0,0,12.14-7,69.6,69.6,0,0,1,21.9-5.83,7,7,0,0,0,14,0,69.87,69.87,0,0,1,21.9,5.83,7,7,0,0,0,12.13,7,71.11,71.11,0,0,1,16,16,7,7,0,0,0,7,12.14A69.91,69.91,0,0,1,361,260.37a7,7,0,0,0,0,14A69.9,69.9,0,0,1,355.13,296.3Z" />
        <path d="M101.26,267.39a21.09,21.09,0,1,0,21.08,21.08A21.08,21.08,0,0,0,101.26,267.39Zm0,28.11a7,7,0,1,1,7-7A7,7,0,0,1,101.26,295.5Z" />
      </svg>
    </SVGIconWrapper>
  );
}
