import React from "react";
import { useTranslation } from "react-i18next";
import useAreaColumn from "components/Table/useAreaColumn";

export function useAssetReportColumns() {
  const { t } = useTranslation();

  const cidHeader = { Header: "ID", accessor: "cid" };

  const areaSumHeader = useAreaColumn({ aggregate: true });

  const lengthSumHeader = {
    Header: t("common.distance"),
    id: "length",
    aggregate: "sum",
    // aggregateValue: (vals, row) => {
    //   return row?.original?.length;
    // },
    accessor: "length",
    Cell: ({
      value,
      data,
    }: {
      value: number;
      data: { lengthUom: string }[];
    }) => {
      const lengthUom = data[0]?.lengthUom;
      const val = Number(value?.toFixed(2));
      return `${val.toLocaleString()} ${lengthUom || ""}`;
    },
  };

  const fieldHeader = {
    Header: t("common.field"),
    accessor: "fieldFarmGroupKey",
    styles: {
      maxWidth: "175px",
      minWidth: "175px",
    },
    Cell: function FieldCol({ value }: { value?: string }) {
      const [field, farm] = value?.split(":");
      return (
        <div className="float-left d-flex flex-column mr-1">
          <span>{`${field}`}</span>
          <small>{farm}</small>
        </div>
      );
    },
  };

  const bornOnUtcDateHeader = {
    Header: t("common.date"),
    id: "bornOnUtc",
    accessor: (row: { bornOnUtc: string }) => {
      return new Date(row.bornOnUtc).toLocaleDateString();
    },
  };
  const assetStatusHeader = {
    Header: t("common.status"),
    accessor: "assetStatus",
  };
  const ageHeader = { Header: t("common.age"), accessor: "age" };
  const breedHeader = {
    Header: t("common.breed"),
    accessor: "assetAnimalBreed",
  };
  const notesHeader = { Header: t("common.notes"), accessor: "description" };
  const nameHeader = { Header: t("common.name"), accessor: "name" };

  return {
    getFixedCols: (geomType: "line" | "polygon" | "point") => {
      const base = [
        fieldHeader,
        bornOnUtcDateHeader,
        { Header: t("common.type"), accessor: "fixedAssetType" },
        nameHeader,
        ageHeader,
        notesHeader,
      ];
      switch (geomType) {
        case "line": {
          base.splice(4, 0, lengthSumHeader);
          return base;
        }
        case "polygon": {
          base.splice(4, 0, areaSumHeader);
          return base;
        }
        default: {
          return base;
        }
      }
    },
    plantCols: [
      fieldHeader,
      {
        Header: t("common.crop"),
        accessor: "subtype",
      },
      bornOnUtcDateHeader,
      cidHeader,
      assetStatusHeader,
      ageHeader,
      { Header: t("common.notes"), accessor: "description" },
    ],
    animalCols: [
      fieldHeader,
      {
        Header: t("inventory.assets.bornOn"),
        id: "bornOnUtc",
        accessor: (row: { bornOnUtc: string }) => {
          return new Date(row.bornOnUtc).toLocaleDateString();
        },
      },
      nameHeader,
      cidHeader,
      assetStatusHeader,
      ageHeader,
      breedHeader,
      notesHeader,
    ],
    fieldHeader,
  };
}
