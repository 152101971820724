import React, { useEffect, useState } from "react";
import Loader from "lib/Loader";
import { useTranslation } from "react-i18next";
import { useAssetsState } from "providers/AssetsProvider";
import AssetsOverviewChart from "./AssetOverviewChart";

export default function AssetsOverview() {
  const { t } = useTranslation();
  const { assetsBySeasonQuery } = useAssetsState();
  const [data, setData] = useState(
    assetsBySeasonQuery?.data?.filter((d) => d.count)
  );
  const isLoading = assetsBySeasonQuery.isLoading;

  useEffect(() => {
    if (!isLoading) {
      setData(assetsBySeasonQuery?.data?.filter((d) => d.count));
    }
  }, [assetsBySeasonQuery, isLoading]);
  return (
    <div className="p-3">
      <h3>
        {t("inventory.assets.title")} {t("inventory.title")}
      </h3>
      {!isLoading && data?.length ? <AssetsOverviewChart data={data} /> : null}
      {!isLoading && !data?.length ? (
        <p className="text-center">{t("common.noData")}</p>
      ) : null}
      {isLoading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : null}
    </div>
  );
}
