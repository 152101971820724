import React from "react";
import "./Loader.scss";

export default function Loader({ color }: { color?: string }) {
  const divStyle = {
    backgroundColor: color,
  };

  return (
    <div className="lds-ellipsis">
      <div
        className={!color ? "loader-dot" : null}
        style={color ? divStyle : null}
      />
      <div
        className={!color ? "loader-dot" : null}
        style={color ? divStyle : null}
      />
      <div
        className={!color ? "loader-dot" : null}
        style={color ? divStyle : null}
      />
      <div
        className={!color ? "loader-dot" : null}
        style={color ? divStyle : null}
      />
    </div>
  );
}
