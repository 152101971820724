import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

export default function PrivacyAndTOS({
  className = "mt-3",
  linksOnly,
}: {
  className?: string;
  linksOnly?: boolean;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <p className={className}>
      {!linksOnly ? `${t("auth.bySigningInYouAgreeToOur")} ` : null}
      <a
        className={theme.palette.mode === "dark" ? "text-warning" : ""}
        href="https://platform.cultivateagi.com/legal/terms-of-service.html"
      >
        {t("auth.termsOfService")}
      </a>
      {` ${t("common.and")} `}{" "}
      <a
        className={theme.palette.mode === "dark" ? "text-warning" : ""}
        href="https://platform.cultivateagi.com/legal/privacy-policy.html"
      >
        {t("auth.privacyPolicy")}
      </a>
      {!linksOnly ? `.` : null}
    </p>
  );
}
