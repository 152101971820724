import React, { useCallback, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Route, useParams } from "react-router-dom";
import { useOrgState, useOrgDispatch } from "providers/OrgProvider";
import usePrevious from "components/usePrevious";
import { useOperationsState } from "providers/OperationsProvider";
import PlantedOperationForm from "./Planted/PlantedOperationForm";
import AppliedOperationForm from "./Applied/AppliedOperationForm";
import HarvestOperationForm from "./Harvested/HarvestOperationForm";
import TillOperation from "./Tilled/TilledOperationForm";
import { FieldSubfieldTableTabs } from "views/InventoryView/FieldSubfieldTableTabs";

export default function CreateOrEditOperation() {
  const { rootUrl } = useOrgState();
  const { type, itemId } = useParams();
  const { currentEditFtr } = useOperationsState();
  const prevEditFtr = usePrevious(currentEditFtr);
  const { getCropTypes, getProducts } = useOrgDispatch();
  const [operationData, setOperationData] = useState({});
  const [selectedIds, setSelectedIds] = useState({
    fieldIds: operationData?.fieldId ? [operationData.fieldId] : null,
    subfieldIds: null,
  });
  const prevOpData = usePrevious(operationData);
  // const { showMap, hideMap, setColSizes } = useInventoryDispatch();
  const baseRoute = `${rootUrl}/inventory/operations/${type}`;

  // useEffect(() => {
  //   setColSizes([12, 0]);
  //   return () => {
  //     setColSizes([7, 5]);
  //   };
  // }, [setColSizes]);

  // useEffect(() => {
  //   hideMap();
  //   return showMap;
  // }, [hideMap, showMap]);

  const getCancelHref = useCallback(
    (onDelete) => {
      if (itemId && !onDelete) {
        return `${baseRoute}/items/${itemId}`;
      }
      return baseRoute;
    },
    [baseRoute, itemId]
  );

  useEffect(() => {
    if (currentEditFtr !== prevEditFtr) {
      if (currentEditFtr) {
        setOperationData({ ...operationData, ...currentEditFtr.properties });
      } else {
        setOperationData(null);
      }
    }
  }, [currentEditFtr, prevEditFtr, operationData]);

  useEffect(() => {
    if (operationData !== prevOpData) {
      if (operationData?.fieldId) {
        setSelectedIds({
          fieldIds: [operationData.fieldId],
          subfieldIds: null,
        });
      }
    }
  }, [operationData, prevOpData]);

  // fetch crop types if not already in org provider
  useEffect(() => {
    getCropTypes();
  }, [getCropTypes]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return (
    <div className="px-3 pb-3 overflow-hidden d-flex flex-column flex-grow-1">
      <Row className="overflow-auto flex-grow-1">
        <Col md={!itemId ? 6 : 12} className="h-100 overflow-auto">
          <Route
            path={[
              `/:org/:season/inventory/operations/planted/items/:itemId/edit`,
              `/:org/:season/inventory/operations/planted/add`,
            ]}
            exact
          >
            <PlantedOperationForm
              selectedIds={selectedIds}
              getCancelHref={getCancelHref}
            />
          </Route>
          <Route
            path={[
              `/:org/:season/inventory/operations/applied/items/:itemId/edit`,
              `/:org/:season/inventory/operations/applied/add`,
            ]}
            exact
          >
            <AppliedOperationForm
              selectedIds={selectedIds}
              getCancelHref={getCancelHref}
            />
          </Route>
          <Route
            path={[
              `/:org/:season/inventory/operations/harvested/items/:itemId/edit`,
              `/:org/:season/inventory/operations/harvested/add`,
            ]}
            exact
          >
            <HarvestOperationForm
              selectedIds={selectedIds}
              getCancelHref={getCancelHref}
            />
          </Route>
          <Route
            path={[
              `/:org/:season/inventory/operations/tilled/items/:itemId/edit`,
              `/:org/:season/inventory/operations/tilled/add`,
            ]}
            exact
          >
            <TillOperation
              selectedIds={selectedIds}
              getCancelHref={getCancelHref}
            />
          </Route>
        </Col>
        {!itemId ? (
          <Col md={6} className="h-100 overflow-auto d-flex flex-column">
            <FieldSubfieldTableTabs
              onChange={(d) => {
                setSelectedIds(d);
              }}
            />
          </Col>
        ) : null}
      </Row>
    </div>
  );
}
