import React from "react";
import SVGIconWrapper from "./IconWrapper";

export default function FixedIcon(props) {
  return (
    <SVGIconWrapper {...props}>
      <svg viewBox="-10 0 431 416">
        <path d="M342.39,195.61V169H324.63V113.85L293.55,82.77l-31.08,31.08V169h-22.2V113.85L209.19,82.77l-31.07,31.08V169h-22.2V113.85L124.84,82.77,93.76,113.85V169H76v26.64H93.76V280H76V306.6H93.76V351h62.16V306.6h22.2V351h62.15V306.6h22.2V351h62.16V306.6h17.76V280H324.63V195.61Zm-17.76-17.76h8.88v8.88h-8.88ZM93.76,297.72H84.88v-8.88h8.88Zm0-111H84.88v-8.88h8.88ZM147,342.12h-44.4V117.53l22.2-22.2,22.2,22.2Zm31.08-44.4h-22.2v-8.88h22.2Zm0-17.76h-22.2V195.61h22.2Zm0-93.23h-22.2v-8.88h22.2Zm53.27,155.39H187V117.53l22.2-22.2,22.2,22.2Zm31.08-44.4h-22.2v-8.88h22.2Zm0-17.76h-22.2V195.61h22.2Zm0-93.23h-22.2v-8.88h22.2ZM315.75,280v62.16h-44.4V117.53l22.2-22.2,22.2,22.2Zm17.76,8.88v8.88h-8.88v-8.88Z" />
      </svg>
    </SVGIconWrapper>
  );
}
