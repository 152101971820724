import React from "react";
import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";
import { useOperationsState } from "providers/OperationsProvider";
import SummaryCardView from "components/SummaryCardView";

export default function OperationsSummaryView({ onSelect, activeType }) {
  const { operationsSummary } = useOperationsState();

  return (
    <>
      <Row className="align-items-center mx-3">
        {operationsSummary ? (
          operationsSummary?.map((d) => {
            const opType = d?.operationEn?.toLowerCase();
            return (
              <Col key={d.operation} lg={6} xl={3} className="my-1 p-1">
                <SummaryCardView
                  className="w-100 h-100"
                  value={d.totalArea?.toLocaleString()}
                  as={Button}
                  variant=""
                  active={opType === activeType}
                  onClick={() => {
                    onSelect(d);
                  }}
                  label={
                    <>
                      <div>{d?.operation?.toUpperCase()}</div>
                      <div>{d?.totalAreaUom?.toUpperCase()}</div>
                    </>
                  }
                  childrenLocation="left"
                  style={{ backgroundColor: d.color }}
                >
                  {/* <div
                    className="mr-2 operation-summary-icon"
                    style={{ border: 'solid 1px', borderRadius: '50%' }}
                  >
                    {op === 'planted' ? (
                      <PlantedIcon />
                    ) : op === 'harvested' ? (
                      <HarvestedIcon />
                    ) : op === 'applied' ? (
                      <AppliedIcon />
                    ) : op === 'tilled' ? (
                      <TilledIcon />
                    ) : null}
                  </div> */}
                </SummaryCardView>
              </Col>
            );
          })
        ) : (
          <>
            {[1, 2, 3, 4].map((i) => (
              <Col key={i} lg={6} xl={3} className="my-1 p-1">
                <SummaryCardView
                  value="--"
                  label={
                    <>
                      <div>--</div>
                      <div>--</div>
                    </>
                  }
                />
              </Col>
            ))}
          </>
        )}
      </Row>
    </>
  );
}
OperationsSummaryView.defaultProps = {
  onSelect: () => {},
  activeType: "",
};
OperationsSummaryView.propTypes = {
  onSelect: PropTypes.func,
  activeType: PropTypes.string,
};
