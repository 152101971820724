import React from "react";
import { useParams } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import { AssetsProvider } from "providers/AssetsProvider";
import { useURLSearchParams } from "components";
import { AssetsView } from "./AssetsView";

export function Assets() {
  const { type, subtype, fieldId, itemId } = useParams();
  const { org, season } = useOrgState();
  const query = useURLSearchParams();

  return (
    <AssetsProvider
      org={org}
      seasonId={season?.id}
      type={type}
      editId={itemId}
      subtype={subtype}
      fieldId={fieldId}
      groupId={query.get("groupId")}
    >
      <AssetsView />
    </AssetsProvider>
  );
}
