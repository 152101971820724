import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useOperationsState } from "providers/OperationsProvider";
import { useOrgState } from "providers/OrgProvider";
import OperationsSummaryView from "views/InventoryView/Operations/SummaryView";
import OperationsTimeSeries from "components/Charts/OperationsTimeSeries";
import { groupBy, sortByDate } from "components/Charts/utils";
import Loader from "lib/Loader";

function parseOperations(ops, theme, opTypeColor) {
  const dataKeys = [];
  const dataByOps = [];
  if (ops && ops.length) {
    sortByDate(ops, "beginDateUtc");
    const x = groupBy(ops, "operationId");
    const y = Object.keys(x);
    y.forEach((key) => {
      const data = x[key];
      const dataObj = {
        area: 0,
        totalMass: 0,
        children: data,
      };
      data.forEach((d, idx) => {
        const themeItem = theme?.find((t) => t.id === d.id);
        // TODO: get first begin date
        d.cropColor = themeItem?.cropColor;
        d.productColor = themeItem?.productColor;
        dataObj[`area-${idx}`] = Number(d.area.toFixed(2));
        dataObj[`totalMass-${idx}`] = d.totalMass
          ? Number(d.totalMass.toFixed(2))
          : 0;
        dataObj.cropColor = themeItem?.cropColor;
        dataObj.productColor = themeItem?.productColor;
        dataObj.productTypeColor = themeItem?.productTypeColor;
        dataObj.depthColor = themeItem?.depthColor;
        d.depthColor = themeItem?.depthColor;
        dataObj.color = opTypeColor;
        dataObj.beginDateUtc = d.beginDateUtc;
        dataObj.area += d.area;
        dataObj.totalMass += d.totalMass;
        dataObj.operationId = d.operationId;
        dataObj.farm = d.farm;
        dataObj.field = d.field;
        dataObj.areaUom = d.areaUom;
        dataObj.totalMassUom = d.totalMassUom;
        dataObj.rate = d.rate;
        dataObj.rateUom = d.rateUom;
        dataObj.type = d.type;
        dataObj.product = d.product;
        dataObj.depth = `${d.depth} ${d.depthUom}` || "";
        if (!dataKeys.find((dk) => dk.key === `area-${idx}`)) {
          dataKeys.push({
            operationId: d.operationId,
            key: `area-${idx}`,
            color: themeItem?.opTypeColor,
            cropColor: themeItem?.cropColor,
            productColor: themeItem?.productColor,
            productTypeColor: themeItem?.productTypeColor,
            depthColor: themeItem?.depthColor,
            ...d,
          });
        }
      });
      dataObj.area = Number(dataObj.area.toFixed(2));
      dataByOps.push(dataObj);
    });
  }

  return { dataByOps, dataKeys };
}

export default function OperationsOverview({
  operationType,
  setOperationType,
}) {
  const { t } = useTranslation();
  const {
    currentOperations,
    operationsSummary,
    currentTheme,
    isFetchingData,
  } = useOperationsState();
  const { configQuery } = useOrgState();
  const { uoms } = configQuery?.data || {};
  const currColor = operationsSummary?.find(
    (o) => o?.operationEn?.toLowerCase() === operationType
  )?.color;
  const [chartData, setChartData] = useState(
    parseOperations(currentOperations, currentTheme, currColor)
  );

  useEffect(() => {
    // console.log('parsing chart data');
    setChartData(parseOperations(currentOperations, currentTheme, currColor));
  }, [currentOperations, currentTheme, currColor]);
  return (
    <Container className="p-3">
      <h3>
        {t("inventory.operations.title")} {t("inventory.title")}
      </h3>
      <OperationsSummaryView
        activeType={operationType}
        onSelect={(d) => {
          setOperationType(d.operationEn?.toLowerCase());
        }}
      />
      <Row>
        <Col
          sm={12}
          style={{
            height: "250px",
          }}
        >
          {chartData?.dataByOps?.length ? (
            <OperationsTimeSeries
              operationType={operationType}
              data={chartData.dataByOps}
              yDataKey={operationType === "harvested" ? "totalMass" : "area"}
              yDataUnit={
                operationType === "harvested"
                  ? uoms?.mass?.label
                  : uoms?.area?.label
              }
              yDataLabel={
                operationType === "harvested"
                  ? `${t("common.totalMass")} ${
                      uoms?.mass?.label ? `(${uoms?.mass?.label})` : ""
                    }`
                  : `${t("common.area")} ${
                      uoms?.area?.label ? `(${uoms?.area?.label})` : ""
                    }`
              }
              timeDataKey="beginDateUtc"
            />
          ) : null}
          {!chartData?.dataByOps?.length && !isFetchingData ? (
            <p className="text-center">{t("common.noData")}</p>
          ) : null}
          {isFetchingData ? (
            <div className="text-center">
              <Loader />
            </div>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}

OperationsOverview.propTypes = {
  operationType: PropTypes.oneOf(["planted", "applied", "harvested", "tilled"])
    .isRequired,
  setOperationType: PropTypes.func.isRequired,
};
