import React from "react";
import PropTypes from "prop-types";
import { Tab, Nav } from "react-bootstrap";
import { Link, Route, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers";
import OperationRecordsListView from "../OperationRecordsListView";
import CreateOrEditEvent from "views/InventoryView/Operations/OperationDetails/OperationEvents/CreateOrEditEvent";

export default function OperationEvents({ type }: { type: string }) {
  const { itemId } = useParams<{
    itemId: string;
  }>();
  const { t } = useTranslation();
  const { rootUrl } = useOrgState();
  return (
    <Tab.Container activeKey="records">
      <Nav variant="tabs" className="text-uppercase">
        <Nav.Item>
          <Nav.Link
            eventKey="records"
            as={Link}
            to={`${rootUrl}/inventory/operations/${type}/items/${itemId}`}
            className="btn-primary"
          >
            {t("common.records")}
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content className="p-3">
        <Tab.Pane eventKey="records" className="h-100">
          <Route
            path={[`/:org/:season/inventory/operations/:type/items/:itemId`]}
            exact
          >
            <OperationRecordsListView />
          </Route>
          <Route
            exact
            path={[
              `/:org/:season/inventory/operations/:type/items/:itemId/records/add`,
              `/:org/:season/inventory/operations/:type/items/:itemId/records/:eventId/edit`,
            ]}
          >
            <CreateOrEditEvent />
          </Route>
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
}

OperationEvents.propTypes = {
  type: PropTypes.oneOf(["planted", "harvested", "applied", "tilled"])
    .isRequired,
};
