import React from "react";

export function Chevron({
  direction, // default chevron is downward pointing
  opacity,
  double,
}: {
  direction?: "up" | "down" | "left" | "right";
  double?: boolean;
  opacity?: number;
}) {
  return (
    <svg
      style={{
        opacity,
        minWidth: "24px",
        // transition: "transform 300ms",
        transform:
          direction === "up"
            ? "rotate(180deg)"
            : direction === "left"
            ? "rotate(90deg)"
            : direction === "right"
            ? "rotate(270deg)"
            : "rotate(360deg)",
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={"currentColor"}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g {...(double ? { transform: "translate(0, -3)" } : {})}>
        {double ? (
          <g transform="translate(0, 6)">
            <polyline points="6 9 12 15 18 9"></polyline>
          </g>
        ) : null}
        <polyline points="6 9 12 15 18 9"></polyline>
      </g>
    </svg>
  );
}
