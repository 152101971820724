import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useConservationState } from "providers/ConservationProvider";
import SummaryCardView from "components/SummaryCardView";

export default function ConservationSummaryView() {
  const { summaryState } = useConservationState();
  const { t } = useTranslation();
  const summary = summaryState?.data;
  const count =
    summary?.aoiCount || summary?.aoiCount === 0 ? summary?.aoiCount : "--";
  const area =
    summary?.totalArea || summary?.totalArea === 0 ? summary?.totalArea : "--";

  return (
    <>
      <Row className="row align-items-center m-3">
        <Col className="d-flex flex-column h-100">
          <SummaryCardView
            value={count?.toLocaleString()}
            label={`${t("common.carbon")} \n ${t("common.areas")}`}
            backgroundColor="primary"
          />
        </Col>
        <Col className="d-flex flex-column h-100">
          <SummaryCardView
            value={area?.toLocaleString()}
            label={`${t("common.carbon")} \n ${summary?.totalAreaUom || ""}`}
            backgroundColor="success"
          />
        </Col>
      </Row>
    </>
  );
}
