import React from "react";
import { useOrgState } from "providers/OrgProvider";
import { Link, useParams } from "react-router-dom";
import { ChevronRight } from "react-feather";
import { useAssetColumns } from "components/useAssetColumns";
import { useAssetsByTypeSummary } from "api/useAssetSummaries";
import DataTable from "components/DataTable";

export function AssetsByTypeTable() {
  const { type } = useParams();
  const { org, rootUrl } = useOrgState();
  const { subtype: subtypeColumns } = useAssetColumns();
  const dict = {
    plant: subtypeColumns.plant,
    fixed: subtypeColumns.fixed,
    equipment: subtypeColumns.equipment,
    animal: subtypeColumns.animal,
  };
  const query = useAssetsByTypeSummary(org, type);

  const actionsColumn = {
    Header: "",
    id: "actions",
    isSortable: false,
    styles: {
      width: "50px",
    },
    accessor: function Cell(row) {
      // const y = row.subtype.toLowerCase().replaceAll(' ', '-');
      const encoded = encodeURIComponent(row.subtype);
      return (
        <Link
          className="btn btn-link btn-sm float-left"
          style={{ color: "inherit" }}
          to={`${rootUrl}/inventory/assets/${type}/${encoded}`}
        >
          <span className="row-expander">
            <ChevronRight />
          </span>
        </Link>
      );
    },
  };

  const getColumnsBySubtype = (type) => {
    return [actionsColumn].concat(dict[type] || []);
  };

  return (
    <DataTable
      isLoading={query.isLoading}
      paging={query?.data?.length > 100}
      tableProps={{ size: "sm" }}
      data={query?.data || []}
      columns={getColumnsBySubtype(type)}
    />
  );
}
