import React from "react";
import "./DatePicker.scss";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { Form } from "react-bootstrap";
import es from "date-fns/locale/es";
import fr from "date-fns/locale/fr";
import { IDatePickerProps } from "types";
registerLocale("es", es);
registerLocale("fr", fr);

export default function DatePicker({
  variant,
  className,
  locale,
  ...rest
}: IDatePickerProps) {
  return (
    <ReactDatePicker
      autoComplete="off"
      className={`cai-datepicker ${className} ${variant}`}
      customInput={<Form.Control />}
      dateFormat="P"
      locale={locale}
      dropdownMode={"select"}
      showMonthDropdown
      showYearDropdown
      showPopperArrow={false}
      // update placeholder based on locale
      placeholderText={locale === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY"}
      {...rest}
    />
  );
}
