import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, Route, useParams, useHistory } from "react-router-dom";
import { Button, Container, Card } from "react-bootstrap";
import { useOrgState } from "providers/OrgProvider";
import { useAssetsState, useAssetsDispatch } from "providers/AssetsProvider";
import { usePrevious, sortObjArrByFields } from "components";
import { PageColumn, PageLayout } from "views/MainLayout";
import { useTranslation } from "react-i18next";
import { List } from "react-feather";
import {
  getDefaultExpandedRows,
  parseFieldsFromData,
  getAssetIcon,
  parseSubtypesFromData,
} from "../utils";
import ReportView from "../ReportView";
import ReportFieldsSelect from "../ReportFieldsSelect";
import RecordsReport from "./RecordsReport";
import { useAssetReportColumns } from "./useAssetReportColumns";
import Loader from "lib/Loader";

export default function AssetsReports({ setAssetType }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { rootUrl, orgData, season, fields } = useOrgState();
  const { reportType } = useParams();
  const prevFields = usePrevious(fields);
  const prevReportType = usePrevious(reportType);
  const { assetsBySeasonQuery, listBySeasonState } = useAssetsState();
  const { fetchListBySeason } = useAssetsDispatch();
  const prevAssets = usePrevious(listBySeasonState?.data);
  const [expanded, setExpanded] = useState();
  const [filteredFields, setFilteredFields] = useState();
  const [subtypes, setSubtypes] = useState();
  const [subtypeItem, setSubtypeItem] = useState();
  const [selectedAssets, setSelectedAssets] = useState(listBySeasonState?.data);
  const { plantCols, animalCols, getFixedCols } = useAssetReportColumns();

  const currentAssetType = assetsBySeasonQuery?.data?.find(
    (o) => o.assetEn?.toLowerCase() === reportType
  );

  const getAssetReportCols = useCallback(
    (activeType) => {
      const cols = [
        {
          id: "records",
          Header: "Records",
          accessor: function RecordAccessor(row) {
            return (
              <Button
                style={{ margin: "0 auto" }}
                size="sm"
                onClick={() => {
                  history.push(
                    `${rootUrl}/insights/reports/assets/${activeType}/${row.id}`
                  );
                }}
                variant="outline-secondary"
              >
                <List size={18} />
              </Button>
            );
          },
        },
      ];
      if (activeType?.toLowerCase() === "plant") {
        return plantCols.concat(cols);
      }
      if (activeType?.toLowerCase() === "fixed") {
        const colsByGeom = getFixedCols(subtypeItem?.geometryType);
        return colsByGeom ? colsByGeom.concat(cols) : cols;
      }
      if (activeType?.toLowerCase() === "animal") {
        return animalCols.concat(cols);
      }
      return null;
    },
    [animalCols, plantCols, subtypeItem, getFixedCols, history, rootUrl]
  );

  useEffect(() => {
    fetchListBySeason();
  }, [fetchListBySeason]);

  useEffect(() => {
    if (reportType !== prevReportType) {
      setSubtypeItem(null);
      setAssetType(reportType);
    }
  }, [reportType, prevReportType, setAssetType]);

  useEffect(() => {
    // parse fields list and subtype options from new assets
    if (listBySeasonState?.data !== prevAssets || fields !== prevFields) {
      const newFields = parseFieldsFromData(listBySeasonState?.data, fields);
      const assetSubtypes = parseSubtypesFromData(listBySeasonState?.data);
      setSubtypes(assetSubtypes);
      const sorted = sortObjArrByFields(newFields, "farm", "field");
      setFilteredFields(sorted);
    }
  }, [listBySeasonState?.data, prevAssets, reportType, fields, prevFields]);

  useEffect(() => {
    setExpanded(getDefaultExpandedRows(selectedAssets));
  }, [selectedAssets]);

  return (
    <PageLayout className="cai-reports h-100">
      <PageColumn lg={6} xl={5} className="hide-print">
        <Card
          className="overflow-auto"
          style={{ maxHeight: "175px", minHeight: "105px" }}
        >
          <Container className="report-type-container">
            <h3>{t("insights.selectReportType")}</h3>
            <div className="d-flex select-reports-container justify-content-md-center">
              {assetsBySeasonQuery.isLoading ? (
                <div style={{ margin: "0 auto" }}>
                  <Loader />
                </div>
              ) : null}
              {assetsBySeasonQuery?.data?.map((i) => {
                const asset = i.assetEn?.toLowerCase();
                const Icon = getAssetIcon(asset);
                return (
                  <span
                    key={asset}
                    className={`report-link${
                      reportType === asset ? " active" : ""
                    }`}
                  >
                    <Link to={`${rootUrl}/insights/reports/assets/${asset}`}>
                      {Icon ? (
                        <Icon
                          size={40}
                          className="report-icon"
                          style={{
                            borderRadius: "50%",
                            backgroundColor: i.color,
                            margin: "0 auto",
                          }}
                        />
                      ) : null}
                      <div className="mt-2 small">{i.asset}</div>
                    </Link>
                  </span>
                );
              })}
            </div>
          </Container>
        </Card>
        <Card className="mt-3" style={{ flex: 1, minHeight: "250px" }}>
          <ReportFieldsSelect
            subtypes={subtypes}
            isLoading={
              assetsBySeasonQuery?.isLoading || listBySeasonState?.isLoading
            }
            fields={filteredFields}
            onSubtypeChange={(subtype) => {
              setSubtypeItem(subtype);
            }}
            onChange={(data) => {
              const selectedFields =
                filteredFields?.filter((co) => {
                  return data.find((d) => d.fieldId === co.fieldId);
                }) || [];
              // loop selected fields and get their children
              // (filtered by subtype, if selected)
              const filteredAssets = selectedFields.reduce((curr, item) => {
                let filteredChildren = item.children;
                if (subtypeItem) {
                  filteredChildren = item.children?.filter((c) => {
                    return c.subtypeId === subtypeItem.value;
                  });
                }
                return curr.concat(filteredChildren || []);
              }, []);
              setSelectedAssets(filteredAssets);
            }}
          />
        </Card>
      </PageColumn>
      <PageColumn lg={6} xl={7} className="print-full-width">
        <Route path="/:org/:season/insights/reports/assets/:reportType?" exact>
          <ReportView
            // isLoading={isFetchingData}
            org={orgData?.name}
            season={season}
            color={currentAssetType?.color}
            titleIcon={getAssetIcon(currentAssetType?.assetEn?.toLowerCase())}
            title={
              currentAssetType
                ? `${currentAssetType.asset} ${t("insights.report")}`
                : ""
            }
            columns={getAssetReportCols(
              currentAssetType?.assetEn?.toLowerCase()
            )}
            data={selectedAssets}
            groupBy={["fieldFarmGroupKey"]}
            expanded={expanded}
            sortBy={[
              {
                id: "name",
                desc: false,
              },
            ]}
          />
        </Route>
        <Route
          path="/:org/:season/insights/reports/assets/:reportType/:assetId"
          exact
        >
          <RecordsReport
            currentAssetType={currentAssetType}
            currentAssets={listBySeasonState?.data}
          />
        </Route>
      </PageColumn>
    </PageLayout>
  );
}

AssetsReports.defaultProps = {};

AssetsReports.propTypes = {
  setAssetType: PropTypes.func.isRequired,
};
