import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ConfirmAlert } from "lib";
import useOrgLookupRQ from "components/useOrgLookupRQ";
import { useOrgState } from "providers/OrgProvider";
import { FormField, FormWrapper, Select } from "components";
import { useInventoryDispatch, useInventoryState } from "providers";
import { useAssetsState } from "providers/AssetsProvider";
import {
  AssetStatusIDFormField,
  BornOnUTCFormField,
  CIDFormField,
  DescriptionFormField,
  FieldFormField,
} from "./FormFields";
import { IMapboxDrawControls, ISelectOption, IUrlParams } from "types";
import { IFixedAsset, IFixedAssetType } from "types/IAssetType";

const defaultValues = {
  id: "",
  fieldId: "",
  cid: "",
  name: "",
  fixedAssetTypeId: "",
  bornOnUtc: "",
  assetStatusId: "",
  description: "",
  geometry: "",
} as IFixedAsset;

function getDrawOpts(geom: "point" | "line" | "polygon") {
  const opts = { trash: true } as IMapboxDrawControls;
  let mode = "simple_select";
  if (geom === "point") {
    opts.point = true;
    mode = "draw_point";
  }
  if (geom === "line") {
    opts.line_string = true;
    mode = "draw_line_string";
  }
  if (geom === "polygon") {
    opts.polygon = true;
    mode = "draw_polygon";
  }
  return { controls: opts, mode } as {
    controls: unknown;
    mode: string;
    features: unknown[];
  };
}
export default function FixedAssetForm({
  assetStatuses,
  handleSave,
  handleDelete,
  getCancelHref,
}: {
  assetStatuses: ISelectOption[];
  handleSave: (_d: IFixedAsset, reset: () => void, isDirty: boolean) => void;
  handleDelete: (
    id: string,
    reset: () => void,
    defaultValues: IFixedAsset
  ) => void;
  getCancelHref: () => string;
}) {
  const { org } = useOrgState();
  const { itemId } = useParams<IUrlParams>();
  const { t } = useTranslation();
  const confirmRef = useRef();
  const {
    currentEditFtr,
    fixedAssetTypes,
    deleteInventoryItemState,
    saveInventoryItemState,
  } = useAssetsState();
  const { drawData } = useInventoryState();
  const { zoomToField, setDrawOptions } = useInventoryDispatch();
  const methods = useForm({
    defaultValues,
  });
  const [geomType, setGeomType] = useState<"point" | "line" | "polygon">();
  const [showConfirmGeomChange, setShowConfirmGeomChange] = useState<
    IFixedAssetType
  >(null);
  const {
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    control,
    errors,
    setValue,
  } = methods;
  const { id, fixedAssetTypeId } = watch();
  const { data: items } = useOrgLookupRQ<IFixedAssetType>(
    `/${org}/lookups/assetfixedtypes`
  );

  const fixedAssetTypeOpts = fixedAssetTypes?.map((a: IFixedAssetType) => ({
    value: a.id,
    label: a.name,
    geometryType: a.geometryType,
  }));

  // when fixedAssetTypeId changes, update the draw options
  // based on the asset type's geometry type
  useEffect(() => {
    const fixedAssetType = items?.find((i) => i.id === fixedAssetTypeId);
    const opts = getDrawOpts(fixedAssetType?.geometryType);
    setDrawOptions(opts);
  }, [setDrawOptions, fixedAssetTypeId, items]);
  // set the geometry type for edit feature
  // to handle changes
  useEffect(() => {
    if (currentEditFtr) {
      let type: "point" | "line" | "polygon";
      if (currentEditFtr.geometry.type.includes("Point")) {
        type = "point";
      }
      if (currentEditFtr.geometry.type.includes("LineString")) {
        type = "line";
      }
      if (currentEditFtr.geometry.type.includes("Polygon")) {
        type = "polygon";
      }
      setGeomType(type);
    }
  }, [currentEditFtr]);

  async function onSave(d: IFixedAsset) {
    const data = { ...d, geometry: d.geometry ? JSON.parse(d.geometry) : "" };
    // server doesn't want these props if not set
    if (!data.id) {
      delete data.id;
    }
    if (!data.fieldId) {
      delete data.fieldId;
    }
    return handleSave(data, reset, formState.isDirty);
  }
  return (
    <FormWrapper
      //TODO:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      methods={methods}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={getCancelHref()}
      saveState={saveInventoryItemState}
      deleteState={deleteInventoryItemState}
      onDelete={async () => {
        handleDelete(id, reset, defaultValues);
      }}
      onSubmit={handleSubmit(onSave)}
    >
      <Form.Group>
        <FormField
          label={`${t("common.type")} *`}
          name="fixedAssetTypeId"
          control={control}
          rules={{ required: true }}
          htmlFor="fixed-asset-type-select"
          render={(props: {
            value: string;
            onChange: (_id: string) => void;
          }) => (
            <>
              <Select
                id="fixed-asset-type-select"
                options={fixedAssetTypeOpts}
                value={fixedAssetTypeOpts?.find(
                  (ct) => ct.value === props.value
                )}
                onChange={(item: IFixedAssetType) => {
                  const geomChanged = geomType !== item?.geometryType;
                  if (geomChanged && itemId) {
                    // CONFIRM CHANGE FIRST
                    setShowConfirmGeomChange(item);
                  } else {
                    // UPDATE DRAW TOOLS
                    const opts = getDrawOpts(item.geometryType);
                    if (geomChanged) {
                      opts.features = null;
                    }
                    props.onChange(item?.value || "");
                    setGeomType(item?.geometryType);
                    setDrawOptions(opts);
                  }
                }}
                isClearable
              />
              <span className="w-100 d-flex justify-content-center">
                <span className="" ref={confirmRef} />
              </span>
            </>
          )}
        >
          {errors?.fixedAssetTypeId ? (
            <Form.Text className="text-danger">
              {t("common.requiredField")}
            </Form.Text>
          ) : null}
        </FormField>
        <FieldFormField
          control={control}
          onChange={(item) => {
            zoomToField(item?.value);
          }}
        />
        <FormField label={`${t("common.name")} *`} htmlFor="asset-name">
          <Form.Control
            required
            maxLength={50}
            name="name"
            id="asset-name"
            ref={register}
          />
        </FormField>
        <CIDFormField register={register} />
        <BornOnUTCFormField
          label={`${t("inventory.assets.createdDate")} *`}
          control={control}
        />
        <AssetStatusIDFormField
          control={control}
          assetStatusOpts={assetStatuses}
          isError={Boolean(errors?.assetStatusId)}
        />
        <DescriptionFormField register={register} />
        {errors?.geometry ? (
          <Form.Text className="text-danger">
            {t("common.geometryRequired")}
          </Form.Text>
        ) : null}
      </Form.Group>
      <ConfirmAlert
        target={confirmRef.current}
        show={Boolean(showConfirmGeomChange)}
        message={t("inventory.assets.confirmTypeChange")}
        onConfirm={() => {
          setShowConfirmGeomChange(null);
          const opts = getDrawOpts(showConfirmGeomChange.geometryType);
          opts.features = null;
          setValue("fixedAssetTypeId", showConfirmGeomChange?.value || "");
          setGeomType(showConfirmGeomChange?.geometryType);
          setDrawOptions(opts);
        }}
        onCancel={() => {
          setShowConfirmGeomChange(null);
        }}
      />
    </FormWrapper>
  );
}
