import React from "react";
import SVGIconWrapper from "./IconWrapper";

export default function HarvestedIcon(props) {
  return (
    <SVGIconWrapper {...props}>
      <svg viewBox="0 0 431 416">
        <path d="M354.09,195.88l-16.83-7.21a33,33,0,0,0-34.18,4.91c-15.84-15.06-37.59-23-58.94-24.61V154.63c8.2-6.32,26.54-22.4,26.54-39.57,0-9-4.87-20-14.87-33.69a184.15,184.15,0,0,0-14-16.82,8.3,8.3,0,0,0-12,0,184.15,184.15,0,0,0-14,16.82C205.89,95,201,106.05,201,115.06c0,17.17,18.34,33.25,26.53,39.57V169a106.5,106.5,0,0,0-12.19,1.63,27.83,27.83,0,0,0-38.84,5.31c-.14.18,2.63-3.69-43.81,61.31L80.39,271.75a8.29,8.29,0,0,0,9.13,13.84l53.62-35.37a8.38,8.38,0,0,0,2.18-2.1l44.45-62.23a11.24,11.24,0,0,1,17.85,13.67c-.23.31,1.34-2.05-22.91,34.75a8.3,8.3,0,0,0,6.92,12.85h52.53a58.07,58.07,0,0,0,37.49-13.73l31.84-26.94a16.49,16.49,0,0,1,17.24-2.58l7.45,3.19-42.93,61.33a43.35,43.35,0,0,1-30.07,18.12C173.84,298,180.88,296.87,179,297.81l-61.91,31.51a8.29,8.29,0,0,0,7.53,14.78l60.61-30.85,82-10.25a59.92,59.92,0,0,0,41.6-25.06l48.78-69.69a8.28,8.28,0,0,0-3.52-12.37ZM235.86,82.73c8.89,10.62,18.23,24.62,18.23,32.33s-10.37,18.52-18.24,25c-7.86-6.46-18.24-17.13-18.24-25C217.61,107.38,227,93.36,235.86,82.73Zm35.08,138a41.46,41.46,0,0,1-26.78,9.81H207l14-21.29a27.67,27.67,0,0,0,4.5-23.46c24.22-2.67,48.83,4.36,64.77,18.55Z" />
      </svg>
    </SVGIconWrapper>
  );
}
