import { Form } from "react-bootstrap";
import { randomPoint } from "@turf/random";
import booleanContains from "@turf/boolean-contains";
import turfFlatten from "@turf/flatten";
import Select from "components/Select";
import { FormField } from "components";
import BaseForm from "components/Forms/BaseForm";
import { useFieldsState } from "providers";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IField, ISelectOption } from "types";
import { useAssetsState } from "providers";
import { IAnimalBreed } from "types/IAssetType";
import useOrgLookupRQ from "components/useOrgLookupRQ";
import { useOrgState } from "providers/OrgProvider";
import { ManualSwitchFormField } from "./FormFields";
import DatePicker from "components/DatePicker";
import { useInventoryDispatch, useInventoryState } from "providers";
import { useAssetsDispatch } from "providers";
import { csvToArray } from "components/csvToArray";
import { setFeatureCollectionProps } from "app-utils";
import { Feature, FeatureCollection } from "geojson";
import { AllGeoJSON } from "@turf/helpers";
import { AlertCircle } from "react-feather";
import { GeoDataTable } from "components";
import { validateFeatureCounts } from "./asset-upload-utils";
import { useAssetsUpload } from "api/useAssetsUpload";
import { useHistory } from "react-router-dom";

function isInside(ftrs: Feature[], ftr: Feature) {
  try {
    return ftrs.find((f) => {
      return booleanContains(f, ftr);
    });
  } catch (e) {
    console.error("Failed verifying isInside", e.message);
  }
}

function getRandomPoints(count: number, geom: AllGeoJSON) {
  try {
    const flattened = turfFlatten(geom);
    const fc = randomPoint(count, { bbox: geom.bbox });
    const final = [];
    for (let i = 0; i < fc.features.length; i++) {
      let pnt = fc.features[i];
      while (!isInside(flattened.features, pnt)) {
        pnt = randomPoint(1, { bbox: geom.bbox }).features[0];
      }
      final.push(pnt);
    }
    return final;
  } catch (e) {
    console.error("Failed generating points", e.message);
  }
}

export default function AnimalAssetUpload({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { createGroup } = useAssetsDispatch();
  const { org, season } = useOrgState();
  const {
    getFieldGeodata,
    zoomToField,
    setInventoryFtrs,
    highlightLayerFeatures,
  } = useInventoryDispatch();
  const { inventoryMap, ftrsClicked } = useInventoryState();
  const {
    fieldsByFarm,
  }: {
    fieldsByFarm: { label: string; options: IField[] }[];
  } = useFieldsState();
  const { assetGroups, animalTypes, animalBreeds } = useAssetsState() as {
    animalBreeds: IAnimalBreed[];
    animalTypes: ISelectOption[];
    assetGroups: ISelectOption[];
  };
  const { data: assetStatuses } = useOrgLookupRQ(
    `/${org}/lookups/assetstatuses`
  );

  const [uploadedFc, setUploadedFc] = useState<FeatureCollection>();
  const [parsedKeys, setParsedKeys] = useState<ISelectOption[]>([]);
  const [parseError, setParseError] = useState("");
  const [selectedField, setSelectedField] = useState<ISelectOption>();
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [assetStatusId, setAssetStatusId] = useState("");
  const [selectedAnimalType, setSelectedAnimalType] = useState<ISelectOption>();
  const [selectedBreedType, setSelectedBreedType] = useState<IAnimalBreed>();
  const [filteredBreeds, setFilteredBreeds] = useState<IAnimalBreed[]>(
    animalBreeds
  );
  const [selectedGroup, setSelectedGroup] = useState<ISelectOption>();
  // prop mappings
  const [cidProp, setCidProp] = useState("");
  const [bornOnProp, setBornOnProp] = useState("");
  const [nameProp, setNameProp] = useState("");
  const [descriptionProp, setDescriptionProp] = useState("");
  const [isFemaleProp, setIsFemaleProp] = useState("");
  // manual inputs
  const [manualDate, setManualDate] = useState<Date>();
  const [manualDescription, setManualDescription] = useState("");
  const [manualName, setManualName] = useState("");
  const [manualIsFemale, setManualIsFemale] = useState<"male" | "female">();

  const { uploadAssets, ...query } = useAssetsUpload({
    org,
    seasonId: season.id,
    type: "animal",
  });

  function reset() {
    setParsedKeys([]);
    setParseError("");
    setIsFemaleProp("");
    setCidProp("");
    setNameProp("");
    setBornOnProp("");
    setDescriptionProp("");
    setManualDescription("");
    setManualDate(null);
    setManualName("");
    setManualIsFemale(null);
  }

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    reset();

    if (!file) {
      setUploadedFc(null);
      return;
    }
    const nameSplit = file.name.split(".");
    const ext = nameSplit[nameSplit.length - 1];
    if (!["txt", "csv"].includes(ext)) {
      setUploadedFc(null);
      setParseError(t("common.couldNotLoadFile"));
      return;
    }
    // ~4mb
    if (file.size > 4000000) {
      setUploadedFc(null);
      setParseError(`${t("common.maxFileSize")}: 4mb`);
      return;
    }
    try {
      const reader = new FileReader();

      reader.onload = function (e) {
        const text = e.target.result as string;
        const asArr = csvToArray(text);
        if (asArr.length > 1000) {
          setParseError(
            `${t("common.maxFeatureCount")}: ${(1000).toLocaleString()}`
          );
          setUploadedFc(null);
          return;
        }
        const fc = {
          type: "FeatureCollection",
          features: asArr.map((a, idx) => ({
            type: "Feature",
            id: a.id ?? `genId-${idx}`,
            geometry: null,
            properties: a,
          })),
        } as FeatureCollection;

        const ftr = getFieldGeodata(selectedField?.value);
        if (ftr) {
          const final = getRandomPoints(asArr.length, ftr.geometry);
          fc.features.forEach((f, i) => {
            f.geometry = final[i].geometry;
          });
          setUploadedFc({ ...fc });
          const ftrsWithColor = setFeatureCollectionProps(fc, {
            color: selectedBreedType?.color || "#eee",
            assetAnimalBreed: selectedBreedType?.name ?? "??",
          });
          setInventoryFtrs(ftrsWithColor);
        } else {
          setUploadedFc(fc);
        }

        const keys = Object.keys(asArr[0]);
        setParsedKeys(
          keys.map((k) => ({
            value: k,
            label: k,
          }))
        );
      };
      reader.readAsText(file);
    } catch (e) {
      setParseError(t("common.couldNotLoadFile"));
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "cid",
        Cell: ({ value }: { value: string | number }) => {
          return `${value ?? ""}`;
        },
      },
      {
        Header: `${t("common.name")}`,
        accessor: "name",
        Cell: ({ value }: { value: string | number }) => {
          const val = value || value === 0 ? `${value}` : null;
          return <div>{val ?? manualName}</div>;
        },
      },
      {
        Header: `${t("inventory.assets.sex")} *`,
        accessor: "isFemale",
        Cell: ({ value }: { value: boolean }) => {
          const val =
            value === true
              ? t("inventory.assets.female")
              : value === false
              ? t("inventory.assets.male")
              : null;
          return (
            <div className={val === null ? "text-danger" : ""}>
              {val ?? (
                <>
                  <AlertCircle />
                </>
              )}
            </div>
          );
        },
      },
      {
        Header: `${t("inventory.assets.bornOn")} *`,
        accessor: "bornOnUtc",
        Cell: ({ value }: { value: string }) => {
          const val = manualDate ? manualDate.toLocaleDateString() : value;
          return (
            <div className={!val ? "text-danger" : ""}>
              {val || (
                <>
                  <AlertCircle />
                </>
              )}
            </div>
          );
        },
      },
      {
        Header: t("common.notes"),
        accessor: "description",
        Cell: ({ value }: { value: string }) => {
          return <div>{value ?? manualDescription}</div>;
        },
      },
    ],
    [t, manualName, manualDate, manualDescription]
  );

  const formattedFC = React.useMemo(() => {
    if (!uploadedFc) {
      return null;
    }
    const formatted = setFeatureCollectionProps(
      uploadedFc,
      {
        assetStatusId,
        fieldId: selectedField?.value,
        assetGroupId: selectedGroupId,
        assetAnimalBreedId: selectedBreedType?.id,
        ...(manualDate
          ? {
              bornOnUtc: manualDate.toISOString(),
            }
          : {}),
        ...(manualDescription ? { description: manualDescription } : {}),
        ...(manualName ? { name: manualName } : {}),
        ...(manualIsFemale
          ? { isFemale: manualIsFemale === "female" ? true : false }
          : {}),
      },
      {
        name: nameProp,
        description: descriptionProp,
        bornOnUtc: bornOnProp,
        cid: cidProp,
        isFemale: isFemaleProp,
      }
    );
    return formatted;
  }, [
    uploadedFc,
    assetStatusId,
    selectedField,
    selectedGroupId,
    selectedBreedType?.id,
    manualDate,
    manualDescription,
    manualName,
    manualIsFemale,
    nameProp,
    descriptionProp,
    bornOnProp,
    cidProp,
    isFemaleProp,
  ]);
  const tableData = React.useMemo(
    () =>
      formattedFC?.features.map((f) => ({ id: f.id, ...f.properties })) ?? [],
    [formattedFC?.features]
  );

  const { invalidCount, validCount } = validateFeatureCounts(formattedFC, [
    "isFemale",
    "bornOnUtc",
  ]);

  React.useEffect(() => {
    if (assetGroups) {
      const grp = assetGroups?.find((f) => f.value === selectedGroupId);
      setSelectedGroup(grp);
    }
  }, [assetGroups, selectedGroupId]);

  return (
    <>
      <BaseForm
        saveState={{
          isLoading: query.isLoading,
          isError: query.isError,
          errorMessage: query.error?.message,
        }}
        cancelHref="./"
        onSubmit={() => {
          if (!uploadedFc) {
            setParseError(t("common.requiredField"));
            return false;
          }
          if (invalidCount) {
            return false;
          }
          return uploadAssets(formattedFC, {
            onSuccess: () => {
              onSuccess();
              history.push("./");
            },
          });
        }}
      >
        <FormField
          label={`${t("common.field")} *`}
          name="fieldId"
          htmlFor="field-select"
        >
          <Select
            isRequired
            isClearable
            id="field-select"
            options={fieldsByFarm || []}
            value={selectedField}
            onChange={(item) => {
              setSelectedField(item);
              if (item?.value) {
                const fld = zoomToField(item.value);
                if (uploadedFc) {
                  const final = getRandomPoints(
                    uploadedFc.features.length,
                    fld.geometry
                  );
                  uploadedFc.features.forEach((f, i) => {
                    f.geometry = final[i].geometry;
                  });
                  const ftrsWithColor = setFeatureCollectionProps(uploadedFc, {
                    color: selectedBreedType?.color || "#eee",
                    assetAnimalBreed: selectedBreedType?.name ?? "??",
                  });
                  setUploadedFc({ ...uploadedFc });
                  setInventoryFtrs(ftrsWithColor);
                }
              }
            }}
          />
        </FormField>

        <FormField
          label={t("common.group")}
          name="assetGroupId"
          htmlFor="animal-group"
        >
          <Select
            allowCreate
            isClearable
            id="animal-group"
            options={assetGroups || []}
            value={selectedGroup}
            onCreateOption={async (name: string) => {
              const res = await createGroup({ name });
              if (res && res.isError) {
                // TODO: notify failed
                console.error("failed to create group");
              } else {
                setSelectedGroupId(res?.data?.id);
              }
            }}
            onChange={(item) => {
              setSelectedGroupId(item?.value);
            }}
          />
        </FormField>

        <FormField label={t("common.type")} htmlFor="animal-type">
          <Select
            isClearable
            id="animal-type"
            options={animalTypes || []}
            value={selectedAnimalType}
            onChange={(e) => {
              setSelectedAnimalType(e);
              let newBreeds = animalBreeds;
              if (e?.value) {
                newBreeds = animalBreeds.filter(
                  (b) => b.assetAnimalTypeId === e.value
                );
                if (selectedBreedType) {
                  if (selectedBreedType.assetAnimalTypeId !== e.value) {
                    setSelectedBreedType(null);
                  }
                }
              }
              setFilteredBreeds(newBreeds);
            }}
          />
        </FormField>

        <FormField
          label={`${t("common.breed")} *`}
          name="assetAnimalBreedId"
          rules={{ required: true }}
          htmlFor="animal-breed-select"
        >
          <Select
            isRequired
            isClearable
            id="animal-breed-select"
            options={
              filteredBreeds?.length ? filteredBreeds : animalBreeds || []
            }
            value={selectedBreedType}
            onChange={(e: IAnimalBreed) => {
              setSelectedBreedType(e);
            }}
          />
        </FormField>

        <FormField label={`${t("common.status")} *`}>
          <Select
            isClearable
            isRequired
            id="status-select"
            name="assetStatusId"
            options={assetStatuses}
            value={assetStatuses?.find((ao) => ao.value === assetStatusId)}
            onChange={(item) => {
              setAssetStatusId(item?.value);
            }}
          />
        </FormField>

        {selectedField && selectedBreedType && assetStatusId ? (
          <>
            <div className="d-flex align-items-center mt-3">
              <Form.Control
                onChange={handleFile}
                type="file"
                accept=".txt, .csv"
              />
              <Form.Text className="text-info ml-1">CSV</Form.Text>
            </div>
            {parseError ? (
              <Form.Text className="text-danger">{parseError}</Form.Text>
            ) : null}
          </>
        ) : null}

        {formattedFC ? (
          <>
            <FormField label="ID">
              <Select
                isClearable
                value={
                  cidProp ? parsedKeys?.find((p) => p.value === cidProp) : null
                }
                options={parsedKeys}
                onChange={(item) => {
                  setCidProp(item?.value);
                }}
              />
            </FormField>

            <ManualSwitchFormField
              id="manual-name"
              label={`${t("common.name")}`}
              onChange={(e) => {
                if (!e.currentTarget.checked) {
                  setManualName("");
                } else {
                  setNameProp("");
                }
              }}
              manualInput={
                <Form.Control
                  maxLength={50}
                  name="name"
                  id="manual-name"
                  onChange={(e) => {
                    setManualName(e.target.value);
                  }}
                />
              }
            >
              <Select
                isClearable
                value={
                  nameProp
                    ? parsedKeys?.find((p) => p.value === nameProp)
                    : null
                }
                options={parsedKeys}
                onChange={(item) => {
                  setNameProp(item?.value);
                }}
              />
            </ManualSwitchFormField>

            <ManualSwitchFormField
              id="manual-sex"
              label={`${t("inventory.assets.sex")} *`}
              onChange={(e) => {
                if (e.currentTarget.checked) {
                  setIsFemaleProp("");
                  setManualIsFemale("male");
                } else {
                  setManualIsFemale(null);
                }
              }}
              manualInput={
                <>
                  <Form.Check
                    inline
                    custom
                    name="animal-sex"
                    type="radio"
                    label={t("inventory.assets.male")}
                    id="sex-male"
                    checked={manualIsFemale === "male"}
                    onChange={(e) => {
                      setManualIsFemale(!e.target.checked ? "female" : "male");
                    }}
                  />
                  <Form.Check
                    inline
                    custom
                    name="animal-sex"
                    type="radio"
                    label={t("inventory.assets.female")}
                    id="sex-female"
                    checked={manualIsFemale === "female"}
                    onChange={(e) => {
                      setManualIsFemale(e.target.checked ? "female" : "male");
                    }}
                  />
                </>
              }
            >
              <Select
                isClearable
                isRequired
                value={
                  isFemaleProp
                    ? parsedKeys?.find((p) => p.value === isFemaleProp)
                    : null
                }
                options={parsedKeys}
                onChange={(item) => {
                  setIsFemaleProp(item?.value);
                }}
              />
            </ManualSwitchFormField>

            <ManualSwitchFormField
              id="born-on-date"
              wrapper={"div"}
              label={`${t("inventory.assets.bornOn")} *`}
              onChange={(e) => {
                if (!e.currentTarget.checked) {
                  setManualDate(null);
                } else {
                  setBornOnProp("");
                }
              }}
              manualInput={
                <DatePicker
                  required
                  maxDate={new Date()}
                  selected={manualDate}
                  onChange={(d: Date) => {
                    setManualDate(d);
                  }}
                />
              }
            >
              <Select
                isRequired
                isClearable
                value={
                  bornOnProp
                    ? parsedKeys?.find((p) => p.value === bornOnProp)
                    : null
                }
                options={parsedKeys}
                onChange={(item) => {
                  setBornOnProp(item?.value);
                }}
              />
            </ManualSwitchFormField>

            <ManualSwitchFormField
              id="manual-description"
              label={t("common.notes")}
              onChange={(e) => {
                if (!e.currentTarget.checked) {
                  setManualDescription(null);
                } else {
                  setDescriptionProp("");
                }
              }}
              manualInput={
                <Form.Control
                  onChange={(e) => {
                    setManualDescription(e.target.value);
                  }}
                  as="textarea"
                  maxLength={255}
                />
              }
            >
              <Select
                isClearable
                value={parsedKeys?.find((p) => p.value === descriptionProp)}
                options={parsedKeys}
                onChange={(item) => {
                  setDescriptionProp(item?.value);
                }}
              />
            </ManualSwitchFormField>
            <Form.Text className={invalidCount ? "text-danger" : "text-info"}>
              {`${t("common.validFeatures")}: ${validCount} /
                  ${formattedFC.features.length}`}
            </Form.Text>
          </>
        ) : null}
      </BaseForm>
      {formattedFC ? (
        <div
          className="inventory-list overflow-auto"
          style={{ minHeight: "400px" }}
        >
          <GeoDataTable
            isFilterable={false}
            highlightByIds={highlightLayerFeatures}
            ftrsClicked={ftrsClicked}
            map={inventoryMap}
            featureCollection={formattedFC}
            data={tableData}
            columns={columns}
          />
        </div>
      ) : null}
    </>
  );
}
