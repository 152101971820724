import React, {
  useEffect,
  useState,
  useCallback,
  ReactElement,
  ChangeEvent,
  useRef,
  createRef,
} from "react";
import { Form } from "react-bootstrap";
import DatePicker from "components/DatePicker";
import { FormWrapper, FormField, Select } from "components";
import {
  useOperationsState,
  useOperationsDispatch,
  useOrgState,
} from "providers";
import { useTranslation } from "react-i18next";
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useForm,
  UseFormMethods,
} from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

function getStepFromDecimals(arr: number[]) {
  return !arr.length
    ? ""
    : arr.reduce((curr, next, idx) => {
        const val = idx === arr.length - 1 ? "1" : "0";
        return curr + val;
      }, "0.");
}

function getMaxFromDigitsAndStep(digits: number, step: number) {
  const max = [...Array.from(Array(digits).keys())].reduce((curr) => {
    return `${curr}9`;
  }, "");
  return Number(max) + 1 - step;
}

function UomLabel({ label }: { label: string }) {
  return label ? <span className="ml-3">{label}</span> : null;
}

interface IUom {
  label: string;
  digits: number;
  decimals: number;
}

interface IInputValue {
  id: string;
  value: string | number;
}

interface IFormRules {
  required: boolean;
}

interface IOperationEventInput {
  id: string;
  label: string;
  type: "string" | "numeric" | "list" | "multiselectlist" | "date";
  isRequired: boolean;
  order: number;
  value?: string | string[] | number | number[] | IInputValue[];
  uom?: IUom;
  values?: { id: string; label: string; order: number }[]; // only for list/multiselectlist
}

interface IEventTemplate {
  id: string;
  inputs: IOperationEventInput[] | null;
}

interface IOperationEvent {
  id: string;
  operationId: string;
  templateId: string;
  dateUtc: string;
  notes?: string;
  inputs?: IOperationEventInput[] | null;
}

const defaultValues = {
  id: "",
  operationId: "",
  templateId: "",
  dateUtc: new Date().toISOString(),
  notes: "",
  inputs: null,
} as IOperationEvent;

export default function OperationEventForm({
  onSave,
}: {
  onSave: (id: string) => void;
}) {
  const inputRefs = useRef([]);
  const { t } = useTranslation();
  const { rootUrl } = useOrgState();
  const { itemId, type, eventId } = useParams<{
    itemId: string;
    type: string;
    eventId: string;
  }>();
  const history = useHistory();
  const {
    eventTemplatesState,
    fetchEventsState,
    saveOperationEventState,
    deleteOperationEventState,
    currentEditOperation,
  } = useOperationsState();
  const {
    fetchEvents,
    saveOperationEvent,
    deleteOperationEvent,
    resetOperationEventSave,
    resetOperationEventDelete,
  } = useOperationsDispatch();
  const [selectedTemplate, setSelectedTemplate] = useState<IEventTemplate>();
  const methods: UseFormMethods = useForm<FieldValues>({
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    register,
    watch,
    control,
    reset,
    errors,
    setValue,
    formState: { dirtyFields },
  } = methods;

  const { id, inputs, dateUtc, operationId } = watch();
  const recordEvent: IOperationEvent = fetchEventsState?.data?.find(
    (d: IOperationEvent) => d.id === eventId
  );

  const setInputVals = useCallback(
    (item, val) => {
      const data: IOperationEventInput[] = inputs ? [...inputs] : [];
      const exists = data.find((inp) => inp.id === item.id);
      if (exists) {
        const idx = data.indexOf(exists);
        if (idx >= 0) {
          data.splice(idx, 1, {
            id: item.id,
            value: val,
          } as IOperationEventInput);
        }
      } else {
        data.push({ ...item, value: val } as IOperationEventInput);
      }
      setValue("inputs", data);
    },
    [inputs, setValue]
  );

  const onSubmit = useCallback(
    async (data: { id: string; inputs: { id: string; value: unknown }[] }) => {
      if (!data.inputs) delete data.inputs;
      if (!data.id) delete data.id;
      // loop the template inputs, and include any non-required items
      // with null values
      data.inputs &&
        selectedTemplate?.inputs.forEach((i) => {
          const exists = data.inputs?.find((di) => i.id === di.id);
          if (!exists) {
            data.inputs.push({ id: i.id, value: null });
          }
        });
      const res = await saveOperationEvent(data);
      if (res && !res.isError) {
        fetchEvents(currentEditOperation?.operationId);
        onSave(res.data?.id);
        reset();
        history.push(`${rootUrl}/inventory/operations/${type}/items/${itemId}`);
      }
    },
    [
      selectedTemplate?.inputs,
      saveOperationEvent,
      fetchEvents,
      currentEditOperation?.operationId,
      onSave,
      reset,
      history,
      rootUrl,
      type,
      itemId,
    ]
  );

  useEffect(() => {
    inputRefs.current =
      selectedTemplate?.inputs?.map(
        (el, idx) => inputRefs.current[idx] ?? createRef()
      ) || [];
  }, [selectedTemplate]);

  useEffect(() => {
    if (recordEvent) {
      const copy = { ...recordEvent };
      copy.inputs = copy?.inputs?.map((i: IOperationEventInput) => {
        return {
          ...i,
          value: Array.isArray(i.value)
            ? [...i.value].map((v: IInputValue) => v.id)
            : i.value,
        };
      });
      reset(copy);
      const templateObj = eventTemplatesState.data?.find(
        (e: IEventTemplate) => e.id === copy.templateId
      );
      setSelectedTemplate(templateObj);
    }
  }, [recordEvent, reset, eventTemplatesState]);

  // reset save status when unmounted
  useEffect(() => {
    return () => {
      resetOperationEventDelete();
      resetOperationEventSave();
    };
  }, [resetOperationEventDelete, resetOperationEventSave]);

  return (
    <>
      <FormWrapper
        geometryRequired={false}
        methods={methods}
        data={{ ...recordEvent }}
        ignoreGeom
        cancelHref={`${rootUrl}/inventory/operations/${type}/items/${itemId}`}
        saveState={saveOperationEventState}
        deleteState={deleteOperationEventState}
        onDelete={async () => {
          const res = await deleteOperationEvent(id);
          if (!res?.isError) {
            reset();
            fetchEvents(operationId);
            history.push(
              `${rootUrl}/inventory/operations/${type}/items/${itemId}`
            );
          }
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          type="hidden"
          name="operationId"
          ref={register}
          required
          value={currentEditOperation?.operationId || ""}
        />

        <FormField
          label={`${t("common.type")} *`}
          name="templateId"
          control={control}
          rules={{ required: true }}
          htmlFor="template-select"
          render={(props: { onChange: (id: string) => void }) => (
            <Select
              isRequired
              isDisabled={Boolean(eventId)}
              options={eventTemplatesState.data || []}
              value={selectedTemplate}
              onChange={(e: IEventTemplate) => {
                setSelectedTemplate(e);
                props.onChange(e?.id || "");
              }}
              isClearable
            />
          )}
        >
          {errors?.templateId ? (
            <Form.Text className="text-danger">
              {t("common.requiredField")}
            </Form.Text>
          ) : null}
        </FormField>
        {selectedTemplate ? (
          <FormField
            wrapper="div"
            label={`${t("common.date")} *`}
            name="dateUtc"
            rules={{ required: true }}
            htmlFor="date-utc"
            control={control}
            render={(props: { onChange: (id: string) => void }) => {
              return (
                <DatePicker
                  required
                  id="date-utc"
                  selected={dateUtc ? new Date(dateUtc) : new Date()}
                  onChange={(date: Date) => {
                    props.onChange(date?.toISOString());
                  }}
                />
              );
            }}
          />
        ) : null}
        <Controller
          name="inputs"
          control={control}
          render={() => {
            return ((selectedTemplate?.inputs?.map(
              (i: IOperationEventInput, idx: number) => {
                const uomLabel = i?.uom?.label;
                const rules = {} as IFormRules;
                if (i.isRequired) {
                  rules.required = true;
                }
                switch (i.type) {
                  case "date": {
                    const currValISO = inputs?.find(
                      (d: IInputValue) => d.id === i.id
                    )?.value as string;
                    return (
                      <React.Fragment key={i.id}>
                        <FormField
                          wrapper="div"
                          label={`${i.label}${rules.required ? " *" : ""}`}
                          rules={rules}
                          htmlFor={i.id}
                        >
                          <DatePicker
                            required={rules.required}
                            id={i.id}
                            selected={currValISO ? new Date(currValISO) : null}
                            onChange={(date: Date) => {
                              setInputVals(
                                i,
                                date ? date?.toISOString() : null
                              );
                            }}
                          />
                        </FormField>
                      </React.Fragment>
                    );
                  }
                  case "multiselectlist": {
                    const currInput = inputs?.find(
                      (d: IInputValue) => d.id === i.id
                    );
                    const opts: IInputValue[] = Array.isArray(i.values)
                      ? i.values?.map((v: IOperationEventInput) => ({
                          ...v,
                          value: v.id,
                        })) || []
                      : [];
                    const selectedOpts = opts.filter((f) => {
                      const currVal = Array.isArray(currInput?.value)
                        ? [...currInput?.value]
                        : [];
                      return currVal
                        ? currVal?.find((v: string) => v === f.id)
                        : null;
                    });
                    return (
                      <React.Fragment key={i.id}>
                        <FormField
                          label={`${i.label}${rules.required ? " *" : ""}`}
                          rules={rules}
                          htmlFor={i.id}
                          defaultValue={
                            selectedOpts?.length ? selectedOpts : null
                          }
                          render={(props: ControllerRenderProps) => {
                            return (
                              <>
                                <div className="d-flex align-items-center">
                                  <Select
                                    isRequired={rules.required}
                                    isMulti
                                    value={selectedOpts}
                                    options={opts}
                                    isClearable
                                    onChange={(items: IInputValue[]) => {
                                      props.onChange(items);
                                      setInputVals(
                                        i,
                                        items && items.length
                                          ? items?.map((f) => f.value)
                                          : null
                                      );
                                    }}
                                  />
                                  <UomLabel label={uomLabel} />
                                </div>
                                {errors[i.id] ? (
                                  <Form.Text className="text-danger">
                                    {t("common.requiredField")}
                                  </Form.Text>
                                ) : null}
                              </>
                            );
                          }}
                        />
                      </React.Fragment>
                    );
                  }
                  case "list": {
                    const currInput = inputs?.find(
                      (d: IInputValue) => d.id === i.id
                    );
                    const opts =
                      i.values?.map((v: IOperationEventInput) => ({
                        ...v,
                        value: v.id,
                      })) || [];
                    const selectedOpts = opts.filter((f) => {
                      const currVal = Array.isArray(currInput?.value)
                        ? [...currInput?.value]
                        : [];
                      return currVal
                        ? currVal.find((v: string) => v === f.id)
                        : null;
                    });
                    return (
                      <React.Fragment key={i.id}>
                        <FormField
                          label={`${i.label}${rules.required ? " *" : ""}`}
                          rules={rules}
                          htmlFor={i.id}
                          defaultValue={
                            selectedOpts?.length ? selectedOpts : ""
                          }
                          render={(props: ControllerRenderProps) => {
                            return (
                              <>
                                <div className="d-flex align-items-center flex-grow-1">
                                  <Select
                                    id={i.id}
                                    isRequired={rules.required}
                                    options={opts}
                                    value={selectedOpts}
                                    onChange={(item: IInputValue) => {
                                      props.onChange(item?.value);
                                      setInputVals(
                                        i,
                                        item?.value ? [item?.value] : null
                                      );
                                    }}
                                    isClearable
                                  />
                                  <UomLabel label={uomLabel} />
                                </div>
                                {errors[i.id] ? (
                                  <Form.Text className="text-danger">
                                    {t("common.requiredField")}
                                  </Form.Text>
                                ) : null}
                              </>
                            );
                          }}
                        ></FormField>
                      </React.Fragment>
                    );
                  }
                  case "numeric": {
                    const { digits, decimals } = i?.uom || {};
                    const step = Number(
                      getStepFromDecimals([
                        ...Array.from(Array(decimals).keys()),
                      ])
                    );
                    const max = getMaxFromDigitsAndStep(digits, step);
                    const val = inputs?.find((d: IInputValue) => d.id === i.id)
                      ?.value;
                    return (
                      <React.Fragment key={i.id}>
                        <FormField
                          label={`${i.label}${rules.required ? " *" : ""}`}
                          htmlFor={i.id}
                          render={(props: ControllerRenderProps) => {
                            return (
                              <>
                                <div className="d-flex align-items-center">
                                  <Form.Control
                                    ref={inputRefs.current[idx]}
                                    className="flex-grow-1"
                                    type="number"
                                    step={step}
                                    max={max}
                                    required={i.isRequired}
                                    id={i.id}
                                    defaultValue={
                                      recordEvent?.inputs?.find(
                                        (d) => d.id === i.id
                                      )?.value as string | number
                                    }
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      props.onChange(e.target.value);
                                      setInputVals(
                                        i,
                                        e.target.value
                                          ? Number(e.target.value)
                                          : null
                                      );
                                    }}
                                  />
                                  <UomLabel label={uomLabel} />
                                </div>
                                {(val || dirtyFields[i.id]) &&
                                inputRefs.current[idx]?.current
                                  ?.validationMessage ? (
                                  <Form.Text className="text-danger">
                                    {
                                      inputRefs.current[idx]?.current
                                        ?.validationMessage
                                    }
                                  </Form.Text>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          }}
                        ></FormField>
                      </React.Fragment>
                    );
                  }
                  // default: 'string'
                  default: {
                    return (
                      <React.Fragment key={i.id}>
                        <FormField
                          key={i.id}
                          label={`${i.label}${rules.required ? " *" : ""}`}
                          htmlFor={i.id}
                          defaultValue={
                            recordEvent?.inputs?.find((d) => d.id === i.id)
                              ?.value
                          }
                          render={(props: ControllerRenderProps) => {
                            return (
                              <>
                                <div className="d-flex align-items-center">
                                  <Form.Control
                                    ref={inputRefs.current[idx]}
                                    required={i.isRequired}
                                    defaultValue={
                                      recordEvent?.inputs?.find(
                                        (d) => d.id === i.id
                                      )?.value as string
                                    }
                                    id={i.id}
                                    onChange={(
                                      e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                      props.onChange(e.target.value);
                                      setInputVals(i, e.target.value || null);
                                    }}
                                  />
                                  <UomLabel label={uomLabel} />
                                </div>
                                {dirtyFields[i.id] &&
                                inputRefs.current[idx]?.current
                                  ?.validationMessage ? (
                                  <Form.Text className="text-danger">
                                    {
                                      inputRefs.current[idx]?.current
                                        ?.validationMessage
                                    }
                                  </Form.Text>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          }}
                        ></FormField>
                      </React.Fragment>
                    );
                  }
                }
              }
              // TODO: fix this
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ) || null) as unknown) as ReactElement<any, any>;
          }}
        />
        {selectedTemplate ? (
          <FormField label={t("common.notes")} htmlFor="desc-notes">
            <Form.Control
              name="notes"
              as="textarea"
              maxLength={255}
              id="desc-notes"
              ref={register}
            />
          </FormField>
        ) : null}
      </FormWrapper>
    </>
  );
}
