import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormField, FormWrapper } from "components/Forms";
import { Select } from "components";
import { useInventoryDispatch, useInventoryState } from "providers";
import { useAssetsDispatch, useAssetsState } from "providers/AssetsProvider";
import {
  AssetStatusIDFormField,
  BornOnUTCFormField,
  CIDFormField,
  DescriptionFormField,
  FieldFormField,
} from "./FormFields";
import { IAnimalAsset, IAnimalBreed } from "types/IAssetType";
import { ISelectOption } from "types";

const defaultValues = {
  id: null,
  fieldId: null,
  assetGroupId: null,
  assetAnimalBreedId: null,
  isFemale: false,
  bornOnUtc: null,
  description: null,
  cid: null,
  assetStatusId: null,
  geometry: null,
} as IAnimalAsset;

export default function AnimalAssetForm({
  assetStatuses,
  handleDelete,
  handleSave,
  getCancelHref,
}: {
  assetStatuses: ISelectOption[];
  handleSave: (_d: IAnimalAsset, reset: () => void, isDirty: boolean) => void;
  handleDelete: (
    id: string,
    reset: () => void,
    defaultValues: IAnimalAsset
  ) => void;
  getCancelHref: () => string;
}) {
  const { t } = useTranslation();
  const { drawData } = useInventoryState();
  const { zoomToField, setDrawOptions } = useInventoryDispatch();
  const { assetGroups, animalTypes, animalBreeds } = useAssetsState();
  const [selectedAnimalType, setSelectedAnimalType] = useState<ISelectOption>();
  const [selectedBreedType, setSelectedBreedType] = useState<IAnimalBreed>();
  const [filteredBreeds, setFilteredBreeds] = useState<IAnimalBreed[]>(
    animalBreeds
  );
  const {
    currentEditFtr,
    deleteInventoryItemState,
    saveInventoryItemState,
  } = useAssetsState();
  const { createGroup } = useAssetsDispatch();
  const methods = useForm({
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    control,
    setValue,
    errors,
  } = methods;
  const { id } = watch();

  useEffect(() => {
    if (animalTypes && currentEditFtr) {
      setSelectedAnimalType(
        animalTypes?.find(
          (f) => currentEditFtr.properties.assetAnimalTypeId === f.value
        )
      );
    }
  }, [animalTypes, currentEditFtr]);

  useEffect(() => {
    if (animalBreeds && currentEditFtr) {
      const breeds = selectedAnimalType
        ? animalBreeds.filter(
            (b) => b.assetAnimalTypeId === selectedAnimalType.value
          )
        : animalBreeds;
      setFilteredBreeds(breeds);
      setSelectedBreedType(
        animalBreeds?.find(
          (f) => currentEditFtr.properties.assetAnimalBreedId === f.id
        )
      );
    }
  }, [animalBreeds, currentEditFtr, selectedAnimalType]);

  useEffect(() => {
    setDrawOptions({
      controls: {
        trash: true,
        point: true,
      },
      defaultMode: "draw_point",
    });
  }, [setDrawOptions]);

  async function onSave(d: IAnimalAsset) {
    d.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    // server doesn't want these props if not set
    if (!d.id) {
      delete d.id;
    }
    if (!d.assetGroupId) {
      delete d.assetGroupId;
    }
    return handleSave(d, reset, formState.isDirty);
  }
  return (
    <FormWrapper
      geometryRequired={false}
      // TODO:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      methods={methods}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={getCancelHref()}
      saveState={saveInventoryItemState}
      deleteState={deleteInventoryItemState}
      onDelete={() => {
        handleDelete(id, reset, defaultValues);
      }}
      onSubmit={handleSubmit(onSave)}
    >
      <Form.Group>
        <FieldFormField
          control={control}
          onChange={(item) => {
            zoomToField(item?.value);
          }}
        />
        <FormField
          label={t("common.group")}
          name="assetGroupId"
          control={control}
          htmlFor="animal-group"
          render={(props: {
            value: string;
            onChange: (_id: string) => void;
          }) => (
            <Select
              allowCreate
              isClearable
              id="animal-group"
              options={assetGroups || []}
              value={assetGroups?.find((f) => f.value === props.value)}
              onCreateOption={async (name: string) => {
                const res = await createGroup({ name });
                if (res && res.isError) {
                  // TODO: notify failed
                  console.error("failed to create group");
                } else {
                  props.onChange(res?.data?.id);
                }
              }}
              onChange={(e: ISelectOption) => {
                props.onChange(e?.value || "");
              }}
            />
          )}
        />
        <FormField label={`${t("common.name")}`} htmlFor="asset-name">
          <Form.Control
            maxLength={50}
            name="name"
            id="asset-name"
            ref={register}
          />
        </FormField>
        <FormField
          label={t("common.type")}
          htmlFor="animal-type"
          render={() => (
            <Select
              isClearable
              id="animal-type"
              options={animalTypes || []}
              value={selectedAnimalType}
              onChange={(e: ISelectOption) => {
                setSelectedAnimalType(e);
                let newBreeds = animalBreeds;
                if (e?.value) {
                  newBreeds = animalBreeds.filter(
                    (b) => b.assetAnimalTypeId === e.value
                  );
                  if (selectedBreedType) {
                    if (selectedBreedType.assetAnimalTypeId !== e.value) {
                      setValue("assetAnimalBreedId", "");
                      setSelectedBreedType(null);
                    }
                  }
                }
                setFilteredBreeds(newBreeds);
              }}
            />
          )}
        />
        <FormField
          label={`${t("common.breed")} *`}
          name="assetAnimalBreedId"
          rules={{ required: true }}
          control={control}
          htmlFor="animal-breed-select"
          render={(props: { onChange: (_id: string) => void }) => (
            <Select
              isClearable
              id="animal-breed-select"
              options={filteredBreeds || []}
              value={selectedBreedType}
              onChange={(e: IAnimalBreed) => {
                setSelectedBreedType(e);
                props.onChange(e?.id || "");
              }}
            />
          )}
        >
          {errors?.assetAnimalBreedId ? (
            <Form.Text className="text-danger">
              {t("common.requiredField")}
            </Form.Text>
          ) : null}
        </FormField>
        <FormField
          label={`${t("inventory.assets.sex")} *`}
          name="isFemale"
          // rules={{ required: true }}
          control={control}
          render={(props: {
            onChange: (_checked: boolean) => void;
            value: boolean;
          }) => (
            <>
              <Form.Check
                inline
                custom
                name="animal-sex"
                type="radio"
                label={t("inventory.assets.male")}
                id="sex-male"
                checked={props.value === false}
                onChange={(e) => {
                  props.onChange(!e.target.checked);
                }}
              />
              <Form.Check
                inline
                custom
                name="animal-sex"
                type="radio"
                label={t("inventory.assets.female")}
                id="sex-female"
                checked={props.value === true}
                onChange={(e) => {
                  props.onChange(e.target.checked);
                }}
              />
            </>
          )}
        />
        <BornOnUTCFormField
          control={control}
          label={`${t("inventory.assets.bornOn")} *`}
        />
        <CIDFormField register={register} />
        <AssetStatusIDFormField
          assetStatusOpts={assetStatuses}
          control={control}
          isError={Boolean(errors?.assetStatusId)}
        />
        <DescriptionFormField register={register} />
      </Form.Group>
    </FormWrapper>
  );
}
