import React from "react";
import SelectComponent from "lib/ReactSelect";
import { useTranslation } from "react-i18next";
// select wrapper to handle translations in single location
// rather than having to specify placeholder for each instance
export default function Select({
  isRequired,
  ...props
}: {
  allowCreate?: boolean;
  id?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menuPosition?: any;
  name?: string;
  value?: unknown;
  // TODO:
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any[];
  // TODO:
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (_item: any) => void;
  onCreateOption?: (_name: string) => void;
  isClearable?: boolean;
  isLoading?: boolean;
  className?: string;
  filterOption?: (_d: unknown, query: string) => boolean;
  getOptionLabel?: (_opt: unknown) => string;
  getOptionValue?: (_opt: unknown) => string;
}) {
  const { t } = useTranslation();
  return (
    <span className="position-relative w-100">
      <SelectComponent placeholder={`${t("common.select")}...`} {...props} />
      {isRequired && (
        <input
          name={props.name}
          tabIndex={-1}
          autoComplete="off"
          style={{
            opacity: 0,
            width: "100%",
            height: 0,
            position: "absolute",
          }}
          // defaultValue={props.value}
          value={props.value?.toString() || ""}
          onChange={() => {}}
          required
        />
      )}
    </span>
  );
}
