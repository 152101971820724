import LoadingButton from "components/LoadingButton";
import { useTranslation } from "react-i18next";
import CropFarmField from "./CropFarmField";
import MinMaxYieldFilterInput from "./MinMaxYieldFilterInput";
import { useInsightsFieldLookup } from "../useInsightsFieldLookup";
import { useParams } from "react-router-dom";
import { useOrgState } from "providers";
import {
  useYieldInsightsActions,
  useYieldInsightsState,
} from "./useYieldInsightsStore";
import { IFieldLookup, ProductLookup, YieldType } from "../types";
import { useYieldProducts } from "./useYieldProducts";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Autocomplete from "lib/Select/Autocomplete";
import { useProductsPlanted } from "../useProductsPlanted";
import { useProductsApplied } from "../useProductsApplied";
import ApplicationAndSeedSelect from "../ApplicationAndSeedSelect";

export default function AverageBy({
  operationBufferError,
  units,
  onOperationBufferChange,
  onFieldBufferChange,
  onFieldSelect,
  onAppOrSeedChange,
  onSubmit,
  isLoading,
}: {
  operationBufferError?: boolean;
  units: string;
  isLoading: boolean;
  onAppOrSeedChange?: (_i: {
    application?: ProductLookup;
    seed?: ProductLookup;
  }) => void;
  onOperationBufferChange?: (_n: number | string) => void;
  onFieldBufferChange: (_n: number | string) => void;
  onFieldSelect: (_d: IFieldLookup) => void;
  onSubmit: () => void;
}) {
  const { t } = useTranslation();
  const { org, yieldType } = useParams<{
    org: string;
    yieldType: YieldType;
  }>();
  const { season } = useOrgState();
  const fieldLookupsQ = useInsightsFieldLookup(org, yieldType, season?.id);
  const actions = useYieldInsightsActions();
  const yieldInsightsState = useYieldInsightsState();

  const yieldProductsQ = useYieldProducts(
    org,
    yieldType,
    yieldInsightsState.field?.fieldId
  );
  const plantedItemsQ = useProductsPlanted(
    org,
    yieldType,
    yieldInsightsState.field?.fieldId
  );
  const appliedItemsQ = useProductsApplied(
    org,
    yieldType,
    yieldInsightsState.field?.fieldId
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.currentTarget.reportValidity();
        return onSubmit();
      }}
    >
      <h3>{t("common.include")}</h3>
      <Stack spacing={2} sx={{ mt: 3 }}>
        <CropFarmField
          fields={fieldLookupsQ.data}
          onFieldChange={(item) => {
            actions.reset();
            actions.setField(item);
            onFieldSelect(item);
          }}
        />
        {["productapplied"].includes(yieldType) ? (
          <Autocomplete
            id="product-select"
            disableClearable={false}
            name={"oaId"}
            label={`${t("common.application")}`}
            value={
              yieldProductsQ.data?.find(
                (d) => d.id === yieldInsightsState.appliedOperation?.id
              ) ?? null
            }
            groupBy={(o) => o.date}
            getOptionLabel={(o) => (typeof o !== "string" ? o.product : "")}
            options={yieldProductsQ.data ?? []}
            onChange={(_e, item: ProductLookup) => {
              actions.setAppliedOperation(item);
              actions.setOperationBuffer(null);
              onAppOrSeedChange({
                application: item,
              });
            }}
          />
        ) : null}
        {["productplanted"].includes(yieldType) ? (
          <Autocomplete
            id="product-select"
            name={"operationIds"}
            multiple
            label={`${t("common.product")}`}
            groupBy={(o) => o.date}
            value={yieldInsightsState.products ?? []}
            getOptionLabel={(o) => (typeof o !== "string" ? o.product : "")}
            options={yieldProductsQ.data ?? []}
            onChange={(_e, items: ProductLookup[]) => {
              actions.setProducts(items);
            }}
          />
        ) : null}
        {["productplantedapplied", "imagery"].includes(yieldType) ? (
          <ApplicationAndSeedSelect
            onChange={onAppOrSeedChange}
            applicationProducts={appliedItemsQ.data}
            seedProducts={plantedItemsQ.data}
            value={{
              application: yieldInsightsState.application,
              seed: yieldInsightsState.seed,
            }}
          />
        ) : null}

        {yieldType === "productapplied" ? (
          <Stack>
            <TextField
              error={operationBufferError}
              name="operationBuffer"
              id="operation-buffer-input"
              label={t("insights.yield.operationBuffer")}
              value={yieldInsightsState.operationBufferSize ?? ""}
              inputProps={{
                min: 0,
                max: 1000,
              }}
              InputProps={{
                endAdornment: <Box sx={{ ml: 1 }}>{units}</Box>,
              }}
              disabled={!yieldInsightsState.appliedOperation}
              onChange={(e) => {
                onOperationBufferChange(Number(e.target.value));
              }}
              placeholder="0"
              type="number"
              helperText={t("insights.yield.includeHighConfidenceYield")}
            />
          </Stack>
        ) : null}
      </Stack>
      <hr className="border-light" />
      <div>
        <h3>{t("common.exclude")}</h3>
        <MinMaxYieldFilterInput
          cropTypeId={yieldInsightsState.field?.cropTypeId}
        />
        <div>
          <TextField
            name="fieldBuffer"
            sx={{ mt: 2 }}
            fullWidth
            value={yieldInsightsState.fieldBufferSize ?? ""}
            id="field-buffer-input"
            InputProps={{
              endAdornment: <Box sx={{ ml: 1 }}>{units}</Box>,
            }}
            inputProps={{
              min: 0,
              max: 1000,
            }}
            label={t("insights.yield.fieldBuffer")}
            onChange={(e) => {
              onFieldBufferChange(Number(e.target.value));
            }}
            placeholder="0"
            type="number"
            helperText={t("insights.yield.excludeLowConfidenceYield")}
          />
        </div>
      </div>
      <hr className="border-light" />
      <LoadingButton
        className="my-3"
        color="primary"
        variant="contained"
        type="submit"
        isLoading={isLoading}
      >
        {t("insights.yield.reportIt")}
      </LoadingButton>
    </form>
  );
}
