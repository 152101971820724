import React from "react";
import useSettings from "./useSettings";
import { ISettingsConfig } from "types";

const SettingsStateContext = React.createContext({} as ISettingsConfig);
const SettingsDispatchContext = React.createContext({});

function SettingsProvider({ children }: { children: React.ReactNode }) {
  const { state, saveSettings, languages } = useSettings();
  return (
    <SettingsStateContext.Provider
      value={{
        language: state?.data?.language,
        languages,
        theme: state?.data?.theme,
        unitSystem: state?.data?.unitSystem,
      }}
    >
      <SettingsDispatchContext.Provider
        value={(data: ISettingsConfig) => {
          saveSettings({ ...state.data, ...data });
        }}
      >
        {children}
      </SettingsDispatchContext.Provider>
    </SettingsStateContext.Provider>
  );
}

function useSettingsState() {
  const context = React.useContext(SettingsStateContext);
  if (context === undefined) {
    throw new Error("useSettingsState must be used within a SettingsProvider");
  }
  return context;
}

function useSettingsDispatch() {
  const context = React.useContext(SettingsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useSettingsDispatch must be used within a SettingsProvider"
    );
  }
  return context;
}

export { SettingsProvider, useSettingsState, useSettingsDispatch };
