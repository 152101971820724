import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useOrgDispatch, useOrgState } from "providers/OrgProvider";
import {
  useInventoryDispatch,
  useInventoryState,
} from "providers/InventoryProvider";
import { useFieldsDispatch, useFieldsState } from "providers/FieldsProvider";
import { FormField, FormWrapper, Select } from "components";
import {
  CIDFormField,
  DescriptionFormField,
} from "../../Assets/CreateOrEditAsset/FormFields";
import GeodataUpload from "views/InventoryView/GeodataUpload";

const defaultValues = {
  id: null,
  name: null,
  geometry: null,
  landAgreementTypeId: null,
  farmId: null,
  gpsSourceTypeId: "e9aff444-911c-40a3-8222-614d70162d38",
  seasonId: null,
};

export default function FieldForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const { season, rootUrl, landAgreementTypes, farms } = useOrgState();

  const { currentEditFtr, deleteFieldState, saveFieldState } = useFieldsState();
  const { fetchFieldsSummary } = useOrgDispatch();
  const { saveField, deleteField } = useFieldsDispatch();
  const { drawData } = useInventoryState();
  const { setOutsideDrawData, fitInventoryBounds } = useInventoryDispatch();
  const [uploadError, setUploadError] = useState();

  const methods = useForm({
    defaultValues: { ...defaultValues, ...{ seasonId: season?.id } },
  });
  const {
    getValues,
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    control,
    errors,
    setValue,
  } = methods;
  const { id } = watch();

  async function handleSave(d) {
    // console.log(d);
    if (!formState.isDirty) {
      history.push(`${rootUrl}/inventory/fields`);
      return false;
    }
    d.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    // server doesn't want these props if not set
    if (!d.id) {
      delete d.id;
    }
    if (!d.farmId) {
      delete d.farmId;
    }
    if (!d.landAgreementTypeId) {
      delete d.landAgreementTypeId;
    }
    const res = await saveField(d);
    if (!res.isError) {
      reset({ ...defaultValues, ...d });
      history.push(`${rootUrl}/inventory/fields`);
      fetchFieldsSummary();
    }
    return res;
  }

  const [createType, setCreateType] = useState("draw");

  // cleanup
  useEffect(() => {
    return setOutsideDrawData;
  }, [setOutsideDrawData]);

  return (
    <FormWrapper
      methods={methods}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={`${rootUrl}/inventory/fields`}
      saveState={saveFieldState}
      deleteState={deleteFieldState}
      onDelete={async () => {
        const res = await deleteField(id);
        if (!res.isError) {
          reset(defaultValues);
          fetchFieldsSummary();
          history.push(`${rootUrl}/inventory/fields`);
        }
      }}
      onSubmit={handleSubmit(handleSave)}
    >
      <input ref={register} required type="hidden" name="gpsSourceTypeId" />
      <input ref={register} required type="hidden" name="seasonId" />
      <Row>
        <Col>
          <FormField label={`${t("common.name")} *`} htmlFor="field-name">
            <Form.Control
              id="field-name"
              name="name"
              ref={register}
              required
              maxLength={50}
              placeholder={t("common.name")}
            />
          </FormField>
          <FormField
            label={t("common.farm")}
            name="farmId"
            control={control}
            htmlFor="farm-select"
            render={(props) => (
              <Select
                id="farm-select"
                options={farms}
                // eslint-disable-next-line react/prop-types
                value={farms?.find((f) => f.value === props.value) || ""}
                onChange={(item) => {
                  // eslint-disable-next-line react/prop-types
                  props.onChange(item?.value || "");
                }}
                isClearable
              />
            )}
          />
          <FormField
            label={`${t("inventory.fields.landAgreement")} *`}
            name="landAgreementTypeId"
            control={control}
            htmlFor="land-agreement-select"
            rules={{ required: true }}
            render={(props) => (
              <Select
                id="land-agreement-select"
                options={landAgreementTypes || []}
                value={
                  // eslint-disable-next-line react/prop-types
                  landAgreementTypes?.find((f) => f.value === props.value)
                }
                onChange={(item) => {
                  // eslint-disable-next-line react/prop-types
                  props.onChange(item?.value || "");
                }}
                isClearable
                placeholder={`${t("common.select")}...`}
              />
            )}
          >
            {errors?.landAgreementTypeId ? (
              <div className="invalid-feedback d-block">
                {t("inventory.fields.landAgreementRequired")}
              </div>
            ) : null}
          </FormField>
          <CIDFormField register={register} />
          <DescriptionFormField register={register} />
        </Col>
      </Row>
      <Row>
        <Col>{t("inventory.fields.createFieldBoundary")}:</Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-0">
            <Form.Check
              id="draw-field-radio"
              name="draw-type"
              type="radio"
              label={t("inventory.fields.drawField")}
              onChange={() => {
                setCreateType("draw");
              }}
              checked={createType === "draw"}
            />
            <Form.Check
              id="from-existing-radio"
              name="draw-type"
              type="radio"
              label={`${t("inventory.fields.uploadBoundaryFile")} [.geojson]`}
              onChange={() => {
                setCreateType("layer");
              }}
              checked={createType === "layer"}
            />
            {createType === "layer" ? (
              <GeodataUpload
                type="Polygon"
                onChange={(geojson) => {
                  setUploadError("");
                  if (geojson) {
                    setOutsideDrawData(geojson);
                    fitInventoryBounds({ geojson });
                    const props = geojson.features.length
                      ? geojson.features[0].properties
                      : {};
                    const keys = Object.keys(props);
                    keys.forEach((k) => {
                      if (
                        [
                          "cid",
                          "description",
                          "farmId",
                          "landAgreementTypeId",
                          "name",
                        ].includes(k) &&
                        !getValues(k)
                      )
                        setValue(k, props[k]);
                    });
                  }
                }}
                onError={(e) => {
                  setUploadError(e);
                }}
              />
            ) : null}
            {errors?.geometry || uploadError ? (
              <Form.Text className="text-danger">
                {errors?.geometry?.message ||
                  uploadError ||
                  t("common.geometryRequired")}
              </Form.Text>
            ) : null}
          </Form.Group>
        </Col>
      </Row>
    </FormWrapper>
  );
}
