import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { GeoDataTable, IconButton } from "components";
import { useOrgState } from "providers/OrgProvider";
import {
  useInventoryDispatch,
  useInventoryState,
} from "providers/InventoryProvider";
import { Link } from "react-router-dom";

function getDamagesWithTheme(items, themeData, themeProp) {
  if (items) {
    items.forEach((f) => {
      const theme = themeData?.find((td) => td.id === f.id);
      const themeColor = theme ? theme[themeProp] : "gray";
      f.themeColor = themeColor;
    });
  }
  return items;
}

export default function DamageListView({
  expanded,
  toggleExpanded,
  damages,
  isLoading,
  geodata,
  themeData,
  themeProp,
}) {
  const { t } = useTranslation();
  const { inventoryMap, ftrsClicked } = useInventoryState();
  const { highlightLayerFeatures } = useInventoryDispatch();
  const { rootUrl } = useOrgState();
  useEffect(() => {
    getDamagesWithTheme(damages, themeData, themeProp);
  }, [themeData, damages, themeProp]);

  return (
    <>
      <div className="pb-2 d-flex align-items-center">
        <h3 className="mb-0">{t("inventory.damage.damageList")}</h3>
        <div className="d-flex ml-auto">
          <IconButton
            title={
              expanded ? t("common.collapseTable") : t("common.expandTable")
            }
            icon={expanded ? "chevronDown" : "chevronUp"}
            className="mr-2 d-flex"
            onClick={toggleExpanded}
          />
          <IconButton
            wrapper={Link}
            to={`${rootUrl}/inventory/damage/add`}
            icon="add"
            className="btn-outline-success d-flex"
          />
        </div>
      </div>
      <GeoDataTable
        components={{
          HeaderLeft: (
            <p className="small m-0">
              {damages && damages.length ? t("common.selectToZoom") : ""}
            </p>
          ),
        }}
        style={{ minHeight: "100%" }}
        isLoading={isLoading}
        map={inventoryMap}
        ftrsClicked={ftrsClicked}
        featureCollection={geodata}
        highlightByIds={highlightLayerFeatures}
        tableProps={{ size: "sm" }}
        data={damages}
        hiddenColumns={["farm"]}
        columns={[
          {
            id: "damage-table-actions",
            accessor: "id",
            // eslint-disable-next-line react/prop-types
            Cell: function ActionCell({ value }) {
              return (
                <div className="d-flex mt-2 mx-3 position-relative">
                  <IconButton
                    icon="edit"
                    wrapper={Link}
                    title={t("common.edit")}
                    className="mr-1"
                    to={`${rootUrl}/inventory/damage/${value}/edit`}
                  />
                </div>
              );
            },
            isSortable: false,
            disableGlobalFilter: true,
            styles: { width: "50px" },
          },
          {
            Header: t("common.field"),
            accessor: "field",
            // eslint-disable-next-line react/prop-types
            Cell: function FieldCol({ value, row }) {
              // eslint-disable-next-line react/prop-types
              const { farm } = row.original;
              return (
                <div className="float-left d-flex flex-column">
                  <span>{value}</span>
                  <small>{farm}</small>
                </div>
              );
            },
          },
          {
            Header: t("common.area"),
            accessor: "area",
            disableGlobalFilter: true,
          },
          // search only column
          { accessor: "farm" },
        ]}
      />
    </>
  );
}
DamageListView.defaultProps = {
  damages: null,
  isLoading: false,
  themeData: null,
  themeProp: "",
  geodata: null,
};

DamageListView.propTypes = {
  expanded: PropTypes.bool,
  toggleExpanded: PropTypes.func,
  damages: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  themeData: PropTypes.arrayOf(PropTypes.shape({})),
  themeProp: PropTypes.string,
  geodata: PropTypes.shape({}),
};
