import React from "react";
import { Popover as P, PopoverProps } from "react-bootstrap";

export const Popover = React.forwardRef(function PopoverRef(
  { /* popper, */ children, title, ...props }: PopoverProps,
  ref
) {
  // NOTE: https://react-bootstrap-v4.netlify.app/components/overlays/#overlays-dynamic-updates
  //   useEffect(() => {
  //     console.log('updating popover');
  //     popper.scheduleUpdate();
  //   }, [children, popper]);

  return (
    <P ref={ref} {...props}>
      {title && <P.Title>{title}</P.Title>}
      <P.Content>{children}</P.Content>
    </P>
  );
});
