import React from "react";
import { useTranslation } from "react-i18next";
import { PageColumn, PageLayout } from "views/MainLayout";
import LoadingButton from "components/LoadingButton";

export function ImageryImport() {
  const { t } = useTranslation();
  const tilerOrigin = process.env.REACT_APP_TILER_ORIGIN;

  return (
    <>
      <h2>{t("imagery.import.importImagery")}</h2>
      <PageLayout>
        <PageColumn sm={12}>
          <p className="text-uppercase mt-3">
            {t("imagery.import.uploadTifImage")}
          </p>
          <a
            className="my-3 align-self-start"
            href={tilerOrigin}
            target="_blank"
            rel="noreferrer"
          >
            <LoadingButton color="primary" variant="contained" type="submit">
              {t("imagery.import.orthoUploader")}
            </LoadingButton>
          </a>
          <p className="text-uppercase mt-3">
            {t("imagery.import.uploadLargeTifImage")}
          </p>
          <LoadingButton
            color="primary"
            variant="contained"
            type="submit"
            className="my-3 align-self-start"
            disabled
          >
            {t("imagery.import.largeOrthoUploader")}
          </LoadingButton>
          <p className="text-uppercase mt-3">{t("imagery.import.aipUpload")}</p>
          <LoadingButton
            color="primary"
            variant="contained"
            type="submit"
            className="my-3 align-self-start"
            disabled
          >
            {t("imagery.import.droneAip")}
          </LoadingButton>
        </PageColumn>
      </PageLayout>
    </>
  );
}
