import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import { useAssetsState, useAssetsDispatch } from "providers/AssetsProvider";
import { useTranslation } from "react-i18next";
import { getAssetIcon } from "../utils";
import ReportView from "../ReportView";

export default function RecordsReport({ currentAssetType, currentAssets }) {
  const { t } = useTranslation();
  const {
    fetchRecordsByAssetId,
    // fetchPhotosByAssetId,
    resetAssetRecords,
  } = useAssetsDispatch();
  const { currentAssetRecords, fetchAssetRecordsDataState } = useAssetsState();
  const { rootUrl, orgData, season } = useOrgState();
  const { reportType, assetId } = useParams();
  const asset = currentAssets?.find((a) => a.id === assetId);
  const assetType = asset?.assetType?.toLowerCase();
  const ddProp =
    assetType === "animal" || assetType === "plant" ? "cid" : "name";
  const ddVal = asset
    ? `${asset[ddProp]}${asset.description ? ` - ${asset.description}` : ""}`
    : "";
  // fetch records when we have assetId
  // reset records on effect cleanup
  useEffect(() => {
    if (assetId) {
      fetchRecordsByAssetId(assetId);
      // fetchPhotosByAssetId(assetId);
    }
    return () => {
      resetAssetRecords();
    };
  }, [assetId, fetchRecordsByAssetId, resetAssetRecords]);
  return (
    <>
      <ReportView
        org={orgData?.name}
        season={season}
        color={currentAssetType?.color}
        titleIcon={getAssetIcon(currentAssetType?.assetEn?.toLowerCase())}
        title={`${reportType} Asset Records Report`}
        columns={[
          {
            Header: t("common.date"),
            id: "dateUtc",
            accessor: (row) => {
              return new Date(row.dateUtc).toLocaleDateString();
            },
          },
          { Header: t("common.type"), accessor: "eventType" },
          { Header: t("common.notes"), accessor: "notes" },
        ]}
        data={currentAssetRecords}
        descriptionDetail={{
          dt: t("common.asset"),
          dd: ddVal,
        }}
        isLoading={fetchAssetRecordsDataState.isLoading}
        goBackUrl={`${rootUrl}/insights/reports/assets/${currentAssetType?.assetEn?.toLowerCase()}`}
      />
    </>
  );
}

RecordsReport.defaultProps = {
  currentAssets: null,
  currentAssetType: null,
};

RecordsReport.propTypes = {
  currentAssetType: PropTypes.shape({
    color: PropTypes.string,
    assetEn: PropTypes.string,
  }),
  currentAssets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
};
