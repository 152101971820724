import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { FormField } from "components";
import DatePicker from "components/DatePicker";
import { useTranslation } from "react-i18next";
import GeodataUpload from "views/InventoryView/GeodataUpload";
import { FeatureCollection } from "geojson";
import Select from "components/Select";
import { useAssetsUpload } from "api/useAssetsUpload";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch } from "providers";
import { GeoDataTable } from "components";
import { useInventoryState } from "providers";
import { AlertCircle } from "react-feather";
import {
  parseFeatureCollectionPropKeys,
  setFeatureCollectionProps,
} from "app-utils";
import BaseForm from "components/Forms/BaseForm";
import { validateFeatureCounts } from "./asset-upload-utils";
import useOrgLookupRQ from "components/useOrgLookupRQ";

export default function PlantAssetUpload({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const { cropTypes, org, season } = useOrgState();
  const {
    fitInventoryBounds,
    highlightLayerFeatures,
    setInventoryFtrs,
  } = useInventoryDispatch();
  const { ftrsClicked, inventoryMap } = useInventoryState();
  const history = useHistory();
  const [parseError, setParseError] = useState("");
  const [parsedKeys, setParsedKeys] = useState<
    { value: string; label: string }[]
  >([]);
  const [uploadedFc, setUploadedFc] = useState<FeatureCollection>();
  const [cropTypeId, setCropTypeId] = useState("");
  const [assetStatusId, setAssetStatusId] = useState("");
  const [cidProp, setCidProp] = useState("");
  const [bornOnProp, setBornOnProp] = useState("");
  const [descriptionProp, setDescriptionProp] = useState("");
  const [useManualDateSelect, setUseManualDateSelect] = useState(false);
  const [useManualDescriptionInput, setUseManualDescriptionInput] = useState(
    false
  );
  const [manualDate, setManualDate] = useState<Date>();
  const [manualDescription, setManualDescription] = useState("");
  const { uploadAssets, reset: resetUpload, ...query } = useAssetsUpload({
    org,
    seasonId: season.id,
    type: "plant",
  });

  const { data: assetStatuses } = useOrgLookupRQ(
    `/${org}/lookups/assetstatuses`
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "ID *",
        accessor: "cid",
        Cell: ({ value }: { value: string | number }) => {
          const val = value || value === 0 ? `${value}` : null;
          return (
            <div className={!val ? "text-danger" : ""}>
              {val ?? (
                <>
                  <AlertCircle />
                </>
              )}
            </div>
          );
        },
      },
      {
        Header: `${t("inventory.assets.plantedDate")} *`,
        accessor: "bornOnUtc",
        Cell: ({ value }: { value: string }) => {
          const val = manualDate ? manualDate.toLocaleDateString() : value;
          return (
            <div className={!val ? "text-danger" : ""}>
              {val || (
                <>
                  <AlertCircle />
                </>
              )}
            </div>
          );
        },
      },
      {
        Header: t("common.notes"),
        accessor: "description",
        Cell: ({ value }: { value: string }) => {
          return <div>{value ?? manualDescription}</div>;
        },
      },
    ],
    [manualDescription, manualDate, t]
  );
  const formattedFC = React.useMemo(() => {
    if (!uploadedFc) {
      return null;
    }
    const formatted = setFeatureCollectionProps(
      uploadedFc,
      {
        name: null,
        cropTypeId,
        assetStatusId,
        ...(manualDate
          ? {
              bornOnUtc: manualDate.toISOString(),
            }
          : {}),
        ...(manualDescription ? { description: manualDescription } : {}),
      },
      {
        description: descriptionProp,
        bornOnUtc: bornOnProp,
        cid: cidProp,
      }
    );
    return formatted;
  }, [
    assetStatusId,
    bornOnProp,
    cidProp,
    cropTypeId,
    descriptionProp,
    manualDescription,
    manualDate,
    uploadedFc,
  ]);
  const tableData = React.useMemo(
    () => formattedFC?.features.map((f) => f.properties) ?? [],
    [formattedFC?.features]
  );
  const { validCount, invalidCount } = validateFeatureCounts(formattedFC, [
    "bornOnUtc",
    "cid",
  ]);

  return (
    <>
      <BaseForm
        cancelHref="./"
        saveState={{
          isLoading: query.isLoading,
          isError: query.isError,
          errorMessage: query.error?.message,
        }}
        onSubmit={() => {
          resetUpload();
          if (!uploadedFc) {
            setParseError(t("common.requiredField"));
            return false;
          }
          if (invalidCount) {
            return false;
          }
          return uploadAssets(formattedFC, {
            onSuccess: () => {
              onSuccess();
              history.push("./");
            },
          });
        }}
      >
        <FormField label={`${t("common.crop")} *`}>
          <Select
            isRequired
            isClearable
            id="crop-select"
            options={cropTypes || []}
            value={cropTypes?.find((f) => f.value === cropTypeId)}
            onChange={(item) => {
              setCropTypeId(item?.value);
              if (uploadedFc) {
                const ftrsWithColor = setFeatureCollectionProps(uploadedFc, {
                  color: item?.color || "#eee",
                  crop: item?.name ?? "??",
                });
                setInventoryFtrs(ftrsWithColor);
              }
            }}
          />
        </FormField>
        <FormField label={`${t("common.status")} *`}>
          <Select
            isClearable
            isRequired
            id="status-select"
            name="assetStatusId"
            options={assetStatuses}
            value={assetStatuses?.find((ao) => ao.value === assetStatusId)}
            onChange={(item) => {
              setAssetStatusId(item?.value);
            }}
          />
        </FormField>

        <div className="d-flex align-items-center mt-3">
          <GeodataUpload
            type="Point"
            onChange={(geojson) => {
              setParsedKeys([]);
              setParseError("");
              setUploadedFc(geojson);
              if (geojson) {
                const cropType = cropTypes?.find((f) => f.value === cropTypeId);
                const ftrsWithColor = setFeatureCollectionProps(geojson, {
                  color: cropType?.color || "#eee",
                  crop: cropType?.name ?? "??",
                });
                setInventoryFtrs(ftrsWithColor);
                fitInventoryBounds({ geojson });
                const keys = parseFeatureCollectionPropKeys(geojson);
                setParsedKeys(
                  keys.map((p) => ({
                    value: p,
                    label: p,
                  }))
                );
              } else {
                setInventoryFtrs(null);
              }
            }}
            onError={(e) => {
              setParseError(e);
            }}
          />
          <Form.Text className="text-info ml-1">GeoJSON</Form.Text>
        </div>
        {parseError ? (
          <Form.Text className="text-danger">{parseError}</Form.Text>
        ) : null}

        {parsedKeys.length ? (
          <div className="mt-3">
            {t("common.mapGeoJSONProps")}
            <FormField label="ID *">
              <Select
                isRequired
                isClearable
                value={parsedKeys?.find((p) => p.value === cidProp)}
                options={parsedKeys}
                onChange={(item) => {
                  setCidProp(item?.value);
                }}
              />
            </FormField>
            <FormField
              wrapper={useManualDateSelect ? "div" : Form.Label}
              label={`${t("inventory.assets.plantedDate")} *`}
            >
              <div className="d-flex align-items-center">
                <div style={{ flexGrow: 2 }}>
                  {useManualDateSelect ? (
                    <DatePicker
                      required
                      maxDate={new Date()}
                      selected={manualDate}
                      onChange={(d: Date) => {
                        setManualDate(d);
                      }}
                    />
                  ) : (
                    <Select
                      isRequired
                      isClearable
                      value={parsedKeys?.find((p) => p.value === bornOnProp)}
                      options={parsedKeys}
                      onChange={(item) => {
                        setBornOnProp(item?.value);
                      }}
                    />
                  )}
                </div>
                <Form.Switch
                  className="ml-3 text-uppercase"
                  id="manual-planted-date"
                  checked={useManualDateSelect}
                  label={t("common.manualInput")}
                  onChange={(e) => {
                    e.stopPropagation();
                    setUseManualDateSelect(e.currentTarget.checked);
                    if (!e.currentTarget.checked) {
                      setManualDate(null);
                    } else {
                      setBornOnProp("");
                    }
                  }}
                />
              </div>
            </FormField>
            <FormField label={t("common.notes")}>
              <div className="d-flex align-items-center">
                <div style={{ flexGrow: 2 }}>
                  {useManualDescriptionInput ? (
                    <Form.Control
                      onChange={(e) => {
                        setManualDescription(e.target.value);
                      }}
                      as="textarea"
                      maxLength={255}
                    />
                  ) : (
                    <Select
                      isClearable
                      value={parsedKeys?.find(
                        (p) => p.value === descriptionProp
                      )}
                      options={parsedKeys}
                      onChange={(item) => {
                        setDescriptionProp(item?.value);
                      }}
                    />
                  )}
                </div>
                <Form.Switch
                  className="ml-3 text-uppercase"
                  id="manual-description"
                  checked={useManualDescriptionInput}
                  label={t("common.manualInput")}
                  onChange={(e) => {
                    e.stopPropagation();
                    setUseManualDescriptionInput(e.currentTarget.checked);
                    if (!e.currentTarget.checked) {
                      setManualDescription("");
                    } else {
                      setDescriptionProp("");
                    }
                  }}
                />
              </div>
            </FormField>
            {formattedFC ? (
              <Form.Text className={invalidCount ? "text-danger" : "text-info"}>
                {`${t("common.validFeatures")}: ${validCount} /
                  ${formattedFC.features.length}`}
              </Form.Text>
            ) : null}
          </div>
        ) : null}
      </BaseForm>

      {formattedFC ? (
        <div
          className="inventory-list overflow-auto"
          style={{ minHeight: "250px" }}
        >
          <GeoDataTable
            isFilterable={false}
            highlightByIds={highlightLayerFeatures}
            ftrsClicked={ftrsClicked}
            map={inventoryMap}
            featureCollection={formattedFC}
            data={tableData}
            columns={columns}
          />
        </div>
      ) : null}
    </>
  );
}
