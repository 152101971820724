import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useOrgDispatch, useOrgState } from "providers/OrgProvider";
import {
  useAssetsState,
  useInventoryDispatch,
  useInventoryState,
} from "providers";
import { FormWrapper } from "components";
import {
  AssetStatusIDFormField,
  BornOnUTCFormField,
  CIDFormField,
  CropTypeIDFormField,
  DescriptionFormField,
  FieldFormField,
} from "./FormFields";
import { IPlantAsset } from "types/IAssetType";
import { ISelectOption } from "types";

const defaultValues = {
  id: "",
  fieldId: "",
  cropTypeId: "",
  bornOnUtc: "",
  description: "",
  cid: "",
  assetStatusId: "",
  geometry: "",
} as IPlantAsset;

export default function PlantAssetForm({
  assetStatuses,
  handleSave,
  handleDelete,
  getCancelHref,
}: {
  assetStatuses: ISelectOption[];
  handleSave: (_d: IPlantAsset, reset: () => void, isDirty: boolean) => void;
  handleDelete: (
    id: string,
    reset: () => void,
    defaultValues: IPlantAsset
  ) => void;
  getCancelHref: () => string;
}) {
  const { t } = useTranslation();
  const { zoomToField, setDrawOptions } = useInventoryDispatch();
  const { drawData } = useInventoryState();
  const { cropTypes, cropTypesState } = useOrgState();
  const { getCropTypes } = useOrgDispatch();

  const {
    currentEditFtr,
    deleteInventoryItemState,
    saveInventoryItemState,
  } = useAssetsState();

  const methods = useForm({
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    control,
    errors,
  } = methods;
  const { id } = watch();

  // fetch crop types if not already in org provider
  useEffect(() => {
    if (!cropTypes && !cropTypesState.isLoading && !cropTypesState.isError) {
      getCropTypes();
    }
  }, [cropTypes, getCropTypes, cropTypesState]);

  useEffect(() => {
    setDrawOptions({
      controls: {
        trash: true,
        point: true,
      },
      defaultMode: "draw_point",
    });
  }, [setDrawOptions]);

  async function onSave(d: IPlantAsset) {
    d.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    // server doesn't want these props if not set
    if (!d.id) {
      delete d.id;
    }
    if (!d.fieldId) {
      delete d.fieldId;
    }
    if (!d.assetGroupId) {
      delete d.assetGroupId;
    }
    return handleSave(d, reset, formState.isDirty);
  }
  return (
    <FormWrapper
      // TODO:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      methods={methods}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      // cancelHref={itemId ? `${baseRoute}/${itemId}` : baseRoute}
      cancelHref={getCancelHref()}
      saveState={saveInventoryItemState}
      deleteState={deleteInventoryItemState}
      onDelete={() => {
        return handleDelete(id, reset, defaultValues);
      }}
      onSubmit={handleSubmit(onSave)}
    >
      <Form.Group>
        <FieldFormField
          control={control}
          onChange={(item) => {
            zoomToField(item?.value);
          }}
        />
        <CIDFormField register={register} required />
        <CropTypeIDFormField
          control={control}
          isError={Boolean(errors?.cropTypeId)}
        />
        <BornOnUTCFormField
          control={control}
          label={`${t("inventory.assets.plantedDate")} *`}
        />
        <AssetStatusIDFormField
          control={control}
          assetStatusOpts={assetStatuses}
          isError={Boolean(errors?.assetStatusId)}
        />
        <DescriptionFormField register={register} />
        {errors?.geometry ? (
          <Form.Text className="text-danger">
            {t("common.geometryRequired")}
          </Form.Text>
        ) : null}
      </Form.Group>
    </FormWrapper>
  );
}
