import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Select } from "components";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryState } from "providers/InventoryProvider";
import {
  useOperationsDispatch,
  useOperationsState,
} from "providers/OperationsProvider";
import { useTranslation } from "react-i18next";
import {
  DateRangeInputs,
  FormField,
  FormWrapper,
  usePrevious,
} from "components";
import FieldAndSubfieldInputs from "../FieldAndSubfieldInputs";
import { SelectedIdsProps } from "../PropTypes";

const defaultValues = {
  id: "",
  fieldIds: "",
  subfieldIds: "",
  beginDateUtc: "",
  endDateUtc: "",
  productId: "",
  rate: "",
  geometry: "",
};

export default function AppliedOperationForm({ selectedIds, getCancelHref }) {
  const { t } = useTranslation();
  const { products, season } = useOrgState();
  const history = useHistory();
  const {
    currentEditFtr,
    saveAppliedState,
    deleteAppliedState,
  } = useOperationsState();
  const prevProducts = usePrevious(products);
  const {
    saveAppliedOperation,
    deleteAppliedOperation,
    fetchOperationsSummary,
  } = useOperationsDispatch();
  const { drawData } = useInventoryState();

  const methods = useForm({
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    setValue,
    control,
    errors,
  } = methods;
  const { id, beginDateUtc, endDateUtc, productId } = watch();
  const [productOps, setProductOpts] = useState(products);
  const currRateUom = productOps?.find((ao) => ao.id === productId)?.rateUom;
  // update product list when prop changes
  // (filter out seed products)
  useEffect(() => {
    if (products !== prevProducts) {
      const opts = products?.filter((p) => !p.isSeed) || [];
      setProductOpts(opts);
    }
  }, [products, prevProducts]);

  async function handleSave(d) {
    if (!formState.isDirty) {
      history.push(getCancelHref());
      return false;
    }
    const body = { ...d };
    body.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    body.rate = Number(body.rate);
    // need at least 1 fieldId or subfieldId
    // but cannot make either input required
    // therefore custom logic to handle validation here
    if (!body.fieldIds?.length && !body.subfieldIds?.length) {
      return false;
    }
    // server doesn't want these props if not set
    if (!body.id) {
      delete body.id;
    }
    if (!body.fieldIds?.length) {
      delete body.fieldIds;
    } else {
      body.fieldIds = JSON.parse(body.fieldIds);
    }
    if (!body.subfieldIds?.length) {
      delete body.subfieldIds;
    } else {
      body.subfieldIds = JSON.parse(body.subfieldIds);
    }
    const res = await saveAppliedOperation(body);
    if (!res.isError) {
      reset(d);
      history.push(getCancelHref());
      fetchOperationsSummary();
    }
    return res;
  }

  return (
    <FormWrapper
      methods={methods}
      defaultValues={defaultValues}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={getCancelHref()}
      saveState={saveAppliedState}
      deleteState={deleteAppliedState}
      // NOTE: ignoring geometry, using fieldIds
      ignoreGeom
      onDelete={async () => {
        const res = await deleteAppliedOperation(id);
        if (!res.isError) {
          reset(defaultValues);
          fetchOperationsSummary();
          history.push(getCancelHref(true));
        }
      }}
      onSubmit={handleSubmit(handleSave)}
    >
      <Form.Group>
        <DateRangeInputs
          control={control}
          Controller={Controller}
          startProp="beginDateUtc"
          endProp="endDateUtc"
          startDate={beginDateUtc ? new Date(beginDateUtc) : null}
          endDate={endDateUtc ? new Date(endDateUtc) : null}
          minDate={season?.beginOnUtc ? new Date(season.beginOnUtc) : null}
          maxDate={season?.endOnUtc ? new Date(season.endOnUtc) : null}
        />
        <FormField
          label={`${t("common.product")} *`}
          name="productId"
          control={control}
          rules={{ required: true }}
          htmlFor="product-select"
          render={(props) => (
            <Select
              id="product-select"
              options={productOps || []}
              value={productOps?.find((ao) => ao.id === props.value)}
              onChange={(item) => {
                props.onChange(item?.id || "");
              }}
              isClearable
            />
          )}
        >
          {errors?.productId ? (
            <Form.Text className="text-danger">
              {t("common.requiredField")}
            </Form.Text>
          ) : null}
        </FormField>
        <FormField label="Rate *" htmlFor="rate-input">
          <div className="d-flex">
            <Form.Control
              name="rate"
              ref={register}
              required
              id="rate-input"
              type="number"
              step={0.01}
              min={0}
            />
            <div className="ml-1 d-flex align-items-center justify-content-center">
              {currRateUom}
            </div>
          </div>
        </FormField>
        <FieldAndSubfieldInputs
          show={!currentEditFtr}
          selectedIds={selectedIds}
          control={control}
          setValue={setValue}
          formState={formState}
        />
      </Form.Group>
    </FormWrapper>
  );
}

AppliedOperationForm.propTypes = {
  ...SelectedIdsProps,
};
