import React from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useOperationsState } from "providers";
import { useTranslation } from "react-i18next";
import { DetailItem } from "components";

export default function HarvestedOperationDetails() {
  const { t } = useTranslation();
  const { itemId } = useParams<{ itemId: string }>();
  const { currentOperations } = useOperationsState();
  const op = currentOperations?.find((o: { id: string }) => o.id === itemId);

  return (
    <>
      <Row>
        <Col className="d-flex flex-wrap">
          <DetailItem value={op?.type} title={t("common.cropType")} />
          <DetailItem
            title={t("common.startDate")}
            value={
              op?.beginDateUtc
                ? new Date(op?.beginDateUtc).toLocaleDateString()
                : "--"
            }
          />
          <DetailItem
            title={t("common.endDate")}
            value={
              op?.endDateUtc
                ? new Date(op?.endDateUtc).toLocaleDateString()
                : "--"
            }
          />
          <DetailItem
            value={op?.averagePercentMoisture}
            title={t("inventory.operations.averagePercentMoisture")}
          />
          <DetailItem
            value={`${op?.averageYield ?? ""} ${op?.averageYieldUom ?? ""}`}
            title={t("common.averageYield")}
          />
          <DetailItem
            value={`${op?.totalMass ?? ""} ${op?.totalMassUom ?? ""}`}
            title={t("common.totalMass")}
          />
          {op?.count || op?.count === 0 ? (
            <DetailItem value={op?.count} title={t("common.count")} />
          ) : null}
        </Col>
      </Row>
    </>
  );
}
