import { useState } from "react";
import { Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { AssetsProvider } from "providers/AssetsProvider";
import { OperationsProvider } from "providers";
import OperationsReports from "./OperationsReports";
import "./index.scss";
import AssetsReports from "./AssetsReports";
import FieldsReports from "./FieldsReports";

export default function ReportsView() {
  const { t } = useTranslation();
  const [operationType, setOperationType] = useState();
  const [assetType, setAssetType] = useState();
  const { org, season } = useOrgState();
  return (
    <>
      <Route path={["/:org/:season/insights/reports/fields/:subfields?"]}>
        <h2 className="hide-print">
          {t("inventory.fields.title")} {t("insights.reports.title")}
        </h2>
        <FieldsReports />
      </Route>
      <Route path="/:org/:season/insights/reports/operations/:reportType?">
        <h2 className="hide-print">
          {t("inventory.operations.title")} {t("insights.reports.title")}
        </h2>
        <OperationsProvider
          org={org}
          seasonId={season?.id}
          type={operationType}
        >
          <OperationsReports
            // Pass method to update the operation type in the operation provider
            setOperationType={setOperationType}
          />
        </OperationsProvider>
      </Route>
      <Route path="/:org/:season/insights/reports/assets/:reportType?">
        <h2 className="hide-print">
          {t("inventory.assets.title")} {t("insights.reports.title")}
        </h2>
        <AssetsProvider org={org} seasonId={season?.id} type={assetType}>
          <AssetsReports setAssetType={setAssetType} />
        </AssetsProvider>
      </Route>
    </>
  );
}
