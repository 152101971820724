import React from "react";
import DatePicker from "lib/DatePicker";
import { useSettingsState } from "providers";
import { ReactDatePickerProps } from "react-datepicker";

export default function DatePickerWrapper(props: ReactDatePickerProps) {
  const { language } = useSettingsState();

  return (
    <DatePicker
      locale={language}
      popperProps={{
        // fixes issue of overflow hidden
        positionFixed: true,
      }}
      {...props}
    />
  );
}
