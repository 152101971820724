import { useEffect, useRef, useCallback, useState } from "react";
import useFetchWithToken from "components/useFetchWithToken";
import { ParsedImage } from "./Images/ImageComponent";

// TODO: get these types from styleguide
type IStatus = "idle" | "pending" | "fulfilled" | "failed";

interface IFetchState<T> {
  status: IStatus;
  data: T | null;
  errorMessage: string;
  isError: boolean;
  isLoading: boolean;
}

const blobToImage = (blob: Blob) => {
  return new Promise<HTMLImageElement>((resolve) => {
    const reader = new window.FileReader();
    reader.readAsDataURL(blob);
    reader.onload = (e) => {
      const img = new window.Image();
      img.onload = () => {
        resolve(img as HTMLImageElement);
      };
      img.src = e.target.result as string;
    };
  });
};

export default function useFetchPhotos() {
  const didCancel = useRef<boolean>();
  const { fetchData: fetchPhotos } = useFetchWithToken();
  const { fetchData: fetchItemPhotosById } = useFetchWithToken<Blob>();
  const {
    fetchData: savePhoto,
    state: savePhotoState,
    setDataState: setSavePhotoState,
  } = useFetchWithToken();
  const {
    fetchData: deletePhoto,
    state: deletePhotoState,
    setDataState: setDeletePhotoState,
  } = useFetchWithToken();
  const [photos, setPhotos] = useState<ParsedImage[]>();
  const [getPhotosLoading, setGetPhotosLoading] = useState<boolean>();

  useEffect(() => {
    didCancel.current = false;
    return function cleanup() {
      didCancel.current = true;
    };
  }, []);

  function savePhotoFn(image: File, url: string) {
    const formData = new window.FormData();
    formData.append("", image, image.name);
    return savePhoto(`${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
    });
  }

  function deletePhotoFn(url: string) {
    return deletePhoto(`${url}`, {
      method: "DELETE",
    });
  }

  const fetchItemPhotos = useCallback(
    (url: string) => {
      try {
        setGetPhotosLoading(true);
        return fetchPhotos(`${url}`, {
          transformResponse: async (res: string[]) => {
            const photoIds: string[] = [];
            const reqs: Promise<IFetchState<Blob>>[] = [];
            res.forEach(async (r) => {
              const req = fetchItemPhotosById(r, {
                apiOrigin: "",
                responseType: "blob",
              });
              reqs.push(req);
              photoIds.push(r.split("photos/")[1]);
            });
            const x = await Promise.all(reqs);
            const imgPromises: Promise<HTMLImageElement>[] = [];
            x.forEach((value) => {
              imgPromises.push(blobToImage(value.data));
            });
            Promise.all(imgPromises).then((images) => {
              let i = 0;
              const all: ParsedImage[] = [];
              images.forEach((image) => {
                const pid = photoIds[i];
                i += 1;
                // create object to pass to the photoswipe viewer
                // but also include source image
                all.push({
                  image,
                  src: image.src,
                  w: image.width,
                  h: image.height,
                  id: pid,
                });
              });
              if (!didCancel.current) {
                setPhotos(all);
                setGetPhotosLoading(false);
              }
            });
          },
        });
      } catch (e) {
        if (!didCancel.current) {
          setGetPhotosLoading(false);
        }
        return null;
      }
    },
    [fetchItemPhotosById, fetchPhotos]
  );
  return {
    fetchItemPhotos,
    photos,
    getPhotosLoading,
    savePhoto: savePhotoFn,
    deletePhoto: deletePhotoFn,
    savePhotoState,
    deletePhotoState,
    resetSave: useCallback(
      (d) => {
        if (!didCancel.current) {
          setSavePhotoState(d);
        }
      },
      [setSavePhotoState]
    ),
    resetDelete: useCallback(
      (d) => {
        if (!didCancel.current) {
          setDeletePhotoState(d);
        }
      },
      [setDeletePhotoState]
    ),
    reset: useCallback((d) => {
      if (!didCancel.current) {
        setPhotos(d);
      }
    }, []),
  };
}
