import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { Link, Redirect, Route, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { useSettingsState } from "providers/SettingsProvider";
import { useYieldFilters } from "./useYieldFilters";
import YieldFilterForm from "./YieldFilterForm";
import { DataTable, IconButton } from "components";

export default function DataFilters() {
  const { rootUrl, org, season, cropTypes } = useOrgState();
  const { language } = useSettingsState();
  const { addOrEdit } = useParams();
  const { editId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    yieldFilters,
    saveItem,
    deleteItem,
    state: yieldFiltersState,
    saveState,
    deleteState,
    resetErrors,
    getYieldFilters,
  } = useYieldFilters({
    org,
    seasonId: season?.id,
  });

  const editItem = yieldFilters?.find((d) => d.cropTypeId === editId);

  const columns = [
    {
      Header: "",
      id: "actions",
      isSortable: false,
      accessor: function Cell(row) {
        return (
          <IconButton
            icon="edit"
            onClick={(e) => {
              e.stopPropagation();
              history.push(
                `${rootUrl}/settings/filters/edit/${row.cropTypeId}`
              );
            }}
          />
        );
      },
    },
    {
      Header: t("common.cropType"),
      accessor: "crop",
    },
    {
      Header: t("common.yieldMin"),
      id: "yieldMin",
      accessor: (row) => {
        return row.yieldMin ? `${row.yieldMin} ${row.yieldUom}` : "--";
      },
    },
    {
      Header: t("common.yieldMax"),
      id: "yieldMax",
      accessor: (row) => {
        return row.yieldMax ? `${row.yieldMax} ${row.yieldUom}` : "--";
      },
    },
    {
      Header: t("common.speedMin"),
      id: "speedMin",
      accessor: (row) => {
        return row.speedMin ? `${row.speedMin} ${row.speedUom}` : "--";
      },
    },
    {
      Header: t("common.speedMax"),
      id: "speedMax",
      accessor: (row) => {
        return row.speedMax ? `${row.speedMax} ${row.speedUom}` : "--";
      },
    },
  ];

  useEffect(() => {
    getYieldFilters();
  }, [getYieldFilters, language]);

  if (!editItem && yieldFilters && editId) {
    return <Redirect to={`${rootUrl}/settings`} />;
  }
  return (
    <>
      <div className="d-flex mb-3">
        <h3>{t("settings.yieldDataFilters")}</h3>
        {/* only show Add button on filters home view */}
        <Route path="/:org/:season/settings" exact>
          <div className="ml-auto">
            <IconButton
              icon="add"
              className="btn-success d-flex"
              as={Link}
              to={`${rootUrl}/settings/filters/add`}
            />
          </div>
        </Route>
        <Route path="/:org/:season/settings/filters/:addOrEdit">
          <div className="ml-auto">
            <IconButton icon="back" as={Link} to={`${rootUrl}/settings`} />
          </div>
        </Route>
      </div>
      {/* only show data table on filters home view */}
      <Route path="/:org/:season/settings" exact>
        <DataTable
          tableProps={{ size: "sm" }}
          isFilterable={false}
          data={yieldFilters}
          columns={columns}
        />
      </Route>
      {/* show create or edit view */}
      <Route path="/:org/:season/settings/filters/:addOrEdit">
        <YieldFilterForm
          editId={editId}
          cropTypes={
            addOrEdit === "add"
              ? cropTypes?.map((i) => {
                  if (yieldFilters?.find((yf) => yf.cropTypeId === i.id)) {
                    return { disabled: true, ...i };
                  }
                  return { ...i };
                })
              : cropTypes?.filter((i) =>
                  yieldFilters?.find((yf) => yf.cropTypeId === i.id)
                )
          }
          data={editItem}
          onSubmit={async (d) => {
            const res = await saveItem(d, editItem ? "PUT" : "POST");
            if (!res.isError) {
              history.push(`${rootUrl}/settings`);
            }
          }}
          onDelete={async (d) => {
            const res = await deleteItem(d);
            if (!res.isError) {
              history.push(`${rootUrl}/settings`);
            }
          }}
          onCancel={() => {
            resetErrors();
          }}
        />
      </Route>
      {yieldFiltersState.isError || saveState.isError || deleteState.isError ? (
        <div className="mt-3">
          {yieldFiltersState?.isError ? (
            <Alert variant="danger">
              An error occurred fetching yield filters
            </Alert>
          ) : null}
          {saveState?.isError ? (
            <Alert variant="danger">
              An error occurred saving yield filter.
            </Alert>
          ) : null}
          {deleteState?.isError ? (
            <Alert variant="danger">
              An error occurred deleting yield filter.
            </Alert>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
