import React from "react";
import "./summary-card.scss";

export default function SummaryCardView({
  active,
  as: Wrapper = "div",
  children,
  childrenLocation = "left",
  className,
  dlClassName,
  dtClassName,
  ddClassName,
  label,
  onClick,
  style,
  to,
  value,
  variant,
}: {
  active?: boolean;
  as?: React.ElementType;
  children?: React.ReactNode;
  childrenLocation?: "left" | "right";
  className?: string;
  dlClassName?: string;
  dtClassName?: string;
  ddClassName?: string;
  label: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
  to?: string;
  value: React.ReactNode;
  variant?: "primary" | "secondary" | "success" | "danger" | "warn" | "link";
}) {
  return (
    <Wrapper
      style={style}
      // Link wrapper props
      to={to}
      // Button wrapper props
      onClick={onClick}
      active={active}
      variant={variant}
      className={`${
        active ? "active " : ""
      }summary-card h-100 d-flex flex-column rounded p-2 px-3 align-items-center text-left${
        className ? ` ${className}` : ""
      }`}
    >
      <dl
        className={`w-100 text-uppercase m-0${
          dlClassName ? ` ${dlClassName}` : ""
        }`}
      >
        <dt
          title={value?.toString()}
          className={`h4 ellipsis-overflow${
            dtClassName ? ` ${dtClassName}` : ""
          }`}
        >
          {value}
        </dt>
        <dd
          className={`m-0 d-flex small${ddClassName ? ` ${ddClassName}` : ""}`}
        >
          {childrenLocation === "left" ? <span>{children}</span> : null}

          <span>{label}</span>
        </dd>
      </dl>
      {childrenLocation === "right" ? children : null}
      {active ? <hr /> : null}
    </Wrapper>
  );
}
