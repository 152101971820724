import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useRef } from "react";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface ImageObj {
  src: string;
  file: File;
  w: number;
  h: number;
}

export default function ImageUpload({
  onChange,
}: {
  onChange: (_d: ImageObj[]) => void;
}) {
  const fileInputRef = useRef<HTMLInputElement>();

  function getImgSrc(input: HTMLInputElement) {
    return new Promise<ImageObj[]>((resolve) => {
      const imgs: ImageObj[] = [];

      let count = 0;
      const len = input.files.length;
      function handleOnload(e: ProgressEvent<FileReader>, file: File) {
        const image = new window.Image();
        image.src = e.target.result as string;
        image.onload = () => {
          imgs.push({
            src: e.target.result as string,
            file,
            w: image.width,
            h: image.height,
          });
          count += 1;
          if (count === len) {
            resolve(imgs);
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          }
        };
      }
      if (input.files && len) {
        for (let i = 0; i < len; i += 1) {
          const reader = new window.FileReader();
          reader.onload = (e) => {
            handleOnload(e, input.files[i]);
          };
          reader.readAsDataURL(input.files[i]);
        }
      }
    });
  }
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    getImgSrc(e.currentTarget).then((imgs) => {
      onChange(imgs);
    });
  }
  return (
    <Button
      sx={{ height: "100%", width: "100%" }}
      component="label"
      color="success"
      role={undefined}
      variant="contained"
      tabIndex={-1}
    >
      <AddIcon />
      <VisuallyHiddenInput
        accept=".gif, .jpeg, .jpg, .png"
        type="file"
        onChange={handleChange}
      />
    </Button>
  );
}
