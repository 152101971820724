import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch } from "providers/InventoryProvider";
import ConservationForm from "./ConservationForm";
import { FieldSubfieldTableTabs } from "../FieldSubfieldTableTabs";

export function CreateOrEditConservation() {
  const { editId } = useParams<{ editId: string }>();
  const { t } = useTranslation();
  const { rootUrl } = useOrgState();
  const [selectedIds, setSelectedIds] = useState<{
    fieldIds: string[] | null;
    subfieldIds: string[] | null;
  }>({ fieldIds: null, subfieldIds: null });

  const {
    showMap,
    hideMap,
    enableDraw,
    disableDraw,
    setDrawOptions,
    setColSizes,
  } = useInventoryDispatch();

  useEffect(() => {
    if (!editId) {
      setColSizes([12, 0]);
    }
    return () => {
      setColSizes([7, 5]);
    };
  }, [editId, setColSizes]);

  useEffect(() => {
    enableDraw();
    return disableDraw;
  }, [enableDraw, disableDraw]);

  useEffect(() => {
    setDrawOptions({
      controls: {
        trash: true,
        polygon: true,
        rectangle: true,
      },
      defaultMode: "draw_polygon",
    });
  }, [setDrawOptions]);

  useEffect(() => {
    if (!editId) {
      hideMap();
    }
    return showMap;
  }, [editId, hideMap, showMap]);

  return (
    <div className="d-flex flex-column h-100">
      <Breadcrumb listProps={{ className: "p-0 mb-0" }}>
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: `${rootUrl}/inventory/conservation` }}
        >
          {t("common.carbon")}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {!editId ? t("common.add") : t("common.edit")}
        </Breadcrumb.Item>
      </Breadcrumb>
      {/* NOTE: handling field select table outside of Form to prevent 
      performance issues with too many re-renders of the datatable */}
      <Row className="overflow-auto">
        <Col lg={!editId ? 6 : 12}>
          <ConservationForm
            subfieldIds={selectedIds.subfieldIds}
            fieldIds={selectedIds.fieldIds}
          />
        </Col>
        {!editId ? (
          <Col
            lg={6}
            style={{
              overflow: "auto",
              height: "100%",
            }}
          >
            <FieldSubfieldTableTabs
              onChange={(d) => {
                setSelectedIds(d);
              }}
            />
          </Col>
        ) : null}
      </Row>
    </div>
  );
}
