import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Form, Alert } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryState } from "providers/InventoryProvider";
import { useFieldsDispatch } from "providers/FieldsProvider";
import {
  useConservationDispatch,
  useConservationState,
} from "providers/ConservationProvider";
import DateRangeInputs from "components/Forms/DateRangeInputs";
import { FormWrapper } from "components";
import { ConfirmAlert } from "lib";
import { Box, Chip, Typography } from "@mui/material";
import { useSubfields } from "api/useSubfields";
import Autocomplete from "lib/Select/Autocomplete";

const defaultValues = {
  conservationStatusId: null,
  conservationTypeId: "",
  fieldIds: "",
  subfieldIds: null,
  beginOnUtc: null,
  endOnUtc: null,
  geometry: null,
  description: "",
  gpsSourceTypeId: "e9aff444-911c-40a3-8222-614d70162d38",
};

function dateRangeOverlaps(a_start, a_end, b_start, b_end) {
  if (a_end < b_start || a_start > b_end) {
    return false;
  }
  if (a_start < b_start && b_start < a_end) return true; // b starts in a
  if (a_start < b_end && b_end < a_end) return true; // b ends in a
  if (a_start < b_start && b_end < a_end) return true; // b in a
  if (b_start < a_start && a_end < b_end) return true; // a in b
  return false;
}

export default function ConservationForm({ fieldIds, subfieldIds }) {
  const history = useHistory();
  const alertRef = useRef();
  const { t } = useTranslation();
  const { getFieldsArea, getAreaUom } = useFieldsDispatch();
  const { drawData } = useInventoryState();
  const { org, rootUrl, seasons, season } = useOrgState();
  const { currentEditFtr } = useConservationState();
  const [showSeasonAlert, setShowSeasonAlert] = useState();
  const { getSubfieldArea } = useSubfields(org, season?.id);

  const {
    conservationTypesState,
    conservationStatusesState,
    deleteState,
    saveState,
  } = useConservationState();
  const {
    saveConservationArea,
    deleteConservationArea,
    fetchSummary,
  } = useConservationDispatch();
  const methods = useForm({
    defaultValues: { ...defaultValues, ...currentEditFtr },
  });
  const {
    formState: { isSubmitted },
    handleSubmit,
    register,
    reset,
    watch,
    control,
    setValue,
    errors,
  } = methods;
  const {
    id,
    beginOnUtc,
    endOnUtc,
    conservationTypeId,
    conservationStatusId,
  } = watch();

  // set fieldId input based on prop
  useEffect(() => {
    if (fieldIds?.length) {
      setValue("fieldIds", fieldIds);
    } else {
      setValue("fieldIds", "");
    }
  }, [fieldIds, setValue]);
  useEffect(() => {
    if (subfieldIds?.length) {
      setValue("subfieldIds", subfieldIds);
    } else {
      setValue("subfieldIds", "");
    }
  }, [subfieldIds, setValue]);

  async function handleSave(d) {
    const body = { ...d };
    body.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    if (!body.fieldIds && !body.subfieldIds && !body.geometry) {
      return;
    }
    if (!body.fieldIds) {
      delete body.fieldIds;
    }
    if (!body.subfieldIds) {
      delete body.subfieldIds;
    }
    const res = await saveConservationArea(body);
    if (!res.isError) {
      reset({ ...defaultValues, ...body });
      history.push(`${rootUrl}/inventory/conservation`);
      fetchSummary();
    }
    return res;
  }

  const onSave = async (d) => {
    try {
      const startInput = new Date(d.beginOnUtc);
      const endInput = new Date(d.endOnUtc);
      const inSeasons = seasons.filter((i) => {
        const seasonStart = new Date(i.beginOnUtc);
        const seasonEnd = new Date(i.endOnUtc);
        return dateRangeOverlaps(startInput, endInput, seasonStart, seasonEnd);
      }, false);
      if (inSeasons?.length) {
        const currSeasonId = season?.id;
        const ids = inSeasons?.map((s) => s.id);
        if (!ids.includes(currSeasonId)) {
          // TODO: lang
          setShowSeasonAlert({
            data: d,
            message: (
              <>
                <Alert variant="info">
                  * NOTE: This conservation area falls outside of your current
                  season, but would fall under the following:
                  <div>
                    {inSeasons
                      .map((s) => s.name)
                      .sort()
                      .join(",")}
                  </div>
                </Alert>
              </>
            ),
          });
          return null;
        }
        // if it intersects the current season, go ahead and save
        handleSave(d);
      } else {
        setShowSeasonAlert({
          data: d,
          message: (
            <Alert variant="danger">
              * WARNING: This conservation area falls outside of all existing
              seasons and you will not be able to view it.
            </Alert>
          ),
        });
      }
      return inSeasons;
    } catch (e) {
      console.error("Failed trying to submit");
      return e;
    }
  };
  return (
    <FormWrapper
      methods={methods}
      defaultValues={defaultValues}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={`${rootUrl}/inventory/conservation`}
      geometryRequired={false}
      saveState={saveState}
      deleteState={deleteState}
      onDelete={async () => {
        const res = await deleteConservationArea(id);
        if (!res.isError) {
          reset(defaultValues);
          fetchSummary();
          history.push(`${rootUrl}/inventory/conservation`);
        }
      }}
      onSubmit={handleSubmit(onSave)}
    >
      <Form.Group>
        <input ref={register} required type="hidden" name="gpsSourceTypeId" />
        <Form.Label htmlFor="conservation-cid" className="input-label mt-3">
          <div className="label">ID</div>
          <div className="input-container">
            <Form.Control maxLength={255} name="cid" ref={register} />
          </div>
        </Form.Label>
        <DateRangeInputs
          control={control}
          Controller={Controller}
          startDate={beginOnUtc ? new Date(beginOnUtc) : null}
          endDate={endOnUtc ? new Date(endOnUtc) : null}
        />

        <Form.Label htmlFor="conservation-type" className="input-label mt-3">
          <div className="label">{t("common.type")} *</div>
          <div className="input-container">
            <Controller
              name="conservationTypeId"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Autocomplete
                  options={conservationTypesState?.data}
                  value={
                    conservationTypesState?.data?.find(
                      (f) => f.id === conservationTypeId
                    ) ?? null
                  }
                  onChange={(_e, item) => {
                    // eslint-disable-next-line react/prop-types
                    props.onChange(item.value);
                  }}
                />
              )}
            />
            {errors?.conservationTypeId ? (
              <Form.Text className="text-danger">
                {t("common.requiredField")}
              </Form.Text>
            ) : null}
          </div>
        </Form.Label>

        <Form.Label htmlFor="conservation-status" className="input-label mt-3">
          <div className="label">{t("common.status")} *</div>
          <div className="input-container">
            <Controller
              name="conservationStatusId"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Autocomplete
                  options={conservationStatusesState?.data}
                  value={
                    conservationStatusesState?.data?.find(
                      (f) => f.id === conservationStatusId
                    ) ?? null
                  }
                  onChange={(_e, item) => {
                    // eslint-disable-next-line react/prop-types
                    props.onChange(item.value);
                  }}
                />
              )}
            />
            {errors?.conservationStatusId ? (
              <Form.Text className="text-danger">
                {t("common.requiredField")}
              </Form.Text>
            ) : null}
          </div>
        </Form.Label>
        <Form.Label htmlFor="carbon-credit-switch" className="py-1 input-label">
          <div className="label">{t("inventory.conservation.applyToCC")}:</div>
          <div className="input-container d-flex">
            <Form.Check
              ref={register}
              name="isCarbonCredit"
              type="switch"
              id="carbon-credit-switch"
              label=""
            />
          </div>
        </Form.Label>
      </Form.Group>
      {!id ? (
        <>
          <div className="mt-3">
            <Box sx={{ textTransform: "uppercase" }}>
              <Box sx={{ mb: -0.5, display: "flex" }}>
                <Typography sx={{ mr: 1 }}>
                  {t("inventory.fields.title")}
                </Typography>
                <Chip
                  size="small"
                  color="primary"
                  label={<>{(fieldIds && fieldIds.length) || 0}</>}
                />
              </Box>
              {fieldIds?.length ? (
                <Typography variant="caption">
                  {getFieldsArea(fieldIds)?.toLocaleString() || 0}{" "}
                  {getAreaUom() || t("common.area")}
                </Typography>
              ) : null}
              <Box sx={{ mt: 3, mb: -0.5, display: "flex" }}>
                <Typography sx={{ mr: 1 }}>
                  {t("inventory.subfields.title")}
                </Typography>
                <Chip
                  size="small"
                  color="primary"
                  label={<>{(subfieldIds && subfieldIds.length) || 0}</>}
                />
              </Box>
              {subfieldIds?.length ? (
                <Typography variant="caption">
                  {getSubfieldArea(subfieldIds)?.toLocaleString() || 0}{" "}
                  {getAreaUom() || t("common.area")}
                </Typography>
              ) : null}
            </Box>

            <Controller
              name="fieldIds"
              control={control}
              render={(props) => (
                <input
                  type="hidden"
                  name="fieldIds"
                  // eslint-disable-next-line react/prop-types
                  value={props.value || ""}
                />
              )}
            />
            <Controller
              name="subfieldIds"
              control={control}
              render={(props) => (
                <input
                  type="hidden"
                  name="subfieldIds"
                  // eslint-disable-next-line react/prop-types
                  value={props.value || ""}
                />
              )}
            />
          </div>
          {isSubmitted && !fieldIds?.length && !subfieldIds?.length ? (
            <Form.Text className="mt-3 text-danger">
              {t("inventory.operations.oneFieldSubfieldRequired")}
            </Form.Text>
          ) : null}
        </>
      ) : null}
      <div ref={alertRef} />
      <ConfirmAlert
        show={Boolean(showSeasonAlert)}
        target={alertRef.current}
        alertType="popover"
        title={t("common.confirm")}
        cancelText={t("common.no")}
        confirmText={t("common.yes")}
        message={
          <div className="d-flex flex-column">
            {showSeasonAlert?.message}
            <span style={{ fontWeight: "bold" }}>{t("common.areYouSure")}</span>
          </div>
        }
        onConfirm={async () => {
          handleSave(showSeasonAlert.data);
          setShowSeasonAlert(false);
        }}
        onCancel={() => {
          setShowSeasonAlert(false);
        }}
      />
    </FormWrapper>
  );
}

ConservationForm.defaultProps = {
  fieldIds: [],
  subfieldIds: null,
};

ConservationForm.propTypes = {
  fieldIds: PropTypes.arrayOf(PropTypes.string),
  subfieldIds: PropTypes.arrayOf(PropTypes.string),
};
