import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import { useTheme } from "@mui/material/styles";
import { useOrgDispatch, useOrgState } from "providers/OrgProvider";
import { useAssetsState } from "providers/AssetsProvider";
import FieldsSummaryView from "../InventoryView/Fields/FieldsSummaryView";
import { MainLayout, PageLayout, PageColumn } from "../MainLayout";
import OperationsOverview from "./OperationsOverview";
import AssetsOverview from "./AssetsOverview";
import DashboardMapView from "./DashboardMapView";
import { useURLSearchParams, useSetURLSearchParams } from "components";
import {
  useOperationsDispatch,
  useOperationsState,
} from "../../providers/OperationsProvider";
import { useSetAppDataLoading } from "../../components";
import "./dashboard.scss";

export default function DashboardView() {
  const {
    org,
    season,
    fieldsSummaryState,
    darkOrgLogoState,
    lightOrgLogoState,
  } = useOrgState();
  const theme = useTheme();
  const { t } = useTranslation();
  const { dispatch } = useOrgDispatch();
  const { isFetchingData: operationsFetching } = useOperationsState();
  const { fetchOperations, fetchOperationsSummary } = useOperationsDispatch();
  const { assetsBySeasonQuery } = useAssetsState();
  const query = useURLSearchParams();
  const type = query.get("operationType") || "planted";
  const setQuery = useSetURLSearchParams();
  const [operationType, setOperationType] = useState(type);
  useSetAppDataLoading({
    dispatch,
    isFetchingData:
      assetsBySeasonQuery.isLoading ||
      operationsFetching ||
      fieldsSummaryState.isLoading,
  });
  useEffect(() => {
    setQuery([["operationType", operationType]]);
  }, [operationType, type, setQuery]);

  useEffect(() => {
    fetchOperations();
  }, [fetchOperations]);

  useEffect(() => {
    fetchOperationsSummary();
  }, [fetchOperationsSummary]);

  return (
    <MainLayout className="dashboard">
      <h2 aria-label={t("dashboard.title")} className="mb-0">
        <div className="d-flex align-items-center mb-2 overflow-hidden">
          <img
            alt={"Org logo"}
            // height to match the other pages text headers
            style={{ height: "30px" }}
            src={
              theme.palette.mode === "light"
                ? lightOrgLogoState.data?.src
                : darkOrgLogoState.data?.src
            }
          />
        </div>
      </h2>

      <PageLayout>
        <PageColumn xl={4}>
          <Card className="flex-grow-1">
            <FieldsSummaryView org={org} seasonId={season?.id} />
            <DashboardMapView />
          </Card>
        </PageColumn>
        <PageColumn xl={8}>
          <Card className="mb-3 flex-grow-1">
            <OperationsOverview
              operationType={operationType}
              setOperationType={setOperationType}
            />
          </Card>
          <Card className="flex-grow-1">
            <AssetsOverview />
          </Card>
        </PageColumn>
      </PageLayout>
    </MainLayout>
  );
}
