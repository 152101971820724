import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Card } from "react-bootstrap";
import FieldsIcon from "icons/FieldsIcon";
import { useOrgState } from "providers/OrgProvider";
import { useFieldsState } from "providers/FieldsProvider";
import { PageColumn, PageLayout } from "views/MainLayout";
import { useTranslation } from "react-i18next";
import ReportView from "../ReportView";
import ReportFieldsSelect from "../ReportFieldsSelect";
import useAreaColumn from "components/Table/useAreaColumn";

export default function FieldsReports() {
  const { t } = useTranslation();
  const { rootUrl, orgData, season } = useOrgState();
  const { fieldListState } = useFieldsState();
  const { subfields } = useParams();
  // const [expanded, setExpanded] = useState();
  const [selectedFields, setSelectedFields] = useState();
  const areaHeader = useAreaColumn();
  // useEffect(() => {
  //   setExpanded(getDefaultExpandedRows(selectedFields));
  // }, [selectedFields]);

  return (
    <PageLayout className="cai-reports h-100">
      <PageColumn lg={6} xl={4} className="hide-print">
        <Card
          className="overflow-auto"
          style={{ maxHeight: "175px", minHeight: "105px" }}
        >
          <Container className="report-type-container">
            <h3>{t("insights.selectReportType")}</h3>
            <div className="d-flex select-reports-container justify-content-md-center">
              <span className={`report-link${!subfields ? " active" : ""}`}>
                <Link to={`${rootUrl}/insights/reports/fields`}>
                  <FieldsIcon
                    size={40}
                    className="report-icon"
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "rgb(0, 141, 205)",
                      margin: "0 auto",
                    }}
                  />
                  <div className="mt-2 small">
                    {t("inventory.fields.title")}
                  </div>
                </Link>
              </span>
              {/* <span className={`report-link${subfields ? ' active' : ''}`}>
                <Link to={`${rootUrl}/insights/reports/fields/subfields`}>
                  <PlantIcon
                    size={40}
                    className="report-icon"
                    style={{
                      borderRadius: '50%',
                      // backgroundColor: i.color,
                      margin: '0 auto',
                    }}
                  />
                  <div className="mt-2 small">
                    {t('inventory.subfields.title')}
                  </div>
                </Link>
              </span> */}
            </div>
          </Container>
        </Card>
        <Card className="mt-3" style={{ flex: 1, minHeight: "250px" }}>
          <ReportFieldsSelect
            // subtypes={subtypes}
            // isLoading={isFetchingData}
            fields={fieldListState?.data}
            onChange={(data) => {
              // console.log('change', data);
              setSelectedFields(data);
              // const selectedFields = filteredFields?.filter((co) => {
              //   return data.find((d) => d.fieldId === co.fieldId);
              // });
              // // loop selected fields and get their children
              // // (filtered by subtype, if selected)
              // const filteredAssets = selectedFields.reduce((curr, item) => {
              //   let filteredChildren = item.children;
              //   if (subtypeItem) {
              //     filteredChildren = item.children?.filter((c) => {
              //       return c.subtypeId === subtypeItem.value;
              //     });
              //   }
              //   return curr.concat(filteredChildren || []);
              // }, []);
              // setSelectedAssets(filteredAssets);
            }}
          />
        </Card>
      </PageColumn>
      <PageColumn lg={6} xl={8} className="print-full-width">
        <ReportView
          // isLoading={isFetchingData}
          org={orgData?.name}
          season={season}
          color="rgb(0, 141, 205)"
          titleIcon={FieldsIcon}
          title={`${t("inventory.fields.title")} ${t(
            "insights.reports.title"
          )}`}
          columns={[
            {
              Header: t("common.field"),
              accessor: "name",
            },
            {
              Header: t("common.farm"),
              accessor: "farm",
            },
            areaHeader,
          ]}
          // columns={getAssetReportCols(currentAssetType?.asset?.toLowerCase())}
          data={selectedFields}
          groupBy={[]}
          // expanded={expanded}
          sortBy={[
            {
              id: "name",
              desc: false,
            },
          ]}
        />
      </PageColumn>
    </PageLayout>
  );
}

FieldsReports.defaultProps = {};

FieldsReports.propTypes = {
  // setAssetType: PropTypes.func.isRequired,
};
