import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useInventoryDispatch } from "providers/InventoryProvider";
import { useOrgDispatch } from "providers/OrgProvider";
import { useSubfieldsState } from "providers/SubfieldsProvider";
import CardStack from "../../CardStack";
import SubfieldsSummaryView from "./SubfieldsSummaryView";
import SubfieldsMapThemesView from "./SubfieldsMapThemesView";
import SubfieldsListView from "./SubfieldsListView";
import { CreateOrEditSubfield } from "./CreateOrEditSubfield";
import { useSetAppDataLoading } from "../../../components";

export function SubfieldsView() {
  const { t } = useTranslation();
  const { dispatch } = useOrgDispatch();
  const location = useLocation();
  const [expanded, setExpanded] = useState();
  const {
    setTitle,
    setColSizes,
    setLegendData,
    setInventoryFtrs,
    setLabelProp,
  } = useInventoryDispatch();
  const { subfieldsGeodataState, isFetchingData } = useSubfieldsState();
  const { editId } = useParams();
  const isAdd = location.pathname.endsWith("/add");

  useSetAppDataLoading({ dispatch, isFetchingData });

  useEffect(() => {
    setTitle(t("inventory.subfields.title"));
    setColSizes([7, 5]);
    setLabelProp("cid");
    setLegendData({
      title: t("common.type"),
      colorProp: "subfieldTypeColor",
      dataProp: "subfieldType",
    });
  }, [setTitle, setColSizes, setLegendData, setLabelProp, t]);

  useEffect(() => {
    setInventoryFtrs(subfieldsGeodataState?.data);
  }, [subfieldsGeodataState, setInventoryFtrs]);

  useEffect(() => {
    return () => {
      setInventoryFtrs(null);
    };
  }, [setInventoryFtrs]);

  return (
    <>
      <Route path="/:org/:season/inventory/subfields/:subfieldType?">
        <CardStack
          topHeight={"400px"}
          expanded={Boolean(editId || isAdd || expanded)}
          topChildren={
            <>
              <Card className="mb-3 d-flex p-3">
                <SubfieldsSummaryView />
              </Card>
              <Card className="flex-grow-1">
                <SubfieldsMapThemesView />
              </Card>
            </>
          }
        >
          <Card className="h-100 overflow-auto">
            <Switch>
              <Route
                path={[
                  `/:org/:season/inventory/subfields/:subfieldType/:editId/edit`,
                  `/:org/:season/inventory/subfields/:subfieldType/add`,
                ]}
                exact
              >
                <div className="p-3 overflow-auto h-100">
                  <CreateOrEditSubfield />
                </div>
              </Route>
              <Route path="/:org/:season/inventory/subfields/:subfieldType?">
                <SubfieldsListView
                  onToggle={() => {
                    setExpanded(!expanded);
                  }}
                  toggled={expanded}
                />
              </Route>
            </Switch>
          </Card>
        </CardStack>
      </Route>
    </>
  );
}

SubfieldsView.defaultProps = {};

SubfieldsView.propTypes = {};
