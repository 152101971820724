import React, { useRef } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ApiStatus, DeleteButton, PromptView } from "components";
import LoadingButton from "components/LoadingButton";
import { IFetchResponse } from "types";
import { Button } from "@mui/material";

export interface IBaseForm {
  children: unknown;
  isDirty?: boolean;
  onSubmit: (_e: React.FormEvent<HTMLFormElement>) => Promise<unknown> | false;
  onDelete?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  data?: Record<string, unknown>;
  saveState: IFetchResponse;
  deleteState?: IFetchResponse;
  cancelHref: string;
  className?: string;
  style?: React.CSSProperties;
}

const BaseForm = React.forwardRef<HTMLFormElement, IBaseForm>(
  (
    {
      children,
      isDirty: dirty,
      onSubmit,
      onDelete,
      data,
      saveState,
      deleteState,
      cancelHref,
      className,
      style,
      onConfirm,
      onCancel,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [isDirty, setIsDirty] = React.useState(dirty);

    const cancelBtnRef = useRef();
    const { id } = data || {};

    return (
      <Form
        ref={ref}
        onChange={() => {
          setIsDirty(true);
        }}
        style={style}
        className={className}
        onSubmit={async (e) => {
          e.preventDefault();
          setIsDirty(false);
          try {
            const res = await onSubmit(e);
            if (res === false) {
              setIsDirty(true);
              return;
            }
          } catch (e) {
            setIsDirty(true);
          }
        }}
      >
        {children}
        <hr className="border-light" />
        <ApiStatus saveState={saveState} deleteState={deleteState} />
        <Form.Group className="d-flex">
          <LoadingButton
            isLoading={saveState?.isLoading}
            color="primary"
            // variant="outline-success"
            variant="contained"
            type="submit"
          >
            {t("common.save")}
          </LoadingButton>

          <Link to={cancelHref} ref={cancelBtnRef}>
            <Button color="secondary" className="ml-1">
              {t("common.cancel")}
            </Button>
          </Link>
          {id ? (
            <DeleteButton
              isLoading={deleteState?.isLoading}
              handleDelete={onDelete}
            />
          ) : null}
        </Form.Group>
        <PromptView
          onConfirm={onConfirm}
          onCancel={onCancel}
          when={dirty || isDirty}
          target={cancelBtnRef.current}
          alertType="popover"
          message={t("common.confirmUnsavedChanges")}
          cancelText={t("common.no")}
          confirmText={t("common.yes")}
          title={t("common.confirm")}
        />
      </Form>
    );
  }
);
BaseForm.displayName = "BaseForm";

export default BaseForm;
