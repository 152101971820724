import React from "react";
import { Row, Col, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDamageState } from "providers/DamageProvider";
import SummaryCardView from "components/SummaryCardView";

export default function DamageSummaryView() {
  const { t } = useTranslation();
  const { summaryDataState } = useDamageState();

  const count =
    summaryDataState?.data?.aoiCount || summaryDataState?.data?.aoiCount === 0
      ? summaryDataState?.data?.aoiCount
      : "--";
  const area =
    summaryDataState?.data?.totalArea || summaryDataState?.data?.totalArea === 0
      ? summaryDataState?.data?.totalArea
      : "--";

  return (
    <>
      <Row className="flex-grow-1 row align-items-center m-3">
        <Col className="d-flex flex-column h-100">
          <SummaryCardView
            value={count}
            label={t("inventory.damage.fieldsAssessed")}
            backgroundColor="primary"
          />
        </Col>
        <Col className="d-flex flex-column h-100">
          <SummaryCardView
            value={area?.toLocaleString()}
            label={`${
              summaryDataState?.data?.totalAreaUom?.toUpperCase() ||
              t("common.area")
            }`}
            backgroundColor="success"
          />
        </Col>
      </Row>
      {summaryDataState.isError ? (
        <Alert className="p-0 mx-3" variant="danger">
          <h6 className="text-center">{summaryDataState.errorMessage}</h6>
        </Alert>
      ) : null}
    </>
  );
}
