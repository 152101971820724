import SvgIcon from "@mui/material/SvgIcon";
import { IconProps } from "./IconProps";

export default function MapLayersIcon({ fill, size, sx }: IconProps) {
  return (
    <SvgIcon fontSize={size} sx={sx}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.04 78">
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="29.17"
            y1="53.92"
            x2="118.21"
            y2="53.92"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.12" stopColor="#d5e100" />
            <stop offset="0.99" stopColor="#79c80e" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="29.17"
            y1="78.9"
            x2="118.22"
            y2="78.9"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="aqua" />
            <stop offset="1" stopColor="#0071c1" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-3"
            x1="29.17"
            y1="96.98"
            x2="118.21"
            y2="96.98"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.23" stopColor="#00fafc" />
            <stop offset="0.51" stopColor="#d5e100" />
            <stop offset="0.99" stopColor="#79c80e" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-4"
            x1="89.01"
            y1="42.79"
            x2="94.56"
            y2="42.79"
            xlinkHref="#linear-gradient"
          />
        </defs>
        <g>
          <path
            style={{ fill: fill ?? "#fff" }}
            d="M89,42.75A2.78,2.78,0,1,0,91.84,40,2.72,2.72,0,0,0,89,42.75Z"
            transform="translate(-29.17 -33)"
          />
          <path
            style={{ fill: fill ?? "url(#linear-gradient)" }}
            d="M29.17,52.87c.67-2.31,2.32-3.51,4.49-4.41C44.94,43.79,56.16,39,67.41,34.29a16,16,0,0,1,12.44-.06c1.1.44,2.19.9,3.28,1.37a2.77,2.77,0,0,1,1.53,3.67A2.82,2.82,0,0,1,81,40.73a59.19,59.19,0,0,0-5.81-2,8.71,8.71,0,0,0-5.52.64Q54.41,45.72,39.2,52.08l-1.12.46A1.41,1.41,0,0,0,37,53.91c0,.76.46,1.12,1.1,1.39q5.13,2.12,10.25,4.26c6.86,2.86,13.73,5.7,20.57,8.59a11.84,11.84,0,0,0,9.6,0q15.36-6.45,30.73-12.84c.58-.24,1.13-.49,1.21-1.21a1.33,1.33,0,0,0-.92-1.5c-1.33-.54-2.67-1.1-4-1.67a2.73,2.73,0,0,1-1.61-3.75,2.77,2.77,0,0,1,3.82-1.27c2.33,1,4.68,2,7,3a5.34,5.34,0,0,1,.05,10q-17.34,7.32-34.7,14.6a16,16,0,0,1-12.76,0q-17.4-7.29-34.78-14.63A6,6,0,0,1,29.17,55Z"
            transform="translate(-29.17 -33)"
          />
          <path
            style={{
              fill: fill ?? "url(#linear-gradient-2)",
            }}
            d="M29.17,71a6.31,6.31,0,0,1,4.42-4.36c1.18-.4,2.29-1,3.43-1.47a2.77,2.77,0,0,1,3.72,1.39,2.68,2.68,0,0,1-1.45,3.6c-.4.19-.8.35-1.2.51-.64.24-1.12.59-1.13,1.36s.46,1.13,1.1,1.39l15,6.27c5.12,2.14,10.27,4.23,15.36,6.43a12.4,12.4,0,0,0,10.24.08c10.17-4.3,20.38-8.51,30.57-12.76.63-.26,1.14-.57,1.16-1.34a1.45,1.45,0,0,0-1.14-1.45c-.43-.16-.86-.32-1.28-.52a2.71,2.71,0,0,1-1.32-3.66,2.76,2.76,0,0,1,3.69-1.29c1.42.62,2.86,1.23,4.3,1.83a5.35,5.35,0,0,1,0,10.06Q97.38,84.32,80.08,91.6a15.79,15.79,0,0,1-12.59.05c-11.68-4.88-23.34-9.82-35-14.72a5.84,5.84,0,0,1-3.31-3.88Z"
            transform="translate(-29.17 -33)"
          />
          <path
            style={{
              fill: fill ?? "url(#linear-gradient-3)",
            }}
            className="cls-4"
            d="M29.17,89.05a6.34,6.34,0,0,1,4.42-4.37c1.2-.4,2.35-1,3.52-1.49a2.74,2.74,0,0,1,3.63,1.42,2.7,2.7,0,0,1-1.37,3.57c-.47.22-1,.4-1.44.6a1.33,1.33,0,0,0-1,1.3,1.38,1.38,0,0,0,1,1.35l16.81,7c4.67,2,9.35,3.86,14,5.85a11.94,11.94,0,0,0,9.76,0q15.34-6.43,30.72-12.83a2.68,2.68,0,0,0,.82-.48,1.33,1.33,0,0,0-.39-2.12c-.51-.26-1.07-.44-1.59-.68a2.7,2.7,0,0,1-1.45-3.61,2.76,2.76,0,0,1,3.64-1.41l4.47,1.9a5.35,5.35,0,0,1-.07,10l-21.5,9c-4.34,1.83-8.68,3.67-13,5.48a16.15,16.15,0,0,1-12.91,0C55.84,104.87,44.45,100,33,95.28a6.32,6.32,0,0,1-3.86-4.14Z"
            transform="translate(-29.17 -33)"
          />
          <path
            d="M89,42.75a2.78,2.78,0,1,1,2.76,2.81A2.73,2.73,0,0,1,89,42.75Z"
            transform="translate(-29.17 -33)"
            fill={fill ?? "url(#linear-gradient-4)"}
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
