import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import ObservationPhotoCard from "./ObservationPhotoCard";
import PhotoSwipeViewer from "components/Images/PhotoSwipeViewer";
import ImageUpload from "./ImageUpload";
import { useObservationPhotos } from "api/observations/useObservationPhotos";
import { useParams } from "react-router-dom";
import { IUrlParams } from "types";
import { useObservationPhotoPost } from "api/observations/useObservationPhotoPost";

function ObservationPhotosStack() {
  const { org, observationId } = useParams<IUrlParams>();
  const photosQ = useObservationPhotos({ org, observationId });
  const savePhotoQ = useObservationPhotoPost({ org, observationId });
  const photoSwipeBtnRef = useRef<HTMLButtonElement>();
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const handleOpen = (i: number) => {
    setIsOpen(true);
    setIndex(i);
  };

  const handleClose = () => {
    setIsOpen(false);
    if (photoSwipeBtnRef.current) {
      photoSwipeBtnRef.current.focus();
      photoSwipeBtnRef.current = null;
    }
  };

  return (
    <Box>
      <Stack direction="row" gap={3}>
        {photosQ.isLoading
          ? [1, 2, 3].map((i) => (
              <Skeleton
                key={i}
                variant="rounded"
                sx={{ height: 175, width: 175 }}
              />
            ))
          : photosQ.data?.map((item, i) => (
              <ObservationPhotoCard
                key={item.id || i}
                onClick={(e) => {
                  photoSwipeBtnRef.current = e.currentTarget;
                  handleOpen(i);
                }}
                image={item}
              />
            ))}

        {!photosQ.isLoading ? (
          <Box
            sx={{
              borderRadius: 1,
              textAlign: "center",
              bgcolor: (theme) =>
                theme.palette.mode === "dark" ? "#000" : "#fff",
              height: 175,
              width: 175,
            }}
          >
            <ImageUpload
              onChange={(imgs) => {
                imgs.forEach((i) => {
                  savePhotoQ.mutate(i);
                });
              }}
            />
          </Box>
        ) : null}
      </Stack>
      <PhotoSwipeViewer
        isOpen={isOpen}
        index={index}
        items={photosQ.data}
        onClose={handleClose}
      />
    </Box>
  );
}

export default ObservationPhotosStack;
