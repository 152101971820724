import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { FormField, GeoDataTable } from "components";
import Select from "components/Select";
import BaseForm from "components/Forms/BaseForm";
import { useAssetsState, useInventoryState } from "providers";
import { IFixedAssetType } from "types/IAssetType";
import GeodataUpload from "views/InventoryView/GeodataUpload";
import { useInventoryDispatch } from "providers";
import { FeatureCollection } from "geojson";
import { ISelectOption } from "types";
import { setFeatureCollectionProps } from "app-utils";
import { useAssetsUpload } from "api/useAssetsUpload";
import { useOrgState } from "providers";
import { ManualSwitchFormField } from "./FormFields";
import DatePicker from "components/DatePicker";
import { useHistory } from "react-router";
import { AlertCircle } from "react-feather";
import { validateFeatureCounts } from "./asset-upload-utils";
import useOrgLookupRQ from "components/useOrgLookupRQ";

export default function FixedAssetUpload({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const { org, season } = useOrgState();
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>();
  const fileUploadRef = useRef<HTMLInputElement>();
  const { fixedAssetTypes } = useAssetsState() as {
    fixedAssetTypes: IFixedAssetType[];
  };

  const { data: assetStatuses } = useOrgLookupRQ(
    `/${org}/lookups/assetstatuses`
  );

  const {
    highlightLayerFeatures,
    fitInventoryBounds,
    setInventoryFtrs,
  } = useInventoryDispatch();
  const { ftrsClicked, inventoryMap } = useInventoryState();
  const [fixedAssetTypeId, setFixedAssetTypeId] = useState("");
  const [assetStatusId, setAssetStatusId] = useState("");
  const [parseError, setParseError] = useState("");
  const [parsedKeys, setParsedKeys] = useState<ISelectOption[]>([]);
  const [uploadedFc, setUploadedFc] = useState<FeatureCollection>();
  // prop mappings
  const [cidProp, setCidProp] = useState("");
  const [bornOnProp, setBornOnProp] = useState("");
  const [nameProp, setNameProp] = useState("");
  const [descriptionProp, setDescriptionProp] = useState("");
  // manual inputs
  const [manualDate, setManualDate] = useState<Date>();
  const [manualDescription, setManualDescription] = useState("");
  const [manualName, setManualName] = useState("");

  const { uploadAssets, ...query } = useAssetsUpload({
    org,
    seasonId: season.id,
    type: "fixed",
  });

  function reset() {
    setParsedKeys([]);
    setParseError("");
    setCidProp("");
    setNameProp("");
    setBornOnProp("");
    setDescriptionProp("");
    setManualDescription("");
    setManualDate(null);
    setManualName("");
  }

  const assetType = fixedAssetTypes?.find(
    (ct) => ct.value === fixedAssetTypeId
  );
  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "cid",
        Cell: ({ value }: { value: string | number }) => {
          return `${value ?? ""}`;
        },
      },
      {
        Header: `${t("common.name")} *`,
        accessor: "name",
        Cell: ({ value }: { value: string | number }) => {
          const val = value || value === 0 ? `${value}` : null;
          return (
            <div className={!val ? "text-danger" : ""}>
              {val ?? (
                <>
                  <AlertCircle />
                </>
              )}
            </div>
          );
        },
      },
      {
        Header: `${t("inventory.assets.createdDate")} *`,
        accessor: "bornOnUtc",
        Cell: ({ value }: { value: string }) => {
          const val = manualDate ? manualDate.toLocaleDateString() : value;
          return (
            <div className={!val ? "text-danger" : ""}>
              {val || (
                <>
                  <AlertCircle />
                </>
              )}
            </div>
          );
        },
      },
      {
        Header: t("common.notes"),
        accessor: "description",
        Cell: ({ value }: { value: string }) => {
          return <div>{value ?? manualDescription}</div>;
        },
      },
    ],
    [manualDescription, manualDate, t]
  );

  const formattedFC = React.useMemo(() => {
    if (!uploadedFc) {
      return null;
    }
    const formatted = setFeatureCollectionProps(
      uploadedFc,
      {
        fixedAssetTypeId,
        assetStatusId,
        ...(manualDate
          ? {
              bornOnUtc: manualDate.toISOString(),
            }
          : {}),
        ...(manualDescription ? { description: manualDescription } : {}),
        ...(manualName ? { name: manualName } : {}),
      },
      {
        name: nameProp,
        description: descriptionProp,
        bornOnUtc: bornOnProp,
        cid: cidProp,
      }
    );
    return formatted;
  }, [
    uploadedFc,
    fixedAssetTypeId,
    assetStatusId,
    manualDate,
    manualDescription,
    manualName,
    nameProp,
    descriptionProp,
    bornOnProp,
    cidProp,
  ]);

  const tableData = React.useMemo(
    () =>
      formattedFC?.features.map((f) => ({ id: f.id, ...f.properties })) ?? [],
    [formattedFC?.features]
  );

  const { invalidCount, validCount } = validateFeatureCounts(formattedFC, [
    "name",
    "bornOnUtc",
  ]);

  return (
    <>
      <BaseForm
        ref={formRef}
        onSubmit={() => {
          if (!uploadedFc) {
            setParseError(t("common.requiredField"));
            return false;
          }
          if (invalidCount) {
            return false;
          }
          return uploadAssets(formattedFC, {
            onSuccess: () => {
              onSuccess();
              history.push("./");
            },
          });
        }}
        cancelHref="./"
        saveState={{
          isLoading: query.isLoading,
          isError: query.isError,
          errorMessage: query.error?.message,
        }}
      >
        <FormField
          label={`${t("common.type")} *`}
          name="fixedAssetTypeId"
          rules={{ required: true }}
          htmlFor="fixed-asset-type-select"
        >
          <Select
            id="fixed-asset-type-select"
            isRequired
            name={"fixedAssetTypeId"}
            options={fixedAssetTypes || []}
            value={fixedAssetTypes?.find((ct) => ct.value === fixedAssetTypeId)}
            onChange={(item: IFixedAssetType) => {
              if (fixedAssetTypeId) {
                fileUploadRef.current.value = "";
                reset();
                setUploadedFc(null);
                setInventoryFtrs(null);
              }
              setFixedAssetTypeId(item?.id ?? "");
            }}
            isClearable
          />
        </FormField>
        <FormField label={`${t("common.status")} *`}>
          <Select
            isClearable
            isRequired
            id="status-select"
            name="assetStatusId"
            options={assetStatuses}
            value={assetStatuses?.find((ao) => ao.value === assetStatusId)}
            onChange={(item) => {
              setAssetStatusId(item?.value);
            }}
          />
        </FormField>
        {fixedAssetTypeId ? (
          <>
            <div className="d-flex align-items-center mt-3">
              <GeodataUpload
                ref={fileUploadRef}
                type={
                  assetType?.geometryType === "point"
                    ? "Point"
                    : assetType?.geometryType === "line"
                    ? "LineString"
                    : "Polygon"
                }
                onChange={(geojson, keys) => {
                  reset();
                  setUploadedFc(geojson);
                  if (geojson) {
                    const ftrsWithColor = setFeatureCollectionProps(geojson, {
                      color: assetType?.color || "#eee",
                      fixedAssetType: assetType?.name ?? "??",
                    });
                    setInventoryFtrs(ftrsWithColor);
                    fitInventoryBounds({ geojson });
                    setParsedKeys(
                      keys.map((p) => ({
                        value: p,
                        label: p,
                      }))
                    );
                  } else {
                    setInventoryFtrs(null);
                  }
                }}
                onError={(e) => {
                  setParseError(e);
                }}
              />
              <Form.Text className="text-info ml-1">GeoJSON</Form.Text>
            </div>

            {parseError ? (
              <Form.Text className="text-danger">{parseError}</Form.Text>
            ) : null}
            {uploadedFc ? (
              <>
                <FormField label="ID">
                  <Select
                    isClearable
                    value={
                      cidProp
                        ? parsedKeys?.find((p) => p.value === cidProp)
                        : null
                    }
                    options={parsedKeys}
                    onChange={(item) => {
                      setCidProp(item?.value);
                    }}
                  />
                </FormField>

                <ManualSwitchFormField
                  id="manual-name"
                  label={`${t("common.name")} *`}
                  onChange={(e) => {
                    if (!e.currentTarget.checked) {
                      setManualName("");
                    } else {
                      setNameProp("");
                    }
                  }}
                  manualInput={
                    <Form.Control
                      required
                      maxLength={50}
                      name="name"
                      id="manual-name"
                      onChange={(e) => {
                        setManualName(e.target.value);
                      }}
                    />
                  }
                >
                  <Select
                    isClearable
                    isRequired
                    value={
                      nameProp
                        ? parsedKeys?.find((p) => p.value === nameProp)
                        : null
                    }
                    options={parsedKeys}
                    onChange={(item) => {
                      setNameProp(item?.value);
                    }}
                  />
                </ManualSwitchFormField>

                <ManualSwitchFormField
                  id="born-on-date"
                  wrapper={"div"}
                  label={`${t("inventory.assets.createdDate")} *`}
                  onChange={(e) => {
                    if (!e.currentTarget.checked) {
                      setManualDate(null);
                    } else {
                      setBornOnProp("");
                    }
                  }}
                  manualInput={
                    <DatePicker
                      required
                      maxDate={new Date()}
                      selected={manualDate}
                      onChange={(d: Date) => {
                        setManualDate(d);
                      }}
                    />
                  }
                >
                  <Select
                    isRequired
                    isClearable
                    value={
                      bornOnProp
                        ? parsedKeys?.find((p) => p.value === bornOnProp)
                        : null
                    }
                    options={parsedKeys}
                    onChange={(item) => {
                      setBornOnProp(item?.value);
                    }}
                  />
                </ManualSwitchFormField>

                <ManualSwitchFormField
                  id="manual-description"
                  label={t("common.notes")}
                  onChange={(e) => {
                    if (!e.currentTarget.checked) {
                      setManualDescription(null);
                    } else {
                      setDescriptionProp("");
                    }
                  }}
                  manualInput={
                    <Form.Control
                      onChange={(e) => {
                        setManualDescription(e.target.value);
                      }}
                      as="textarea"
                      maxLength={255}
                    />
                  }
                >
                  <Select
                    isClearable
                    value={parsedKeys?.find((p) => p.value === descriptionProp)}
                    options={parsedKeys}
                    onChange={(item) => {
                      setDescriptionProp(item?.value);
                    }}
                  />
                </ManualSwitchFormField>
              </>
            ) : null}
          </>
        ) : null}
        {formattedFC ? (
          <Form.Text className={invalidCount ? "text-danger" : "text-info"}>
            {`${t("common.validFeatures")}: ${validCount} /
                  ${formattedFC.features.length}`}
          </Form.Text>
        ) : null}
      </BaseForm>
      {formattedFC ? (
        <div
          className="inventory-list overflow-auto"
          style={{ minHeight: "250px" }}
        >
          <GeoDataTable
            isFilterable={false}
            highlightByIds={highlightLayerFeatures}
            ftrsClicked={ftrsClicked}
            map={inventoryMap}
            featureCollection={formattedFC}
            data={tableData}
            columns={columns}
          />
        </div>
      ) : null}
    </>
  );
}
