import React from "react";
import PropTypes from "prop-types";

export default function LegendItem({ data }) {
  return (
    <div className="mr-n1 mb-1">
      <table className="w-100">
        <tbody>
          <tr className="d-flex align-items-center">
            <td className="pb-1">
              <h4 className="mb-0" style={{ textTransform: "none" }}>
                {data?.name}
              </h4>
            </td>
            <td className="pb-1 ml-auto">{data?.legendSymbol}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

LegendItem.defaultProps = {
  data: null,
};

LegendItem.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    legendSymbol: PropTypes.node,
  }),
};
