import React from "react";
import SVGIconWrapper from "./IconWrapper";

export default function TilledIcon(props) {
  return (
    <SVGIconWrapper {...props}>
      <svg viewBox="10 0 431 416">
        <path d="M288.37,211.87a5.75,5.75,0,0,0-3.55-3.8,40.4,40.4,0,0,0-7.62-1.78c18.88-20.09,12.65-43.56,11.17-48.14a5.77,5.77,0,0,0-3.55-3.8,49.69,49.69,0,0,0-31,.33,51.83,51.83,0,0,0,1.44-12.33c0-28.78-21.79-41.43-26.17-43.68a5.77,5.77,0,0,0-5.2-.17c-1.08.5-26.51,12.67-26.51,43.85a52,52,0,0,0,1.45,12.35,49.68,49.68,0,0,0-31.11-.35,5.77,5.77,0,0,0-3.55,3.8c-1.48,4.58-7.71,28,11.17,48.14a40.4,40.4,0,0,0-7.62,1.78,5.75,5.75,0,0,0-3.55,3.8c-1.51,4.68-8,29,12.38,49.39a47.88,47.88,0,0,0,34.64,14.58,51.6,51.6,0,0,0,9.29-.84v37.76a5.77,5.77,0,1,0,11.54,0V275a51.2,51.2,0,0,0,9.37.86A47.9,47.9,0,0,0,276,261.26C296.35,240.9,289.88,216.55,288.37,211.87ZM220.44,257a38.68,38.68,0,0,1-.85,6.4,38.08,38.08,0,0,1-45.21-45.24,38.74,38.74,0,0,1,19.83.94c.55.21,1.1.4,1.65.58a38.12,38.12,0,0,1,13.41,8.8,36.8,36.8,0,0,1,11.17,24.67Zm0-53.72a39,39,0,0,1-.85,6.41,38.35,38.35,0,0,1-19.43-.78c-.83-.32-1.66-.61-2.49-.88a38.12,38.12,0,0,1-13-8.66,37.22,37.22,0,0,1-10.29-34.92,37.43,37.43,0,0,1,34.89,10.32l.24.25c.51.57,1,1.11,1.52,1.63a36.6,36.6,0,0,1,9.41,22.78v3.85Zm5.85-29a39.38,39.38,0,0,1-7.06-5.83c-.27-.3-.55-.59-.83-.89a36.83,36.83,0,0,1-9.52-25.26,37.24,37.24,0,0,1,17.37-32,37.2,37.2,0,0,1,17.43,32A36.79,36.79,0,0,1,235,166.64c-.93.94-1.81,1.88-2.63,2.83A37.92,37.92,0,0,1,226.29,174.33ZM232,201.42a36.49,36.49,0,0,1,8.92-24.16q1.27-1.27,2.55-2.73a37.23,37.23,0,0,1,34.63-10.08,37.22,37.22,0,0,1-10.29,34.93,38.07,38.07,0,0,1-13,8.65c-.84.27-1.68.57-2.53.9a38.47,38.47,0,0,1-19.41.77,38.23,38.23,0,0,1-.89-8.13Zm35.81,51.67a37.27,37.27,0,0,1-34.91,10.33,37.46,37.46,0,0,1,10.31-34.93,38.23,38.23,0,0,1,13-8.67c.82-.26,1.64-.55,2.48-.87a38.34,38.34,0,0,1,19.39-.79A37.21,37.21,0,0,1,267.83,253.09Z" />
        <path d="M257,63.72a148.26,148.26,0,0,0-30.79-3.22A147.75,147.75,0,0,0,109.72,299.08a21.89,21.89,0,1,0,8.61-7.74A136.42,136.42,0,0,1,90,208.25C90,133.15,151.15,72,226.25,72a137,137,0,0,1,28.4,3A5.77,5.77,0,0,0,257,63.72ZM120.82,303.61a10.39,10.39,0,1,1-3,7.34A10.32,10.32,0,0,1,120.82,303.61Z" />
        <path d="M342.78,117.42a21.89,21.89,0,1,0-8.61,7.74,136.42,136.42,0,0,1,28.29,83.09c0,75.1-61.11,136.21-136.21,136.21a138.05,138.05,0,0,1-24.06-2.12,5.77,5.77,0,0,0-2,11.36,148.61,148.61,0,0,0,26.09,2.3A147.75,147.75,0,0,0,342.78,117.42Zm-11.1-4.53a10.37,10.37,0,0,1-14.69,0,10.39,10.39,0,1,1,14.69,0Z" />
        <path d="M175.54,334.7l-2.18,5.35,2.16-5.35a5.77,5.77,0,0,0-4.32,10.7l.17.07a5.81,5.81,0,0,0,7.48-3.32A5.76,5.76,0,0,0,175.54,334.7Z" />
        <path d="M281.55,71.2l-.1,0a5.77,5.77,0,0,0-4.29,10.72l.07,0a5.7,5.7,0,0,0,2.16.43,5.78,5.78,0,0,0,2.16-11.13Z" />
      </svg>
    </SVGIconWrapper>
  );
}
