import { useCallback, useMemo } from "react";
import useFetchWithToken from "components/useFetchWithToken";
import { useQuery } from "@tanstack/react-query";
import { IDataItem, parseMapThemes } from "views/InventoryView/parseMapThemes";

interface IMapThemeItem {
  id: string;
  [index: string]: unknown;
  count: number;
  area: number;
  totalAreaUom: string;
  color: string;
}

function getThemeConfig(type: string): { dataProp: string; colorProp: string } {
  switch (type) {
    case "subfields": {
      return {
        dataProp: "subfieldType",
        colorProp: "subfieldTypeColor",
      };
    }
    case "assets/plant": {
      return {
        dataProp: "subtype",
        colorProp: "color",
      };
    }
    case "assets/animal": {
      return {
        dataProp: "breed",
        colorProp: "breedColor",
      };
    }
    case "assets/fixed": {
      return {
        dataProp: "subtype",
        colorProp: "color",
      };
    }
    default: {
      return null;
    }
  }
}

export function useInventoryMapThemes<T extends IMapThemeItem>(
  org: string,
  type: string,
  seasonId: string
) {
  const { fetchData: fetchMapThemesFn } = useFetchWithToken<T[]>();
  const [inventoryType, subtype] = type.split("/");
  const queryKey = useMemo(
    () => [
      "organization",
      org,
      inventoryType,
      subtype,
      "season",
      seasonId,
      "type",
      type,
      "mapthemes",
    ],
    [inventoryType, org, seasonId, subtype, type]
  );

  const fetchMapThemes = useCallback(async () => {
    const res = await fetchMapThemesFn(
      `/${org}/${type}/season/${seasonId}/mapthemes`,
      {
        transformResponse: (res) => {
          const config = getThemeConfig(type);
          const total = res?.reduce((item, next) => {
            const cnt = item + next.count;
            return cnt;
          }, 0);
          const items = res?.reduce((item, next) => {
            const percent = Number(((next.count / total) * 100).toFixed(2));
            item.push({
              percent,
              name: next[config.dataProp],
              ...next,
              color: next[config.colorProp],
            });
            return item;
          }, []);
          return {
            raw: items,
            rollup: parseMapThemes(res, config),
          };
        },
      }
    );
    return (res?.data as unknown) as {
      raw: IMapThemeItem[];
      rollup: IDataItem[];
    };
  }, [fetchMapThemesFn, org, type, seasonId]);

  const query = useQuery({
    queryKey,
    queryFn: fetchMapThemes,
    enabled: !!seasonId && !!type,
  });
  return { ...query, queryKey };
}
