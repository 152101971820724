import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAssetsState } from "providers/AssetsProvider";
import IconButton from "components/IconButton";
import { DataTable, useURLSearchParams } from "components";
import { useOrgState } from "providers";
import { Col, Row } from "react-bootstrap";

export default function AssetRecordsListView() {
  const { t } = useTranslation();
  const { rootUrl } = useOrgState();
  const { itemId, type, subtype, fieldId, recordId } = useParams();
  const query = useURLSearchParams();
  const { currentAssetRecords } = useAssetsState();

  const assetGroupId = query.get("groupId");

  const recordsCols = [
    {
      Header: "",
      id: "actions",
      isSortable: false,
      accessor: function Cell(row) {
        return (
          <IconButton
            as={Link}
            icon="eye"
            to={{
              pathname: `${rootUrl}/inventory/assets/${type}/${subtype}/${fieldId}/items/${itemId}/records/${row.id}`,
              search: `${assetGroupId ? `?groupId=${assetGroupId}` : ""}`,
            }}
          />
        );
      },
    },
    {
      Header: t("common.date"),
      id: "dateUtc",
      accessor: (row) => {
        return new Date(row.dateUtc).toLocaleDateString();
      },
    },
    { Header: t("common.type"), accessor: "eventType" },
    { Header: t("common.weight"), accessor: "weightLbs" },
    { Header: t("common.photos"), accessor: "photoCount" },
  ];

  return (
    <Row>
      <Col className="d-flex flex-column">
        {type && type !== "equipment" && !recordId ? (
          <div className="ml-auto">
            <IconButton
              as={Link}
              icon="add"
              className="btn-success"
              to={`${rootUrl}/inventory/assets/${type}/${subtype}/${fieldId}/items/${itemId}/records/add${
                assetGroupId ? `?groupId=${assetGroupId}` : ""
              }`}
            />
          </div>
        ) : null}
        <div className="h-100">
          <DataTable
            isFilterable={false}
            tableProps={{ size: "sm" }}
            data={currentAssetRecords}
            columns={recordsCols}
          />
        </div>
      </Col>
    </Row>
  );
}
