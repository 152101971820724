import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import MapThemeItem from "components/MapThemeItem";

export default function MapThemes({ themeType, onThemeTypeChange, themeData }) {
  const { t } = useTranslation();
  return (
    <Container className="h-100">
      <Row className="h-100">
        {themeData && themeData.length ? (
          <Col>
            <MapThemeItem
              id="landAgreementType"
              title={t("inventory.fields.landAgreement")}
              isActive={themeType === "landAgreementType"}
              onClick={() => {
                onThemeTypeChange("landAgreementType");
              }}
              data={themeData}
            />
          </Col>
        ) : null}
        {themeData && !themeData.length ? (
          <Col>
            <h6 className="text-center small mt-4">
              {t("inventory.fields.addFieldsToSeeMapThemes")}
            </h6>
          </Col>
        ) : null}
        {!themeData ? (
          <Col
            style={{
              maxHeight: "150px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MapThemeItem
              id="skeleton"
              title={t("inventory.fields.landAgreement")}
              isActive={false}
              data={[{ percent: 100, name: "" }]}
              showTooltip={false}
            />
          </Col>
        ) : null}
      </Row>
    </Container>
  );
}
MapThemes.defaultProps = {
  themeType: "landAgreementType",
  themeData: null,
};
MapThemes.propTypes = {
  themeType: PropTypes.oneOf(["landAgreementType", "cropType"]),
  onThemeTypeChange: PropTypes.func.isRequired,
  themeData: PropTypes.arrayOf(PropTypes.shape({})),
};
