import React, { useEffect } from "react";
import {
  Image,
  Row,
  Col,
  Tab,
  Nav,
  Badge,
  Container,
  Card,
} from "react-bootstrap";
import { useParams, Route, Switch, Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { useAssetsState } from "providers/AssetsProvider";
import { useInventoryDispatch } from "providers/InventoryProvider";
import { useURLSearchParams, NotFoundView } from "components";
import PlantAssetDetails from "./PlantAssetDetails";
import FixedAssetDetails from "./FixedAssetDetails";
import AnimalAssetDetails from "./AnimalAssetDetails";
import AssetRecordsListView from "../AssetRecordsListView";
import AssetImages from "../CreateOrEditAsset/AssetImages";
import Breadcrumbs from "../ListView/AssetsBreadcrumbs";
import { IconButton } from "components";
import AssetRecordDetails from "./AssetRecordDetails";
import CreateOrEditRecord from "../CreateOrEditRecord";
import { LinearProgress } from "lib";

export default function AssetView() {
  const { t } = useTranslation();
  const { itemId, type, subtype, fieldId } = useParams();
  const query = useURLSearchParams();
  const { currentEditFtr, geodataState, assetPhotos } = useAssetsState();
  const { zoomToFtrIds } = useInventoryDispatch();
  const { rootUrl, seasonId } = useOrgState();
  const location = useLocation();
  const isEdit =
    location.pathname.includes("/edit") &&
    !location.pathname.includes("/records");

  const outOfSeason =
    currentEditFtr?.properties.seasonId &&
    currentEditFtr?.properties.seasonId !== seasonId;
  const editPhotos = location.pathname.includes(`${itemId}/photos`);
  const groupId = query.get("groupId");

  useEffect(() => {
    zoomToFtrIds([itemId]);
  }, [zoomToFtrIds, itemId]);
  return (
    <>
      {!currentEditFtr && !geodataState?.isLoading && itemId ? (
        <NotFoundView>
          <Link to={`${rootUrl}/inventory/assets/${type}`}>
            {t("common.back")}
          </Link>
        </NotFoundView>
      ) : geodataState?.isLoading ? (
        <Card className="p-3 h-100">
          <LinearProgress absolute />
        </Card>
      ) : (
        <Switch>
          <Route
            path={[
              `/:org/:season/inventory/assets/:type/add`,
              `/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId`,
              `/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/records/*`,
              `/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/photos`,
              `/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/photos/:photoId/edit`,
            ]}
            exact
          >
            <Card className="py-3">
              <Container fluid>
                <Row>
                  <Col>
                    <Breadcrumbs />
                  </Col>
                  <Col xs="auto">
                    {itemId && !isEdit ? (
                      <Link
                        className="ml-auto"
                        to={`${rootUrl}/inventory/assets/${type}/${subtype}/${fieldId}/items/${itemId}/edit${
                          groupId ? `?groupId=${groupId}` : ""
                        }`}
                        onClick={(e) => {
                          if (!currentEditFtr || outOfSeason) {
                            e.preventDefault();
                          }
                        }}
                      >
                        <IconButton
                          disabled={!currentEditFtr || outOfSeason}
                          title={
                            outOfSeason
                              ? t("inventory.assets.cannotEditOutOfSeason")
                              : t("common.edit")
                          }
                          icon="edit"
                        />
                      </Link>
                    ) : null}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs={12} md={assetPhotos?.length ? 10 : 12}>
                    <Route
                      path={[
                        `/:org/:season/inventory/assets/plant/:subtype/:fieldId/items/:itemId`,
                      ]}
                    >
                      <PlantAssetDetails />
                    </Route>
                    <Route
                      path={[
                        `/:org/:season/inventory/assets/animal/:subtype/:fieldId/items/:itemId`,
                      ]}
                    >
                      <AnimalAssetDetails />
                    </Route>
                    <Route
                      path={[
                        `/:org/:season/inventory/assets/fixed/:subtype/:fieldId/items/:itemId`,
                      ]}
                    >
                      <FixedAssetDetails />
                    </Route>
                  </Col>
                  {assetPhotos?.length ? (
                    <Col xs={12} md={2}>
                      <Link
                        className="p-3 d-block"
                        to={`${rootUrl}/inventory/assets/${type}/${subtype}/${fieldId}/items/${itemId}/photos${
                          groupId ? `?groupId=${groupId}` : ""
                        }`}
                      >
                        <Image fluid rounded src={assetPhotos[0].src} />
                      </Link>
                    </Col>
                  ) : null}
                </Row>
              </Container>
            </Card>
            <Tab.Container activeKey={editPhotos ? "photos" : "records"}>
              <Nav variant="tabs" className="text-uppercase mt-3">
                <Nav.Item>
                  <Nav.Link
                    eventKey="records"
                    as={Link}
                    to={`${rootUrl}/inventory/assets/${type}/${subtype}/${fieldId}/items/${itemId}${
                      groupId ? `?groupId=${groupId}` : ""
                    }`}
                  >
                    {t("common.records")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="photos"
                    as={Link}
                    to={`${rootUrl}/inventory/assets/${type}/${subtype}/${fieldId}/items/${itemId}/photos${
                      groupId ? `?groupId=${groupId}` : ""
                    }`}
                  >
                    {t("inventory.assets.assetPhotos")}{" "}
                    <Badge variant="primary">{assetPhotos?.length || 0}</Badge>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Card className="flex-grow-1 border-0">
                <Tab.Content className="py-3">
                  <Tab.Pane eventKey="records" className="h-100">
                    <Container fluid>
                      <Switch>
                        <Route path="/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/records/add">
                          <CreateOrEditRecord />
                        </Route>
                        <Route
                          path={`/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/records/:recordId/edit`}
                        >
                          <CreateOrEditRecord />
                        </Route>
                        <Route
                          path={`/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/records/:recordId`}
                        >
                          <AssetRecordDetails />
                        </Route>
                        <Route
                          path={[
                            `/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/records/*`,
                            `/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId`,
                          ]}
                          exact
                        >
                          <AssetRecordsListView />
                        </Route>
                      </Switch>
                    </Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="photos" className="px-3 h-100">
                    <Route
                      path={[
                        `/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/photos/:photoId`,
                        `/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/photos/`,
                      ]}
                    >
                      <AssetImages />
                    </Route>
                  </Tab.Pane>
                </Tab.Content>
              </Card>
            </Tab.Container>
          </Route>
        </Switch>
      )}
    </>
  );
}
