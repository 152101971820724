import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useHistory, useParams } from "react-router-dom";
import { PageColumn, PageLayout } from "views/MainLayout";
import { IUrlParams } from "types";
import { useOrgState } from "providers";
import { GeoJSONSource, Map } from "mapbox-gl";
import { featureCollection } from "@turf/helpers";
import { StatsChart } from "./StatsChart";
import Autocomplete from "lib/Select/Autocomplete";
import ImageryInsightsBySubfield from "./BySubfield";
import { useImageryInsightsChartData } from "./useImageryInsightsChartData";
import { ChartDataRequestBody } from "../types";
import { useRasterLegends } from "views/InventoryView/MapView/RasterLayersControl/useRasterLegends";
import ImageryInsightsByProduct from "./ByProduct";
import ImageryInsightsMapView from "./ImageryInsightsMapView";

function setRasterLayer(map: Map, url?: string) {
  const src = map.getSource("raster-lyr-src");
  if (src) {
    map.removeLayer("raster-lyr");
    map.removeSource("raster-lyr-src");
  }
  if (url) {
    map.addSource("raster-lyr-src", {
      type: "raster",
      tiles: [url],
      maxzoom: 24,
      // bounds: selectedCollectEvent?.extent?.bbox,
    });
    map.addLayer(
      {
        id: `raster-lyr`,
        source: `raster-lyr-src`,
        type: "raster",
      },
      "fields-lyr"
    );
  }
}

export default function ImageryInsights() {
  const { org, type: insightsType } = useParams<IUrlParams>();
  const { rootUrl, tenant } = useOrgState();
  const { t } = useTranslation();
  const history = useHistory();
  const mapRef = useRef<Map>();
  const [activeRasterLyr, setActiveRasterLyr] = useState<string>(null);
  const [queryData, setQueryData] = useState<ChartDataRequestBody>();
  const reportTypes = [
    { label: t("insights.imagery.responseBySubfield"), id: "subfields" },
    {
      label: t("insights.yield.imgResByProductPlantedApplied"),
      id: "product",
    },
  ];
  const rasterLegendQ = useRasterLegends({
    tenant,
    org,
    types: activeRasterLyr ? [activeRasterLyr.split(":::")[1]] : undefined,
  });
  const chartDataQ = useImageryInsightsChartData(
    org,
    tenant,
    insightsType,
    queryData
  );

  const handleReset = useCallback(() => {
    setQueryData(null);
    setRasterLayer(mapRef.current, null);
    setActiveRasterLyr(null);
    const src = mapRef.current.getSource("geodata-src") as GeoJSONSource;
    if (src) {
      src.setData(featureCollection([]));
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: ChartDataRequestBody) => {
      handleReset();
      setQueryData(data);
    },
    [handleReset]
  );

  //  add first raster layer image
  useEffect(() => {
    if (
      chartDataQ.data?.chartData &&
      mapRef.current &&
      activeRasterLyr === null
    ) {
      const cd = chartDataQ.data?.chartData?.[0];
      mapRef.current.resize();
      setActiveRasterLyr(`${cd.url}:::${cd.name}`);
      setRasterLayer(mapRef.current, cd.url);
    }
  }, [activeRasterLyr, chartDataQ.data]);

  // reset operations when chart data returned
  useEffect(() => {
    if (chartDataQ.data?.processedFtrs) {
      const src = mapRef.current?.getSource("operations-src") as GeoJSONSource;
      if (src) {
        src.setData(featureCollection([]));
      }
    }
  }, [chartDataQ.data]);

  // resize the map when chart toggled
  useEffect(() => {
    mapRef.current?.resize();
  }, [chartDataQ.data]);

  return (
    <>
      <h2>{`${t("imagery.title")} ${t("insights.title")}`}</h2>
      <PageLayout>
        <PageColumn lg={4}>
          <Paper sx={{ height: "100%", overflow: "auto", p: 3 }}>
            <Autocomplete
              label={t("insights.selectReportType")}
              id="report-type-select"
              value={reportTypes.find((r) => r.id === insightsType) ?? null}
              options={reportTypes}
              onChange={(_e, item) => {
                handleReset();
                if (!Array.isArray(item) && typeof item !== "string") {
                  history.push(`${rootUrl}/insights/imagery/${item.id}`);
                }
              }}
            />
            <hr className="border-light" />
            {insightsType === "product" ? (
              <ImageryInsightsByProduct
                chartDataQ={chartDataQ}
                mapRef={mapRef.current}
                onSubmit={handleSubmit}
                onProductChange={handleReset}
                onCollectEventChange={handleReset}
                onFieldChange={handleReset}
              />
            ) : (
              <ImageryInsightsBySubfield
                chartDataQ={chartDataQ}
                mapRef={mapRef.current}
                onSubmit={handleSubmit}
                onFieldChange={handleReset}
              />
            )}
          </Paper>
        </PageColumn>
        <PageColumn
          className="mt-3 mt-lg-0"
          style={{
            minHeight: "500px",
            overflow: "hidden",
          }}
          lg={8}
        >
          <ImageryInsightsMapView
            rasterLegend={rasterLegendQ.data?.[0]}
            geodata={
              chartDataQ.data?.processedFtrs
                ? featureCollection(chartDataQ.data.processedFtrs)
                : null
            }
            onLoad={(map) => {
              mapRef.current = map;
            }}
          />
          {chartDataQ.data?.chartData?.length ? (
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "275px",
              }}
            >
              <>
                <Box
                  sx={{
                    px: 3,
                    py: 1,
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <FormControl
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <FormLabel
                      sx={{
                        textTransform: "uppercase",
                        color: "inherit !important",
                        mr: 2,
                      }}
                      id="raster layer results"
                    >
                      {t("insights.yield.viewRasterOnMap")}
                    </FormLabel>
                    <RadioGroup
                      row
                      value={activeRasterLyr}
                      aria-labelledby="raster layer results"
                      name="raster-layer-results-group"
                      onChange={(_e, val) => {
                        const url = val.split(":::")[0];
                        setActiveRasterLyr(val);
                        setRasterLayer(mapRef.current, url);
                      }}
                    >
                      <FormControlLabel
                        value={""}
                        control={<Radio />}
                        label={t("insights.yield.none")}
                      />
                      {chartDataQ.data?.chartData?.map((cd) => (
                        <FormControlLabel
                          control={<Radio />}
                          key={cd.name}
                          label={cd.name}
                          value={`${cd.url}:::${cd.name}`}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Box>
                <StatsChart data={chartDataQ.data?.chartData} />
              </>
            </Paper>
          ) : null}
        </PageColumn>
      </PageLayout>
    </>
  );
}
