import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Button } from "react-bootstrap";
import { Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SummaryCardView from "components/SummaryCardView";
import { useOrgState } from "providers/OrgProvider";
import { useAssetsState } from "providers/AssetsProvider";

export default function AssetsSummaryView({ onSelect, activeType }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { assetsBySeasonQuery } = useAssetsState();
  const { rootUrl } = useOrgState();

  // redirect to first asset summary type if not specified
  if (
    !activeType &&
    assetsBySeasonQuery.data &&
    assetsBySeasonQuery.data.length
  ) {
    return (
      <Redirect
        to={`${rootUrl}/inventory/assets/${assetsBySeasonQuery.data[0]?.assetEn?.toLowerCase()}`}
      />
    );
  }
  return (
    <>
      <Row className="flex-grow-1 align-items-center mx-3">
        {assetsBySeasonQuery?.data ? (
          assetsBySeasonQuery?.data?.map((d) => {
            const assetType = d?.assetEn?.toLowerCase();
            return (
              <Col key={d.asset} lg={6} xl={3} className="my-1 p-1">
                <SummaryCardView
                  className="w-100 h-100"
                  as={Button}
                  variant=""
                  active={assetType === activeType}
                  onClick={() => {
                    history.push(`${rootUrl}/inventory/assets/${assetType}`);
                    onSelect(d);
                  }}
                  value={d.count?.toLocaleString()}
                  label={
                    <>
                      <div>{d?.asset?.toUpperCase()}</div>
                    </>
                  }
                  childrenLocation="left"
                  style={{ backgroundColor: d.color }}
                >
                  {/* <div
                    className="mr-2 operation-summary-icon"
                    style={{ border: 'solid 1px', borderRadius: '50%' }}
                  >
                    {assetType === 'plant' ? (
                      <PlantIcon />
                    ) : assetType === 'animal' ? (
                      <AnimalIcon />
                    ) : assetType === 'equipment' ? (
                      <EquipmentIcon />
                    ) : assetType === 'fixed' ? (
                      <FixedIcon />
                    ) : null}
                  </div> */}
                </SummaryCardView>
              </Col>
            );
          })
        ) : (
          <>
            {assetsBySeasonQuery?.isError ||
            (assetsBySeasonQuery.data && !assetsBySeasonQuery.data.length) ? (
              <Col sm={12} className="text-center">
                <h3>{t("common.noData")}</h3>
              </Col>
            ) : (
              <>
                {[1, 2, 3, 4].map((i) => (
                  <Col key={i} lg={6} xl={3} className="my-1 p-1">
                    <SummaryCardView
                      value="--"
                      label={
                        <>
                          <div>--</div>
                        </>
                      }
                    />
                  </Col>
                ))}
              </>
            )}
          </>
        )}
      </Row>
    </>
  );
}
AssetsSummaryView.defaultProps = {
  onSelect: () => {},
  activeType: "",
};
AssetsSummaryView.propTypes = {
  onSelect: PropTypes.func,
  activeType: PropTypes.string,
};
