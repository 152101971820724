import { useCallback } from "react";
import useFetchWithToken from "components/useFetchWithToken";

export interface IYieldFilter {
  seasonId: string;
  cropTypeId: string;
  yieldMin: string;
  yieldMax: string;
  yieldUomId: string;
  speedMin: string;
  speedMax: string;
  speedUomId: string;
  crop: string;
  yieldUom: string;
  speedUom: string;
}

export function useYieldFilters({
  org,
  seasonId,
}: {
  org: string;
  seasonId: string;
}) {
  const {
    fetchData: fetchYieldFilters,
    state: yieldFiltersState,
    resetFetchState: resetYieldFilters,
  } = useFetchWithToken();
  const {
    fetchData: saveData,
    state: saveState,
    setDataState: setSaveState,
  } = useFetchWithToken();
  const {
    fetchData: deleteData,
    state: deleteState,
    setDataState: setDeleteState,
  } = useFetchWithToken();
  const yieldFiltersUrl = `/${org}/myaccount/yieldfilters/seasons/${seasonId}`;
  const getYieldFilters = useCallback(() => {
    return fetchYieldFilters(yieldFiltersUrl);
  }, [fetchYieldFilters, yieldFiltersUrl]);

  async function saveItem(data: IYieldFilter, method: "POST" | "PUT" = "POST") {
    data.seasonId = seasonId;
    const res = await saveData(yieldFiltersUrl, {
      body: JSON.stringify(data),
      method,
    });
    getYieldFilters();
    return res;
  }

  async function deleteItem(data: IYieldFilter) {
    data.seasonId = seasonId;
    const res = await deleteData(yieldFiltersUrl, {
      body: JSON.stringify(data),
      method: "DELETE",
    });
    getYieldFilters();
    return res;
  }

  return {
    yieldFilters: yieldFiltersState?.data,
    state: yieldFiltersState,
    saveItem,
    deleteItem,
    saveState,
    deleteState,
    getYieldFilters,
    resetYieldFilters,
    resetErrors: () => {
      setSaveState(null);
      setDeleteState(null);
    },
  };
}
