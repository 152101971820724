import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAssetsDispatch, useAssetsState } from "providers/AssetsProvider";
import ImageComponent from "components/Images/ImageComponent";
import AssetRecordForm from "./AssetRecordForm";

const MAX_IMAGES = 4;

export default function CreateOrEditRecord() {
  const { t } = useTranslation();
  const {
    currentEditFtr,
    assetEventTypes,
    currentAssetRecords,
    assetRecordPhotos,
    fetchingRecordPhotos,
    saveRecordPhotoState,
  } = useAssetsState();
  const {
    resetSaveRecord,
    resetDeleteRecord,
    resetSaveRecordPhoto,
    resetDeleteRecordPhoto,
    saveRecordPhoto,
    deleteRecordPhoto,
    fetchRecordPhotosById,
    resetRecordPhotos,
    fetchRecordsByAssetId,
  } = useAssetsDispatch();
  const { itemId, recordId } = useParams();
  const [images, setImages] = useState([]);

  // when leaving form, reset the fetch state
  useEffect(() => {
    return () => {
      resetSaveRecord();
      resetDeleteRecord();
      resetDeleteRecordPhoto();
    };
  }, [
    resetSaveRecord,
    resetDeleteRecord,
    resetSaveRecordPhoto,
    resetDeleteRecordPhoto,
  ]);
  const assetTypeId = currentEditFtr?.properties?.assetTypeId;
  const selectedAssetEventTypes = assetEventTypes?.filter(
    (at) => at.type === assetTypeId
  );
  const currentEditRecord = recordId
    ? currentAssetRecords?.find((r) => r.id === recordId)
    : null;

  useEffect(() => {
    if (recordId) {
      fetchRecordPhotosById(recordId);
    }
  }, [fetchRecordPhotosById, recordId]);

  useEffect(() => {
    return () => {
      resetRecordPhotos();
    };
  }, [resetRecordPhotos]);

  useEffect(() => {
    if (assetRecordPhotos?.length > 0) {
      setImages(assetRecordPhotos);
    }
  }, [assetRecordPhotos]);

  const saveImages = async (imgs, newRecordId) => {
    resetDeleteRecordPhoto();
    resetSaveRecordPhoto();
    const reqs = [];
    imgs.forEach(async (img) => {
      const recordIdForPost = newRecordId ?? recordId;
      if (recordIdForPost && !img.id) {
        const req = saveRecordPhoto(img.file, recordIdForPost);
        reqs.push(req);
        req.then((res) => {
          img.isLoading = false;
          if (res?.data?.id) {
            img.id = res.data.id;
          } else {
            img.failed = true;
            img.errorMessage = res.errorMessage;
          }
        });
      }
    });
    Promise.all(reqs).then(() => {
      setImages([...imgs]);
      // update records to get new photo counts
      if (itemId) {
        fetchRecordsByAssetId(itemId);
      }
    });
  };
  const deleteImage = async (img) => {
    try {
      const photoId = img.id;
      img.isLoading = true;
      setImages([...images]);
      const res = await deleteRecordPhoto(recordId, photoId);
      img.isLoading = false;
      if (res.isError) {
        img.failed = true;
        img.errorMessage = res.errorMessage;
        setImages([...images]);
      }
      fetchRecordsByAssetId(itemId);
      return res;
    } catch (e) {
      img.isLoading = false;
      img.failed = true;
      img.errorMessage = "Failed to delete";
      setImages([...images]);
      console.error(e);
    }
  };

  return (
    <Row>
      <Col lg={4}>
        <AssetRecordForm
          assetEventTypes={selectedAssetEventTypes}
          currentEditRecord={currentEditRecord}
          onSaveRecord={(newRecordId) => {
            images.forEach((i) => {
              if (!i.id) {
                i.isLoading = true;
              }
            });
            setImages([...images]);
            saveImages(images, newRecordId);
          }}
        />
      </Col>
      <Col lg={8}>
        <h6 className="text-uppercase">
          {t("common.photos")} (Max {MAX_IMAGES})
        </h6>
        {fetchingRecordPhotos ? <div>{t("common.loadingData")}...</div> : null}
        <ImageComponent
          disabled={fetchingRecordPhotos || saveRecordPhotoState?.isLoading}
          images={images}
          maxImages={MAX_IMAGES}
          onImageChange={(imgs) => {
            const all = images?.concat(imgs);
            if (recordId) {
              imgs.forEach((i) => {
                i.isLoading = true;
              });
              saveImages(all);
            }
            setImages(all);
          }}
          onImageDelete={async (img, idx) => {
            if (img.id) {
              // ensure delete is successful before removing
              const res = await deleteImage(img);
              if (!res?.isError) {
                const newImages = [...images];
                newImages.splice(idx, 1);
                setImages(newImages);
              }
            } else {
              // no id, hasnt been uploaded yet
              // so we just remove it from the array
              const newImages = [...images];
              newImages.splice(idx, 1);
              setImages(newImages);
            }
          }}
          accept=".gif, .jpeg, .jpg, .png"
        />
      </Col>
    </Row>
  );
}
