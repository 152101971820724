import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SummaryCardView from "components/SummaryCardView";
import useSubfieldsSummary from "api/useSubfieldsSummary";
import { useOrgState } from "providers/OrgProvider";

export default function SubfieldsSummaryView() {
  const { org, season } = useOrgState();
  const query = useSubfieldsSummary(org, season?.id);
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col>
          <SummaryCardView
            value={query.data?.totalCount?.toLocaleString() || "--"}
            label={t("common.count")}
          />
        </Col>
        <Col>
          <SummaryCardView
            value={query.data?.totalArea?.toLocaleString() || "--"}
            label={query.data?.totalAreaUom || t("common.area")}
          />
        </Col>
      </Row>
    </>
  );
}
