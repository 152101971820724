import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";

export function AddSeedProduct() {
  const { rootUrl } = useOrgState();
  const { t } = useTranslation();
  return (
    <Row className="flex-grow-1 mb-3 overflow-auto">
      <Col className="d-flex flex-grow-1" sm={12}>
        <Breadcrumb listProps={{ className: "pb-0" }}>
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: `${rootUrl}/inventory/products/seed` }}
          >
            {t("common.goBack")}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {t("inventory.products.seed.addProduct")}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Col>TODO: </Col>
    </Row>
  );
}
