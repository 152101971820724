import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Select } from "components";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { DateRangeInputs, FormField, FormWrapper } from "components";
import { useInventoryState } from "providers/InventoryProvider";
import {
  useOperationsDispatch,
  useOperationsState,
} from "providers/OperationsProvider";
import FieldAndSubfieldInputs from "../FieldAndSubfieldInputs";
import { SelectedIdsProps } from "../PropTypes";

const defaultValues = {
  id: "",
  fieldIds: "",
  subfieldIds: "",
  cropTypeId: "",
  beginDateUtc: "",
  endDateUtc: "",
  averagePercentMoisture: "",
  count: "",
  averageYield: "",
  totalMass: "",
  geometry: "",
};

export default function HarvestOperationForm({ selectedIds, getCancelHref }) {
  const { t } = useTranslation();
  const { cropTypes, season } = useOrgState();

  const history = useHistory();
  const { drawData } = useInventoryState();
  const {
    currentEditFtr,
    saveHarvestState,
    deleteHarvestState,
  } = useOperationsState();
  const [massUom, setMassUom] = useState();
  const [yieldUom, setYieldUom] = useState();
  const [countLabel, setCountLabel] = useState();
  // const [selectedCrop, setSelectedCrop] = useState();

  // update uom values if currentEditFtr changes
  useEffect(() => {
    const cropTypeObj = cropTypes?.find(
      (ct) => ct.id === currentEditFtr?.properties?.cropTypeId
    );
    setYieldUom(cropTypeObj?.yieldUom);
    setMassUom(cropTypeObj?.massUom);
    setCountLabel(cropTypeObj?.countLabel);
  }, [currentEditFtr, cropTypes]);

  const {
    saveHarvestOperation,
    deleteHarvestOperation,
    fetchOperationsSummary,
  } = useOperationsDispatch();
  const methods = useForm({
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    setValue,
    control,
    errors,
  } = methods;
  const { id, beginDateUtc, endDateUtc } = watch();

  async function handleSave(d) {
    if (!formState.isDirty) {
      history.push(getCancelHref());
      return false;
    }
    const body = { ...d };
    if (!yieldUom) {
      delete body.averageYield;
    }
    body.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    body.averagePercentMoisture = Number(body.averagePercentMoisture);
    body.averageYield = Number(body.averageYield);
    body.totalMass = Number(body.totalMass);
    // need at least 1 fieldId or subfieldId
    // but cannot make either input required
    // therefore custom logic to handle validation here
    if (!body.fieldIds?.length && !body.subfieldIds?.length) {
      return false;
    }
    // server doesn't want these props if not set
    if (!body.id) {
      delete body.id;
    }
    if (!body.count && body.count !== 0) {
      delete body.count;
    }
    if (!body.fieldIds?.length) {
      delete body.fieldIds;
    } else {
      body.fieldIds = JSON.parse(body.fieldIds);
    }
    if (!body.subfieldIds?.length) {
      delete body.subfieldIds;
    } else {
      body.subfieldIds = JSON.parse(body.subfieldIds);
    }
    const res = await saveHarvestOperation(body);
    if (!res.isError) {
      reset(d);
      history.push(getCancelHref());
      fetchOperationsSummary();
    }
    return res;
  }

  return (
    <FormWrapper
      methods={methods}
      defaultValues={defaultValues}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={getCancelHref()}
      saveState={saveHarvestState}
      deleteState={deleteHarvestState}
      // NOTE: ignoring geometry, using fieldIds
      ignoreGeom
      onDelete={async () => {
        const res = await deleteHarvestOperation(id);
        if (!res.isError) {
          reset(defaultValues);
          fetchOperationsSummary();
          history.push(getCancelHref(true));
        }
      }}
      onSubmit={handleSubmit(handleSave)}
    >
      <Form.Group>
        <DateRangeInputs
          control={control}
          Controller={Controller}
          startProp="beginDateUtc"
          endProp="endDateUtc"
          startDate={beginDateUtc ? new Date(beginDateUtc) : null}
          endDate={endDateUtc ? new Date(endDateUtc) : null}
          minDate={season?.beginOnUtc ? new Date(season.beginOnUtc) : null}
          maxDate={season?.endOnUtc ? new Date(season.endOnUtc) : null}
        />
        <FormField
          label={`${t("common.cropType")} *`}
          name="cropTypeId"
          control={control}
          rules={{ required: true }}
          htmlFor="crop-type-select"
          render={(props) => (
            <Select
              id="crop-type-select"
              options={cropTypes || []}
              value={cropTypes?.find((ct) => ct.value === props.value)}
              onChange={(item) => {
                // setSelectedCrop(item);
                props.onChange(item?.value || "");
                if (item?.value) {
                  // set uOm values by crop type
                  setMassUom(item.massUom);
                  setYieldUom(item.yieldUom);
                  setCountLabel(item.countLabel);
                  if (!item.yieldUom) {
                    setValue("averageYield", "");
                  }
                } else {
                  setMassUom(null);
                  setYieldUom(null);
                  setCountLabel(null);
                }
              }}
              isClearable
            />
          )}
        >
          {errors?.cropTypeId ? (
            <Form.Text className="text-danger">
              {t("common.requiredField")}
            </Form.Text>
          ) : null}
        </FormField>
        <FormField
          label={t("inventory.operations.averagePercentMoisture")}
          htmlFor="avg-moisture-input"
        >
          <div className="d-flex align-items-center">
            <Form.Control
              name="averagePercentMoisture"
              ref={register}
              id="avg-moisture-input"
              type="number"
              min={0}
              max={100}
              step={0.001}
            />
          </div>
        </FormField>
        <FormField
          label={`${t("common.averageYield")}`}
          htmlFor="avg-yld-input"
          // style={{
          //   textDecoration: !yieldUom && selectedCrop ? 'line-through' : '',
          // }}
        >
          <div className="d-flex align-items-center">
            <Form.Control
              disabled={!yieldUom}
              name="averageYield"
              ref={register}
              id="avg-yld-input"
              type="number"
              min={0}
              step={0.001}
            />
            <span className="ml-1">{yieldUom}</span>
          </div>
        </FormField>
        <FormField
          label={`${t("common.totalMass")}`}
          htmlFor="total-mass-input"
        >
          <div className="d-flex align-items-center">
            <Form.Control
              disabled={!massUom}
              name="totalMass"
              ref={register}
              id="total-mass-input"
              type="number"
              min={0}
              step={0.001}
            />
            <span className="ml-1">{massUom}</span>
          </div>
        </FormField>
        <FormField label={t("common.count")} htmlFor="harvestCount">
          <div className="d-flex align-items-center">
            <Form.Control
              disabled={!countLabel}
              name="count"
              ref={register}
              id="harvestCount"
              type="number"
              step={0.1}
              min={0}
            />
            <span className="ml-1">{countLabel}</span>
          </div>
        </FormField>
        <FieldAndSubfieldInputs
          show={!currentEditFtr}
          selectedIds={selectedIds}
          control={control}
          setValue={setValue}
          formState={formState}
        />
      </Form.Group>
    </FormWrapper>
  );
}

HarvestOperationForm.propTypes = {
  ...SelectedIdsProps,
};
