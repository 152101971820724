import React, { useState, useEffect, useCallback } from "react";
import { Card } from "react-bootstrap";
import { Route, useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState, useOrgDispatch } from "providers/OrgProvider";
import {
  useInventoryDispatch,
  useInventoryState,
} from "providers/InventoryProvider";
import {
  useOperationsState,
  useOperationsDispatch,
} from "providers/OperationsProvider";
import SummaryView from "./SummaryView";
import OperationsListView from "./ListView";
import OperationMapThemes from "./MapThemes";
import CardStack from "../../CardStack";
import OperationDetails from "./OperationDetails";
import { usePrevious, useSetAppDataLoading } from "components";
import { useFieldsState } from "providers/FieldsProvider";
import { fitBounds } from "components/Map";

export function OperationsView() {
  const location = useLocation();
  const { t } = useTranslation();
  const { rootUrl } = useOrgState();
  const { fieldsGeodataState } = useFieldsState();
  const { dispatch } = useOrgDispatch();
  const { inventoryMap } = useInventoryState();
  const { setLegendData } = useInventoryDispatch();
  const { type, itemId } = useParams();
  const addView = location.pathname.includes("/add");
  const [expanded, setExpanded] = useState();
  const [legendProp, setLegendProp] = useState();
  const history = useHistory();
  const {
    currentOperations,
    currentOpsGeodata,
    currentThemeRollup,
    isFetchingGeodata,
    isFetchingData,
  } = useOperationsState();
  const {
    fetchOperations,
    fetchOperationsByField,
    fetchOperationsSummary,
  } = useOperationsDispatch();
  const {
    setInventoryFtrs,
    setIsMapLoading,
    highlightLayerFeatures,
    setColSizes,
    setTitle,
    setLabelProp,
    toggleFieldLabels,
  } = useInventoryDispatch();

  useSetAppDataLoading({ dispatch, isFetchingData });

  useEffect(() => {
    setTitle(t("inventory.operations.title"));
    setLabelProp("product");
    setColSizes([7, 5]);
  }, [setColSizes, setTitle, setLabelProp, t]);

  useEffect(() => {
    toggleFieldLabels(true);
    return () => {
      toggleFieldLabels(false);
    };
  }, [toggleFieldLabels]);

  useEffect(() => {
    setIsMapLoading(isFetchingGeodata);
    return () => {
      setIsMapLoading(false);
    };
  }, [setIsMapLoading, isFetchingGeodata]);

  useEffect(() => {
    fetchOperations();
  }, [fetchOperations]);

  useEffect(() => {
    fetchOperationsSummary();
  }, [fetchOperationsSummary]);

  const getActiveLegendData = useCallback(() => {
    switch (legendProp) {
      case "product": {
        return {
          title: t("common.product"),
          colorProp: "productColor",
          dataProp: "product",
        };
      }
      case "productType": {
        return {
          title: t("common.type"),
          colorProp: "productTypeColor",
          dataProp: "productType",
        };
      }
      case "crop": {
        return {
          title: t("common.crop"),
          colorProp: "cropColor",
          dataProp: "crop",
        };
      }
      case "depth": {
        return {
          title: t("common.depth"),
          colorProp: "depthColor",
          dataProp: (props) => {
            // eslint-disable-next-line react/prop-types
            return `${props?.depth} ${props?.depthUom}`;
          },
        };
      }
      default: {
        return null;
      }
    }
  }, [legendProp, t]);

  const updateLegend = useCallback(() => {
    const themeData = getActiveLegendData();
    setLegendData(themeData);
  }, [setLegendData, getActiveLegendData]);

  const prevOpsGeodata = usePrevious(currentOpsGeodata);
  useEffect(() => {
    if (currentOpsGeodata !== prevOpsGeodata) {
      setInventoryFtrs(currentOpsGeodata);
    }
  }, [currentOpsGeodata, prevOpsGeodata, setInventoryFtrs]);

  // effect for updating legend
  useEffect(() => {
    updateLegend();
  }, [legendProp, updateLegend]);

  // effect for setting themeProp
  useEffect(() => {
    const tLower = type?.toLowerCase();
    let legendP = "product";
    if (tLower === "harvested") {
      legendP = "crop";
    }
    if (tLower === "tilled") {
      legendP = "depth";
    }
    setLegendProp(legendP);
  }, [type]);

  return (
    <>
      <Route path="/:org/:season/inventory/operations">
        <CardStack
          bottomMinHeight="400px"
          topHeight={"300px"}
          expanded={Boolean(itemId || addView || expanded)}
          topChildren={
            <>
              <Card className="mb-3 overflow-auto">
                <SummaryView
                  activeType={type?.toLowerCase()}
                  onSelect={(d) => {
                    history.push(
                      `${rootUrl}/inventory/operations/${d?.operationEn?.toLowerCase()}`
                    );
                  }}
                />
              </Card>
              <Card className="flex-grow-1">
                <OperationMapThemes
                  operationType={type?.toLowerCase()}
                  themeData={currentThemeRollup}
                  activeType={legendProp}
                  onThemeChange={(themeProp) => {
                    setLegendProp(themeProp);
                  }}
                />
              </Card>
            </>
          }
        >
          <Card className="h-100 overflow-auto">
            <Route path="/:org/:season/inventory/operations/:type" exact>
              <OperationsListView
                activeType={type}
                toggleExpanded={() => {
                  setExpanded(!expanded);
                }}
                expanded={expanded}
                operations={currentOperations}
                onRowExpand={(fieldId) => {
                  // check how many list items for this field
                  const listItems = currentOperations.filter(
                    (op) => op.fieldId === fieldId
                  );
                  // do we already have geodata?
                  const existingFtrs = currentOpsGeodata?.features?.filter(
                    (f) => {
                      return f.properties.fieldId === fieldId;
                    }
                  );
                  // if no geodata, or if list items length doesn't match geodata length
                  // fetch/refetch geodata by fieldId
                  if (
                    !existingFtrs ||
                    !existingFtrs.length ||
                    (listItems && listItems.length !== existingFtrs.length)
                  ) {
                    fetchOperationsByField(fieldId);
                  }
                  // zoom to field geodata, or existing operation features if any
                  const fieldGeodata = fieldsGeodataState?.data?.features?.find(
                    (f) => f.id === fieldId
                  );
                  const zoomFtrs = fieldGeodata ? [fieldGeodata] : existingFtrs;
                  if (zoomFtrs && zoomFtrs.length && inventoryMap) {
                    fitBounds({
                      map: inventoryMap,
                      geojson: {
                        type: "FeatureCollection",
                        features: zoomFtrs,
                      },
                      options: { animate: false },
                    });
                  }
                }}
                onEditClick={(data) => {
                  highlightLayerFeatures([]);
                  const ftr = currentOpsGeodata?.features.find(
                    (f) => f.id === data.id
                  );
                  if (!ftr) {
                    return;
                  }
                  history.push(
                    `${rootUrl}/inventory/operations/${type}/${ftr.id}/edit`
                  );
                }}
              />
            </Route>
            <Route
              path={[
                "/:org/:season/inventory/operations/:type/items/:itemId/edit",
                `/:org/:season/inventory/operations/:type/items/:itemId`,
                `/:org/:season/inventory/operations/:type/add`,
              ]}
            >
              <OperationDetails />
            </Route>
          </Card>
        </CardStack>
      </Route>
    </>
  );
}
