import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import DatePicker from "components/DatePicker";
import { useTranslation } from "react-i18next";

export default function DateRangeInputs({
  minDate: defaultMinDate,
  maxDate: defaultMaxDate,
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  onStartChange,
  onEndChange,
  isValidated,
  Controller,
  control,
  startProp,
  endProp,
}) {
  const { t } = useTranslation();
  const [minDate, setMinDate] = useState(defaultMinDate);
  const [maxDate, setMaxDate] = useState(defaultMaxDate);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  useEffect(() => {
    setStartDate(defaultStartDate);
    setMinDate(defaultStartDate);
    setEndDate(defaultEndDate);
  }, [defaultStartDate, defaultEndDate]);

  return (
    <>
      <div className="input-label mt-3 d-flex">
        <div className="label">{t("common.startDate")} *</div>
        <div className="input-container">
          {control && Controller ? (
            <Controller
              name={startProp}
              control={control}
              defaultValue={false}
              rules={{ required: true }}
              render={(props) => (
                <DatePicker
                  startDate={startDate}
                  endDate={endDate}
                  minDate={defaultMinDate}
                  maxDate={maxDate || defaultMaxDate}
                  selectsStart
                  required
                  // eslint-disable-next-line react/prop-types
                  selected={props.value ? new Date(props.value) : null}
                  onChange={(e) => {
                    onStartChange(e);
                    setStartDate(e);
                    setMinDate(e);
                    // eslint-disable-next-line react/prop-types
                    props.onChange(e);
                  }}
                />
              )} // props contains: onChange, onBlur and value
            />
          ) : (
            <DatePicker
              startDate={startDate}
              endDate={endDate}
              minDate={defaultMinDate}
              maxDate={maxDate || defaultMaxDate}
              selectsStart
              required
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                setMinDate(date);
                onStartChange(date);
              }}
            />
          )}
          <Form.Control.Feedback
            type="invalid"
            className={isValidated && !startDate ? "d-block" : ""}
          >
            Required field
          </Form.Control.Feedback>
        </div>
      </div>
      <div className="input-label mt-3">
        <div className="label">{t("common.endDate")} *</div>
        <div className="input-container">
          {control && Controller ? (
            <Controller
              name={endProp}
              control={control}
              defaultValue={false}
              rules={{ required: true }}
              render={(props) => (
                <DatePicker
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={minDate || defaultMinDate}
                  maxDate={defaultMaxDate}
                  required
                  // eslint-disable-next-line react/prop-types
                  selected={props.value ? new Date(props.value) : null}
                  // selected={props.value}
                  onChange={(e) => {
                    onEndChange(e);
                    setEndDate(e);
                    // eslint-disable-next-line react/prop-types
                    props.onChange(e);
                  }}
                />
              )} // props contains: onChange, onBlur and value
            />
          ) : (
            <DatePicker
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={minDate || defaultMinDate}
              maxDate={defaultMaxDate}
              required
              selected={endDate}
              onChange={(date) => {
                setMaxDate(date);
                setEndDate(date);
                onEndChange(date);
              }}
            />
          )}

          <Form.Control.Feedback
            type="invalid"
            className={isValidated && !endDate ? "d-block" : ""}
          >
            {t("common.requiredField")}
          </Form.Control.Feedback>
        </div>
      </div>
    </>
  );
}

DateRangeInputs.defaultProps = {
  minDate: null,
  maxDate: null,
  startDate: null,
  endDate: null,
  isValidated: false,
  Controller: null,
  control: null,
  onStartChange: () => {},
  onEndChange: () => {},
  startProp: "beginOnUtc",
  endProp: "endOnUtc",
};

DateRangeInputs.propTypes = {
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onStartChange: PropTypes.func,
  onEndChange: PropTypes.func,
  isValidated: PropTypes.bool,
  Controller: PropTypes.elementType,
  control: PropTypes.shape({}),
  startProp: PropTypes.string,
  endProp: PropTypes.string,
};
