import React from "react";
import { Row, Col } from "react-bootstrap";
// import { useTranslation } from 'react-i18next';
import { useTranslation } from "react-i18next";
import { useAssetsState } from "providers/AssetsProvider";
import { DetailItem } from "components";

export default function AnimalAssetDetails() {
  const { currentEditFtr } = useAssetsState();
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col className="d-flex flex-wrap">
          <DetailItem
            title={t("common.field")}
            value={currentEditFtr?.properties?.field || "--"}
          />
          <DetailItem
            title={t("common.type")}
            value={currentEditFtr?.properties?.assetAnimalType}
          />
          <DetailItem
            title={t("common.name")}
            value={currentEditFtr?.properties?.name}
          />
          <DetailItem
            title={t("inventory.assets.sex")}
            value={
              currentEditFtr?.properties?.isFemale
                ? t("inventory.assets.female")
                : t("inventory.assets.male")
            }
          />
          <DetailItem
            title="ID"
            value={currentEditFtr?.properties?.cid || "--"}
          />
          <DetailItem
            title={t("common.group")}
            value={currentEditFtr?.properties?.assetGroup || "--"}
          />
          <DetailItem
            title={t("common.breed")}
            value={currentEditFtr?.properties?.assetAnimalBreed || "--"}
          />
          <DetailItem
            title={t("inventory.assets.bornOn")}
            value={
              currentEditFtr?.properties?.bornOnUtc
                ? new Date(
                    currentEditFtr?.properties?.bornOnUtc
                  ).toLocaleDateString()
                : "--"
            }
          />
          <DetailItem
            title={t("common.status")}
            value={currentEditFtr?.properties?.assetStatus || "--"}
          />
        </Col>
        <Col sm={12}>
          <DetailItem
            title={t("common.notes")}
            value={currentEditFtr?.properties?.description || "--"}
          />
        </Col>
      </Row>
    </>
  );
}
