import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import MapThemeItem from "components/MapThemeItem";

export default function MapThemes({ themeType, onThemeTypeChange, themeData }) {
  const { t } = useTranslation();
  return (
    <Container className="h-100">
      <Row className="h-100">
        {themeData && themeData.length ? (
          <Col>
            <MapThemeItem
              id="damage-type"
              title={t("common.type")}
              isActive={themeType === "damage-type"}
              onClick={() => {
                onThemeTypeChange("damage-type");
              }}
              data={themeData}
            />
          </Col>
        ) : null}
        {themeData && !themeData.length ? (
          <Col>
            <h6 className="text-center small mt-4">{t("common.noData")}</h6>
          </Col>
        ) : null}
        {!themeData ? (
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MapThemeItem
              id="skeleton"
              title={t("common.type")}
              isActive={false}
              data={[{ percent: 100, name: "" }]}
              showTooltip={false}
            />
          </Col>
        ) : null}
      </Row>
    </Container>
  );
}
MapThemes.defaultProps = {
  themeType: "damage-type",
  themeData: null,
};
MapThemes.propTypes = {
  themeType: PropTypes.oneOf(["damage-type"]),
  onThemeTypeChange: PropTypes.func.isRequired,
  themeData: PropTypes.arrayOf(PropTypes.shape({})),
};
