import React, { useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { ConfirmAlert } from "lib";
import { Location } from "history";

export default function PromptView({
  message,
  when,
  onConfirm,
  onCancel,
  target,
  alertType,
  ...rest
}: {
  alertType?: "card" | "popover";
  children?: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  message: React.ReactNode;
  onConfirm?: (_e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancel?: () => void;
  requireInputConfirmText?: string;
  requireInputConfirmTextLabel?: React.ReactNode;
  target?: HTMLElement | null;
  title?: string;
  when: boolean;
}) {
  const history = useHistory();
  const [showConfirmLeave, setShowConfirmLeave] = useState<Location>(null);
  return (
    <>
      <Prompt
        message={(l) => {
          if (when && !showConfirmLeave) {
            setShowConfirmLeave(l);
          } else {
            return true;
          }
          return false;
        }}
      />
      <ConfirmAlert
        alertType={alertType}
        message={message}
        show={Boolean(showConfirmLeave)}
        onConfirm={(e) => {
          history.push(showConfirmLeave.pathname + showConfirmLeave.search);
          onConfirm && onConfirm(e);
        }}
        onCancel={() => {
          setShowConfirmLeave(null);
          onCancel && onCancel();
        }}
        target={target}
        {...rest}
      />
    </>
  );
}
