import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { UseQueryResult } from "@tanstack/react-query";
import { Breadcrumb } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useURLSearchParams } from "components";
import { useOrgState } from "providers/OrgProvider";
import { useAssetsState } from "providers";
import { IUrlParams } from "types";

export default function AssetsBreadcrumbs() {
  const { t } = useTranslation();
  const location = useLocation();
  const query = useURLSearchParams();
  const isEdit =
    location.pathname.includes("/edit") &&
    !location.pathname.includes("/records");
  const { rootUrl } = useOrgState();
  const { assetsBySeasonQuery, fieldName } = useAssetsState() as {
    fieldName?: string;
    assetsBySeasonQuery: UseQueryResult<{ asset: string; assetEn: string }[]>;
  };

  const { subtype, fieldId, itemId, type } = useParams<IUrlParams>();

  const groupId = query.get("groupId");

  const currentAssetType = assetsBySeasonQuery?.data?.find(
    (o) => o.assetEn?.toLowerCase() === type
  );

  return (
    <Breadcrumb listProps={{ className: "h3 p-0 mb-0" }}>
      <Breadcrumb.Item
        className="flex-shrink-0"
        linkAs={Link}
        linkProps={{
          to: `${rootUrl}/inventory/assets/${type}`,
        }}
        active={!subtype && !fieldId}
      >
        {currentAssetType?.asset} {t("inventory.assets.title")}
      </Breadcrumb.Item>
      {subtype ? (
        <Breadcrumb.Item
          className="flex-shrink-0"
          linkAs={Link}
          linkProps={{
            to: `${rootUrl}/inventory/assets/${type}/${subtype}`,
          }}
          active={subtype && !fieldId}
        >
          {decodeURIComponent(subtype)}
        </Breadcrumb.Item>
      ) : null}
      {fieldName ? (
        <Breadcrumb.Item
          className="flex-shrink-1"
          title={fieldName}
          active={!itemId}
          linkAs={Link}
          linkProps={{
            to: `${rootUrl}/inventory/assets/${type}/${subtype}/${fieldId}/items${
              groupId ? `?groupId=${groupId}` : ""
            }`,
          }}
        >
          <span className="ellipsis-overflow">{fieldName}</span>
        </Breadcrumb.Item>
      ) : null}
      {fieldName && itemId ? (
        <>
          <Breadcrumb.Item
            className="flex-shrink-0"
            active={itemId && !isEdit}
            linkAs={Link}
            linkProps={{
              to: `${rootUrl}/inventory/assets/${type}/${subtype}/${fieldId}/items/${itemId}${
                groupId ? `?groupId=${groupId}` : ""
              }`,
            }}
          >
            {t("common.details")}
          </Breadcrumb.Item>
        </>
      ) : null}
      {isEdit ? (
        <Breadcrumb.Item className="flex-shrink-0" active>
          {t("common.edit")}
        </Breadcrumb.Item>
      ) : null}
    </Breadcrumb>
  );
}
