import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Route, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgDispatch, useOrgState } from "providers/OrgProvider";
import { useFieldsState } from "providers/FieldsProvider";
import { useInventoryDispatch } from "providers/InventoryProvider";
import FieldListView from "./FieldListView";
import FieldsSummaryView from "./FieldsSummaryView";
import MapThemesView from "./MapThemes";
import CreateOrEditField from "./CreateOrEditField";
import CardStack from "../../CardStack";
import { useSetAppDataLoading } from "components";

export default function FieldsView() {
  const { editId } = useParams();
  const { t } = useTranslation();

  const {
    setLegendData,
    setColSizes,
    showFields,
    hideFields,
    setTitle,
    setLabelProp,
  } = useInventoryDispatch();
  const [legendProp, setLegendProp] = useState("landAgreementType");
  const { dispatch } = useOrgDispatch();
  const { org, season } = useOrgState();
  const { fieldListState, themeState, themeRollup } = useFieldsState();
  const [expanded, setExpanded] = useState();
  const themeData = themeState?.data;

  const seasonId = season?.id;

  useEffect(() => {
    setLabelProp("name");
    setTitle(t("inventory.fields.title"));
    setColSizes([5, 7]);
  }, [setTitle, setColSizes, setLabelProp, t]);

  useEffect(() => {
    hideFields();
    return showFields;
  }, [hideFields, showFields]);

  useSetAppDataLoading({ dispatch, isFetchingData: fieldListState.isLoading });

  // set legend data props (NOTE: currently only landAgreementColor used)
  useEffect(() => {
    setLegendData({
      title: t("inventory.fields.landAgreement"),
      colorProp: "landAgreementColor",
      dataProp: "landAgreementType",
    });
    return () => {
      setLegendData(null);
    };
  }, [setLegendData, t]);

  return (
    <Route path="/:org/:season/inventory/fields">
      <CardStack
        expanded={Boolean(editId) || expanded}
        bottomMinHeight="500px"
        topHeight={"310px"}
        topChildren={
          <>
            <Card className="mb-3">
              <FieldsSummaryView org={org} seasonId={seasonId} />
            </Card>
            <Card className="flex-grow-1">
              <MapThemesView
                themeType={legendProp}
                onThemeTypeChange={(type) => {
                  setLegendProp(type);
                }}
                themeData={themeRollup?.[legendProp]}
              />
            </Card>
          </>
        }
      >
        <Card
          className="p-3 flex-grow-1 bottom fields-list h-100"
          style={{ maxHeight: "100%" }}
        >
          <Route path="/:org/:season/inventory/fields" exact>
            <FieldListView
              onToggle={() => {
                setExpanded(!expanded);
              }}
              toggled={expanded}
              isLoading={fieldListState?.isLoading}
              fields={fieldListState?.data}
              themeData={themeData}
              themeType={legendProp}
            />
          </Route>
          <Route
            path={[
              `/:org/:season/inventory/fields/:editId/edit`,
              `/:org/:season/inventory/fields/add`,
            ]}
            exact
          >
            {season ? <CreateOrEditField /> : null}
          </Route>
        </Card>
      </CardStack>
    </Route>
  );
}
