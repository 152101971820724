import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PageColumn, PageLayout } from "./MainLayout";

export function FeatureLockedView({ title }) {
  const { t } = useTranslation();
  return (
    <>
      {title ? <h2>{title}</h2> : null}
      <PageLayout>
        <PageColumn sm={12}>
          <p className="text-uppercase mt-3">{t("common.featureLocked")}</p>
        </PageColumn>
      </PageLayout>
    </>
  );
}
FeatureLockedView.defaultProps = {
  title: "",
};
FeatureLockedView.propTypes = {
  title: PropTypes.string,
};
