import React, { useEffect } from "react";
import { Row, Col, Card, Nav, Tab } from "react-bootstrap";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Route, useParams, Redirect, Link } from "react-router-dom";
import { useOrgDispatch, useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch } from "providers/InventoryProvider";
import { AddApplicationProduct } from "./AddApplicationProduct";
import { AddSeedProduct } from "./AddSeedProduct";
import { DataTable, useSetAppDataLoading } from "components";

export function ProductsView() {
  const { rootUrl, org, season, productsState } = useOrgState();
  const { dispatch, getProducts } = useOrgDispatch();
  const { t } = useTranslation();
  const { type } = useParams();
  const theme = useTheme();

  const { setColSizes, hideMap, showMap, setTitle } = useInventoryDispatch();

  useSetAppDataLoading({ dispatch, isFetchingData: productsState.isLoading });

  useEffect(() => {
    setColSizes([12, 0]);
    setTitle(t("inventory.products.title"));
  }, [setColSizes, setTitle, t]);

  useEffect(() => {
    hideMap();
    return showMap;
  }, [hideMap, showMap]);

  useEffect(() => {
    getProducts();
  }, [getProducts, org, season]);

  if (!type) {
    return <Redirect to={`${rootUrl}/inventory/products/seed`} />;
  }
  // TODO: get from useTableColumns
  const seedTableCols = [
    { Header: t("common.name"), accessor: "name" },
    { Header: t("common.manufacturer"), accessor: "manufacturer" },
    { Header: t("common.type"), accessor: "type" },
  ];
  const appTableCols = [
    { Header: t("common.name"), accessor: "name" },
    { Header: t("common.type"), accessor: "type" },
    { Header: t("common.form"), accessor: "productForm" },
  ];
  return (
    <Row className="flex-grow-1 overflow-auto">
      <Col className="d-flex flex-grow-1 h-100" sm={12}>
        <Card
          className="w-100 py-1 border-0"
          style={{ backgroundColor: "inherit" }}
        >
          <Tab.Container activeKey={type}>
            <Nav variant="tabs" className="mb-n1">
              <Nav.Item>
                <Nav.Link
                  eventKey="seed"
                  as={Link}
                  to={`${rootUrl}/inventory/products/seed`}
                >
                  {t("inventory.products.seed.title")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="application"
                  as={Link}
                  to={`${rootUrl}/inventory/products/application`}
                >
                  {t("inventory.products.application.title")}
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content
              className={`p-3 border-0 rounded overflow-auto ${
                theme.palette.mode === "light" ? "bg-light" : "bg-dark"
              }`}
            >
              <Tab.Pane eventKey="seed" className="h-100">
                <Route path="/:org/:season/inventory/products/seed" exact>
                  <DataTable
                    isLoading={productsState.isLoading}
                    data={productsState?.data?.filter((d) => {
                      return d.isSeed;
                    })}
                    columns={seedTableCols}
                  />
                </Route>
                <Route path="/:org/:season/inventory/products/seed/add" exact>
                  <AddSeedProduct />
                </Route>
              </Tab.Pane>
              <Tab.Pane eventKey="application" className="h-100">
                <Route
                  path="/:org/:season/inventory/products/application"
                  exact
                >
                  <DataTable
                    isLoading={productsState.isLoading}
                    data={productsState?.data?.filter((d) => {
                      return !d.isSeed;
                    })}
                    columns={appTableCols}
                  />
                </Route>
                <Route
                  path="/:org/:season/inventory/products/application/add"
                  exact
                >
                  <AddApplicationProduct />
                </Route>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card>
      </Col>
    </Row>
  );
}
