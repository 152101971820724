import React from "react";
import { Container, Row, Col, ColProps } from "react-bootstrap";

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  minWidth?: string;
  style?: React.CSSProperties;
}

export function MainLayout({ children, className }: LayoutProps) {
  return (
    <Container
      style={{ maxHeight: "100%", height: "100%" }}
      fluid
      className={`d-flex flex-column flex-grow-1${
        className ? ` ${className}` : ""
      }`}
    >
      {children}
    </Container>
  );
}

export function PageLayout({ children, className, style }: LayoutProps) {
  return (
    <Row
      style={style}
      className={`h-100 flex-grow-1 mb-3 overflow-auto${
        className ? ` ${className}` : ""
      }`}
    >
      {children}
    </Row>
  );
}

export function PageColumn({
  children,
  minWidth,
  className,
  ...rest
}: LayoutProps & ColProps) {
  return (
    <Col
      style={{
        height: "100%",
        minWidth,
      }}
      className={`page-col overflow-auto${className ? ` ${className}` : ""}`}
      {...rest}
    >
      {children}
    </Col>
  );
}
