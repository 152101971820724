import React, { useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch } from "providers";
import { SubfieldFormView } from "./SubfieldFormView";
import { useSubfieldsGeodata } from "api/useSubfieldsGeodata";
import { IUrlParams } from "types";
import { useSubfieldsDispatch } from "providers/SubfieldsProvider";

export function CreateOrEditSubfield() {
  const { org, season, rootUrl } = useOrgState();
  const { t } = useTranslation();
  const { subfieldType, editId } = useParams<IUrlParams>();
  const { enableDraw, disableDraw } = useInventoryDispatch();
  const { resetGeodata } = useSubfieldsDispatch();

  const query = useSubfieldsGeodata({
    org,
    type: subfieldType,
    seasonId: season?.id,
    subfieldId: editId,
  });

  useEffect(() => {
    if (query.data) {
      resetGeodata(query.data);
    }
  }, [query.data, resetGeodata]);

  useEffect(() => {
    enableDraw();
    return disableDraw;
  }, [enableDraw, disableDraw]);

  return (
    <div>
      <Breadcrumb listProps={{ className: "p-0" }}>
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: `${rootUrl}/inventory/subfields/${subfieldType}` }}
        >
          {subfieldType} {t("inventory.subfields.title")}
        </Breadcrumb.Item>
        {editId ? (
          <Breadcrumb.Item active>{`${t("common.edit")}`}</Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item active>{`${t("common.add")}`}</Breadcrumb.Item>
        )}
      </Breadcrumb>
      <SubfieldFormView subfieldType={subfieldType} />
    </div>
  );
}
