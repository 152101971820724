import React from "react";
import Select, {
  components as selectComponents,
  CSSObjectWithLabel,
  GroupBase,
  Props,
  StylesConfig,
  Theme,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import "./ReactSelect.scss";

function getTheme(base: Theme): Theme {
  return {
    ...base,
    borderRadius: 4,
    spacing: {
      baseUnit: 4,
      controlHeight: 24,
      menuGutter: 6,
    },
  };
}

function getStyles(styles: StylesConfig, bg: string): StylesConfig {
  return {
    menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 9999 }),
    singleValue: (base: CSSObjectWithLabel) => {
      return { ...base, color: "#fff" };
    },
    option: (
      base: { backgroundColor: string },
      { isDisabled }: { isDisabled: boolean }
    ) => ({
      ...base,
      cursor: isDisabled ? "not-allowed" : "pointer",
      backgroundColor: "",
    }),
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      overflow: "hidden",
      zIndex: "3",
      backgroundColor: bg || null,
    }),
    control: (base: CSSObjectWithLabel) => ({
      ...base,
      border: 0,
      borderRadius: 4,
      backgroundColor: bg || null,
      // boxShadow: 'none',
    }),
    ...styles,
  };
}

function getComponents(components = {}) {
  return {
    IndicatorSeparator: (): null => null,
    ...components,
  };
}

/**
 *
 * @visibleName ReactSelect
 */

function ReactSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {
  const { allowCreate, altBg, bg, ...rest } = props;
  const final = {
    ...rest,
    classNamePrefix: `${altBg ? "alt " : ""}cai-react-select`,
    className: `w-100${props.className ? ` ${props.className}` : ""}`,
    components: getComponents(props.components),
    styles: getStyles(props.styles, bg),
    theme: getTheme,
  };

  return !allowCreate ? <Select {...final} /> : <CreatableSelect {...final} />;
}

ReactSelect.components = selectComponents;

export default ReactSelect;

// module augmentation for custom select props
declare module "react-select/dist/declarations/src/Select" {
  export interface Props<
    Option,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    IsMulti extends boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Group extends GroupBase<Option>
  > {
    altBg?: boolean;
    allowCreate?: boolean;
    bg?: string;
  }
}
