import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import {
  useInventoryDispatch,
  useInventoryState,
} from "providers/InventoryProvider";
import {
  useConservationDispatch,
  useConservationState,
} from "providers/ConservationProvider";
import { GeoDataTable } from "components";
import { IconButton } from "../../../components";
import useAreaColumn from "components/Table/useAreaColumn";
import { fitBounds } from "components/Map";

export default function ConservationListView({
  onEditClick,
  expanded,
  toggleExpanded,
}) {
  const { t } = useTranslation();
  const areaHeader = useAreaColumn();
  // TODO: move to useTableColumns hook
  const searchOnlyFields = [
    // search/filter only fields
    { accessor: "farm" },
    // end search/filter only fields
  ];

  const final = [
    { accessor: "field", Header: t("common.field") },
    {
      Header: t("common.startDate"),
      id: "beginOnUtc",
      accessor: (row) => {
        return new Date(row.beginOnUtc).toLocaleDateString();
      },
    },
    {
      Header: "ID",
      accessor: "cid",
    },
    {
      Header: t("common.type"),
      accessor: "conservationType",
    },
    {
      Header: t("common.status"),
      accessor: "conservationStatus",
    },
    {
      Header: t("inventory.conservation.timeEnrolled"),
      accessor: "timeEnrolled",
    },
    {
      Header: "CC",
      accessor: (row) => {
        return row?.isCarbonCredit ? t("common.yes") : t("common.no");
      },
    },
    areaHeader,
  ].concat(searchOnlyFields);
  const { rootUrl } = useOrgState();
  const { items, geodataState } = useConservationState();
  const { inventoryMap, ftrsClicked } = useInventoryState();
  const { highlightLayerFeatures } = useInventoryDispatch();
  const { fetchConservationByFieldId } = useConservationDispatch();
  // const [selectedRows, setSelectedRows] = useState([]);
  function getCols(onEdit) {
    return [
      {
        Header: " ",
        isSortable: false,
        accessor: function Cell(row) {
          return (
            <IconButton
              icon="edit"
              onClick={(e) => {
                e.stopPropagation();
                onEdit(row);
              }}
            />
          );
        },
      },
    ].concat(final);
  }
  return (
    <div className="d-flex flex-column overflow-hidden">
      <div className="pb-2 d-flex">
        <h3 className="mb-0">{t("common.carbon")}</h3>
        <div className="d-flex ml-auto">
          <IconButton
            title={
              expanded ? t("common.collapseTable") : t("common.expandTable")
            }
            icon={expanded ? "chevronDown" : "chevronUp"}
            className="mr-2 d-flex"
            onClick={toggleExpanded}
          />
          <IconButton
            as={Link}
            to={`${rootUrl}/inventory/conservation/add`}
            icon="add"
            className="d-flex"
            variant="outline-success"
          />
        </div>
      </div>
      <span className="inventory-list overflow-auto">
        <GeoDataTable
          tableProps={{ size: "sm" }}
          hiddenColumns={["farm"]}
          columns={getCols(onEditClick)}
          data={items}
          highlightByIds={highlightLayerFeatures}
          ftrsClicked={ftrsClicked}
          map={inventoryMap}
          featureCollection={geodataState?.data}
          onRowExpand={(row) => {
            const { fieldId } = row.leafRows[0].original;
            // check how many list items for this field
            const listItems = items.filter((op) => op.fieldId === fieldId);
            // do we already have geodata?
            const existingFtrs = geodataState?.data?.features?.filter((f) => {
              return f.properties.fieldId === fieldId;
            });
            // if no geodata, or if list items length doesn't match geodata length
            // fetch/refetch geodata by fieldId
            if (
              !existingFtrs ||
              !existingFtrs.length ||
              (listItems && listItems.length !== existingFtrs.length)
            ) {
              // TODO:
              fetchConservationByFieldId(fieldId);
            }

            if (existingFtrs && existingFtrs.length && inventoryMap) {
              fitBounds({
                map: inventoryMap,
                geojson: {
                  type: "FeatureCollection",
                  features: existingFtrs,
                },
                options: { animate: false },
              });
            }
          }}
          groupBy={["field"]}
        />
      </span>
    </div>
  );
}

ConservationListView.defaultProps = {
  onEditClick: () => {},
};

ConservationListView.propTypes = {
  onEditClick: PropTypes.func,
  expanded: PropTypes.bool,
  toggleExpanded: PropTypes.func,
};
