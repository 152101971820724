import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import DialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle";
import DialogContent, {
  DialogContentProps as DPProps,
} from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog, { DialogProps } from "@mui/material/Dialog";

export interface ConfirmationDialogRawProps {
  actionsChildren?: React.ReactNode;
  title?: string;
  message?: React.ReactElement;
  cancelText?: string;
  confirmText?: string;
  cancelButtonProps?: ButtonProps;
  confirmButtonProps?: ButtonProps;
  onClose?: (value: boolean) => void;
  DialogContentProps?: DPProps;
  DialogTitleProps?: DialogTitleProps;
}

export default function ConfirmationDialog(
  props: ConfirmationDialogRawProps & Partial<Omit<DialogProps, "onClose">>
) {
  const {
    cancelText,
    confirmText,
    cancelButtonProps,
    confirmButtonProps,
    DialogContentProps,
    DialogTitleProps,
    message,
    onClose,
    title,
    actionsChildren,
    ...other
  } = props;

  const handleCancel = () => {
    onClose && onClose(false);
  };

  const handleOk = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClose && onClose(true);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open
      {...other}
    >
      <DialogTitle {...DialogTitleProps}>{title}</DialogTitle>
      <DialogContent {...DialogContentProps}>{message}</DialogContent>
      <DialogActions>
        {actionsChildren}
        <Button {...cancelButtonProps} autoFocus onClick={handleCancel}>
          {cancelText ?? "Cancel"}
        </Button>
        <Button {...confirmButtonProps} onClick={handleOk}>
          {confirmText ?? "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
