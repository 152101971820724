import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DatePicker from "components/DatePicker";
import { useOrgState } from "providers/OrgProvider";
import { useAssetsDispatch, useAssetsState } from "providers/AssetsProvider";
import { FormWrapper, Select, useURLSearchParams } from "components";

const defaultValues = {
  id: null,
  assetId: null,
  eventTypeId: null,
  dateUtc: null,
  weightLbs: null,
  notes: null,
  photoCount: null,
};

export default function AssetRecordForm({
  assetEventTypes,
  currentEditRecord,
  onSaveRecord,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useURLSearchParams();
  const groupId = query.get("groupId");
  const { type, subtype, itemId, fieldId } = useParams();
  const { rootUrl } = useOrgState();

  const { deleteRecordState, saveRecordState } = useAssetsState();
  const { saveRecord, deleteRecord } = useAssetsDispatch();
  const methods = useForm({
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    control,
    errors,
  } = methods;
  const { id } = watch();
  const baseRoute = `${rootUrl}/inventory/assets/${type}/${subtype}/${fieldId}/items${
    itemId ? `/${itemId}` : ""
  }`;

  async function handleSave(d) {
    if (!formState.isDirty) {
      history.push(`${baseRoute}${groupId ? `?groupId=${groupId}` : ""}`);
    }
    if (!d.id) {
      delete d.id;
    }
    if (!d.weightLbs && d.weightLbs !== 0) {
      delete d.weightLbs;
    }
    const res = await saveRecord(d);
    if (!res.isError) {
      onSaveRecord(res.data?.id);
      reset(d);
      history.push(`${baseRoute}${groupId ? `?groupId=${groupId}` : ""}`);
    }
    return res;
  }
  return (
    <FormWrapper
      methods={methods}
      data={{ ...currentEditRecord }}
      cancelHref={`${baseRoute}${groupId ? `?groupId=${groupId}` : ""}`}
      saveState={saveRecordState}
      deleteState={deleteRecordState}
      ignoreGeom
      geometryRequired={false}
      onDelete={async () => {
        const res = await deleteRecord(id);
        if (!res.isError) {
          reset(defaultValues);
          history.push(`${baseRoute}${groupId ? `?groupId=${groupId}` : ""}`);
        }
      }}
      onSubmit={handleSubmit(handleSave)}
    >
      <Form.Group>
        <Form.Label htmlFor="event-type" className="input-label mt-3">
          <div className="label">Type</div>
          <div className="input-container">
            <Controller
              name="eventTypeId"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Select
                  id="event-type"
                  options={assetEventTypes || []}
                  // eslint-disable-next-line react/prop-types
                  value={assetEventTypes?.find((a) => a.id === props.value)}
                  onChange={(e) => {
                    // eslint-disable-next-line react/prop-types
                    props.onChange(e.value || "");
                  }}
                />
              )}
            />
            {errors?.eventTypeId ? (
              <Form.Text className="text-danger">Type is required</Form.Text>
            ) : null}
          </div>
        </Form.Label>
        {/* TODO: use <FormField> component */}
        {/* wrapping date in <label> breaks auto dismiss on select */}
        <Form.Label as="div" htmlFor="date-select" className="input-label mt-3">
          <div className="input-label mt-3">
            <div className="label">{t("common.date")} *</div>
            <div className="input-container">
              <Controller
                name="dateUtc"
                control={control}
                defaultValue={false}
                rules={{ required: true }}
                render={(props) => (
                  <DatePicker
                    id="date-select"
                    required
                    // eslint-disable-next-line react/prop-types
                    selected={props.value ? new Date(props.value) : null}
                    onChange={(date) => {
                      // eslint-disable-next-line react/prop-types
                      props.onChange(date || "");
                    }}
                  />
                )}
              />
            </div>
          </div>
        </Form.Label>
        <Form.Label htmlFor="weight-input" className="input-label mt-3">
          <div className="label">{t("common.weight")}</div>
          <div className="input-container">
            <Form.Control
              id="weight-input"
              name="weightLbs"
              rules={{ required: true }}
              ref={register}
              type="number"
            />
          </div>
        </Form.Label>
        <Form.Label htmlFor="event-notes" className="input-label mt-3">
          <div className="label">{t("common.notes")}</div>
          <div className="input-container">
            <Form.Control
              id="event-notes"
              as="textarea"
              maxLength={255}
              name="notes"
              ref={register}
            />
          </div>
        </Form.Label>
      </Form.Group>
    </FormWrapper>
  );
}

AssetRecordForm.defaultProps = {
  assetEventTypes: null,
  currentEditRecord: null,
};

AssetRecordForm.propTypes = {
  assetEventTypes: PropTypes.arrayOf(PropTypes.shape({})),
  currentEditRecord: PropTypes.shape({}),
  onSaveRecord: PropTypes.func.isRequired,
};
