import { Col, Row } from "react-bootstrap";
import { DetailItem, ImageComponent } from "components";
import { useURLSearchParams } from "components";
import IconButton from "components/IconButton";
import { useAssetsDispatch, useAssetsState } from "providers/AssetsProvider";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { LinearProgress } from "lib";

export default function AssetRecordDetails() {
  const { t } = useTranslation();
  const { recordId } = useParams<{ recordId: string }>();
  const query = useURLSearchParams();
  const assetGroupId = query.get("groupId");
  const {
    assetRecordPhotos,
    fetchingRecordPhotos,
    isFetchingData,
    currentAssetRecords,
  } = useAssetsState();
  const { fetchRecordPhotosById, resetRecordPhotos } = useAssetsDispatch();

  const currentEditRecord = recordId
    ? currentAssetRecords?.find((r) => r.id === recordId)
    : null;

  useEffect(() => {
    if (recordId) {
      fetchRecordPhotosById(recordId);
    }
    return () => {
      resetRecordPhotos();
    };
  }, [fetchRecordPhotosById, recordId, resetRecordPhotos]);

  return (
    <>
      <LinearProgress
        isLoading={isFetchingData || fetchingRecordPhotos}
        absolute
      />
      <Row>
        <Col xs="auto">
          <Link to={".."}>
            <IconButton icon="chevronLeft" />
          </Link>
        </Col>
        <Col className="ml-auto" xs="auto">
          <Link
            to={{
              pathname: `${recordId}/edit`,
              search: `${assetGroupId ? `?groupId=${assetGroupId}` : ""}`,
            }}
          >
            <IconButton icon="edit" />
          </Link>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="d-flex">
          <DetailItem
            title={t("common.type")}
            value={currentEditRecord?.eventType ?? "--"}
          />
          <DetailItem
            title={t("common.date")}
            value={
              currentEditRecord?.dateUtc
                ? new Date(currentEditRecord.dateUtc).toLocaleDateString()
                : "--"
            }
          />
          <DetailItem
            title={t("common.weight")}
            value={currentEditRecord?.weightLbs ?? "--"}
          />
          <DetailItem
            title={t("common.notes")}
            value={currentEditRecord?.notes ?? "--"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ImageComponent
            id="asset-record-photos"
            images={assetRecordPhotos ?? []}
            readonly
          />
        </Col>
      </Row>
    </>
  );
}
