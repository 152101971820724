import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAssetsState } from "providers/AssetsProvider";
import { DetailItem } from "components";

export default function FixedAssetDetails() {
  const { currentEditFtr } = useAssetsState();
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col className="d-flex flex-wrap">
          <DetailItem
            title={t("common.field")}
            value={currentEditFtr?.properties?.field || "--"}
          />
          <DetailItem
            title={t("common.type")}
            value={currentEditFtr?.properties?.fixedAssetType || "--"}
          />
          <DetailItem
            title={t("common.status")}
            value={currentEditFtr?.properties?.assetStatus || "--"}
          />
          <DetailItem
            title={t("common.name")}
            value={currentEditFtr?.properties?.name || "--"}
          />
          <DetailItem
            title="ID"
            value={currentEditFtr?.properties?.cid || "--"}
          />
          <DetailItem
            title={t("inventory.assets.createdDate")}
            value={
              currentEditFtr?.properties?.bornOnUtc
                ? new Date(
                    currentEditFtr?.properties?.bornOnUtc
                  ).toLocaleDateString()
                : "--"
            }
          />
        </Col>
        <Col sm={12}>
          <DetailItem
            title={t("common.notes")}
            value={currentEditFtr?.properties?.description || "--"}
          />
        </Col>
      </Row>
    </>
  );
}
