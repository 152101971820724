import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export default function NotFoundView({ children, message }) {
  const { t } = useTranslation();
  return (
    <div className="text-center d-flex flex-column">
      <span className="h3">{message || t("common.viewNotFound")}</span>
      {children}
    </div>
  );
}

NotFoundView.defaultProps = {
  children: null,
  message: "",
};

NotFoundView.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
};
