import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const CustomTooltip = ({ active, payload }) => {
  const { t } = useTranslation();
  if (active) {
    const { name, area, areaPercent, areaUom, count } = payload[0]?.payload;
    return (
      <div
        className="bg-light text-dark p-2"
        style={{ border: "solid 1px", opacity: 0.9, minWidth: "175px" }}
      >
        <h6
          className="text-center mb-0"
          style={{ fontWeight: "bold" }}
        >{`${name}`}</h6>
        <div>{`${payload[0].value}% ${t("inventory.fields.ofTotal")}`}</div>
        <div>{`${t("common.count")}: ${count}`}</div>
        {area ? (
          <>
            <div>{`${Number(area).toLocaleString()} ${areaUom}`}</div>
            <div>{`${areaPercent}% ${t("inventory.fields.ofTotalArea")}`}</div>
          </>
        ) : null}
      </div>
    );
  }

  return null;
};
export default CustomTooltip;

CustomTooltip.defaultProps = {
  active: false,
  payload: null,
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({}),
      value: PropTypes.number,
    })
  ),
};
