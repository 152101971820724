import React from "react";

export default function CardStack({
  children,
  topChildren,
  expanded,
  bottomMinHeight,
  topHeight = "auto",
  delay = 500,
  bottomClasses = "mt-3",
}: {
  children: React.ReactNode;
  topChildren: React.ReactNode;
  expanded?: boolean;
  bottomMinHeight?: string;
  topHeight?: string;
  delay?: number;
  bottomClasses?: string;
}) {
  return (
    <>
      <div
        style={{
          ...(expanded ? { pointerEvents: "none" } : {}),
          opacity: expanded ? 0 : 1,
          display: "flex",
          flexDirection: "column",
          transition: `all ${delay}ms`,
          height: !expanded ? topHeight : 0,
        }}
        className={`top ${!expanded ? `show` : "hide"}`}
      >
        {topChildren}
      </div>
      <div
        className={`flex-grow-1 overflow-auto${
          !expanded && bottomClasses ? ` ${bottomClasses}` : ""
        }`}
        style={{
          height: bottomMinHeight,
          border: "none",
        }}
      >
        {children}
      </div>
    </>
  );
}
