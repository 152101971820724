import React from "react";
import { useParams } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import { SubfieldsProvider } from "providers/SubfieldsProvider";
import { SubfieldsView } from "./SubfieldsView";

export function Subfields() {
  const { season, org } = useOrgState();
  const { subfieldType, editId } = useParams();

  return (
    <SubfieldsProvider
      org={org}
      seasonId={season?.id}
      editId={editId}
      subfieldType={subfieldType}
    >
      <SubfieldsView />
    </SubfieldsProvider>
  );
}
