import React from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";
import { IFetchResponse } from "types";

const stateProp = PropTypes.shape({
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
});

export default function ApiStatus({
  saveState,
  deleteState,
}: {
  saveState: IFetchResponse;
  deleteState: IFetchResponse;
}) {
  return (
    <>
      {saveState?.isError ? (
        <div className="mt-3">
          <Alert variant="danger">
            {saveState.errorMessage ||
              "Unable to save. Please try again or contact an administrator."}
          </Alert>
        </div>
      ) : null}
      {deleteState?.isError ? (
        <div className="mt-3">
          <Alert variant="danger">
            {deleteState.errorMessage ||
              "Unable to delete. Please try again or contact an administrator."}
          </Alert>
        </div>
      ) : null}
    </>
  );
}

ApiStatus.defaultProps = {
  saveState: null,
  deleteState: null,
};

ApiStatus.propTypes = {
  saveState: stateProp,
  deleteState: stateProp,
};
