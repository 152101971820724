import React, { useCallback, useReducer } from "react";
import { useParams } from "react-router-dom";
import { blobToImage, sortObjArr } from "../lib/utils";
import useFetchWithToken from "components/useFetchWithToken";
import { useCollectEventUtils } from "components";
import {
  ICropType,
  IFetchState,
  IOrganization,
  IProduct,
  ISeason,
  ISensor,
} from "types";
import { ISelectOpt } from "types/ISelectOpt";
import { useOrgConfig } from "api/useOrgConfig";
import { UseQueryResult } from "@tanstack/react-query";
import { IOrgConfig } from "types/IOrgConfig";
import useSeasonsByOrg from "api/useSeasonsByOrg";
import { IRasterLegend } from "types/IRasterLegend";

interface IReducerAction {
  type: "SET_DATA_LOADING" | "SET_SEASON" | "SET_ORG";
  payload: string | boolean | { name: string };
}

interface IOrgState {
  org: string;
  tenant?: string;
  season?: ISeason;
  isDataLoading?: boolean;
}

interface IOrgDispatchContext {
  abortFetchRasterLegend: () => void;
  dispatch: (_data: IReducerAction) => void;
  fetchDefaultCollectEvents: (_org: string) => void;
  getOrgDarkLogo: (_ent: string) => Promise<IFetchState<HTMLImageElement>>;
  getOrgLightLogo: (_ent: string) => Promise<IFetchState<HTMLImageElement>>;
  getEnterpriseLogos: (
    _ent: string
  ) => Promise<IFetchState<HTMLImageElement>[]>;
  getEntDarkLogo: (_ent: string) => Promise<IFetchState<HTMLImageElement>>;
  getEntLightLogo: (_ent: string) => Promise<IFetchState<HTMLImageElement>>;
  fetchFieldsSummary: () => void;
  getCropTypes: () => void;
  getFarms: () => void;
  getFieldsSummary: () => void;
  getCurrentSensorTypes: () => void;
  getLandAgreements: () => void;
  getProducts: () => void;
  getRasterLegend: (_type: string) => Promise<IFetchState<IRasterLegend>>;
  resetRasterLegend: () => void;
  resetProducts: () => void;
  resetCurrentSensorTypes: () => void;
  resetFieldsSummary: () => void;
  resetFarms: () => void;
}

interface IOrgStateContext {
  org: string;
  tenant?: string;
  season?: ISeason;
  isDataLoading?: boolean;
  orgData?: IOrganization;
  rootUrl?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultCollectEventsState?: IFetchState<any[]>;
  darkOrgLogoState?: IFetchState<HTMLImageElement>;
  lightOrgLogoState?: IFetchState<HTMLImageElement>;
  darkEntLogoState?: IFetchState<HTMLImageElement>;
  lightEntLogoState?: IFetchState<HTMLImageElement>;
  fieldsSummary?: unknown;
  fieldsSummaryState?: IFetchState<unknown>;
  seasonId?: string;
  seasons?: ISeason[];
  seasonsState?: UseQueryResult<ISeason[]>;
  farms?: unknown;
  cropTypes?: ICropType[];
  cropTypesState?: IFetchState<ICropType[]>;
  products?: IProduct[];
  productsState?: IFetchState<IProduct[]>;
  currentSensorTypesState?: IFetchState<ISensor[]>;
  currentSensorTypes?: unknown;
  rasterLegendState?: IFetchState<IRasterLegend>;
  landAgreementTypesState?: IFetchState<unknown>;
  landAgreementTypes?: unknown;
  configQuery: UseQueryResult<IOrgConfig>;
}
const tileServerOrigin = process.env.REACT_APP_TILESERVER_ORIGIN;
const portalApiOrigin = process.env.REACT_APP_PORTAL_API_ORIGIN;

const OrgStateContext = React.createContext({} as IOrgStateContext);
const OrgStateDispatch = React.createContext({} as IOrgDispatchContext);

const logoHeaders = {
  transformResponse: (res: Blob) => {
    return blobToImage(res);
  },
  headers: {
    "Content-Type": "multipart/form-data",
  },
  apiOrigin: "",
};

const setValLabelSort = (
  res: (
    | { id: string; name: string; value: string; label: string }
    | (ISensor & ISelectOpt)
  )[]
) => {
  res.forEach((r) => {
    r.value = r.id;
    r.label = r.name;
  });
  sortObjArr(res, "name");
  return res;
};

const appReducer = (state: IOrgState, action: IReducerAction): IOrgState => {
  switch (action.type) {
    case "SET_DATA_LOADING":
      return { ...state, isDataLoading: action.payload as boolean };
    case "SET_SEASON":
      return { ...state, season: action.payload as ISeason };
    case "SET_ORG": {
      return {
        ...state,
        org: action.payload as string,
        season: null,
      } as IOrgState;
    }
    default:
      throw new Error("Invalid reducer action type");
  }
};

function OrgProvider({
  children,
  organizations,
}: {
  children: React.ReactNode;
  organizations: IOrganization[];
}) {
  const { org }: { org: string } = useParams();
  const orgConfigQ = useOrgConfig(org);

  const seasonsQ = useSeasonsByOrg(org);

  const [state, dispatch] = useReducer(appReducer, {
    org,
  });
  const orgData = organizations?.find((o) => o.slug === org);
  const tenant = orgData?.tenant?.slug;
  const {
    state: landAgreementTypesState,
    fetchData: fetchLandAgreements,
  } = useFetchWithToken();

  const {
    defaultCollectEventsState,
    fetchDefaultCollectEvents,
  } = useCollectEventUtils();

  const {
    fetchData: fetchFieldsSummary,
    state: fieldsSummaryState,
    resetFetchState: resetFieldsSummary,
  } = useFetchWithToken();
  const {
    fetchData: fetchCurrentSensorTypes,
    state: currentSensorTypesState,
    resetFetchState: resetCurrentSensorTypes,
  } = useFetchWithToken<(ISensor & ISelectOpt)[]>();

  const {
    state: rasterLegendState,
    fetchData: fetchRasterLegend,
    resetFetchState: resetRasterLegend,
    abort: abortFetchRasterLegend,
  } = useFetchWithToken<IRasterLegend>();

  const {
    fetchData: fetchCropTypes,
    state: cropTypesState,
  } = useFetchWithToken<ICropType[]>();
  const {
    fetchData: fetchProducts,
    state: productsState,
    resetFetchState: resetProducts,
  } = useFetchWithToken<IProduct[]>();
  const {
    fetchData: fetchFarms,
    state: farmsState,
    resetFetchState: resetFarms,
  } = useFetchWithToken();

  const {
    fetchData: getDarkOrganizationLogo,
    state: darkOrgLogoState,
  } = useFetchWithToken<HTMLImageElement>();
  const {
    fetchData: getLightOrganizationLogo,
    state: lightOrgLogoState,
  } = useFetchWithToken<HTMLImageElement>();
  const {
    fetchData: getLightEnterpriseLogo,
    state: lightEntLogoState,
  } = useFetchWithToken<HTMLImageElement>();
  const {
    fetchData: getDarkEnterpriseLogo,
    state: darkEntLogoState,
  } = useFetchWithToken<HTMLImageElement>();
  const { fetchData: getLogoByEnterprise } = useFetchWithToken(null, {
    abortPrevious: false,
    formatResponse: blobToImage,
    baseUrl: "",
  });

  const getEnterpriseLogos = useCallback(
    (ent) => {
      const dark = getLogoByEnterprise(
        `${portalApiOrigin}/enterprises/${ent}/config/logos/dark`,
        logoHeaders
      );
      const light = getLogoByEnterprise(
        `${portalApiOrigin}/enterprises/${ent}/config/logos/light`,
        logoHeaders
      );
      return Promise.all([dark, light]);
    },
    [getLogoByEnterprise]
  );

  const getRasterLegend = useCallback(
    (type) => {
      return fetchRasterLegend(`/cog/noauth/${tenant}/${org}/${type}/legend`, {
        apiOrigin: tileServerOrigin,
        transformResponse: (res) => {
          return { type: type?.toUpperCase(), data: res };
        },
      });
    },
    [org, fetchRasterLegend, tenant]
  );

  const getOrgDarkLogo = useCallback(
    (enterprise) => {
      return getDarkOrganizationLogo(
        `${portalApiOrigin}/enterprises/${enterprise}/organizations/${org}/config/logos/dark`,
        // @ts-expect-error response transformed from Blob -> HTMLImageElement
        logoHeaders
      );
    },
    [getDarkOrganizationLogo, org]
  );
  const getOrgLightLogo = useCallback(
    (enterprise) => {
      return getLightOrganizationLogo(
        `${portalApiOrigin}/enterprises/${enterprise}/organizations/${org}/config/logos/light`,
        // @ts-expect-error response transformed from Blob -> HTMLImageElement
        logoHeaders
      );
    },
    [getLightOrganizationLogo, org]
  );

  const getEntDarkLogo = useCallback(
    (enterprise) => {
      return getDarkEnterpriseLogo(
        `${portalApiOrigin}/enterprises/${enterprise}/config/logos/dark`,
        // @ts-expect-error response transformed from Blob -> HTMLImageElement
        logoHeaders
      );
    },
    [getDarkEnterpriseLogo]
  );
  const getEntLightLogo = useCallback(
    (enterprise) => {
      return getLightEnterpriseLogo(
        `${portalApiOrigin}/enterprises/${enterprise}/config/logos/light`,
        // @ts-expect-error response transformed from Blob -> HTMLImageElement
        logoHeaders
      );
    },
    [getLightEnterpriseLogo]
  );
  const getLandAgreements = useCallback(() => {
    return fetchLandAgreements(`/${org}/lookups/landagreementtypes`, {
      transformResponse: setValLabelSort,
    });
  }, [fetchLandAgreements, org]);

  const getCropTypes = useCallback(() => {
    return fetchCropTypes(`/${state.org}/lookups/croptypes`, {
      transformResponse: setValLabelSort,
    });
  }, [fetchCropTypes, state.org]);

  const getFarms = useCallback(() => {
    return fetchFarms(`/${state.org}/farms`, {
      transformResponse: setValLabelSort,
    });
  }, [fetchFarms, state.org]);

  const getCurrentSensorTypes = useCallback(() => {
    return fetchCurrentSensorTypes(`/${state.org}/sensors`, {
      transformResponse: setValLabelSort,
    });
  }, [fetchCurrentSensorTypes, state.org]);

  const getFieldsSummary = useCallback(() => {
    return fetchFieldsSummary(
      `/${state.org}/fields/season/${state.season.id}/summary`
    );
  }, [fetchFieldsSummary, state.org, state.season]);

  const getProducts = useCallback(async () => {
    if (!state.org || !state.season) {
      return null;
    }
    return fetchProducts(`/${state.org}/products/season/${state.season?.id}`, {
      transformResponse: (res: IProduct[]) => {
        res.forEach((r) => {
          // appending cropTypeId to support "unknown" product type across many
          // crop types
          r.value = `${r.id}:${r.cropTypeId}`;
          r.label = r.name;
        });
        sortObjArr(res, "name");
        return res;
      },
    });
  }, [fetchProducts, state.season, state.org]);

  return (
    <OrgStateContext.Provider
      value={{
        tenant,
        org: org,
        configQuery: orgConfigQ,
        orgData,
        rootUrl: `/${org}${state?.season ? `/${state?.season?.name}` : ""}`,
        defaultCollectEventsState,
        darkOrgLogoState,
        lightOrgLogoState,
        darkEntLogoState,
        lightEntLogoState,
        fieldsSummary: fieldsSummaryState.data,
        fieldsSummaryState,
        season: state?.season,
        seasonId: state?.season?.id,
        seasons: seasonsQ.data,
        seasonsState: seasonsQ,
        farms: farmsState?.data,
        cropTypes: cropTypesState.data,
        cropTypesState,
        products: productsState.data,
        productsState,
        currentSensorTypesState,
        currentSensorTypes: currentSensorTypesState?.data,
        rasterLegendState,
        landAgreementTypesState,
        landAgreementTypes: landAgreementTypesState?.data,
        isDataLoading: state?.isDataLoading,
      }}
    >
      <OrgStateDispatch.Provider
        value={{
          abortFetchRasterLegend,
          dispatch,
          fetchDefaultCollectEvents,
          getEnterpriseLogos,
          getOrgDarkLogo,
          getOrgLightLogo,
          getEntDarkLogo,
          getEntLightLogo,
          fetchFieldsSummary: useCallback(() => {
            if (!org || !state.season) {
              return false;
            }
            return fetchFieldsSummary(
              `/${org}/fields/season/${state.season.id}/summary`
            );
          }, [state.season, org, fetchFieldsSummary]),
          getCropTypes,
          getFarms,
          getFieldsSummary,
          getCurrentSensorTypes,
          getLandAgreements,
          getProducts,
          getRasterLegend,
          resetRasterLegend,
          resetProducts,
          resetCurrentSensorTypes,
          resetFieldsSummary,
          resetFarms,
        }}
      >
        {children}
      </OrgStateDispatch.Provider>
    </OrgStateContext.Provider>
  );
}

function useOrgState() {
  const context = React.useContext(OrgStateContext);
  if (context === undefined) {
    throw new Error("useOrgState must be used within an OrgProvider");
  }
  return context;
}

function useOrgDispatch() {
  const context = React.useContext(OrgStateDispatch);
  if (context === undefined) {
    throw new Error("useOrgDispatch must be used within an OrgProvider");
  }
  return context;
}

export { OrgProvider, useOrgState, useOrgDispatch };
