import { Route, Switch } from "react-router-dom";
import YieldInsights from "./Yield";
import { MainLayout } from "../MainLayout";
import ReportsView from "./Reports";
import { YieldEstimation } from "./YieldEstimation";
import ImageryInsights from "./Imagery";

export default function InsightsView() {
  return (
    <MainLayout>
      <Switch>
        <Route path="/:org/:season/insights/yield/estimation">
          <YieldEstimation />
        </Route>
        <Route path="/:org/:season/insights/imagery/:type?">
          <ImageryInsights />
        </Route>
        <Route path="/:org/:season/insights/yield/:yieldType?">
          <YieldInsights />
        </Route>
        <Route path="/:org/:season/insights/reports/:reportGroup?/:reportType?">
          <ReportsView />
        </Route>
      </Switch>
    </MainLayout>
  );
}
