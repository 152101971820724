import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useFieldsDispatch,
  useFieldsState,
  useInventoryState,
  useInventoryDispatch,
  useOrgState,
} from "providers";
import { Link } from "react-router-dom";
import { downloadFile, GeoDataTable } from "components";
import IconButton from "components/IconButton";
import { IField, IDataTableCellProps } from "types";
import "./index.scss";
import useAreaColumn from "components/Table/useAreaColumn";

interface IFieldThemeData {
  id: string;
  cropTypeColor: string;
  landAgreementColor: string;
}

function getFieldsWithTheme(
  fields: IField[],
  themeData: {
    id: string;
    cropTypeColor: string;
    landAgreementColor: string;
  }[],
  themeType: "cropType" | "landAgreementType"
) {
  if (fields) {
    fields.forEach((f) => {
      const theme = themeData?.find((td) => td.id === f.id);
      const themeColor =
        themeType === "cropType"
          ? theme?.cropTypeColor
          : theme?.landAgreementColor;
      f.themeColor = themeColor;
    });
  }
  return fields;
}

export default function FieldListView({
  fields,
  isLoading,
  themeData,
  themeType,
  onToggle,
  toggled,
}: {
  fields: IField[];
  isLoading: boolean;
  themeData: IFieldThemeData[];
  themeType: "cropType" | "landAgreementType";
  onToggle?: () => void;
  toggled?: boolean;
}) {
  const { t } = useTranslation();
  const { ftrsClicked, inventoryMap } = useInventoryState();
  const { exportFieldState, fieldsGeodataState } = useFieldsState();
  const { exportField, resetExportFieldState } = useFieldsDispatch();
  const { highlightLayerFeatures } = useInventoryDispatch();
  const { rootUrl } = useOrgState();
  const [loadingCellId, setLoadingCellId] = useState<string | number>();
  const areaHeader = useAreaColumn();

  useEffect(() => {
    getFieldsWithTheme(fields, themeData, themeType);
  }, [themeType, themeData, fields]);

  useEffect(() => {
    async function getData() {
      if (exportFieldState?.data) {
        try {
          downloadFile({
            data: exportFieldState?.data,
            filetype: "text/plain",
            filename: `${
              exportFieldState?.data?.features
                ? exportFieldState?.data?.features[0].properties.name
                : "Field export"
            }.geojson`,
          });
        } catch (err) {
          console.error(err);
        }
      }
    }
    getData();
  }, [exportFieldState?.data]);

  useEffect(() => {
    return () => {
      resetExportFieldState();
    };
  }, [resetExportFieldState]);

  return (
    <div className="h-100 inventory-list">
      <div className="pb-2 d-flex align-items-center">
        <h3 className="mr-2 mb-0">{t("inventory.fields.fieldList")}</h3>
        <div className="d-flex ml-auto">
          <IconButton
            title={
              toggled ? t("common.collapseTable") : t("common.expandTable")
            }
            icon={toggled ? "chevronDown" : "chevronUp"}
            className="mr-2 d-flex"
            onClick={onToggle}
          />
          <IconButton
            wrapper={Link}
            to={`${rootUrl}/inventory/fields/add`}
            icon="add"
            className="btn-outline-success d-flex"
          />
        </div>
      </div>
      <GeoDataTable
        components={{
          HeaderLeft: (
            <p className="small m-0">
              {fields && fields.length
                ? t("inventory.fields.selectFieldToZoom")
                : ""}
            </p>
          ),
        }}
        style={{ minHeight: "100%" }}
        isLoading={isLoading}
        map={inventoryMap}
        ftrsClicked={ftrsClicked}
        featureCollection={fieldsGeodataState?.data}
        highlightByIds={highlightLayerFeatures}
        tableProps={{ size: "sm" }}
        data={fields}
        hiddenColumns={["farm"]}
        columns={[
          {
            id: "field-table-actions",
            accessor: "id",
            Cell: function ActionCell({ value }: IDataTableCellProps<IField>) {
              const errMsg =
                value === loadingCellId && exportFieldState.isError
                  ? `Download failed: ${exportFieldState.errorMessage}`
                  : null;
              const isLoading =
                value === loadingCellId && exportFieldState?.isLoading;
              return (
                <div className="d-flex mt-2 mx-3 position-relative">
                  <IconButton
                    icon="edit"
                    wrapper={Link}
                    title={t("common.edit")}
                    className="mr-1"
                    to={`${rootUrl}/inventory/fields/${value}/edit`}
                  />
                  <IconButton
                    role={errMsg ? "alert" : ""}
                    aria-label={errMsg || t("common.download")}
                    variant={errMsg ? "danger" : ""}
                    title={!errMsg ? t("common.download") : errMsg}
                    onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                      setLoadingCellId(value);
                      exportField(value);
                    }}
                    icon={isLoading ? "loader" : errMsg ? "info" : "download"}
                  />
                </div>
              );
            },
            isSortable: false,
            disableGlobalFilter: true,
          },
          {
            Header: t("common.field"),
            accessor: "name",
            Cell: function FieldCol({
              value,
              row,
            }: IDataTableCellProps<IField>) {
              const { farm } = row.original;
              return (
                <div className="float-left d-flex flex-column">
                  <span>{value}</span>
                  <small>{farm}</small>
                </div>
              );
            },
          },
          areaHeader,
          // search only column
          { accessor: "farm" },
        ]}
      />
    </div>
  );
}
