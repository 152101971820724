import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useOrgState } from "./OrgProvider";
import { useInventoryType } from "views/InventoryView/useInventoryType";
import { useCallback } from "react";
import useFetchWithToken from "components/useFetchWithToken";
import { groupFieldsByFarm } from "app-utils";

const FieldsStateContext = React.createContext();
const FieldsDispatchContext = React.createContext();

const fieldThemeConfig = [
  { dataProp: "landAgreementType", colorProp: "landAgreementColor" },
];

function FieldsProvider({ children }) {
  const { editId } = useParams();
  const { org, seasonId } = useOrgState();
  const {
    fetchData: exportField,
    state: exportFieldState,
    resetFetchState: resetExportFieldState,
  } = useFetchWithToken();

  const {
    fetchList,
    fetchGeodata,
    fetchMapThemes: fetchFieldMapThemes,
    geodataState: fieldsGeodataState,
    listState: fieldListState,
    saveItem: saveField,
    deleteItem: deleteField,
    saveItemState: saveFieldState,
    deleteItemState: deleteFieldState,
    resetSaveItem: resetSaveField,
    resetDeleteItem: resetDeleteField,
    themeState,
    themeRollup,
    reset,
  } = useInventoryType({
    org,
    seasonId,
    type: "fields",
    themeConfig: fieldThemeConfig,
  });
  const currentEditFtr = editId
    ? fieldsGeodataState?.data?.features?.find((f) => f.id === editId)
    : null;

  const fetchFields = useCallback(async () => {
    // fetch theme and list data first, to merge with geodata
    const theme = await fetchFieldMapThemes();
    const list = await fetchList();
    fetchGeodata(theme?.data, list?.data);
  }, [fetchFieldMapThemes, fetchGeodata, fetchList]);

  return (
    <FieldsStateContext.Provider
      value={{
        currentEditFtr,
        exportFieldState,
        fieldsGeodataState,
        fieldListState,
        fieldsByFarm: useMemo(() => {
          return !fieldListState.isError && fieldListState.data
            ? groupFieldsByFarm(fieldListState.data)
            : [];
        }, [fieldListState.data, fieldListState.isError]),
        saveFieldState,
        deleteFieldState,
        themeState,
        themeRollup,
      }}
    >
      <FieldsDispatchContext.Provider
        value={{
          fetchFieldsGeodata: fetchGeodata,
          fetchFields,
          saveField: async (...data) => {
            // save the field
            const savedField = await saveField(...data);
            // update the season (which contains extent) to account for new geometry
            // if (savedField && !savedField.isError) {
            //   const updatedSeason = await updateSeason(state?.season?.id);
            //   if (updatedSeason) {
            //     dispatch({
            //       type: 'SET_SEASON',
            //       payload: updatedSeason,
            //     });
            //   }
            // }
            return savedField;
          },
          deleteField,
          resetSaveField,
          resetDeleteField,
          resetExportFieldState,
          exportField: useCallback(
            (fieldId) => {
              return exportField(`/${org}/fields/geodata/${fieldId}/export`);
            },
            [exportField, org]
          ),
          getFieldsArea: (ids) => {
            const sum = fieldsGeodataState?.data?.features?.reduce(
              (area, f) => {
                if (!ids || !ids.includes(f.properties.id)) {
                  return area;
                }
                return area + f?.properties?.area;
              },
              0
            );
            return sum ? Number(sum.toFixed(2)) : 0;
          },
          getAreaUom: () => {
            const ftrs = fieldsGeodataState?.data?.features;
            if (ftrs?.length) {
              return ftrs[0]?.properties?.areaUom;
            }
            return "";
          },
          resetFields: reset,
        }}
      >
        {children}
      </FieldsDispatchContext.Provider>
    </FieldsStateContext.Provider>
  );
}
FieldsProvider.defaultProps = {
  fields: null,
  editId: null,
};

FieldsProvider.propTypes = {
  children: PropTypes.any,
  fields: PropTypes.shape({ features: PropTypes.arrayOf(PropTypes.shape({})) }),
  editId: PropTypes.string,
};

function useFieldsState() {
  const context = React.useContext(FieldsStateContext);
  if (context === undefined) {
    throw new Error("useFieldsState must be used within a FieldsProvider");
  }
  return context;
}
function useFieldsDispatch() {
  const context = React.useContext(FieldsDispatchContext);
  if (context === undefined) {
    throw new Error("useFieldsDispatch must be used within a FieldsProvider");
  }
  return context;
}

export { FieldsProvider, useFieldsDispatch, useFieldsState };
