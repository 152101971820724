import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import { ImageComponent, useFetchPhotos } from "components";
import {
  useOperationsDispatch,
  useOperationsState,
} from "providers/OperationsProvider";
import OperationEventForm from "./OperationEventForm";

export default function CreateOrEditRecord() {
  const { t } = useTranslation();
  const { org } = useOrgState();
  const { fetchEvents } = useOperationsDispatch();
  const { currentEditOperation } = useOperationsState();
  const { eventId } = useParams();
  const didCancel = useRef();
  const {
    photos,
    fetchItemPhotos,
    savePhoto,
    deletePhoto,
    // getPhotosLoading,
  } = useFetchPhotos();
  const [images, setImages] = useState(photos || []);

  const saveImages = async (imgs, newId) => {
    const id = newId ?? eventId;
    const reqs = [];
    imgs.forEach(async (img) => {
      if (id && !img.id) {
        const req = savePhoto(img.file, `/${org}/operationevents/${id}/photos`);
        reqs.push(req);
        req.then((res) => {
          img.isLoading = false;
          if (res?.data?.id) {
            img.id = res.data.id;
          } else {
            img.failed = true;
            img.errorMessage = res.errorMessage;
          }
        });
      }
    });
    await Promise.all(reqs).then(() => {
      if (!didCancel.current) {
        setImages(images.concat([...imgs]));
      }
    });
    fetchEvents(currentEditOperation?.operationId);
  };

  const deleteImage = async (img) => {
    const photoId = img.id;
    img.isLoading = true;
    setImages([...images]);
    const res = await deletePhoto(
      `/${org}/operationevents/${eventId}/photos/${photoId}`
    );
    img.isLoading = false;
    if (res.isError && !didCancel.current) {
      img.failed = true;
      img.errorMessage = res.errorMessage;
      setImages([...images]);
    }
    fetchEvents(currentEditOperation?.operationId);
    return res;
  };

  useEffect(() => {
    return () => {
      didCancel.current = true;
    };
  }, []);

  useEffect(() => {
    if (eventId) {
      fetchItemPhotos(`/${org}/operationevents/${eventId}/photos`);
    }
  }, [org, eventId, fetchItemPhotos]);

  useEffect(() => {
    if (photos?.length > 0) {
      setImages(photos);
    }
  }, [photos]);
  return (
    <>
      <OperationEventForm
        onSave={(newEventId) => {
          saveImages(images, newEventId);
        }}
      />
      <>
        <h6 className="text-uppercase">{t("common.photos")} (Max 4)</h6>
        <ImageComponent
          id="event-photo-upload"
          // disabled={!eventId}
          images={images}
          maxImages={4}
          onImageChange={(imgs) => {
            const all = images?.concat(imgs);

            if (eventId) {
              imgs.forEach((i) => {
                i.isLoading = true;
              });
              saveImages(imgs);
            }
            setImages(all);
          }}
          onImageDelete={async (img, idx) => {
            try {
              if (img.id) {
                // ensure delete is successful before removing
                const res = await deleteImage(img);
                if (!res?.isError) {
                  const newImages = [...images];
                  newImages.splice(idx, 1);
                  setImages(newImages);
                }
              } else {
                // no id, hasnt been uploaded yet
                // so we just remove it from the array
                const newImages = [...images];
                newImages.splice(idx, 1);
                setImages(newImages);
              }
            } catch (e) {
              img.isLoading = false;
              img.failed = true;
              img.errorMessage = "Failed to delete";
              setImages([...images]);
              console.error(e);
            }
          }}
          accept=".gif, .jpeg, .jpg, .png"
        />
      </>
    </>
  );
}
