import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import MapThemeItem from "components/MapThemeItem";
import TotalMassBarChart from "components/MapThemeItem/TotalMassBarChart";

const emptyData = [{ name: "--", percent: 100 }];

export default function OperationMapThemes({
  operationType,
  themeData,
  onThemeChange,
  activeType,
}) {
  const { t } = useTranslation();
  function handleThemeSelect(e) {
    onThemeChange(e);
  }
  return (
    <Container>
      <Row>
        <Col
          sm={operationType === "harvested" ? 4 : 6}
          //   style={{ maxHeight: '150px' }}
        >
          {operationType === "planted" || operationType === "applied" ? (
            <MapThemeItem
              id="product"
              title={t("common.product")}
              isActive={activeType === "product"}
              data={themeData?.product?.length ? themeData?.product : emptyData}
              showTooltip={Boolean(themeData?.product?.length)}
              onClick={handleThemeSelect}
            />
          ) : null}
          {operationType === "harvested" ? (
            <MapThemeItem
              id="crop"
              title={t("common.crop")}
              isActive={activeType === "crop"}
              data={themeData?.crop?.length ? themeData?.crop : emptyData}
              showTooltip={Boolean(themeData?.crop?.length)}
              onClick={handleThemeSelect}
            />
          ) : null}
          {operationType === "tilled" ? (
            <MapThemeItem
              id="depth"
              title={t("common.depth")}
              isActive
              showTooltip={Boolean(themeData?.depth?.length)}
              data={themeData?.depth?.length ? themeData?.depth : emptyData}
              showActiveBorder={false}
              onClick={handleThemeSelect}
            />
          ) : null}
        </Col>
        <Col
          sm={operationType === "harvested" ? 8 : 6}
          //   style={{ maxHeight: '150px' }}
        >
          {operationType === "planted" ? (
            <MapThemeItem
              id="crop"
              title={t("common.crop")}
              isActive={activeType === "crop"}
              data={themeData?.crop?.length ? themeData?.crop : emptyData}
              showTooltip={Boolean(themeData?.crop?.length)}
              onClick={handleThemeSelect}
            />
          ) : null}
          {operationType === "applied" ? (
            <MapThemeItem
              id="productType"
              title={t("common.type")}
              isActive={activeType === "productType"}
              data={
                themeData?.productType?.length
                  ? themeData?.productType
                  : emptyData
              }
              showTooltip={Boolean(themeData?.productType?.length)}
              onClick={handleThemeSelect}
            />
          ) : null}
          {operationType === "harvested" ? (
            <TotalMassBarChart
              id="totalMass"
              title={t("common.totalMass")}
              isActive={false}
              data={themeData?.crop || emptyData}
            />
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}
OperationMapThemes.defaultProps = {
  operationType: "",
  themeData: null,
  activeType: null,
};

OperationMapThemes.propTypes = {
  onThemeChange: PropTypes.func.isRequired,
  activeType: PropTypes.oneOf(["crop", "product", "productType", "depth"]),
  operationType: PropTypes.string,
  themeData: PropTypes.shape({
    crop: PropTypes.arrayOf(PropTypes.shape({})),
    product: PropTypes.arrayOf(PropTypes.shape({})),
    productType: PropTypes.arrayOf(PropTypes.shape({})),
    depth: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};
