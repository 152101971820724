import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Select } from "components";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import {
  useOperationsDispatch,
  useOperationsState,
} from "providers/OperationsProvider";
import {
  DateRangeInputs,
  FormField,
  FormWrapper,
  usePrevious,
  filterListById,
  sortObjArr,
} from "components";
import { groupByKeys } from "components/Charts/utils";
import { useInventoryState } from "providers/InventoryProvider";
import { SelectedIdsProps } from "../PropTypes";
import FieldAndSubfieldInputs from "../FieldAndSubfieldInputs";
import { filterOption } from "app-utils";

const defaultValues = {
  id: "",
  fieldIds: "",
  subfieldIds: "",
  productId: "",
  cropTypeId: "",
  beginDateUtc: "",
  endDateUtc: "",
  rate: "",
  geometry: "",
};

function getDefaultData(data, val, prop = "id") {
  return data?.find((d) => d[prop] === val);
}

function groupProducts(prods, cropTypes) {
  const prodsByCrop = groupByKeys(prods, ["cropTypeId"]);
  const groups = Object.keys(prodsByCrop);
  const prodsGrouped = groups.map((i) => {
    const cropObj = cropTypes?.find((ct) => ct.id === i);
    return {
      label: cropObj?.name,
      options: prodsByCrop[i],
    };
  });
  sortObjArr(prodsGrouped, "label");
  return prodsGrouped;
}

export default function PlantedOperationForm({ selectedIds, getCancelHref }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { cropTypes, products, season } = useOrgState();
  const { drawData } = useInventoryState();
  const {
    currentEditFtr,
    savePlantedState,
    deletePlantedState,
  } = useOperationsState();
  const prevProducts = usePrevious(products);
  const {
    savePlantedOperation,
    deletePlantedOperation,
    fetchOperationsSummary,
  } = useOperationsDispatch();
  const methods = useForm({
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    setValue,
    control,
    errors,
  } = methods;
  const { id, cropTypeId, productId, beginDateUtc, endDateUtc } = watch();
  const initProds = filterListById(products, cropTypeId, "cropTypeId") || [];
  const [productOps, setProductOpts] = useState(
    groupProducts(initProds, cropTypes)
  );
  const [selectedProduct, setSelectedProduct] = useState(
    getDefaultData(products, productId)
  );
  const prods = productOps?.map((p) => [...p.options])?.flat();
  const currRateUom = prods?.find((ao) => ao.id === productId)?.rateUom;

  async function handleSave(d) {
    if (!formState.isDirty) {
      history.push(getCancelHref());
      return false;
    }
    const body = { ...d };
    body.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    body.rate = Number(body.rate);
    // need at least 1 fieldId or subfieldId
    // but cannot make either input required
    // therefore custom logic to handle validation here
    if (!body.fieldIds?.length && !body.subfieldIds?.length) {
      return false;
    }
    // server doesn't want these props if not set
    if (!body.id) {
      delete body.id;
    }
    if (!body.fieldIds?.length) {
      delete body.fieldIds;
    } else {
      body.fieldIds = JSON.parse(body.fieldIds);
    }
    if (!body.subfieldIds?.length) {
      delete body.subfieldIds;
    } else {
      body.subfieldIds = JSON.parse(body.subfieldIds);
    }
    if (!body.cropTypeId) {
      body.cropTypeId = selectedProduct?.cropTypeId;
    }
    const res = await savePlantedOperation(body);
    if (!res.isError) {
      reset(d);
      history.push(getCancelHref());
      fetchOperationsSummary();
    }
    return res;
  }

  // set init selected product
  useEffect(() => {
    if (products && productId) {
      if (!selectedProduct) {
        const currProd = products?.find(
          (p) => p.id === productId && p.cropTypeId === cropTypeId
        );
        if (cropTypeId === currProd?.cropTypeId) {
          setSelectedProduct(currProd);
        }
      }
    }
  }, [selectedProduct, products, productId, cropTypeId]);

  // set selected product if edit ftr changes
  useEffect(() => {
    if (currentEditFtr?.id !== id) {
      setSelectedProduct(getDefaultData(products, productId));
    }
  }, [currentEditFtr, id, productId, products]);

  // update product list when prop changes
  useEffect(() => {
    if (products !== prevProducts) {
      const opts = products?.filter((p) => p.isSeed) || [];
      const prods = filterListById(opts, cropTypeId, "cropTypeId");
      const prodsGrouped = groupProducts(prods, cropTypes);
      setProductOpts(prodsGrouped);
    }
  }, [products, cropTypeId, prevProducts, cropTypes]);

  // update product list when crop type changes
  useEffect(() => {
    const productOpts = products?.filter((p) => p.isSeed) || [];
    const prods = filterListById(productOpts, cropTypeId, "cropTypeId");
    const prodsGrouped = groupProducts(prods, cropTypes);
    setProductOpts(prodsGrouped);
  }, [products, cropTypeId, setValue, cropTypes]);

  return (
    <FormWrapper
      methods={methods}
      defaultValues={defaultValues}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={getCancelHref()}
      saveState={savePlantedState}
      deleteState={deletePlantedState}
      // NOTE: ignoring geometry, using fieldIds
      ignoreGeom
      onDelete={async () => {
        const res = await deletePlantedOperation(id);
        if (!res.isError) {
          reset(defaultValues);
          fetchOperationsSummary();
          history.push(getCancelHref(true));
        }
      }}
      onSubmit={handleSubmit(handleSave)}
    >
      <Form.Group>
        <DateRangeInputs
          control={control}
          Controller={Controller}
          startProp="beginDateUtc"
          endProp="endDateUtc"
          startDate={beginDateUtc ? new Date(beginDateUtc) : null}
          endDate={endDateUtc ? new Date(endDateUtc) : null}
          minDate={season?.beginOnUtc ? new Date(season.beginOnUtc) : null}
          maxDate={season?.endOnUtc ? new Date(season.endOnUtc) : null}
        />
        <FormField
          label={t("common.crop")}
          name="cropTypeId"
          control={control}
          htmlFor="crop-type-select"
          render={(props) => (
            <Select
              id="crop-type-select"
              options={cropTypes || []}
              value={cropTypes?.find((ct) => ct.value === props.value)}
              onChange={(item) => {
                setValue("productId", "");
                props.onChange(item?.value || "");
                if (
                  item &&
                  selectedProduct &&
                  selectedProduct.cropTypeId !== item.value
                ) {
                  setSelectedProduct(null);
                }
              }}
              isClearable
            />
          )}
        />
        <FormField
          label={`${t("common.product")} *`}
          name="productId"
          control={control}
          rules={{ required: true }}
          htmlFor="product-select"
          render={(props) => (
            <Select
              id="product-select"
              options={productOps || []}
              value={selectedProduct}
              filterOption={({ data }, input) => {
                const { cropTypeId: ctid, label } = data;
                const cropObj = cropTypes?.find((ct) => ct.id === ctid);
                const cropType = cropObj?.name;
                return filterOption({ cropType, label }, input);
              }}
              onChange={(item) => {
                setSelectedProduct(item);
                setValue("cropTypeId", item?.cropTypeId);
                props.onChange(item?.id || "");
              }}
              isClearable
            />
          )}
        >
          {errors?.productId ? (
            <Form.Text className="text-danger">
              {t("common.requiredField")}
            </Form.Text>
          ) : null}
        </FormField>
        <FormField label={`${t("common.rate")} *`} htmlFor="rate-input">
          <div className="d-flex">
            <Form.Control
              name="rate"
              ref={register}
              required
              id="rate-input"
              step={0.01}
              type="number"
              min={0}
            />
            <div className="ml-1 d-flex align-items-center justify-content-center">
              {currRateUom}
            </div>
          </div>
        </FormField>
        <FieldAndSubfieldInputs
          show={!currentEditFtr}
          control={control}
          formState={formState}
          selectedIds={selectedIds}
          setValue={setValue}
        />
      </Form.Group>
    </FormWrapper>
  );
}

PlantedOperationForm.propTypes = {
  ...SelectedIdsProps,
};
