import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import MapThemeItem from "components/MapThemeItem";
import { useAssetsState } from "providers/AssetsProvider";
import HerdSummaryBarChart from "./HerdSummaryBarChart";

export default function AssetMapThemes({ assetType }) {
  const { t } = useTranslation();
  const { assetsMapThemesQuery } = useAssetsState();

  const title =
    assetType === "animal"
      ? t("common.breed")
      : assetType === "plant"
      ? t("common.crop")
      : assetType === "fixed"
      ? t("common.type")
      : "";

  return (
    <Container>
      <Row>
        <Col sm={4}>
          {assetsMapThemesQuery?.data?.raw &&
          assetsMapThemesQuery?.data?.raw.length ? (
            <MapThemeItem
              id={assetType}
              title={title}
              // title={themeData.title}
              isActive
              data={assetsMapThemesQuery?.data?.raw}
              showActiveBorder={false}
            />
          ) : (
            <MapThemeItem
              id="skeleton"
              title="--"
              showTooltip={false}
              data={[{ name: "--", percent: 1 }]}
            />
          )}
        </Col>
        <Col sm={8}>
          {assetType === "animal" ? (
            <>
              <h6 className="text-center m-0 ellipsis-overflow">
                {t("inventory.assets.herdSummary")}
              </h6>
              <HerdSummaryBarChart data={assetsMapThemesQuery?.data?.raw} />
            </>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}
AssetMapThemes.defaultProps = {
  assetType: "",
  themeData: null,
};

AssetMapThemes.propTypes = {
  assetType: PropTypes.string,
  themeData: PropTypes.shape({
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};
