import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { useOperationsState } from "providers/OperationsProvider";
import {
  useInventoryState,
  useInventoryDispatch,
} from "providers/InventoryProvider";
import { GeoDataTable, IconButton } from "components";
import { useTableColumns } from "components/useTableColumns";

export default function OperationsListView({
  activeType,
  onRowExpand,
  expanded,
  toggleExpanded,
}) {
  const { rootUrl } = useOrgState();
  const { t } = useTranslation();
  const { operations: operationsCols } = useTableColumns();
  const { inventoryMap, ftrsClicked } = useInventoryState();
  const { currentOperations, currentOpsGeodata } = useOperationsState();
  const { highlightLayerFeatures } = useInventoryDispatch();

  function getOpTypeText() {
    switch (activeType) {
      case "planted": {
        return t("inventory.operations.plantedOperations");
      }
      case "applied": {
        return t("inventory.operations.appliedOperations");
      }
      case "harvested": {
        return t("inventory.operations.harvestedOperations");
      }
      case "tilled": {
        return t("inventory.operations.tilledOperations");
      }
      default: {
        return t("inventory.operations.title");
      }
    }
  }

  function getCols() {
    const editCol = {
      Header: "",
      id: "actions",
      isSortable: false,
      accessor: function Cell(row) {
        return (
          <Link
            to={`${rootUrl}/inventory/operations/${activeType}/items/${row.id}`}
          >
            <IconButton icon="info" />
          </Link>
        );
      },
    };
    if (activeType?.toLowerCase() === "planted") {
      return [editCol].concat(operationsCols.planted);
    }
    if (activeType?.toLowerCase() === "applied") {
      return [editCol].concat(operationsCols.applied);
    }
    if (activeType?.toLowerCase() === "harvested") {
      return [editCol].concat(operationsCols.harvested);
    }
    if (activeType?.toLowerCase() === "tilled") {
      return [editCol].concat(operationsCols.tilled);
    }
    return null;
  }

  return (
    <Container fluid className="d-flex flex-column overflow-auto">
      <Row>
        <Col>
          <div className="px-3 pt-3 pb-2 d-flex align-items-center">
            <h3 className="mb-0">{getOpTypeText()}</h3>
            <div className="d-flex ml-auto">
              <IconButton
                title={
                  expanded ? t("common.collapseTable") : t("common.expandTable")
                }
                icon={expanded ? "chevronDown" : "chevronUp"}
                className="mr-2 d-flex"
                onClick={toggleExpanded}
              />
              <IconButton
                icon="add"
                variant="outline-success"
                className="d-flex"
                as={Link}
                to={`${rootUrl}/inventory/operations/${activeType}/add`}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="flex-grow-1 overflow-auto inventory-list h-100">
        <Col className="h-100">
          <div className="px-3 h-100">
            <GeoDataTable
              paging={currentOperations?.length > 100}
              map={inventoryMap}
              ftrsClicked={ftrsClicked}
              highlightByIds={highlightLayerFeatures}
              tableProps={{ size: "sm" }}
              hiddenColumns={["farm"]}
              data={currentOperations}
              featureCollection={currentOpsGeodata}
              columns={getCols()}
              onRowExpand={(row) => {
                const { fieldId } = row.leafRows[0].original;
                onRowExpand(fieldId);
              }}
              groupBy={["fieldFarmGroupKey"]}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
OperationsListView.defaultProps = {
  activeType: "",
};
OperationsListView.propTypes = {
  activeType: PropTypes.string,
  onRowExpand: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
  toggleExpanded: PropTypes.func,
  // onEditClick: PropTypes.func.isRequired,
};
