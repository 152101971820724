import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Breadcrumb } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch } from "providers/InventoryProvider";
import { useDamageDispatch } from "providers/DamageProvider";
import DamageForm from "./DamageForm";

export default function CreateOrEditDamage({
  damageTypesState,
  onFieldSelect,
  setWholeFieldDamage,
}) {
  const { t } = useTranslation();
  const { rootUrl } = useOrgState();
  const { editId } = useParams();
  const { resetSave, resetDelete } = useDamageDispatch();
  const { enableDraw, disableDraw, setDrawOptions } = useInventoryDispatch();

  // when leaving form, reset the fetch state
  useEffect(() => {
    return () => {
      resetSave();
      resetDelete();
    };
  }, [resetSave, resetDelete]);

  useEffect(() => {
    enableDraw();
    return disableDraw;
  }, [enableDraw, disableDraw]);

  useEffect(() => {
    setDrawOptions({
      controls: {
        trash: true,
        polygon: true,
        rectangle: true,
      },
      defaultMode: "draw_polygon",
    });
  }, [setDrawOptions]);

  return (
    <div className="overflow-auto h-100">
      <Breadcrumb listProps={{ className: "p-0" }}>
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: `${rootUrl}/inventory/damage` }}
        >
          {t("inventory.damage.damageList")}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {!editId ? t("common.add") : t("common.edit")}{" "}
          {t("inventory.damage.title")}
        </Breadcrumb.Item>
      </Breadcrumb>
      <DamageForm
        damageTypesState={damageTypesState}
        onFieldSelect={onFieldSelect}
        setWholeFieldDamage={setWholeFieldDamage}
      />
    </div>
  );
}

CreateOrEditDamage.defaultProps = {
  damageTypesState: null,
};

CreateOrEditDamage.propTypes = {
  damageTypesState: PropTypes.shape({
    isLoading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onFieldSelect: PropTypes.func.isRequired,
  setWholeFieldDamage: PropTypes.func.isRequired,
};
