import { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "lib/Select/Autocomplete";
import { useFieldsState } from "providers";
import { IField } from "types";

export function FieldsByFarm({
  value,
  defaultValue,
  onChange,
  required = true,
}: {
  value?: string;
  defaultValue?: string;
  onChange?: (_e: SyntheticEvent, item: IField) => void;
  required?: boolean;
}) {
  const { t } = useTranslation();
  const { fieldListState } = useFieldsState() as {
    fieldListState: { data: IField[] };
  };
  const [fieldId, setFieldId] = useState<string>(defaultValue);

  return (
    <Autocomplete
      label={`${t("common.field")} *`}
      disableClearable={false}
      id="field-select"
      options={
        fieldListState.data?.sort((a, b) =>
          a.farm > b.farm ? 1 : b.farm > a.farm ? -1 : 0
        ) || []
      }
      InputProps={{
        required,
      }}
      onChange={(_e, item) => {
        const fld = item as IField;
        onChange(_e, fld);
        setFieldId(fld?.id);
      }}
      value={
        fieldListState.data?.find((d: IField) =>
          [value ?? fieldId].includes(d.id)
        ) ?? null
      }
    />
  );
}
