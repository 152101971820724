import React, { useState, useEffect } from "react";
import { Route, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useOrgLookup } from "components/useOrgLookup";
import { useOrgDispatch, useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch } from "providers/InventoryProvider";
import { useDamageState } from "providers/DamageProvider";
import CreateOrEditDamage from "./CreateOrEditDamage";
import DamageListView from "./DamageListView";
import MapThemesView from "./MapThemes";
import DamageSummaryView from "./SummaryView";
import CardStack from "../../CardStack";
import { usePrevious, useSetAppDataLoading } from "components";

export function DamageView() {
  const { t } = useTranslation();
  const { season, org } = useOrgState();
  const { dispatch } = useOrgDispatch();
  const seasonId = season?.id;
  const {
    setInventoryFtrs,
    setLegendData,
    setColSizes,
    setTitle,
    setLabelProp,
    zoomToField,
  } = useInventoryDispatch();
  // TODO: use wholeFieldDamage to toggle of draw tools
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [wholeFieldDamage, setWholeFieldDamage] = useState();
  const [expanded, setExpanded] = useState();
  const { editId } = useParams();
  const {
    damageList,
    damageGeodata,
    damageThemeRollup,
    damageThemeData,
    isFetchingData,
  } = useDamageState();
  const prevDamageGeodata = usePrevious(damageGeodata);
  const { state: damageTypesState } = useOrgLookup({
    org,
    url: `${org}/lookups/damagetypes`,
  });
  useSetAppDataLoading({ dispatch, isFetchingData });

  useEffect(() => {
    setTitle(t("inventory.damage.title"));
    setLabelProp("damageType");
    setColSizes([5, 7]);
  }, [setColSizes, setTitle, setLabelProp, t]);

  // set legend data
  useEffect(() => {
    setLegendData({
      title: t("common.type"),
      colorProp: "damageTypeColor",
      dataProp: "damageType",
    });
    return () => {
      setLegendData(null);
    };
  }, [setLegendData, t]);

  useEffect(() => {
    if (damageGeodata !== prevDamageGeodata) {
      setInventoryFtrs(damageGeodata);
    }
  }, [damageGeodata, prevDamageGeodata, setInventoryFtrs]);

  return (
    <Route path="/:org/:season/inventory/damage">
      <CardStack
        topHeight={"310px"}
        expanded={Boolean(editId || expanded)}
        topChildren={
          <>
            <Card className="mb-3">
              <DamageSummaryView />
            </Card>
            <Card className="flex-grow-1">
              <MapThemesView
                org={org}
                seasonId={seasonId}
                themeType="damage-type"
                onThemeTypeChange={() => {
                  // TODO: when adding new theme types
                }}
                themeData={damageThemeRollup?.damageType}
              />
            </Card>
          </>
        }
      >
        <Card
          className="p-3 flex-grow-1 bottom h-100"
          style={{ maxHeight: "100%" }}
        >
          <Route path="/:org/:season/inventory/damage" exact>
            <DamageListView
              toggleExpanded={() => {
                setExpanded(!expanded);
              }}
              expanded={expanded}
              isLoading={isFetchingData}
              damages={damageList}
              themeProp="damageTypeColor"
              themeData={damageThemeData}
              geodata={damageGeodata}
            />
          </Route>
          <Route
            path={[
              `/:org/:season/inventory/damage/:editId/edit`,
              `/:org/:season/inventory/damage/add`,
            ]}
            exact
          >
            <CreateOrEditDamage
              damageTypesState={damageTypesState}
              onFieldSelect={(fieldId) => {
                zoomToField(fieldId);
              }}
              // set flag indicating whether
              // they are applying damage to the entire field
              // rather than digitizing the damage area
              //
              setWholeFieldDamage={setWholeFieldDamage}
            />
          </Route>
        </Card>
      </CardStack>
    </Route>
  );
}

DamageView.defaultProps = {};

DamageView.propTypes = {};
