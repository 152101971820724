import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Form } from "react-bootstrap";
import {
  ResponsiveContainer,
  BarChart as RechartsBarChart,
  Bar,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  ReferenceArea,
  Cell,
  Label,
  LabelList,
  // Brush,
} from "recharts";
import { ArrowLeftCircle, Calendar } from "react-feather";
import { getDateString, useZoomChart } from "../utils";
import { OperationTooltip } from "../Tooltips/OperationTooltip";
import usePrevious from "../../usePrevious";
import DatePicker from "../../DatePicker";
import "./index.scss";

const valueAccessor = (attributes) => ({ payload }) => {
  let final = "";
  attributes.forEach((attribute) => {
    final += `${payload[attribute]} `;
  });
  return final;
  // return payload[attribute];
};

function getNext(data, startDate, numDays) {
  if (!data.length) {
    return [];
  }
  let firstDateObj = startDate ? new Date(startDate) : "";
  firstDateObj = firstDateObj || new Date(data ? data[0]?.beginDateUtc : "");
  // only interested in date, so strip time to be consistent for all dates
  firstDateObj = new Date(firstDateObj.toJSON());
  let lastDate = firstDateObj ? new Date(startDate) : "";
  if (lastDate) {
    lastDate.setDate(firstDateObj.getDate() + numDays);
  }
  if (!numDays && numDays !== 0) {
    lastDate = new Date(data[data.length - 1].beginDateUtc);
  }
  lastDate = new Date(lastDate.toJSON());

  return data.filter((d) => {
    let date = new Date(d.beginDateUtc);
    date = new Date(date.toJSON());
    if (numDays) {
      return date >= firstDateObj && date < lastDate;
    }
    return date >= firstDateObj && date <= lastDate;
  });
}
function getThemeProp(operationType, selectedOperation) {
  if (
    operationType === "harvested" ||
    (operationType === "planted" && !selectedOperation)
  ) {
    return "cropColor";
  }
  if (operationType === "planted" && selectedOperation) {
    return "productColor";
  }
  if (operationType === "applied") {
    return "productColor";
  }
  if (operationType === "tilled") {
    return "depthColor";
  }
  return "color";
}

function getLegendData(data, operationType, selectedOperation) {
  const legendData = [];
  let colorProp;
  let dataProp = "type";
  switch (operationType) {
    case "planted": {
      colorProp = selectedOperation ? "productColor" : "cropColor";
      dataProp = selectedOperation ? "product" : "type";
      break;
    }
    case "applied": {
      colorProp = "productColor";
      dataProp = "product";
      break;
    }
    case "harvested": {
      colorProp = "cropColor";
      break;
    }
    case "tilled": {
      colorProp = "depthColor";
      dataProp = "depth";
      break;
    }
    default:
      break;
  }
  if (data) {
    // if only a single data item (selected operation),
    // parse legend data from the children prop
    const dataForLegend = selectedOperation ? data[0].children : data;
    dataForLegend.forEach((d) => {
      const exists = legendData.find((ld) => ld.id === d[dataProp]);
      if (!exists) {
        if (!d[dataProp]) {
          return;
        }
        legendData.push({
          id: d[dataProp],
          color: d[colorProp],
        });
      }
    });
  }
  return legendData;
}

export default function OperationsTimeSeries({
  // MUST PRE-SORT BY DATE
  data,
  timeDataKey,
  showTooltip,
  color,
  height,
  width,
  onClick,
  // onReset,
  yDataKey,
  yDataLabel,
  yDataUnit,
  operationType,
  ...rest
}) {
  const prevData = usePrevious(data);
  const [startDate, setStartDate] = useState(
    data && data.length ? new Date(data[0]?.beginDateUtc) : null
  );
  const [daysToShow, setDaysToShow] = useState();
  const [activeData, setActiveData] = useState(data);
  const [selectedOperation, setSelectedOperation] = useState();
  const prevChartData = useRef([]);
  const [hasPreviousData, setHasPreviousData] = useState();
  const [dataIndexes, setDataIndexes] = useState();
  const [tooltipDataKey, setTooltipDataKey] = useState();
  const [tooltipDataIdx, setTooltipDataIdx] = useState();
  const updateData = useCallback(
    ({ numDays, start, newData, noCache, resetHistory } = {}) => {
      if (resetHistory) {
        setHasPreviousData(false);
        prevChartData.current = [];
      }
      if (newData) {
        if (activeData?.length && !noCache) {
          prevChartData.current.push(activeData);
          setHasPreviousData(true);
        }
        setActiveData(newData);

        return;
      }
      const next = getNext(data, start || startDate?.toJSON(), numDays);
      if (next.length) {
        if (activeData?.length && !noCache) {
          prevChartData.current.push(activeData);
          setHasPreviousData(true);
        }
      }
      setActiveData(next);
    },
    [data, startDate, activeData]
  );

  function getInitialStartDate() {
    const start = data && data.length ? new Date(data[0]?.beginDateUtc) : null;
    return start;
  }
  // data reset
  useEffect(() => {
    if (data && data !== prevData) {
      setSelectedOperation(null);
      const start =
        data && data.length ? new Date(data[0]?.beginDateUtc) : null;
      setStartDate(start);
      updateData({
        numDays: daysToShow,
        start,
        resetHistory: true,
        noCache: true,
      });
    }
  }, [data, prevData, updateData, daysToShow]);

  const { state } = useZoomChart({
    data: activeData,
    xDataKey: (d) => {
      return getDateString(d, timeDataKey);
    },
  });
  return (
    <div
      className="operations-chart-container"
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        userSelect: "none",
      }}
    >
      <div className="date-filters">
        <div>
          <Button
            title="Back to previous data view"
            size="sm"
            variant="link"
            disabled={!hasPreviousData}
            onClick={() => {
              if (prevChartData.current.length) {
                const dataTo =
                  prevChartData.current[prevChartData.current.length - 1];
                if (dataTo) {
                  setActiveData(dataTo);
                }
                setSelectedOperation(null);
                prevChartData.current.pop();
                if (!prevChartData.current.length) {
                  setHasPreviousData(false);
                }
              }
            }}
          >
            <ArrowLeftCircle />
          </Button>
        </div>
        <div className="operation-chart-legend">
          {getLegendData(activeData, operationType, selectedOperation).map(
            (ld) => (
              <div className="legend-item" key={ld.id}>
                <span
                  style={{
                    borderRadius: "5rem",
                    height: "25px",
                    width: "25px",
                    backgroundColor: ld.color,
                  }}
                />
                <span>{ld.id}</span>
              </div>
            )
          )}
        </div>
        <div
          className="btn-group btn-group-toggle ml-auto align-items-center"
          data-toggle="buttons"
        >
          <div className="d-flex">
            <div style={{ width: "150px" }}>
              <DatePicker
                // minDate={getInitialStartDate()}
                isClearable
                selected={startDate}
                onChange={(date) => {
                  if (!date) {
                    date = getInitialStartDate();
                  }
                  if (date.getTime() === startDate?.getTime()) {
                    return;
                  }
                  setStartDate(date);
                  updateData({
                    numDays: daysToShow,
                    start: date,
                    noCache: true,
                  });
                }}
              />
            </div>
          </div>
          <Form.Label
            className={`btn btn-link btn-sm${
              daysToShow === 30 ? " active" : ""
            }`}
          >
            <input
              type="radio"
              name="data-filters"
              checked={daysToShow === 30}
              onClick={() => {
                if (daysToShow === 30) {
                  setDaysToShow(null);
                  updateData({ noCache: true });
                }
              }}
              onChange={() => {
                setDaysToShow(30);
                updateData({ numDays: 30, noCache: true });
              }}
            />
            <Calendar size={32} />
            <span
              style={{
                position: "absolute",
                bottom: "6px",
                fontSize: "0.75rem",
                left: "calc(50% - 7px)",
              }}
            >
              30
            </span>
          </Form.Label>
          <Form.Label
            className={`btn btn-link btn-sm${
              daysToShow === 7 ? " active" : ""
            }`}
          >
            <input
              type="radio"
              checked={daysToShow === 7}
              name="data-filters"
              onClick={() => {
                if (daysToShow === 7) {
                  setDaysToShow(null);
                  updateData({ noCache: true });
                }
              }}
              onChange={() => {
                setDaysToShow(7);
                updateData({ numDays: 7, noCache: true });
              }}
            />
            <Calendar size={32} />
            <span
              style={{
                position: "absolute",
                bottom: "6px",
                fontSize: "0.75rem",
                left: "calc(50% - 3px)",
              }}
            >
              7
            </span>
          </Form.Label>
          <Form.Label
            className={`btn btn-link btn-sm${
              daysToShow === 1 ? " active" : ""
            }`}
          >
            <input
              type="radio"
              checked={daysToShow === 1}
              name="data-filters"
              onClick={() => {
                if (daysToShow === 1) {
                  setDaysToShow(null);
                  updateData({ noCache: true });
                }
              }}
              onChange={() => {
                setDaysToShow(1);
                updateData({ numDays: 1, noCache: true });
              }}
            />
            <Calendar size={32} />
            <span
              style={{
                position: "absolute",
                bottom: "6px",
                fontSize: "0.75rem",
                left: "calc(50% - 3px)",
              }}
            >
              1
            </span>
          </Form.Label>
        </div>
      </div>

      <ResponsiveContainer>
        <RechartsBarChart
          height={height}
          width={width}
          data={state.data}
          margin={{
            top: 5,
            // right: 20,
            left: 25,
            bottom: 5,
          }}
          onMouseDown={(e) => {
            if (!e || selectedOperation) {
              return;
            }
            const oid = e?.activePayload[0]?.payload?.operationId;
            const item = activeData.find((d) => d.operationId === oid);
            const idx = activeData.indexOf(item);
            setDataIndexes({ startIndex: idx });
          }}
          onMouseMove={(e) => {
            if (!e || selectedOperation) {
              return;
            }
            if (dataIndexes?.startIndex) {
              const oid = e?.activePayload[0]?.payload?.operationId;
              const item = activeData.find((d) => d.operationId === oid);
              const idx = activeData.indexOf(item);
              setDataIndexes({
                startIndex: dataIndexes.startIndex,
                endIndex: idx,
              });
            }
          }}
          onMouseUp={(e) => {
            if (!e || selectedOperation) {
              return;
            }
            const oid = e?.activePayload[0]?.payload?.operationId;
            const item = activeData.find((d) => d.operationId === oid);
            const idx = activeData.indexOf(item);
            let startIdx = dataIndexes?.startIndex;
            let lastIdx = idx + 1;
            if (startIdx > lastIdx) {
              startIdx = lastIdx;
              lastIdx = dataIndexes?.startIndex;
            }
            setDataIndexes({ startIndex: 0 });
            const newData = activeData.slice(startIdx, lastIdx);
            updateData({ newData });
            if (startIdx === idx) {
              setSelectedOperation(item);
            }
          }}
          onClick={(e) => {
            if (!e) {
              return;
            }
            onClick(e);
            if (dataIndexes?.startIndex === dataIndexes?.endIndex) {
              setDaysToShow(1);
            }
          }}
          {...rest}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            // strokeOpacity={0.5}
            // vertical={false}
          />

          <XAxis
            tick={{ fill: "#fff" }}
            dataKey={(d) => {
              if (selectedOperation) {
                const fieldLabel = ` (${selectedOperation.field})`;
                return getDateString(d, timeDataKey) + fieldLabel;
              }
              return getDateString(d, timeDataKey);
            }}
            // tickFormatter={(val) => {
            //   return new Date(val).toLocaleDateString();
            // }}
            domain={[state.left, state.right]}
            allowDataOverflow
            // type="number"
          />

          <YAxis
            yAxisId="left"
            orientation="left"
            stroke="#fff"
            allowDataOverflow
            // domain={[state.bottom, state.top]}
          >
            <Label
              value={yDataLabel}
              // offset={5}
              angle={-90}
              position="left"
              fill="#fff"
              fontSize={14}
            />
          </YAxis>
          {showTooltip && !dataIndexes?.startIndex ? (
            <Tooltip
              content={
                <OperationTooltip
                  dataIdx={tooltipDataIdx}
                  dataKey={tooltipDataKey}
                  unit={yDataUnit}
                />
              }
              allowEscapeViewBox={{ x: false, y: true }}
              wrapperStyle={{ zIndex: 3 }}
            />
          ) : null}
          {/* 
          {state.data && state.data.length ? (
            <Brush
              // startIndex={dataIndexes?.startIndex}
              // endIndex={dataIndexes?.endIndex}
              onChange={(e) => {
                setDataIndexes(e);
                onBrushChange(e);
              }}
              dataKey={(d) => {
                return getDateString(d, timeDataKey);
              }}
              tickFormatter={(val) => {
                return new Date(val).toDateString();
              }}
              height={30}
              stroke={color}
            />
          ) : null} */}

          {selectedOperation ? (
            activeData[0].children.map((d, i) => {
              return (
                <Bar
                  minPointSize={15}
                  onMouseOver={() => {
                    setTooltipDataIdx(i);
                    setTooltipDataKey(`${yDataKey}-${i}`);
                  }}
                  yAxisId="left"
                  key={d.id}
                  dataKey={`${yDataKey}-${i}`}
                  stackId="stackId"
                  fill={d[getThemeProp(operationType, selectedOperation)]}
                >
                  <LabelList
                    valueAccessor={valueAccessor([
                      `${yDataKey}-${i}`,
                      `${yDataKey}Uom`,
                    ])}
                  />
                </Bar>
              );
            })
          ) : (
            <Bar
              onMouseOver={() => {
                setTooltipDataIdx(null);
                setTooltipDataKey(yDataKey);
              }}
              yAxisId="left"
              dataKey={yDataKey}
              stackId="stackId"
              fill={color}
            >
              {activeData.map((d) => (
                <Cell
                  key={d.operationId}
                  fill={d[getThemeProp(operationType, selectedOperation)]}
                />
              ))}
            </Bar>
          )}
          {dataIndexes?.startIndex && dataIndexes?.endIndex ? (
            <ReferenceArea
              yAxisId="left"
              x1={dataIndexes?.startIndex}
              x2={dataIndexes?.endIndex}
              strokeOpacity={0.3}
            />
          ) : null}
        </RechartsBarChart>
      </ResponsiveContainer>
    </div>
  );
}
OperationsTimeSeries.defaultProps = {
  data: null,
  showTooltip: true,
  color: null,
  height: 400,
  width: 800,
  onClick: () => {},
  // onBrushChange: () => {},
  operationType: null,
  yDataLabel: "",
  yDataUnit: "",
};
OperationsTimeSeries.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      beginDateUtc: PropTypes.string,
    })
  ),
  showTooltip: PropTypes.bool,
  timeDataKey: PropTypes.string.isRequired,
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
  // onBrushChange: PropTypes.func,
  yDataKey: PropTypes.string.isRequired,
  yDataLabel: PropTypes.string,
  yDataUnit: PropTypes.string,
  operationType: PropTypes.oneOf(["planted", "harvested", "applied", "tilled"]),
};
