import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import ActiveShapePieChart from "components/Charts/PieChart/ActiveShapePieChart";
import { useSubfieldsState } from "providers/SubfieldsProvider";
import { IUrlParams } from "types";
import { useInventoryMapThemes } from "api/useInventoryMapThemes";

export default function SubfieldsMapThemesView() {
  const { t } = useTranslation();
  const { org, rootUrl, season } = useOrgState();
  const { subfieldType } = useParams<IUrlParams>();
  const {
    // themeRollup,
    subfieldTypes,
    // themeState: { data, isLoading },
  } = useSubfieldsState();
  const { isLoading, data } = useInventoryMapThemes(
    org,
    "subfields",
    season?.id
  );
  const { raw, rollup } = data ?? {};
  // const themeData = themeRollup?.subfieldType;
  const subfieldTypeLocal =
    subfieldTypes?.find((st) => st.nameEn.toLowerCase() === subfieldType)
      ?.name || "";
  // get active item from subfieldType param in url
  const item = rollup?.find(
    (td: { name: string }) =>
      td.name.toLowerCase() === subfieldTypeLocal.toLowerCase()
  );
  // get the index of the item to tell the chart which slice is active
  const idx = item ? rollup?.indexOf(item) : null;
  const history = useHistory();
  return (
    <>
      <h3
        className="text-center m-0 ellipsis-overflow"
        style={{ textTransform: "none" }}
      >
        {t("common.type")}
      </h3>
      {raw || isLoading ? (
        <p className="mb-0 small text-center w-100">
          {t("inventory.subfields.selectType")}
        </p>
      ) : null}
      <div
        className="mb-3"
        style={{
          height: "200px",
          display: "flex",
          flexDirection: "column",
          // width: 100%
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {raw && !raw.length && !isLoading ? (
          <p className="small text-center w-100">{t("common.noData")}</p>
        ) : (
          <>
            <ActiveShapePieChart
              dataKey="percent"
              activeIndex={idx}
              data={rollup}
              onClick={(d: { name: string }) => {
                const sub =
                  subfieldTypes?.find(
                    (st) => st.name.toLowerCase() === d.name.toLowerCase()
                  )?.nameEn || "";
                if (sub) {
                  history.push(
                    `${rootUrl}/inventory/subfields/${encodeURIComponent(
                      sub.toLowerCase()
                    )}`
                  );
                } else {
                  console.warn(`Could not find subfield type: ${d.name}`);
                }
              }}
            />
          </>
        )}
      </div>
    </>
  );
}
