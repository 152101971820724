import React from "react";
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import { DateRangeInputs, FormField, FormWrapper, Select } from "components";
import { useOrgLookup } from "components/useOrgLookup";
import { useInventoryState } from "providers/InventoryProvider";
import {
  useOperationsDispatch,
  useOperationsState,
} from "providers/OperationsProvider";
import { useTranslation } from "react-i18next";
import FieldAndSubfieldInputs from "../FieldAndSubfieldInputs";
import { SelectedIdsProps } from "../PropTypes";

const defaultValues = {
  id: "",
  fieldIds: "",
  subfieldIds: "",
  tilledTypeId: "",
  beginDateUtc: "",
  endDateUtc: "",
  depth: "",
  geometry: "",
};

export default function TillOperation({ selectedIds, getCancelHref }) {
  const { t } = useTranslation();
  const { org, season, configQuery } = useOrgState();
  const { uoms } = configQuery.data || {};
  const history = useHistory();
  const {
    currentEditFtr,
    saveTillState,
    deleteTillState,
  } = useOperationsState();

  const { drawData } = useInventoryState();
  const { state: tilledTypesState } = useOrgLookup({
    org,
    url: `${org}/lookups/tilledtypes`,
  });
  const {
    saveTillOperation,
    deleteTillOperation,
    fetchOperationsSummary,
  } = useOperationsDispatch();
  const methods = useForm({
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    setValue,
    control,
    errors,
  } = methods;
  const { id, beginDateUtc, endDateUtc } = watch();

  async function handleSave(d) {
    if (!formState.isDirty) {
      history.push(getCancelHref());
      return false;
    }
    const body = { ...d };
    body.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    body.depth = Number(body.depth);
    // need at least 1 fieldId or subfieldId
    // but cannot make either input required
    // therefore custom logic to handle validation here
    if (!body.fieldIds?.length && !body.subfieldIds?.length) {
      return false;
    }
    // server doesn't want these props if not set
    if (!body.id) {
      delete body.id;
    }
    if (!body.fieldIds?.length) {
      delete body.fieldIds;
    } else {
      body.fieldIds = JSON.parse(body.fieldIds);
    }
    if (!body.subfieldIds?.length) {
      delete body.subfieldIds;
    } else {
      body.subfieldIds = JSON.parse(body.subfieldIds);
    }
    const res = await saveTillOperation(body);
    if (!res.isError) {
      reset(d);
      history.push(getCancelHref());
      fetchOperationsSummary();
    }
    return res;
  }

  return (
    <FormWrapper
      methods={methods}
      defaultValues={defaultValues}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={getCancelHref()}
      saveState={saveTillState}
      deleteState={deleteTillState}
      // NOTE: ignoring geometry, using fieldIds
      ignoreGeom
      onDelete={async () => {
        const res = await deleteTillOperation(id);
        if (!res.isError) {
          reset(defaultValues);
          fetchOperationsSummary();
          history.push(getCancelHref(true));
        }
      }}
      onSubmit={handleSubmit(handleSave)}
    >
      <Form.Group>
        <DateRangeInputs
          control={control}
          Controller={Controller}
          startProp="beginDateUtc"
          endProp="endDateUtc"
          startDate={beginDateUtc ? new Date(beginDateUtc) : null}
          endDate={endDateUtc ? new Date(endDateUtc) : null}
          minDate={season?.beginOnUtc ? new Date(season.beginOnUtc) : null}
          maxDate={season?.endOnUtc ? new Date(season.endOnUtc) : null}
        />
        <FormField label={`${t("common.depth")} *`} htmlFor="depth-input">
          <div className="d-flex align-items-center">
            <Form.Control
              name="depth"
              ref={register}
              required
              id="depth-input"
              type="number"
              min={0}
              max={100}
              step={0.001}
            />
            <span className="ml-1">{uoms?.smallDistance?.label}</span>
          </div>
        </FormField>
        <FormField
          label={`${t("common.type")} *`}
          name="tilledTypeId"
          control={control}
          rules={{ required: true }}
          htmlFor="tilled-type-select"
          render={(props) => (
            <Select
              id="tilled-type-select"
              options={tilledTypesState?.data || []}
              value={tilledTypesState?.data?.find(
                (ct) => ct.value === props.value
              )}
              onChange={(item) => {
                props.onChange(item?.value || "");
              }}
              isClearable
            />
          )}
        >
          {errors?.tilledTypeId ? (
            <Form.Text className="text-danger">
              {t("common.requiredField")}
            </Form.Text>
          ) : null}
        </FormField>
        <FieldAndSubfieldInputs
          show={!currentEditFtr}
          control={control}
          formState={formState}
          selectedIds={selectedIds}
          setValue={setValue}
        />
      </Form.Group>
    </FormWrapper>
  );
}

TillOperation.propTypes = {
  ...SelectedIdsProps,
};
