import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import useFetchWithToken from "components/useFetchWithToken";
import { useInventoryType } from "views/InventoryView/useInventoryType";
import { useOrgLookup } from "components/useOrgLookup";
import { useInventorySummaries } from "views/InventoryView/useInventorySummaries";
import { useFetchPhotos } from "../components";
import {
  useAssetsByFieldAndGroup,
  useAssetsBySubtypeSummary,
  useAssetsByTypeSummary,
  useAssetsSeasonSummary,
} from "api/useAssetSummaries";
import useOrgLookupRQ from "components/useOrgLookupRQ";
import { useInventoryMapThemes } from "api/useInventoryMapThemes";
import { IAssetRecord } from "types/IAssetRecord";
import { ParsedImage } from "components/Images/ImageComponent";
import { Feature, FeatureCollection } from "geojson";
import { IFetchState, ISelectOption } from "types";
import { IAnimalBreed } from "types/IAssetType";

const AssetsStateContext = React.createContext<
  Record<string, unknown> & {
    assetRecordPhotos?: ParsedImage[];
    currentEditFtr?: Feature;
    fixedAssetTypes?: unknown[];
    deleteInventoryItemState?: IFetchState<void>;
    saveInventoryItemState?: IFetchState<unknown>;
    currentAssetRecords?: IAssetRecord[];
    fetchingRecordPhotos?: boolean;
    isFetchingData?: boolean;
    subtypeId?: string;
    animalBreeds?: IAnimalBreed[];
    animalTypes?: ISelectOption[];
    assetGroups?: ISelectOption[];
  }
>({});

type Context = {
  [index: string]: (_first?: unknown, _second?: unknown) => unknown;
  fetchGeodataByFieldAndQueryParams: (
    _d: unknown
  ) => Promise<IFetchState<FeatureCollection>>;
  fetchGeodataByItemId: (
    _d: unknown
  ) => Promise<IFetchState<FeatureCollection>>;
  fetchListBySeason: (
    _d: unknown
  ) => Promise<IFetchState<Record<string, unknown>[]>>;
  createGroup: (_d: {
    name: string;
  }) => Promise<IFetchState<{ nameEn: string; name: string; id: string }>>;
  saveInventoryItem?: (_d: {
    name: string;
  }) => Promise<IFetchState<FeatureCollection>>;
  deleteInventoryItem?: (_id: string) => Promise<IFetchState<void>>;
};

const AssetsDispatchContext = React.createContext<Context>({
  createGroup: null,
  fetchGeodataByFieldAndQueryParams: null,
  fetchGeodataByItemId: null,
  fetchListBySeason: null,
});

function AssetsProvider({
  children,
  org,
  seasonId,
  type,
  editId,
  groupId,
  subtype,
  fieldId,
}: {
  children: React.ReactNode;
  org: string;
  seasonId: string;
  type: string;
  editId: string;
  groupId: string;
  subtype: string;
  fieldId: string;
}) {
  const { items: assetGroups, reset: resetGroups } = useOrgLookup({
    org,
    url: `${org}/lookups/assetgroups`,
  });
  const { data: animalTypes } = useOrgLookupRQ(`/${org}/lookups/animaltypes`);
  const { data: cropTypes } = useOrgLookupRQ(`/${org}/lookups/croptypes`);
  const { data: animalBreeds } = useOrgLookupRQ<IAnimalBreed>(
    `/${org}/lookups/animalbreeds`
  );
  const { data: assetEventTypes } = useOrgLookupRQ(
    `/${org}/lookups/asseteventtypes`
  );
  const { data: assetTypes } = useOrgLookupRQ(`/${org}/lookups/assettypes`);
  const { data: fixedAssetTypes } = useOrgLookupRQ(
    `/${org}/lookups/assetfixedtypes`
  );

  const { fetchData: createGroup } = useFetchWithToken<{
    name: string;
    nameEn: string;
    id: string;
  }>();
  const {
    state: fetchAssetRecordsDataState,
    fetchData: fetchRecordsById,
  } = useFetchWithToken<IAssetRecord[]>();

  const [currentAssetRecords, setCurrentAssetRecords] = useState<
    IAssetRecord[]
  >();
  const assetsBySeasonQuery = useAssetsSeasonSummary(org, seasonId);
  const assetsByTypeQuery = useAssetsByTypeSummary(org, type);

  const groupName = groupId
    ? assetGroups?.find((d) => d.id === groupId)?.name
    : null;

  const subtypeObj = assetsByTypeQuery.data?.find(
    (at: { subtype: string }) => at.subtype === decodeURIComponent(subtype)
  );
  const fieldAndGroupQuery = useAssetsByFieldAndGroup(
    org,
    type,
    subtypeObj?.subtypeId,
    fieldId,
    groupId
  );
  const assetsBySubtypeQuery = useAssetsBySubtypeSummary(
    org,
    type,
    subtypeObj?.subtypeId,
    seasonId
  );
  const fieldObj =
    fieldId && assetsBySubtypeQuery?.data
      ? assetsBySubtypeQuery?.data?.find(
          (f: { fieldId: string }) =>
            (fieldId === "null" && !f.fieldId) || f.fieldId === fieldId
        )
      : null;
  const assetsMapThemesQuery = useInventoryMapThemes(
    org,
    type ? `assets/${type}` : "",
    seasonId
  );
  const dataProp = type === "animal" ? "breed" : "subtype";
  const {
    fetchGeodataByFieldAndQueryParams,
    fetchGeodataByItemId,
    geodataState,
    resetGeodata,
    deleteInventoryItem,
    resetDeleteItemState,
    deleteInventoryItemState,
    saveInventoryItem,
    resetSaveItemState,
    saveInventoryItemState,
  } = useInventorySummaries({
    org,
    inventoryType: "assets",
    subtype: type,
    colorProp: type === "animal" ? "breedColor" : "color",
    dataProp,
    seasonId,
  });
  const {
    photos: assetPhotos,
    fetchItemPhotos: fetchAssetItemPhotos,
    reset: resetAssetPhotos,
    savePhoto: saveAssetItemPhoto,
    deletePhoto: deleteAssetItemPhoto,
  } = useFetchPhotos();

  const {
    photos: assetRecordPhotos,
    savePhotoState: saveRecordPhotoState,
    fetchItemPhotos: fetchAssetRecordPhotos,
    reset: resetRecordPhotos,
    savePhoto: saveAssetRecordPhoto,
    deletePhoto: deleteAssetRecordPhoto,
    deletePhotoState: deleteRecordPhotoState,
    getPhotosLoading: fetchingRecordPhotos,
    resetSave: resetSaveRecordPhoto,
    resetDelete: resetDeleteRecordPhoto,
  } = useFetchPhotos();

  const {
    fetchList: fetchPlantList,
    listState: plantListState,
  } = useInventoryType({
    org,
    seasonId,
    type: "assets",
    subType: "plant",
    themeConfig: [
      {
        dataProp: "crop",
        colorProp: "color",
      },
    ],
  });

  const {
    fetchList: fetchFixedList,
    listState: fixedListState,
  } = useInventoryType({
    org,
    seasonId,
    type: "assets",
    subType: "fixed",
    themeConfig: [
      {
        dataProp: "fixedAssetType",
        colorProp: "color",
      },
    ],
  });

  const {
    fetchList: fetchAnimalList,
    listState: animalListState,
  } = useInventoryType({
    org,
    seasonId,
    type: "assets",
    subType: "animal",
    themeConfig: [
      {
        dataProp: "assetAnimalBreed",
        colorProp: "assetAnimalBreedColor",
      },
    ],
  });

  let currentAssetTypes;
  let fetchListData;
  let listState;
  switch (type) {
    case "plant": {
      currentAssetTypes = cropTypes;
      fetchListData = fetchPlantList;
      listState = plantListState;
      break;
    }
    case "fixed": {
      currentAssetTypes = fixedAssetTypes;
      fetchListData = fetchFixedList;
      listState = fixedListState;
      break;
    }
    case "animal": {
      currentAssetTypes = animalTypes;
      fetchListData = fetchAnimalList;
      listState = animalListState;
      break;
    }
    default: {
      fetchListData = () => {};
      currentAssetTypes = null;
      listState = null;
    }
  }

  const currentEditFtr = geodataState?.data?.features?.find(
    (f: Feature) => f.id === editId
  );
  const fetchRecordsByAssetId = useCallback(
    async (id) => {
      return fetchRecordsById(`/${org}/assets/${id}/events`);
    },
    [org, fetchRecordsById]
  );
  const fetchPhotosByAssetId = useCallback(
    (id) => {
      return fetchAssetItemPhotos(`/${org}/assets/${id}/photos`);
    },
    [fetchAssetItemPhotos, org]
  );

  const fetchRecordPhotosById = useCallback(
    async (id) => {
      return fetchAssetRecordPhotos(`/${org}/assetevents/${id}/photos`);
    },
    [org, fetchAssetRecordPhotos]
  );

  const {
    state: saveRecordState,
    fetchData: saveRecordData,
    resetFetchState: resetSaveRecord,
  } = useFetchWithToken();
  const {
    state: deleteRecordState,
    fetchData: callDeleteRecord,
    resetFetchState: resetDeleteRecord,
  } = useFetchWithToken();

  async function deleteRecord(id: string) {
    const res = await callDeleteRecord(
      `/${org}/assets/${editId}/events/${id}`,
      {
        method: "DELETE",
      }
    );
    if (!res.isError) {
      fetchRecordsByAssetId(editId);
    }
    return res;
  }
  async function saveRecord(newData: IAssetRecord) {
    const method = newData.id ? "PUT" : "POST";
    const res = await saveRecordData(`/${org}/assets/${editId}/events`, {
      method,
      body: JSON.stringify(newData),
    });
    if (res && !res.isError) {
      fetchRecordsByAssetId(editId);
    }
    return res;
  }

  async function deleteRecordPhoto(recordId: string, photoId: string) {
    return deleteAssetRecordPhoto(
      `/${org}/assetevents/${recordId}/photos/${photoId}`
    );
  }
  async function saveRecordPhoto(image: File, recordId: string) {
    const res = saveAssetRecordPhoto(
      image,
      `/${org}/assetevents/${recordId}/photos`
    );
    return res;
  }

  async function saveAssetPhoto(image: File, assetId: string) {
    return saveAssetItemPhoto(image, `/${org}/assets/${assetId}/photos`);
  }

  async function deleteAssetPhoto(assetId: string, photoId: string) {
    const res = await deleteAssetItemPhoto(
      `/${org}/assets/${assetId}/photos/${photoId}`
    );

    return res;
  }

  useEffect(() => {
    if (fetchAssetRecordsDataState?.data && assetEventTypes) {
      const records = fetchAssetRecordsDataState?.data as IAssetRecord[];
      records.forEach((record) => {
        record.eventType = assetEventTypes.find(
          (t) => t.id === record.eventTypeId
        )?.name;
      });
      // sort by date
      records.sort(
        (a, b) => new Date(a.dateUtc).getTime() - new Date(b.dateUtc).getTime()
      );
      setCurrentAssetRecords(records);
    }
  }, [assetEventTypes, fetchAssetRecordsDataState.data]);

  return (
    <AssetsStateContext.Provider
      value={{
        assetsMapThemesQuery,
        geodataState,
        deleteInventoryItemState,
        saveInventoryItemState,
        subtypeId: subtypeObj?.subtypeId,
        subtypeName: subtypeObj?.subtype,
        fieldId,
        fieldName: fieldObj?.field,
        groupId,
        groupName,
        currentAssetTypes,
        currentEditFtr,
        currentAssetRecords,
        listBySeasonState: listState,
        fixedAssetTypes,
        assetsBySeasonQuery,
        assetGroups,
        animalTypes,
        animalBreeds,
        assetEventTypes,
        assetTypes,
        assetPhotos,
        assetRecordPhotos,
        saveRecordState,
        deleteRecordState,
        fetchingRecordPhotos,
        saveRecordPhotoState,
        deleteRecordPhotoState,
        fetchAssetRecordsDataState,
        isFetchingData:
          assetsBySeasonQuery.isLoading ||
          assetsBySubtypeQuery.isLoading ||
          assetsByTypeQuery.isLoading ||
          fieldAndGroupQuery.isLoading ||
          assetsMapThemesQuery.isLoading,
      }}
    >
      <AssetsDispatchContext.Provider
        value={{
          fetchGeodataByFieldAndQueryParams,
          fetchGeodataByItemId,
          deleteInventoryItem,
          saveInventoryItem,
          fetchListBySeason: fetchListData,
          saveRecord,
          deleteRecord,
          saveRecordPhoto,
          deleteRecordPhoto,
          fetchRecordsByAssetId,
          fetchRecordPhotosById,
          createGroup: async (body) => {
            const res = await createGroup(`/${org}/assets/groups`, {
              method: "POST",
              body: JSON.stringify(body),
            });
            if (res && res.data && !res.isError) {
              const d = res.data;
              const newData = [{ ...d, value: d.id, label: d.name }];
              if (assetGroups) {
                newData.unshift(...assetGroups);
                newData.sort((a, b) => {
                  return a.name > b.name ? 1 : -1;
                });
              }
              resetGroups(newData);
            }
            return res;
          },
          resetSave: resetSaveItemState,
          resetDelete: resetDeleteItemState,
          resetSaveRecord,
          resetDeleteRecord,
          resetSaveRecordPhoto,
          resetDeleteRecordPhoto,
          resetRecordPhotos,
          resetAssetPhotos,
          resetAssetRecords: useCallback(() => {
            setCurrentAssetRecords(null);
          }, []),
          fetchPhotosByAssetId,
          resetAssetsGeodata: useCallback(() => {
            resetGeodata();
          }, [resetGeodata]),
          saveAssetPhoto,
          deleteAssetPhoto,
        }}
      >
        {children}
      </AssetsDispatchContext.Provider>
    </AssetsStateContext.Provider>
  );
}
AssetsProvider.defaultProps = {
  seasonId: null,
  type: null,
  editId: null,
  subtype: null,
  fieldId: null,
  groupId: null,
};

AssetsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  org: PropTypes.string.isRequired,
  seasonId: PropTypes.string,
  type: PropTypes.oneOf(["plant", "animal", "equipment", "fixed"]),
  editId: PropTypes.string,
  subtype: PropTypes.string,
  fieldId: PropTypes.string,
  groupId: PropTypes.string,
};

function useAssetsState() {
  const context = React.useContext(AssetsStateContext);
  if (context === undefined) {
    throw new Error("useAssetsState must be used within an AssetsProvider");
  }
  return context;
}

function useAssetsDispatch() {
  const context = React.useContext(AssetsDispatchContext);
  if (context === undefined) {
    throw new Error("useAssetsDispatch must be used within an AssetsProvider");
  }
  return context;
}

export { AssetsProvider, useAssetsState, useAssetsDispatch };
