import React from "react";
import PropTypes from "prop-types";

import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
  Label,
  CartesianGrid,
} from "recharts";
import {
  //   CenteredBarLabel,
  TopBarLabel,
} from "components/Charts/utils";
import { useTranslation } from "react-i18next";
// import { TotalMassTooltip } from './TotalMassTooltip';

export default function AssetsOverviewChart({
  data,
  // onClick,
  // id,
  showTooltip,
}) {
  // NOTE: manually setting domain to largest value of totalMass
  // due to issue with recharts
  //   const totalMassVals = data?.map((entry) => Number(entry.totalMass));
  //   const maxVal = Math.max(...totalMassVals);
  const { t } = useTranslation();
  return (
    <ResponsiveContainer height={200}>
      <BarChart
        //   height={200}
        //   width={500}
        layout="vertical"
        // NOTE:  isAnimationActive - THIS PROP NEEDED IN ORDER FOR LABELS TO SHOW
        isAnimationActive={false}
        data={data}
        // style={{ cursor: 'pointer' }}
      >
        <CartesianGrid
          // strokeDasharray="3 3"
          strokeOpacity={0.75}
          horizontal={false}
        />
        <XAxis
          xAxisId="bottom"
          dataKey="count"
          stroke="#fff"
          tickLine={{ stroke: "#fff" }}
          tick={{ fill: "#fff" }}
          type="number"
          //   padding={{ right: 40 }}
        >
          <Label
            dy={20}
            value={t("common.count")}
            position="outside"
            fill="#fff"
            fontSize={14}
          />
        </XAxis>
        <YAxis
          stroke="#fff"
          tick={{ fill: "#fff" }}
          type="category"
          dataKey="asset"
        />
        <Bar
          isAnimationActive={false}
          //   barSize={100}
          xAxisId="bottom"
          label={TopBarLabel}
          //   label={() => {
          //     console.log('hey');
          //     return 'hey';
          //   }}
          //   name="count"
          dataKey="count"
          fill="#000"
        >
          {data?.map((entry) => (
            <Cell key={entry.asset} fill={entry.color} />
          ))}
        </Bar>
        {showTooltip ? (
          <Tooltip
            cursor={{ fill: "transparent" }}
            // content={<TotalMassTooltip />}
            allowEscapeViewBox={{ x: true, y: true }}
            wrapperStyle={{ zIndex: 3 }}
          />
        ) : null}
      </BarChart>
    </ResponsiveContainer>
  );
}
AssetsOverviewChart.defaultProps = {
  data: null,
  // onClick: () => {},
  showTooltip: false,
};
AssetsOverviewChart.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  // onClick: PropTypes.func,
  showTooltip: PropTypes.bool,
};
