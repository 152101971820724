import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, Route, useParams } from "react-router-dom";
import { Container, Card, Row } from "react-bootstrap";
import { useOrgState } from "providers/OrgProvider";
import {
  useOperationsState,
  useOperationsDispatch,
} from "providers/OperationsProvider";
import usePrevious from "components/usePrevious";
import PlantedIcon from "icons/PlantedIcon";
import HarvestedIcon from "icons/HarvestedIcon";
import AppliedIcon from "icons/AppliedIcon";
import TilledIcon from "icons/TilledIcon";
import { PageColumn, PageLayout } from "views/MainLayout";
import { useTranslation } from "react-i18next";
import { useTableColumns } from "components/useTableColumns";
import ReportView from "../ReportView";
import {
  getDefaultExpandedRows,
  getDataByFields,
  parseFieldsFromData,
} from "../utils";
import ReportFieldsSelect from "../ReportFieldsSelect";
import { LinearProgress } from "lib";

export default function OperationsReports({ setOperationType }) {
  const { t } = useTranslation();
  const { operationReports } = useTableColumns();
  const { rootUrl, season, orgData } = useOrgState();
  const { reportType } = useParams();
  const prevReportType = usePrevious(reportType);
  const {
    currentOperations,
    operationsSummary,
    operationsSummaryState,
  } = useOperationsState();
  const { fetchOperations, fetchOperationsSummary } = useOperationsDispatch();
  const prevOperations = usePrevious(currentOperations);
  const [expanded, setExpanded] = useState();
  const [fieldsByOperationType, setFieldsByOperationType] = useState();
  const [selectedOperations, setSelectedOperations] = useState(
    currentOperations
  );

  const currentOperationType = operationsSummary?.find(
    (o) => o.operationEn?.toLowerCase() === reportType
  );
  function getColumnsByReportType(type) {
    const rType = type?.toLowerCase();
    if (rType === "planted") {
      return operationReports.planted;
    }
    if (rType === "applied") {
      return operationReports.applied;
    }
    if (rType === "harvested") {
      return operationReports.harvested;
    }
    if (rType === "tilled") {
      return operationReports.tilled;
    }
    return null;
  }

  useEffect(() => {
    fetchOperationsSummary();
  }, [fetchOperationsSummary]);

  useEffect(() => {
    fetchOperations();
  }, [fetchOperations]);

  useEffect(() => {
    if (reportType !== prevReportType) {
      setOperationType(reportType);
    }
  }, [reportType, prevReportType, setOperationType]);

  useEffect(() => {
    if (currentOperations !== prevOperations) {
      const newFields = parseFieldsFromData(currentOperations);
      // UPDATE EXPANDED OBJECT WITH NEW DATA
      // TO FORCE ALL ROWS EXPANDED BY DEFAULT
      // setExpanded(getDefaultExpandedRows(currentOperations));

      setFieldsByOperationType(newFields);
      // setSelectedOperations(currentOperations);
    }
  }, [
    currentOperations,
    prevOperations,
    // fieldsByOperationType,
    // landAgreementItem,
  ]);

  useEffect(() => {
    setExpanded(getDefaultExpandedRows(selectedOperations));
  }, [selectedOperations]);

  function getCurrentIcon(operationType) {
    let Icon;
    switch (operationType) {
      case "planted": {
        Icon = PlantedIcon;
        break;
      }
      case "harvested": {
        Icon = HarvestedIcon;
        break;
      }
      case "applied": {
        Icon = AppliedIcon;
        break;
      }
      case "tilled": {
        Icon = TilledIcon;
        break;
      }
      default:
        break;
    }
    return Icon;
  }

  return (
    <PageLayout className="cai-reports h-100">
      <LinearProgress
        isLoading={operationsSummaryState.isLoading || false}
        absolute
      />
      <PageColumn lg={6} xl={5} className="hide-print d-flex flex-column">
        <Card className="overflow-auto">
          <Container className="report-type-container">
            <h3>{t("insights.selectReportType")}</h3>
            <Row
              className="select-reports-container justify-content-md-center"
              style={{ minHeight: "75px" }}
            >
              {operationsSummary?.map((i) => {
                const operation = i.operationEn?.toLowerCase();
                const Icon = getCurrentIcon(operation);

                return (
                  <div
                    key={operation}
                    className={`report-link${
                      reportType === operation ? " active" : ""
                    }`}
                  >
                    <Link
                      to={`${rootUrl}/insights/reports/operations/${operation}`}
                    >
                      {Icon ? (
                        <Icon
                          size={40}
                          className="report-icon"
                          style={{
                            borderRadius: "50%",
                            backgroundColor: i.color,
                            margin: "0 auto",
                          }}
                        />
                      ) : null}
                      <div className="mt-2 small">{i.operation}</div>
                    </Link>
                  </div>
                );
              })}
            </Row>
          </Container>
        </Card>
        <Card className="mt-3" style={{ flex: 1, minHeight: "250px" }}>
          <ReportFieldsSelect
            fields={fieldsByOperationType}
            onChange={(data) => {
              const selectedFields = fieldsByOperationType?.filter((co) => {
                return data.find((d) => d.fieldId === co.fieldId);
              });
              const ops = getDataByFields(selectedFields, currentOperations);
              setSelectedOperations(ops);
            }}
          />
        </Card>
      </PageColumn>
      <PageColumn lg={6} xl={7} className="print-full-width operation-report">
        <Route
          path="/:org/:season/insights/reports/operations/:reportType?"
          exact
        >
          <ReportView
            org={orgData?.name}
            season={season}
            title={
              currentOperationType
                ? `${currentOperationType.operation} ${t("insights.report")}`
                : ""
            }
            titleIcon={getCurrentIcon(
              currentOperationType?.operation?.toLowerCase()
            )}
            color={currentOperationType?.color}
            data={selectedOperations}
            groupBy={["fieldFarmTypeKey"]}
            columns={getColumnsByReportType(reportType)}
            expanded={expanded}
          />
        </Route>
      </PageColumn>
    </PageLayout>
  );
}

OperationsReports.defaultProps = {};

OperationsReports.propTypes = {
  setOperationType: PropTypes.func.isRequired,
};
