import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { useFieldsState } from "providers/FieldsProvider";
import { ICollectEvent, IField, ISubfield, ISensor, IRasterType } from "types";
import PrescriptionMapView from "./PrescriptionMapView";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SectionHeader from "../SectionHeader";
import { useParams } from "react-router-dom";
import { useSensorsByGeometry } from "api/useSensors";
import { Feature, MultiPolygon, Polygon } from "geojson";
import Autocomplete from "lib/Select/Autocomplete";
import { Calendar, Camera, Monitor, PlusCircle, Trash2 } from "react-feather";
import { useCollectEventsByGeometry } from "api/useCollectEvents";
import { useAppState } from "providers/AppProvider";
import { LngLatBounds, Map } from "mapbox-gl";
import {
  RasterLegendItem,
  useRasterLegends,
} from "views/InventoryView/MapView/RasterLayersControl/useRasterLegends";
import { downloadFile } from "components";
import sanitize from "sanitize-filename";
import { Zone } from "../types";
import LoadingButton from "components/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import SubfieldsSelect from "../SubfieldsSelect";
import { useOrgState } from "providers";
import union from "@turf/union";
import { useImagerySubfileds } from "api/imagery/useImagerySubfields";
import { useImageryFields } from "api/imagery/useImageryFields";
import { useCreateRX } from "./useCreateRX";
import { fitBounds } from "lib/MapboxMap";
import { useRasterInfoBySensor } from "api/useRasterInfoBySensor";

const defaultZones = [
  { id: 1, min: -1, max: 0.45, rate: 0, color: "#FF0000" },
  { id: 2, min: 0.45, max: 0.88, rate: 0, color: "#D5E100" },
  { id: 3, min: 0.88, max: 1, rate: 0, color: "#408000" },
] as Zone[];

function parseZones(data: RasterLegendItem[]) {
  const zs = data?.map((i, idx) => {
    const start = Number(i.label.split(" - ")[0]);
    const end = Number(i.label.split(" - ")[1]);
    return {
      id: idx + 1,
      name: idx + 1,
      min: start,
      max: end,
      rate: 1,
      color: i.color,
    };
  });
  return zs;
}

export default function Prescription() {
  const { org } = useParams<{ org: string }>();
  const { tenant } = useAppState();
  const { season } = useOrgState();
  const { t } = useTranslation();
  const mapRef = useRef<Map>();
  const { fieldsGeodataState } = useFieldsState();
  // prevent react-query until form submit, reset after any state change
  const [displayEnabled, setDisplayEnabled] = useState(false);
  const [filename, setFilename] = useState<string>();
  const [selectedField, setSelectedField] = useState<IField>();
  const [selectedSensor, setSelectedSensor] = useState<ISensor>();
  const [selectedSubfields, setSelectedSubfields] = useState<ISubfield[]>([]);
  const [selectedRasterType, setSelectedRasterType] = useState<IRasterType>();
  const [selectedCollectEvent, setSelectedCollectEvent] = useState<
    ICollectEvent
  >();
  const selectedFieldFeature = useMemo(() => {
    const x = fieldsGeodataState.data?.features?.find(
      (d: Feature) => d.id === selectedField?.id
    );
    return x as Feature & {
      geometry: (Polygon | MultiPolygon) & { bbox: LngLatBounds };
    };
  }, [fieldsGeodataState.data?.features, selectedField]);

  const fieldsListQ = useImageryFields({ org, seasonId: season?.id });
  const subfieldsGeoQ = useImagerySubfileds({
    org,
    seasonId: season?.id,
    fieldId: selectedField?.id,
  });
  const defaultFilename = useMemo(() => {
    return `rx-${selectedField?.name}-${selectedSubfields
      ?.map((s) => s.cid)
      .join("-")}.zip`;
  }, [selectedField, selectedSubfields]);

  const { subfieldGeom, subfieldFtrs } = useMemo(() => {
    let geom: Polygon | MultiPolygon;
    const ftrs: Feature[] = [];
    try {
      if (selectedSubfields?.length && subfieldsGeoQ.data) {
        selectedSubfields?.map((s) => {
          const f = subfieldsGeoQ.data?.features?.find((sf) => sf.id === s.id);
          if (!f) {
            return {};
          }
          ftrs.push(f);
          if (geom) {
            geom = union(geom, f.geometry as Polygon).geometry;
          } else {
            geom = f.geometry as Polygon;
          }
        });
      }
    } catch (e) {
      console.error("Failed getting subfield geometries");
    }
    return { subfieldGeom: geom, subfieldFtrs: ftrs };
  }, [selectedSubfields, subfieldsGeoQ.data]);

  const sensorsQ = useSensorsByGeometry(
    org,
    subfieldFtrs.length > 0 ? subfieldGeom : selectedFieldFeature?.geometry,
    undefined,
    ["ndvi", "gndvi", "ndre"]
  );
  const demEvents = useCollectEventsByGeometry(
    org,
    subfieldFtrs.length > 0 ? subfieldGeom : selectedFieldFeature?.geometry,
    selectedSensor?.id || ""
  );
  const [zones, setZones] = useState<Zone[]>();

  const rasterLegendQ = useRasterLegends({
    tenant,
    org,
    types: selectedRasterType ? [selectedRasterType.slug] : undefined,
  });

  const rasterQ = useRasterInfoBySensor(org, selectedSensor?.id);

  const { displayQuery, downloadMutation } = useCreateRX({
    collectEventId: selectedCollectEvent?.id,
    rasterType: selectedRasterType?.slug,
    geometry:
      subfieldGeom ?? selectedFieldFeature?.geometry
        ? JSON.stringify(subfieldGeom ?? selectedFieldFeature.geometry)
        : null,
    classes: zones,
    organization: org,
    tenant,
    sensorType: selectedSensor?.slug,
    format: "png",
    enabled: displayEnabled,
  });
  const handleDownload = useCallback(() => {
    try {
      downloadMutation.mutate(
        {
          collectEventId: selectedCollectEvent.id,
          rasterType: selectedRasterType?.slug,
          geometry: JSON.stringify(
            subfieldGeom ?? selectedFieldFeature.geometry
          ),
          classes: zones,
          organization: org,
          tenant,
          sensorType: selectedSensor?.slug,
          format: "tif",
        },
        {
          onSuccess: (res) => {
            downloadFile({
              blob: res,
              filename: filename ? `${filename}.zip` : defaultFilename,
            });
            setFilename(undefined);
          },
        }
      );
    } catch (e) {
      console.error("Failed downloading file.", e);
    }
  }, [
    defaultFilename,
    downloadMutation,
    filename,
    org,
    selectedCollectEvent,
    selectedFieldFeature,
    selectedRasterType,
    selectedSensor,
    subfieldGeom,
    tenant,
    zones,
  ]);

  useEffect(() => {
    if (rasterLegendQ.data) {
      const d = rasterLegendQ.data?.[0];
      if (d?.data) {
        const zs = parseZones(d.data);
        setZones(zs);
      } else {
        setZones(defaultZones.map((z) => ({ ...z })));
      }
    }
  }, [rasterLegendQ.data]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!zones?.length) {
        return;
      }
      setDisplayEnabled(true);
    },
    [zones]
  );

  return (
    <Box
      onSubmit={handleSubmit}
      component={"form"}
      sx={{
        height: "100%",
        maxHeight: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2>{t("imagery.rx.title")}</h2>
      <Grid container flex={1} spacing={4} height={"100%"} overflow={"hidden"}>
        <Grid sm={12} md={5} height="100%" overflow={"hidden"}>
          <Paper
            sx={{
              p: 3,
              height: "100%",
            }}
          >
            <Stack spacing={2} height="100%">
              <SubfieldsSelect
                subfieldsRequired={false}
                sx={{ mt: 2 }}
                onFieldChange={(i) => {
                  setSelectedField(i);
                  setSelectedSensor(undefined);
                  setSelectedRasterType(undefined);
                  setZones(null);
                  setSelectedCollectEvent(undefined);
                  // setRasterSource(null);
                  setDisplayEnabled(false);
                }}
                fields={fieldsListQ.data?.data || []}
                subfields={subfieldsGeoQ.data?.features.map((f) => {
                  return f.properties as ISubfield;
                })}
                onSubfieldsChange={(items) => {
                  setDisplayEnabled(false);
                  setSelectedSubfields(items);
                }}
              />
              <Autocomplete
                loading={sensorsQ.isFetching}
                InputProps={{
                  required: true,
                  startAdornment: (
                    <Box sx={{ ml: 2, mr: 1 }}>
                      <Camera /> *
                    </Box>
                  ),
                }}
                value={selectedSensor ?? null}
                disableClearable={false}
                options={sensorsQ.data || []}
                getOptionLabel={(row: ISensor) => {
                  return `${row.name}`;
                }}
                onChange={(_e, item: ISensor) => {
                  setSelectedSensor(item);
                  // setRasterSource(null);
                  setDisplayEnabled(false);
                  setSelectedCollectEvent(undefined);
                  if (!item) {
                    setSelectedRasterType(null);
                    setZones(null);
                  }
                }}
              />
              <Autocomplete
                loading={demEvents.isFetching}
                InputProps={{
                  required: true,
                  startAdornment: (
                    <Box sx={{ ml: 2, mr: 1 }}>
                      <Calendar /> *
                    </Box>
                  ),
                }}
                value={selectedCollectEvent ?? null}
                disableClearable={false}
                options={demEvents.data || []}
                getOptionLabel={(row: Partial<ICollectEvent>) => {
                  const beginDate = new Date(
                    row.beginOnUtc
                  ).toLocaleDateString();
                  return `${beginDate}${row.name ? ` (${row.name})` : ""}`;
                }}
                onChange={(_e, item: ICollectEvent) => {
                  setDisplayEnabled(false);
                  setSelectedCollectEvent(item);
                  if (!item) {
                    setZones(null);
                    setSelectedRasterType(null);
                  }
                }}
              />
              <Autocomplete
                InputProps={{
                  required: true,
                  startAdornment: (
                    <Box sx={{ ml: 2, mr: 1 }}>
                      <Monitor /> *
                    </Box>
                  ),
                }}
                value={selectedRasterType ?? null}
                options={
                  rasterQ.data?.types?.filter((t) =>
                    ["ndvi", "gndvi", "ndre"].includes(t.slug)
                  ) ?? []
                }
                onChange={(_e, item) => {
                  setDisplayEnabled(false);
                  const i = item as IRasterType;
                  setSelectedRasterType(i);
                }}
                getOptionLabel={(o) => (typeof o !== "string" ? o.name : o)}
              />
              <Box sx={{ position: "relative", display: "flex" }}>
                <SectionHeader>{t("imagery.rx.enterValues")}</SectionHeader>
                <IconButton
                  aria-label="set default zones"
                  sx={{ ml: "auto" }}
                  onClick={() => {
                    setDisplayEnabled(false);
                    const d = rasterLegendQ.data?.[0];
                    if (d?.data) {
                      const zs = parseZones(d.data);
                      setZones(zs);
                    }
                  }}
                >
                  <RestartAltIcon />
                </IconButton>
              </Box>

              <TableContainer
                sx={{
                  opacity: !zones ? 0.5 : 1,
                  overflow: "auto",
                }}
                component={Paper}
              >
                <Table size="small" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("imagery.rx.zone")}</TableCell>
                      <TableCell>{t("imagery.rx.color")}</TableCell>
                      <TableCell>{t("imagery.rx.min")}</TableCell>
                      <TableCell>{t("imagery.rx.max")}</TableCell>
                      <TableCell>{t("common.rate")}</TableCell>
                      <TableCell></TableCell>
                      {/* <TableCell >{t("common.area")}</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {zones?.map((row, idx) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell
                          sx={{
                            'input[type="color"]': {
                              background: "none",
                              border: 0,
                              cursor: "pointer",
                              height: "42px",
                              padding: 0,
                              width: "38px",
                            },
                            position: "relative",
                          }}
                        >
                          <input
                            value={row.color}
                            onChange={(e) => {
                              setDisplayEnabled(false);
                              const newColor = e.target.value;
                              const classes = [...zones];
                              row.color = newColor;
                              setZones(classes);
                            }}
                            type="color"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            sx={{ minWidth: 75 }}
                            type="number"
                            disabled={idx === 0}
                            aria-readonly={idx === 0}
                            value={idx === 0 ? -1 : row.min}
                            inputProps={{
                              step: "0.01",
                              min: -1,
                              max: 1,
                            }}
                            onChange={(e) => {
                              setDisplayEnabled(false);
                              const newMin = Number(e.target.value);
                              const classes = [...zones];
                              const prev = zones[idx - 1];
                              if (prev) {
                                prev.max = newMin;
                              }
                              row.min = Number(e.target.value);
                              if (newMin > row.max) {
                                row.max = newMin + 0.01;
                              }
                              setZones(classes);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            sx={{ minWidth: 75 }}
                            type="number"
                            disabled={idx === zones.length - 1}
                            aria-readonly={idx === zones.length - 1}
                            inputProps={{
                              step: "0.01",
                              min: zones[idx - 1]
                                ? zones[idx - 1].max
                                : row.min,
                              max: zones[idx + 1] ? zones[idx + 1].max : 1,
                            }}
                            onChange={(e) => {
                              setDisplayEnabled(false);
                              const newMax = Number(e.target.value);
                              const classes = [...zones];
                              const next = zones[idx + 1];

                              if (next) {
                                next.min = newMax;
                              }
                              row.max = newMax;
                              if (newMax < row.min) {
                                row.min = newMax - 0.01;
                              }
                              setZones(classes);
                            }}
                            value={row.max}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            sx={{ minWidth: 75 }}
                            type="number"
                            inputProps={{
                              min: 0.001,
                              required: true,
                              step: "any",
                            }}
                            value={row.rate}
                            onChange={(e) => {
                              setDisplayEnabled(false);
                              const classes = [...zones];
                              row.rate = Number(e.target.value);
                              setZones(classes);
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ width: "100px" }}>
                          <IconButton
                            aria-label="delete zone"
                            onClick={() => {
                              setDisplayEnabled(false);
                              const newZones = [...zones];
                              if (newZones.length === 1) {
                                setZones(null);
                                return;
                              }
                              const prev = newZones[idx - 1];
                              const next = newZones[idx + 1];
                              // zipper up the values
                              if (prev && next) {
                                // next.min = prev.max;
                                prev.max = next.min;
                              }
                              // remove it
                              newZones.splice(idx, 1);
                              // set min/max to -1,1
                              if (
                                newZones.length > 1 &&
                                newZones[newZones.length - 1].max !== 1
                              ) {
                                newZones[newZones.length - 1].max = 1;
                              }
                              // reset IDs by index
                              newZones.forEach((i, idx) => (i.id = idx + 1));
                              setZones(newZones);
                            }}
                            size="small"
                          >
                            <Trash2 />
                          </IconButton>
                          <IconButton
                            size="small"
                            title={t("imagery.rx.addZoneBelow")}
                            aria-label={t("imagery.rx.addZoneBelow")}
                            onClick={() => {
                              setDisplayEnabled(false);
                              const newZones = [...zones];
                              const newItemMin =
                                row.max === 1
                                  ? 1
                                  : Number((row.max + 0.01).toFixed(3));
                              const newItemMax = newItemMin;
                              const newItem = {
                                min: newItemMin,
                                max: newItemMax,
                                color: `#${Math.floor(
                                  Math.random() * 16777215
                                ).toString(16)}`,
                                rate: 1,
                                id: undefined,
                              } as Zone;
                              const nextItem = newZones[idx + 1];
                              if (nextItem) {
                                nextItem.min = newItemMax;
                              }
                              newZones.splice(idx + 1, 0, newItem);
                              newZones.forEach((i, idx2) => (i.id = idx2 + 1));
                              setZones(newZones);
                            }}
                          >
                            <PlusCircle />
                          </IconButton>
                        </TableCell>
                        {/* <TableCell >{row.area}</TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box>
                <LoadingButton
                  type="submit"
                  sx={{ opacity: selectedField && zones ? 1 : 0.5 }}
                  variant="contained"
                  isLoading={displayEnabled && displayQuery.isFetching}
                >
                  {t("imagery.rx.display")}
                </LoadingButton>
                <IconButton
                  sx={{
                    opacity: displayQuery.data ? 1 : 0.5,
                    ml: 1,
                  }}
                  onClick={() => {
                    if (displayQuery.data) {
                      fitBounds({
                        map: mapRef.current,
                        geojson: {
                          type: "Polygon",
                          coordinates: [displayQuery.data.coordinates],
                        },
                        options: {
                          animate: true,
                        },
                      });
                    }
                  }}
                >
                  <ZoomInIcon />
                </IconButton>
              </Box>
              <Box sx={{ opacity: zones ? 1 : 0.5, display: "flex" }}>
                <TextField
                  disabled={!zones}
                  fullWidth
                  value={filename}
                  onChange={(e) => {
                    setFilename(sanitize(e.currentTarget.value));
                  }}
                  placeholder={zones ? defaultFilename : ""}
                  label={t("imagery.rx.filename")}
                  InputProps={{
                    endAdornment: zones ? (
                      <InputAdornment position="end">
                        {downloadMutation.isLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          <IconButton
                            aria-label={t("common.download")}
                            onClick={handleDownload}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            <SaveAltIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ) : null,
                  }}
                />
              </Box>
            </Stack>
          </Paper>
        </Grid>
        <Grid sm={12} md={7}>
          <Box sx={{ position: "relative", height: "100%", maxHeight: "100%" }}>
            <PrescriptionMapView
              field={selectedField}
              selectedSubfields={subfieldFtrs}
              rasterSource={displayQuery.data}
              onLoad={(m) => {
                mapRef.current = m;
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
