import React from "react";
import { useTranslation } from "react-i18next";

interface Stats {
  payload: {
    name: string;
    statistics: {
      count: number;
      mean: number;
      min: number;
      max: number;
      std: number;
    };
  };
}
export const StatsBarTooltip = ({
  active,
  payload,
}: {
  active: boolean;
  payload: Stats[];
}) => {
  const { t } = useTranslation();
  if (active && payload) {
    const item = payload ? payload[0] : null;
    // const item = payload.find((p) => p.dataKey === bar) || payload[0];
    const properties = item?.payload;
    const { count, mean, min, max, std } = properties?.statistics ?? {};
    // const barArea = bar ? item.payload[bar] : item.payload.area;
    return (
      <div
        className="bg-light text-dark p-2"
        style={{ border: "solid 1px", opacity: 0.9, minWidth: "175px" }}
      >
        <h6
          className="text-center mb-0 text-uppercase"
          style={{ fontWeight: "bold" }}
        >{`${properties.name}`}</h6>
        <div>
          {t("common.count")}: {count?.toLocaleString()}
        </div>
        <div>
          {t("stats.mean")}: {mean}
        </div>
        <div>
          {t("stats.min")}: {min}
        </div>
        <div>
          {t("stats.max")}: {max}
        </div>
        <div>
          {t("stats.standardDeviation")}: {std}
        </div>
      </div>
    );
  }

  return null;
};
export default StatsBarTooltip;
