import React, { useEffect, useState } from "react";
import { Route, useHistory, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useOrgDispatch, useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch } from "providers/InventoryProvider";
import { useConservationState } from "providers/ConservationProvider";
import CardStack from "../../CardStack";
import ConservationListView from "./ConservationListView";
import ConservationSummaryView from "./ConservationSummaryView";
import { CreateOrEditConservation } from "./CreateOrEditConservation";
import ConservationMapThemesView from "./ConservationMapThemesView";
import { useSetAppDataLoading } from "components";

export function ConservationView() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState();
  const { editId } = useParams();
  const { rootUrl } = useOrgState();
  const { dispatch } = useOrgDispatch();
  const { geodataState, isFetchingData } = useConservationState();
  const {
    setInventoryFtrs,
    setLegendData,
    setColSizes,
    setTitle,
    setLabelProp,
  } = useInventoryDispatch();
  const history = useHistory();

  useEffect(() => {
    setTitle(t("common.carbon"));
    setLabelProp("cid");
    setColSizes([7, 5]);
  }, [setColSizes, setTitle, setLabelProp, t]);

  useEffect(() => {
    setLegendData({
      title: "Type",
      colorProp: "conservationTypeColor",
      dataProp: "conservationType",
    });
  }, [setLegendData]);

  useEffect(() => {
    setInventoryFtrs(geodataState?.data);
  }, [geodataState, setInventoryFtrs]);

  useSetAppDataLoading({ dispatch, isFetchingData });

  return (
    <Route path="/:org/:season/inventory/conservation">
      <CardStack
        topHeight={"300px"}
        bottomMinHeight="400px"
        expanded={Boolean(editId || expanded)}
        topChildren={
          <>
            <Card className="mb-3">
              <ConservationSummaryView />
            </Card>
            <Card className="flex-grow-1">
              <ConservationMapThemesView
                onThemeChange={(theme) => {
                  setLegendData({
                    title: theme === "conservationType" ? "Type" : "Status",
                    colorProp:
                      theme === "conservationType"
                        ? "conservationTypeColor"
                        : "conservationStatusColor",
                    dataProp: theme,
                  });
                }}
              />
            </Card>
          </>
        }
      >
        <Card
          className="p-3 flex-grow-1 bottom h-100"
          style={{ maxHeight: "100%" }}
        >
          <Route path="/:org/:season/inventory/conservation" exact>
            <ConservationListView
              toggleExpanded={() => {
                setExpanded(!expanded);
              }}
              expanded={expanded}
              onEditClick={(row) => {
                history.push(
                  `${rootUrl}/inventory/conservation/${row.id}/edit`
                );
              }}
            />
          </Route>
          <Route
            path={[
              `/:org/:season/inventory/conservation/:editId/edit`,
              `/:org/:season/inventory/conservation/add`,
            ]}
            exact
          >
            <CreateOrEditConservation />
          </Route>
        </Card>
      </CardStack>
    </Route>
  );
}
