import { useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useOrgState, useOrgDispatch } from "providers/OrgProvider";
import { useFieldsDispatch } from "providers/FieldsProvider";
import { useSettingsState } from "providers/SettingsProvider";
import agassessorDarkLogo from "src/images/agassessor-dark.png";
import agassessorLightLogo from "src/images/agassessor-light.png";
import DashboardView from "./DashboardView";
import InsightsView from "./InsightsView";
import InventoryView from "./InventoryView";
import ImageryView from "./ImageryView";
import SettingsView from "./SettingsView";
import { useAppState } from "providers/AppProvider";
import { useIsMutating } from "@tanstack/react-query";
import { IUrlParams } from "types";
import { LinearProgress } from "lib";
import AppLayout from "lib/AppLayout";
import SeasonSelector from "./NavbarView/SeasonSelector";
import OrganizationSelector from "./NavbarView/OrganizationSelector";
import AuthNavItem from "components/AuthNavItem";
import SidebarItems from "./SidebarItems";
import ErrorBoundary from "components/ErrorBoundary";

export default function OrganizationView() {
  const { t } = useTranslation();
  const isMutating = useIsMutating();
  const theme = useTheme();
  const { org, route, season: seasonName } = useParams<IUrlParams>();
  const { setSidebarOpen, tenant } = useAppState();
  const location = useLocation();
  const history = useHistory();
  const { language } = useSettingsState();
  const { fetchFields } = useFieldsDispatch();
  const {
    fetchDefaultCollectEvents,
    getOrgDarkLogo,
    getOrgLightLogo,
    getEntDarkLogo,
    getEntLightLogo,
    getFieldsSummary,
    getCurrentSensorTypes,
    getFarms,
    getLandAgreements,
    getCropTypes,
    dispatch,
  } = useOrgDispatch();

  const {
    rootUrl,
    seasons,
    season,
    seasonsState,
    isDataLoading,
    lightEntLogoState,
    darkEntLogoState,
  } = useOrgState();

  const logo =
    theme.palette.mode === "light"
      ? lightEntLogoState?.data
      : darkEntLogoState?.data;
  const logoError = lightEntLogoState.isError || darkEntLogoState.isError;

  // default data fetch
  // also re-fetches after a language switch
  useEffect(() => {
    if (season) {
      fetchDefaultCollectEvents(org);
      getLandAgreements();
      getFieldsSummary();
      getCurrentSensorTypes();
      getFarms();
      getCropTypes();
      fetchFields();
    }
  }, [
    fetchDefaultCollectEvents,
    fetchFields,
    getCropTypes,
    getCurrentSensorTypes,
    getFarms,
    getFieldsSummary,
    getLandAgreements,
    language,
    org,
    season,
  ]);

  useEffect(() => {
    if (seasons?.length && !season) {
      const curr = seasons?.find((s) => s.name === seasonName);

      dispatch({
        type: "SET_SEASON",
        payload: curr || seasons[0],
      });
      // couldn't match URL to a season, update URL to new season
      if (!curr) {
        const parts = location?.pathname.split("/");
        parts[2] = seasons[0].name;
        history.replace(parts.join("/") + location.search);
      }
    }
  }, [seasonName, seasons, season, dispatch, location, history]);

  useEffect(() => {
    if (tenant) {
      getEntDarkLogo(tenant);
      getEntLightLogo(tenant);
      getOrgDarkLogo(tenant);
      getOrgLightLogo(tenant);
    }
  }, [
    getEntDarkLogo,
    getEntLightLogo,
    getOrgDarkLogo,
    getOrgLightLogo,
    tenant,
  ]);

  return (
    <AppLayout
      title={
        <Box
          className="hide-print"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Box sx={{ width: 175 }}>
            <img style={{ width: "100%" }} src={!logoError ? logo?.src : ""} />
          </Box>
          <Box sx={{ ml: 3, width: 175 }}>
            <img
              style={{ width: "100%" }}
              src={
                theme.palette.mode === "light"
                  ? agassessorDarkLogo
                  : agassessorLightLogo
              }
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", ml: "auto" }}>
            <Container maxWidth="xl" sx={{ pr: "0 !important" }}>
              <Toolbar disableGutters>
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <SeasonSelector />
                  <OrganizationSelector />
                  <AuthNavItem />
                </Stack>
              </Toolbar>
            </Container>
          </Box>
        </Box>
      }
      sidebarProps={{
        activeKey: route,
        onToggled: (open) => {
          // BaseMapView needs to resize after open/close, so storing this
          // in the AppProvider
          setSidebarOpen(open);
        },
      }}
      sidebarChildren={<SidebarItems />}
    >
      <ErrorBoundary>
        {/* d-flex flex-column p-0 pt-4 px-3 overflow-hidden position-relative */}
        <Box sx={{ height: "100%" }} className="main">
          <LinearProgress
            absolute
            isLoading={
              isMutating > 0 || isDataLoading || seasonsState?.isLoading
            }
          />
          {seasonsState?.isLoading ? (
            <div className="d-flex flex-column align-items-center mt-5">
              {t("gettingSeasons")}
            </div>
          ) : null}
          {!season &&
          !seasonsState?.isLoading &&
          seasons &&
          !seasons?.length ? (
            <div className="d-flex flex-column align-items-center mt-5">
              <h2>{t("noSeasons")}</h2>
            </div>
          ) : null}
          {season && !seasonsState.isLoading ? (
            <Switch>
              <Route path="/:org/:season?" exact>
                <DashboardView />
              </Route>
              <Route
                path={[
                  `/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId`,
                  `/:org/:season/inventory/assets/:type?/:subtype?/:fieldId?`,
                  `/:org/:season/inventory/fields/:editId?`,
                  `/:org/:season/inventory/subfields/:type?/:editId?`,
                  `/:org/:season/inventory/damage/:editId?`,
                  `/:org/:season/inventory/conservation/:editId?`,
                  `/:org/:season/inventory/products/:type?`,
                  `/:org/:season/inventory/operations/:type?/items/:itemId`,
                  `/:org/:season/inventory/operations/:type?`,
                  `/:org/:season/inventory/scouting`,
                ]}
              >
                <InventoryView />
              </Route>
              <Route path="/:org/:season/imagery">
                <ImageryView />
              </Route>
              <Route path="/:org/:season/insights">
                <InsightsView />
              </Route>
              <Route path="/:org/:season/settings">
                <SettingsView />
              </Route>
              {/* TODO: 404? */}
              <Route path="*">
                <Redirect to={rootUrl} />
              </Route>
            </Switch>
          ) : null}
        </Box>
      </ErrorBoundary>
    </AppLayout>
  );
}
