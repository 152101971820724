import React from "react";
import SVGIconWrapper from "./IconWrapper";

export default function PlantedIcon(props) {
  return (
    <SVGIconWrapper {...props}>
      <svg viewBox="0 0 431 416">
        <path d="M350.55,104.4c-34.35,0-82.89,2.86-107.69,27.66-18.29,18.28-18.9,53.53-7.8,75.39-11.9,17.15-19.82,36.12-24.9,55.33-4.71-30.5-12.47-53.7-21.31-71.49,17-19.48,19.6-63.53-1.62-84.73C162.43,81.77,113.9,78.9,79.54,78.9a6.45,6.45,0,0,0-6.45,6.44c0,34.36,2.87,82.9,27.67,107.7,10.47,10.47,26.52,15.15,42.42,15.15,12.87,0,25.66-3.07,35.32-8.61C191.61,227,202,268,202,330.28a6.45,6.45,0,1,0,12.89,0c0-35,5.95-77,28.23-111.51,9.68,9.46,26.64,14.89,43.77,14.89,15.9,0,31.94-4.66,42.44-15.13C354.13,193.74,357,145.2,357,110.84A6.45,6.45,0,0,0,350.55,104.4ZM109.89,183.92c-20-20-23.49-60.69-23.87-92.09,31.41.37,72.08,3.84,92.1,23.86,14.47,14.47,14.06,46.76,3.89,63.21-15.31-25.08-32.21-36.65-41.74-43.18a39.9,39.9,0,0,1-4.61-3.36,6.45,6.45,0,0,0-9.12,9.11,51.67,51.67,0,0,0,6.46,4.89c9,6.15,25.1,17.18,39.48,41.83C155.84,198,124.18,198.22,109.89,183.92ZM320.2,209.41c-16.14,16.14-54.46,13.78-68.24,0-.41-.41-.82-.85-1.21-1.31A135.62,135.62,0,0,1,300.41,169s1-.5,1-.5c3.21-1.52,4.08-5.11,2.55-8.33s-5.87-4.34-9.08-2.85a149.28,149.28,0,0,0-50.85,38.4c-5.94-17.37-4.34-42.33,7.89-54.56,20-20,60.69-23.49,92.1-23.87C343.68,148.73,340.22,189.4,320.2,209.41Z" />
      </svg>
    </SVGIconWrapper>
  );
}
