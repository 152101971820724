import React, { useEffect } from "react";
import PropTypes from "prop-types";
import usePrevious from "components/usePrevious";
import useFetchWithToken from "components/useFetchWithToken";
import { useOrgLookup } from "components/useOrgLookup";
import { useInventoryType } from "views/InventoryView/useInventoryType";

const ConservationStateContext = React.createContext();
const ConservationStateDispatch = React.createContext();

const conservationThemeConfig = [
  { dataProp: "conservationType", colorProp: "conservationTypeColor" },
  { dataProp: "conservationStatus", colorProp: "conservationStatusColor" },
];

function ConservationProvider({ org, season, editId, children }) {
  const { state: conservationTypesState } = useOrgLookup({
    org,
    url: `${org}/lookups/conservationtypes`,
  });
  const { state: conservationStatusesState } = useOrgLookup({
    org,
    url: `${org}/lookups/conservationstatuses`,
  });
  const {
    fetchList,
    fetchGeodata,
    fetchMapThemes,
    geodataState,
    listState,
    saveItem,
    deleteItem,
    deleteItemState,
    saveItemState,
    themeState,
    themeRollup,
    reset,
  } = useInventoryType({
    org,
    seasonId: season?.id,
    type: "aois",
    subType: "conservation",
    themeConfig: conservationThemeConfig,
  });
  const {
    fetchData: fetchSummary,
    state: summaryState,
    resetFetchState: resetSummary,
  } = useFetchWithToken();
  const prevSeason = usePrevious(season);
  const prevOrg = usePrevious(org);

  const currentEditFtr = editId
    ? geodataState?.data?.features?.find((f) => f.id === editId)
    : null;

  // refetch on season change
  useEffect(() => {
    async function getData() {
      // fetch theme and list data first, to merge with geodata
      const theme = await fetchMapThemes();
      const list = await fetchList();
      fetchGeodata(theme?.data, list?.data);
      fetchSummary(`/${org}/aois/conservation/season/${season?.id}/summary`);
    }
    if (season && prevSeason !== season) {
      reset();
      resetSummary();
      getData();
    }
  }, [
    org,
    season,
    fetchList,
    fetchGeodata,
    fetchMapThemes,
    fetchSummary,
    prevSeason,
    reset,
    resetSummary,
  ]);
  useEffect(() => {
    if (org !== prevOrg) {
      reset();
    }
  }, [org, prevOrg, reset]);
  return (
    <ConservationStateContext.Provider
      value={{
        editId,
        editFtr:
          editId && listState?.data
            ? listState.data.find((l) => l.id === editId)
            : null,
        editGeodata:
          editId && geodataState?.data?.features
            ? geodataState.data.features.find((d) => d.id)
            : null,
        currentEditFtr,
        itemsState: listState,
        items: listState?.data,
        geodataState,
        summaryState,
        saveState: saveItemState,
        deleteState: deleteItemState,
        themeRollup,
        themeState,
        conservationTypesState,
        conservationStatusesState,
        isFetchingData:
          listState.isLoading ||
          geodataState.isLoading ||
          conservationTypesState.isLoading ||
          conservationStatusesState.isLoading,
      }}
    >
      <ConservationStateDispatch.Provider
        value={{
          fetchSummary: () => {
            if (org && season?.id) {
              return fetchSummary(
                `/${org}/aois/conservation/season/${season?.id}/summary`
              );
            }
            return null;
          },
          saveConservationArea: saveItem,
          deleteConservationArea: deleteItem,
          fetchConservationByFieldId: (id) => {
            console.log("todo: fetch", id);
          },
        }}
      >
        {children}
      </ConservationStateDispatch.Provider>
    </ConservationStateContext.Provider>
  );
}
ConservationProvider.defaultProps = {
  org: null,
  season: null,
  editId: null,
};

ConservationProvider.propTypes = {
  children: PropTypes.node.isRequired,
  org: PropTypes.string,
  season: PropTypes.shape({ id: PropTypes.string }),
  editId: PropTypes.string,
};

function useConservationState() {
  const context = React.useContext(ConservationStateContext);
  if (context === undefined) {
    throw new Error(
      "useConservationState must be used within a ConservationProvider"
    );
  }
  return context;
}

function useConservationDispatch() {
  const context = React.useContext(ConservationStateDispatch);
  if (context === undefined) {
    throw new Error(
      "useConservationDispatch must be used within a ConservationProvider"
    );
  }
  return context;
}

export { ConservationProvider, useConservationState, useConservationDispatch };
