import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAssetsState } from "providers/AssetsProvider";
import { DetailItem } from "components";

export default function PlantAssetDetails() {
  const { currentEditFtr } = useAssetsState();
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col
          className="d-flex flex-wrap"
          // style={{ justifyContent: 'space-between' }}
        >
          <DetailItem
            title={t("common.field")}
            value={currentEditFtr?.properties?.field || "--"}
          />
          <DetailItem
            title={t("common.crop")}
            value={currentEditFtr?.properties?.crop}
          />
          <DetailItem
            title={t("common.status")}
            value={currentEditFtr?.properties?.assetStatus}
          />

          <DetailItem
            title="ID"
            value={currentEditFtr?.properties?.cid || "--"}
          />
          <DetailItem
            title={t("inventory.assets.plantedDate")}
            value={
              currentEditFtr?.properties?.bornOnUtc
                ? new Date(
                    currentEditFtr?.properties?.bornOnUtc
                  ).toLocaleDateString()
                : "--"
            }
          />
        </Col>
        <Col xs={12}>
          <DetailItem
            title={t("common.notes")}
            value={currentEditFtr?.properties?.description || "--"}
          />
        </Col>
      </Row>
    </>
  );
}
