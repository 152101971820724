import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Switch, Route, useParams, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { useAssetsState } from "providers";
import { useInventoryDispatch } from "providers/InventoryProvider";
import { NotFoundView, usePrevious } from "components";
import AssetsSummaryView from "./SummaryView";
import AssetsListView from "./ListView";
import AssetMapThemes from "./MapThemes";
import CardStack from "../../CardStack";
import CreateOrEditAsset from "./CreateOrEditAsset";
import AssetDetails from "./AssetDetails";

export function AssetsView() {
  const { t } = useTranslation();
  const location = useLocation();
  const [expanded, setExpanded] = useState();
  const {
    setInventoryFtrs,
    setLegendData,
    setColSizes,
    setTitle,
    setLabelProp,
  } = useInventoryDispatch();
  const { rootUrl } = useOrgState();
  const { geodataState } = useAssetsState();
  const { type, itemId } = useParams();
  const isAdd = location.pathname.includes("/add");
  const activeType = type?.toLowerCase();
  useEffect(() => {
    setTitle(t("inventory.assets.title"));
    setLabelProp("cid");
    setColSizes([7, 5]);
  }, [setColSizes, setTitle, setLabelProp, t]);

  // effects for updating legend/label prop
  useEffect(() => {
    const lowerType = type?.toLowerCase();
    let returnObj = {};
    if (lowerType === "plant") {
      setLabelProp("cid");
      returnObj = {
        title: t("common.crop"),
        colorProp: "color",
        dataProp: "crop",
      };
    }
    if (lowerType === "fixed") {
      setLabelProp("name");
      returnObj = {
        title: t("common.type"),
        colorProp: "color",
        dataProp: "fixedAssetType",
      };
    }
    if (lowerType === "animal") {
      setLabelProp("cid");
      returnObj = {
        title: t("common.breed"),
        colorProp: "color",
        dataProp: "assetAnimalBreed",
      };
    }
    setLegendData(returnObj);
  }, [type, setLegendData, setLabelProp, t]);

  const prevAssetsGeodata = usePrevious(geodataState?.data);

  useEffect(() => {
    if (geodataState?.data !== prevAssetsGeodata && setInventoryFtrs) {
      // console.log('updating inventory features', currentAssetsGeodata);
      // NOTE: since animals can be without a geometry
      // filtering out null geom features here as they will not work in the map component
      setInventoryFtrs(
        geodataState?.data
          ? {
              type: "FeatureCollection",
              features: geodataState.data.features.filter((f) => f.geometry),
            }
          : null
      );
    }
  }, [geodataState?.data, prevAssetsGeodata, setInventoryFtrs, type]);

  return (
    <>
      <Route
        path={[
          "/:org/:season/inventory/assets/:type?",
          `/:org/:season/inventory/assets/:type/:subtype`,
          `/:org/:season/inventory/assets/:type/:subtype/:fieldId/items`,
          `/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId`,
        ]}
      >
        <CardStack
          bottomMinHeight="400px"
          topHeight={"305px"}
          expanded={Boolean(itemId) || isAdd || expanded}
          bottomClasses="d-flex flex-column"
          topChildren={
            <>
              <Card
                className="mb-3 overflow-auto"
                style={{ minHeight: "100px", flexBasis: "100px" }}
              >
                <AssetsSummaryView activeType={activeType} />
              </Card>
              <Card
                className="mb-3 overflow-visible" // let popover overflow without scroll
                style={{ minHeight: "171px", flexBasis: "171px" }}
              >
                <AssetMapThemes assetType={activeType} />
              </Card>
            </>
          }
        >
          <div
            className="d-flex flex-column flex-grow-1 bottom h-100"
            style={{ minHeight: "400px" }}
          >
            <Switch>
              <Route
                path={[
                  `/:org/:season/inventory/assets/:type/add`,
                  `/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/edit`,
                ]}
                exact
              >
                <CreateOrEditAsset />
              </Route>
              <Route
                path={`/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/records/:recordId`}
              >
                <AssetDetails />
              </Route>
              <Route
                path={[
                  `/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId`,
                ]}
              >
                <AssetDetails />
              </Route>

              <Route
                path={[
                  `/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/photos`,
                  `/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/photos/:photoId/edit`,
                  `/:org/:season/inventory/assets/:type/:subtype/:fieldId/items`,
                  `/:org/:season/inventory/assets/:type/:subtype`,
                  `/:org/:season/inventory/assets/:type`,
                ]}
                exact
              >
                <Card className="p-3 h-100">
                  <AssetsListView
                    toggleExpanded={() => {
                      setExpanded(!expanded);
                    }}
                    expanded={expanded}
                  />
                </Card>
              </Route>

              <Route path="*">
                <NotFoundView>
                  <Link to={`${rootUrl}/inventory/assets/${activeType}`}>
                    {t("common.back")}
                  </Link>
                </NotFoundView>
              </Route>
            </Switch>
          </div>
        </CardStack>
      </Route>
    </>
  );
}
