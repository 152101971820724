import React, { useRef, useState } from "react";
import { Loader } from "react-feather";
import { Alert, Button, Row, Col, Container } from "react-bootstrap";
import IconButton from "../IconButton";
import ImageUpload from "./ImageUpload";
import PhotoSwipeViewer from "./PhotoSwipeViewer";

export interface ParsedImage {
  image?: HTMLImageElement;
  id?: string | number;
  errorMessage?: string;
  failed?: boolean;
  isLoading?: boolean;
  src: string;
  file?: File;
  w: number;
  h: number;
}

function ImageComponent({
  id,
  images,
  onImageChange,
  onImageDelete,
  maxImages = 3,
  disabled,
  accept = "image/*",
  readonly,
}: {
  id: string;
  images: ParsedImage[];
  onImageChange?: (_imgs: ParsedImage[]) => void;
  onImageDelete?: (_img: ParsedImage, _idx: number) => void;
  maxImages?: number;
  disabled?: boolean;
  accept?: string;
  readonly?: boolean;
}) {
  const [alertMsg, setAlertMsg] = useState("");
  const photoSwipeBtnRef = useRef<HTMLButtonElement>();
  function onImageUpload(imgs: ParsedImage[]) {
    if (imgs.length + images?.length > maxImages) {
      setAlertMsg(`Maximum of ${maxImages} images can be uploaded.`);
      return;
    }
    onImageChange && onImageChange(imgs);
  }

  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const handleOpen = (i: number) => {
    setIsOpen(true);
    setIndex(i);
  };

  const handleClose = () => {
    setIsOpen(false);
    if (photoSwipeBtnRef.current) {
      photoSwipeBtnRef.current.focus();
      photoSwipeBtnRef.current = null;
    }
  };

  return (
    <Container fluid>
      <Row>
        {images.map((item, i) => (
          <Col
            key={item.id || i}
            className="mt-3 text-center img-thumbnail-container"
            style={{
              border: `${item.failed ? "solid red" : ""}`,
            }}
          >
            {!readonly ? (
              <IconButton
                disabled={item.isLoading || disabled}
                className="btn-danger delete-button"
                icon="delete"
                size="sm"
                onClick={() => {
                  onImageDelete && onImageDelete(item, i);
                }}
              />
            ) : null}
            {item.isLoading ? (
              <div className="loader">
                <Loader className="spin" />
              </div>
            ) : null}
            <Button
              variant=""
              className="img-btn h-100"
              style={{ opacity: item.id && !item.isLoading ? 1 : 0.5 }}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                photoSwipeBtnRef.current = e.currentTarget;
                handleOpen(i);
              }}
            >
              <img
                className="h-100 w-100 p-1"
                style={{
                  objectFit: "scale-down",
                }}
                src={item.src}
                itemProp="thumbnail"
                alt="description"
              />
            </Button>
            {item.errorMessage ? (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                }}
              >
                <Alert variant="danger">{item.errorMessage}</Alert>
              </div>
            ) : null}
          </Col>
        ))}
        {!readonly ? (
          <Col className="mt-3 text-center img-thumbnail-container p-0">
            <ImageUpload
              id={id}
              disabled={disabled || images.length >= maxImages}
              className="btn-block"
              images={images}
              onChange={onImageUpload}
              accept={accept}
            />
          </Col>
        ) : null}
      </Row>
      <PhotoSwipeViewer
        isOpen={isOpen}
        index={index}
        items={images}
        onClose={handleClose}
      />
      {alertMsg}
    </Container>
  );
}

export default ImageComponent;
