import React, { useCallback } from "react";
import useFetchWithToken from "components/useFetchWithToken";
import PropTypes from "prop-types";
import { useInventoryType } from "views/InventoryView/useInventoryType";
import { sortObjArrByFields } from "../components";

const plantedThemeConfig = [
  {
    dataProp: "product",
    colorProp: "productColor",
  },
  {
    dataProp: "crop",
    colorProp: "cropColor",
  },
];

const appliedThemeConfig = [
  {
    dataProp: "product",
    colorProp: "productColor",
  },
  {
    dataProp: "productType",
    colorProp: "productTypeColor",
  },
];

const harvestedThemeConfig = [
  {
    dataProp: "crop",
    colorProp: "cropColor",
  },
];

const tilledThemeConfig = [
  {
    dataProp: "depth",
    labelFunc: (d) => {
      return `${d.depth} ${d.depthUom || ""}`;
    },
    colorProp: "depthColor",
  },
];

const OperationsStateContext = React.createContext();
const OperationsDispatchContext = React.createContext();

function OperationsProvider({ children, org, seasonId, type, itemId }) {
  const {
    fetchData: fetchOperationsSummary,
    state: operationsSummaryState,
  } = useFetchWithToken();

  const {
    fetchData: fetchEvents,
    state: fetchEventsState,
  } = useFetchWithToken();

  const {
    fetchData: fetchEventTemplates,
    state: eventTemplatesState,
  } = useFetchWithToken();

  const {
    fetchData: saveOperationEvent,
    resetFetchState: resetOperationEventSave,
    state: saveOperationEventState,
  } = useFetchWithToken();

  const {
    fetchData: deleteOperationEvent,
    resetFetchState: resetOperationEventDelete,
    state: deleteOperationEventState,
  } = useFetchWithToken();

  const {
    saveItem: savePlantedOperation,
    deleteItem: deletePlantedOperation,
    saveItemState: savePlantedState,
    deleteItemState: deletePlantedState,
    fetchList: fetchPlantedOps,
    listState: plantedOpsState,
    fetchGeodata: fetchPlantedGeodata,
    fetchMapThemes: fetchPlantedMapThemes,
    fetchItemById: fetchPlantedById,
    fetchItemsByFieldId: fetchPlantedByFieldId,
    list: plantedOps,
    geodata: plantedOpsGeodata,
    geodataState: plantedOpsGeodataState,
    themeData: plantedOpsTheme,
    themeRollup: plantedOpsThemeRollup,
    // geodataByIdState: plantedFetchByIdState,
    geodataByFieldIdState: plantedFetchByFieldIdState,
  } = useInventoryType({
    org,
    seasonId,
    type: "operations",
    subType: "planted",
    themeConfig: plantedThemeConfig,
  });

  const {
    saveItem: saveAppliedOperation,
    deleteItem: deleteAppliedOperation,
    saveItemState: saveAppliedState,
    deleteItemState: deleteAppliedState,
    fetchList: fetchAppliedOps,
    listState: appliedOpsState,
    fetchGeodata: fetchAppliedGeodata,
    fetchMapThemes: fetchAppliedMapThemes,
    fetchItemById: fetchAppliedByField,
    fetchItemsByFieldId: fetchAppliedByFieldId,
    list: appliedOps,
    geodata: appliedOpsGeodata,
    geodataState: appliedOptsGeodataState,
    themeData: appliedOpsTheme,
    themeRollup: appliedOpsThemeRollup,
    geodataByFieldIdState: appliedFetchByFieldIdState,
    // geodataByIdState: appliedFetchByIdState,
  } = useInventoryType({
    org,
    seasonId,
    type: "operations",
    subType: "applied",
    themeConfig: appliedThemeConfig,
  });

  const {
    saveItem: saveHarvestOperation,
    deleteItem: deleteHarvestOperation,
    fetchList: fetchHarvestOps,
    listState: harvestedOpsState,
    fetchGeodata: fetchHarvestedGeodata,
    fetchMapThemes: fetchHarvestedMapThemes,
    fetchItemById: fetchHarvestedById,
    fetchItemsByFieldId: fetchHarvestedByFieldId,
    list: harvestedOps,
    saveItemState: saveHarvestState,
    deleteItemState: deleteHarvestState,
    geodata: harvestedOpsGeodata,
    geodataState: harvestedOpsGeodataState,
    themeData: harvestedOpsTheme,
    themeRollup: harvestedOpsThemeRollup,
    geodataByFieldIdState: harvestedFetchByFieldIdState,
    // geodataByIdState: harvestedFetchByIdState,
  } = useInventoryType({
    org,
    seasonId,
    type: "operations",
    subType: "harvested",
    themeConfig: harvestedThemeConfig,
  });

  const {
    saveItem: saveTillOperation,
    deleteItem: deleteTillOperation,
    saveItemState: saveTillState,
    deleteItemState: deleteTillState,
    fetchList: fetchTilledOps,
    listState: tilledOpsState,
    fetchGeodata: fetchTilledGeodata,
    fetchMapThemes: fetchTilledMapThemes,
    fetchItemById: fetchTilledById,
    fetchItemsByFieldId: fetchTilledByFieldId,
    list: tilledOps,
    geodata: tilledOpsGeodata,
    geodataState: tilledOpsGeodataState,
    themeData: tilledOpsTheme,
    themeRollup: tilledOpsThemeRollup,
    // geodataByIdState: tilledFetchByIdState,
    geodataByFieldIdState: tilledFetchByFieldIdState,
  } = useInventoryType({
    org,
    seasonId,
    type: "operations",
    subType: "tilled",
    themeConfig: tilledThemeConfig,
  });

  let currentOpsGeodata;
  let currentTheme;
  let currentThemeRollup;
  let currentOperations;
  let fetchById;
  let fetchByFieldId;
  // let fetchByIdState;
  let geodataState;
  let fetchByFieldIdState;
  switch (type) {
    case "planted": {
      currentOpsGeodata = plantedOpsGeodata;
      currentTheme = plantedOpsTheme;
      currentThemeRollup = plantedOpsThemeRollup;
      currentOperations = plantedOps;
      fetchById = fetchPlantedById;
      // fetchByIdState = plantedFetchByIdState;
      geodataState = plantedOpsGeodataState;
      fetchByFieldId = fetchPlantedByFieldId;
      fetchByFieldIdState = plantedFetchByFieldIdState;
      break;
    }
    case "applied": {
      currentOpsGeodata = appliedOpsGeodata;
      currentTheme = appliedOpsTheme;
      currentThemeRollup = appliedOpsThemeRollup;
      currentOperations = appliedOps;
      fetchById = fetchAppliedByField;
      // fetchByIdState = appliedFetchByIdState;
      geodataState = appliedOptsGeodataState;
      fetchByFieldId = fetchAppliedByFieldId;
      fetchByFieldIdState = appliedFetchByFieldIdState;
      break;
    }
    case "harvested": {
      currentOpsGeodata = harvestedOpsGeodata;
      currentTheme = harvestedOpsTheme;
      currentThemeRollup = harvestedOpsThemeRollup;
      currentOperations = harvestedOps;
      fetchById = fetchHarvestedById;
      // fetchByIdState = harvestedFetchByIdState;
      geodataState = harvestedOpsGeodataState;
      fetchByFieldId = fetchHarvestedByFieldId;
      fetchByFieldIdState = harvestedFetchByFieldIdState;
      break;
    }
    case "tilled": {
      currentOpsGeodata = tilledOpsGeodata;
      currentTheme = tilledOpsTheme;
      currentThemeRollup = tilledOpsThemeRollup;
      currentOperations = tilledOps;
      fetchById = fetchTilledById;
      // fetchByIdState = tilledFetchByIdState;
      geodataState = tilledOpsGeodataState;
      fetchByFieldId = fetchTilledByFieldId;
      fetchByFieldIdState = tilledFetchByFieldIdState;
      break;
    }
    default: {
      currentOpsGeodata = null;
      currentTheme = null;
      currentThemeRollup = null;
      currentOperations = null;
      fetchById = null;
      // fetchByIdState = null;
      fetchByFieldId = null;
      fetchByFieldIdState = null;
    }
  }
  const currentEditOperation = itemId
    ? currentOperations?.find((f) => f.id === itemId)
    : null;
  const currentEditFtr = itemId
    ? currentOpsGeodata?.features?.find((f) => f.id === itemId)
    : null;
  if (currentEditFtr && currentEditOperation) {
    Object.assign(currentEditFtr.properties, currentEditOperation);
  }

  const fetchOperationsSummaryFn = useCallback(() => {
    return fetchOperationsSummary(
      `/${org}/operations/season/${seasonId}/summary`
    );
  }, [fetchOperationsSummary, org, seasonId]);

  const fetchOperations = useCallback(() => {
    if (type === "planted") {
      fetchPlantedOps();
      fetchPlantedMapThemes();
    }
    if (type === "applied") {
      fetchAppliedOps();
      fetchAppliedMapThemes();
    }
    if (type === "harvested") {
      fetchHarvestOps();
      fetchHarvestedMapThemes();
    }
    if (type === "tilled") {
      fetchTilledOps();
      fetchTilledMapThemes();
    }
  }, [
    fetchAppliedMapThemes,
    fetchAppliedOps,
    fetchHarvestOps,
    fetchHarvestedMapThemes,
    fetchPlantedMapThemes,
    fetchPlantedOps,
    fetchTilledMapThemes,
    fetchTilledOps,
    type,
  ]);

  return (
    <OperationsStateContext.Provider
      value={{
        currentOpsGeodata,
        currentTheme,
        currentThemeRollup,
        currentOperations,
        currentEditFtr,
        fetchByFieldIdState,
        appliedOpsGeodata,
        appliedOptsGeodataState,
        plantedOpsGeodata,
        harvestedOpsGeodata,
        saveHarvestState,
        saveTillState,
        saveAppliedState,
        savePlantedState,
        deletePlantedState,
        deleteAppliedState,
        deleteHarvestState,
        deleteTillState,
        tilledOpsGeodata,
        operationsSummaryState,
        operationsSummary: operationsSummaryState?.data,
        isFetchingData:
          plantedOpsState.isLoading ||
          appliedOpsState.isLoading ||
          tilledOpsState.isLoading ||
          harvestedOpsState.isLoading ||
          operationsSummaryState.isLoading,
        isFetchingGeodata:
          appliedOptsGeodataState.isLoading ||
          plantedOpsGeodataState.isLoading ||
          harvestedOpsGeodataState.isLoading ||
          tilledOpsGeodataState.isLoading ||
          plantedFetchByFieldIdState.isLoading ||
          harvestedFetchByFieldIdState.isLoading ||
          appliedFetchByFieldIdState.isLoading ||
          tilledFetchByFieldIdState.isLoading,
        fetchEventsState,
        geodataState: geodataState ?? {},
        eventTemplatesState,
        saveOperationEventState,
        deleteOperationEventState,
        currentEditOperation,
      }}
    >
      <OperationsDispatchContext.Provider
        value={{
          fetchOperationsByField: (id) => {
            if (fetchByFieldId) {
              return fetchByFieldId(id);
            }
            return null;
          },
          fetchAppliedGeodata,
          fetchPlantedGeodata,
          fetchHarvestedGeodata,
          fetchTilledGeodata,
          savePlantedOperation,
          deletePlantedOperation,
          fetchPlantedOps,
          fetchAppliedOps,
          fetchHarvestOps,
          saveAppliedOperation,
          deleteAppliedOperation,
          saveHarvestOperation,
          deleteHarvestOperation,
          saveTillOperation,
          deleteTillOperation,
          fetchOperationById: fetchById,
          fetchOperations,
          fetchOperationsSummary: fetchOperationsSummaryFn,
          fetchEvents: useCallback(
            async (operationId) => {
              if (org) {
                return fetchEvents(`/${org}/operations/${operationId}/events`, {
                  transformResponse: (res) => {
                    sortObjArrByFields(res, "dateUtc");
                    return res;
                  },
                });
              }
              return null;
            },
            [org, fetchEvents]
          ),
          fetchEventTemplates: useCallback(async () => {
            if (org) {
              return fetchEventTemplates(`/${org}/templates/operationevent`, {
                transformResponse: (res) => {
                  res.forEach((e) => {
                    e.label = e.name;
                    e.value = e.id;
                  });
                  return res;
                },
              });
            }
            return null;
          }, [org, fetchEventTemplates]),
          resetOperationEventSave,
          saveOperationEvent: useCallback(
            (body) => {
              if (org) {
                return saveOperationEvent(`/${org}/operationevents`, {
                  method: body?.id ? "PUT" : "POST",
                  body: JSON.stringify(body),
                });
              }
              return null;
            },
            [org, saveOperationEvent]
          ),
          resetOperationEventDelete,
          deleteOperationEvent: useCallback(
            (id) => {
              if (org) {
                return deleteOperationEvent(`/${org}/operationevents/${id}`, {
                  method: "DELETE",
                });
              }
              return null;
            },
            [org, deleteOperationEvent]
          ),
        }}
      >
        {children}
      </OperationsDispatchContext.Provider>
    </OperationsStateContext.Provider>
  );
}
OperationsProvider.defaultProps = {
  seasonId: null,
  type: null,
  itemId: null,
};

OperationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  org: PropTypes.string.isRequired,
  seasonId: PropTypes.string,
  type: PropTypes.oneOf(["planted", "applied", "harvested", "tilled"]),
  itemId: PropTypes.string,
};

function useOperationsState() {
  const context = React.useContext(OperationsStateContext);
  if (context === undefined) {
    throw new Error(
      "useOperationsState must be used within an OperationsProvider"
    );
  }
  return context;
}

function useOperationsDispatch() {
  const context = React.useContext(OperationsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useOperationsDispatch must be used within an OperationsProvider"
    );
  }
  return context;
}

export { OperationsProvider, useOperationsState, useOperationsDispatch };
