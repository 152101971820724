import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

export default function InvalidUserView({
  message,
  title,
}: {
  message: string;
  title?: string;
}) {
  const { logout } = useAuth0();
  const { t } = useTranslation();
  return (
    <>
      <h2>{title || t("common.invalidUser")}</h2>
      <p>{message}</p>
      <a
        href="/"
        className="btn btn-outline-secondary"
        onClick={(e) => {
          e.preventDefault();
          logout({ logoutParams: { returnTo: window.location.origin } });
        }}
      >
        {t("auth.signOut")}
      </a>
    </>
  );
}
