import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryState } from "providers/InventoryProvider";
import { useDamageDispatch, useDamageState } from "providers/DamageProvider";
import DatePicker from "components/DatePicker";
import { FormField, FormWrapper, Select } from "components";
import { useFieldsState } from "../../../../providers/FieldsProvider";
import { filterOption } from "app-utils";

const defaultValues = {
  id: "",
  fieldId: "",
  farmId: "",
  damageTypeId: "",
  lossEventUtc: "",
  assessedOnUtc: "",
  description: "",
  geometry: "",
  gpsSourceTypeId: "e9aff444-911c-40a3-8222-614d70162d38",
};

export default function DamageForm({
  damageTypesState,
  onFieldSelect,
  // setWholeFieldDamage,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    currentEditFtr,
    saveDamageState,
    deleteDamageState,
  } = useDamageState();
  const { saveDamage, deleteDamage, fetchSummaryData } = useDamageDispatch();
  const { rootUrl, season } = useOrgState();
  const { fieldsByFarm } = useFieldsState();
  const { drawData } = useInventoryState();
  const methods = useForm({
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    control,
    errors,
    setValue,
  } = methods;
  const { id, lossEventUtc, assessedOnUtc } = watch();

  async function handleSave(d) {
    if (!formState.isDirty) {
      history.push(`${rootUrl}/inventory/damage`);
      return false;
    }
    d.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    d.gpsSourceTypeId = defaultValues.gpsSourceTypeId;
    // server doesn't want these props if not set
    if (!d.id) {
      delete d.id;
    }
    if (!d.assessedOnUtc) {
      delete d.assessedOnUtc;
    }
    const res = await saveDamage(d);
    if (!res.isError) {
      reset({ ...defaultValues, ...d });
      history.push(`${rootUrl}/inventory/damage`);
      fetchSummaryData();
    }
    return res;
  }
  // TODO: draw vs apply to whole field
  // const [createType, setCreateType] = useState('draw');
  // create opts to pass to Select components
  const damageTypeOpts = damageTypesState?.data
    ? damageTypesState?.data.map((d) => ({
        label: d.name,
        value: d.id,
        id: d.id,
      }))
    : [];

  return (
    <FormWrapper
      methods={methods}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={`${rootUrl}/inventory/damage`}
      saveState={saveDamageState}
      deleteState={deleteDamageState}
      onDelete={async () => {
        const res = await deleteDamage(id);
        if (!res.isError) {
          reset(defaultValues);
          fetchSummaryData();
          history.push(`${rootUrl}/inventory/damage`);
        }
      }}
      onSubmit={handleSubmit(handleSave)}
    >
      <input ref={register} required type="hidden" name="gpsSourceTypeId" />
      <Form.Text>{t("inventory.damage.selectFieldToDocument")}</Form.Text>
      <Form.Group>
        <FormField
          label={`${t("common.field")} *`}
          name="fieldId"
          htmlFor="field-select"
          control={control}
          rules={{ required: true }}
          render={(props) => (
            <Select
              id="field-select"
              options={fieldsByFarm ?? []}
              value={fieldsByFarm
                ?.map((f) => f.options)
                ?.flat()
                // eslint-disable-next-line react/prop-types
                ?.find((f) => f.value === props.value)}
              onChange={(item) => {
                onFieldSelect(item?.value);
                // eslint-disable-next-line react/prop-types
                props.onChange(item?.value ?? "");
              }}
              filterOption={({ data }, input) => {
                const { farm, name } = data;
                return filterOption({ farm, name }, input);
              }}
              isClearable
            />
          )}
        >
          {errors?.fieldId ? (
            <Form.Text className="text-danger">
              {t("common.requiredField")}
            </Form.Text>
          ) : null}
        </FormField>
      </Form.Group>
      {/* <Form.Text>Enter damage information</Form.Text> */}
      <Form.Group>
        <FormField
          label={`${t("common.type")} *`}
          name="damageTypeId"
          control={control}
          rules={{ required: true }}
          htmlFor="damage-type-select"
          render={(props) => (
            <Select
              isLoading={damageTypesState.isLoading}
              id="damage-type-select"
              // eslint-disable-next-line react/prop-types
              value={damageTypeOpts.find((dt) => dt.id === props.value)}
              options={damageTypeOpts}
              onChange={(item) => {
                // eslint-disable-next-line react/prop-types
                props.onChange(item.value);
              }}
            />
          )}
        >
          {errors?.damageTypeId ? (
            <Form.Text className="text-danger">
              {t("common.requiredField")}
            </Form.Text>
          ) : null}
        </FormField>
        <FormField
          wrapper="div"
          htmlFor="damage-date-picker"
          label={`${t("inventory.damage.damageDate")} *`}
          name="lossEventUtc"
          control={control}
          rules={{ required: true }}
          render={(props) => (
            <DatePicker
              required
              autoComplete="off"
              id="damage-date-picker"
              minDate={season?.beginOnUtc ? new Date(season.beginOnUtc) : null}
              maxDate={season?.endOnUtc ? new Date(season.endOnUtc) : null}
              // eslint-disable-next-line react/prop-types
              selected={props.value ? new Date(props.value) : null}
              onChange={(date) => {
                // reset assessed date if damage date later
                if (assessedOnUtc && date > new Date(assessedOnUtc)) {
                  setValue("assessedOnUtc", "");
                }
                // eslint-disable-next-line react/prop-types
                props.onChange(date);
              }}
            />
          )}
        />

        <FormField
          wrapper="div"
          htmlFor="assessed-date-picker"
          label={t("inventory.damage.assessedDate")}
          name="assessedOnUtc"
          control={control}
          render={(props) => (
            <DatePicker
              maxDate={new Date()}
              minDate={
                // set min date to current damageDate if set,
                // else use the season begin date as min date
                lossEventUtc
                  ? new Date(lossEventUtc)
                  : season?.beginOnUtc
                  ? new Date(season.beginOnUtc)
                  : null
              }
              autoComplete="off"
              id="assessed-date-picker"
              // eslint-disable-next-line react/prop-types
              selected={props.value ? new Date(props.value) : null}
              onChange={(date) => {
                // reset assessed date if damage date later
                if (assessedOnUtc && date > new Date(assessedOnUtc)) {
                  setValue("assessedOnUtc", "");
                }
                // eslint-disable-next-line react/prop-types
                props.onChange(date);
              }}
            />
          )}
        />
        <FormField
          htmlFor="damage-notes-input"
          label={t("common.notes")}
          name="description"
          control={control}
        >
          <Form.Control
            ref={register}
            name="description"
            id="damage-notes-input"
            as="textarea"
          />
        </FormField>
      </Form.Group>
      {errors?.geometry ? (
        <Form.Text className="text-danger">
          {t("common.geometryRequired")}
        </Form.Text>
      ) : null}
      {/* <Form.Group>
        <Form.Text>{t('inventory.damage.enterOrDrawDamage')}</Form.Text>
        <Form.Check
          id="draw-field-radio"
          name="draw-type"
          type="radio"
          label={t('inventory.damage.drawDamage')}
          onChange={() => {
            setCreateType('draw');
            setWholeFieldDamage(false);
          }}
          checked={createType === 'draw'}
        />
        <div className="d-flex">
          <div style={{ width: '275px' }}>
            <Form.Check
              id="from-existing-radio"
              name="draw-type"
              type="radio"
              label={t('inventory.damage.applyToField')}
              onChange={() => {
                setCreateType('whole-field');
                setWholeFieldDamage(true);
              }}
              checked={createType === 'whole-field'}
            />
          </div>
          {createType === 'whole-field' ? (
            <div>
              <Form.Label className="d-flex" htmlFor="whole-field-input">
                <Form.Control id="whole-field-input" type="number" />%
              </Form.Label>
            </div>
          ) : null}
        </div>
      </Form.Group> */}
    </FormWrapper>
  );
}

DamageForm.defaultProps = {
  isLoading: false,
  damageTypesState: null,
};

DamageForm.propTypes = {
  isLoading: PropTypes.bool,
  damageTypesState: PropTypes.shape({
    isLoading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onFieldSelect: PropTypes.func.isRequired,
  setWholeFieldDamage: PropTypes.func.isRequired,
};
