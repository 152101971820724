import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import usePrevious from "components/usePrevious";
import { useAssetsDispatch } from "providers/AssetsProvider";
import AssetView from "./AssetView";

export default function AssetDetails() {
  const { itemId } = useParams();
  const {
    fetchRecordsByAssetId,
    fetchPhotosByAssetId,
    resetAssetsGeodata,
    resetAssetPhotos,
    fetchGeodataByItemId,
  } = useAssetsDispatch();
  const prevEditId = usePrevious(itemId);

  // TODO: reset records on exit
  useEffect(() => {
    if (itemId && itemId !== prevEditId) {
      fetchGeodataByItemId(itemId);
      fetchRecordsByAssetId(itemId);
      fetchPhotosByAssetId(itemId);
    }
  }, [
    prevEditId,
    itemId,
    fetchGeodataByItemId,
    fetchRecordsByAssetId,
    fetchPhotosByAssetId,
  ]);
  useEffect(() => {
    return () => {
      resetAssetsGeodata();
      resetAssetPhotos();
    };
  }, [resetAssetsGeodata, resetAssetPhotos]);

  return (
    <>
      <AssetView />
    </>
  );
}
