import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import PieChartComponent from "../Charts/PieChart";

export default function MapThemePie({
  data,
  isActive,
  onClick,
  id,
  title,
  showTooltip,
  showActiveBorder,
}) {
  let borderBottom = "solid 4px transparent";
  if (!showActiveBorder) {
    borderBottom = "none";
  } else if (isActive) {
    borderBottom = "solid 4px";
  }
  return (
    <>
      <Button
        variant=""
        className="no-styles h-100 w-100 d-flex flex-column align-items-center"
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
        onClick={() => {
          onClick(id);
        }}
      >
        <h3
          title={title}
          className="text-center m-0 ellipsis-overflow"
          style={{ textTransform: "none" }}
        >
          {title}
        </h3>
        <div
          style={{
            height: "150px",
            width: "150px",
            borderBottom,
          }}
        >
          <PieChartComponent
            isActive={isActive}
            data={data}
            dataKey="percent"
            showTooltip={showTooltip}
          />
        </div>
      </Button>
    </>
  );
}
MapThemePie.defaultProps = {
  data: null,
  isActive: false,
  onClick: () => {},
  showTooltip: true,
  showActiveBorder: true,
};
MapThemePie.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  showTooltip: PropTypes.bool,
  showActiveBorder: PropTypes.bool,
};
