import React from "react";
import { AssetsProvider } from "providers/AssetsProvider";
import { OperationsProvider } from "providers/OperationsProvider";
import { useOrgState } from "providers/OrgProvider";
import Dashboard from "./DashboardView";
import { useURLSearchParams } from "components";

export default function DashboardView() {
  const { org, season } = useOrgState();
  const query = useURLSearchParams();
  const operationType = query.get("operationType") || "planted";
  return (
    <OperationsProvider org={org} seasonId={season?.id} type={operationType}>
      <AssetsProvider org={org} seasonId={season?.id}>
        <Dashboard />
      </AssetsProvider>
    </OperationsProvider>
  );
}
