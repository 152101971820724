import React from "react";
import PropTypes from "prop-types";

export function ScoutingInputsCell({ row, value }) {
  const { notes, photoCount, observedOnUtc } = row.values || {};
  const finalInputs = [];
  if (value?.length) {
    value.forEach((v) => {
      switch (v.type) {
        case "date":
          finalInputs.push(
            `${v.label}: ${new Date(v.value).toLocaleDateString()}`
          );
          break;
        case "list": {
          // value should be an array of single object for list
          finalInputs.push(
            `${v.label}: ${v.value?.length ? v.value[0]?.value : v?.value}`
          );
          break;
        }
        case "multiselectlist": {
          const values = v.value?.map((lv) => lv.value);
          finalInputs.push(`${v.label}: ${values?.join(", ") || "--"}`);
          break;
        }
        case "numeric": {
          finalInputs.push(
            `${v.label}: ${v.value} ${v.uom ? v.uom.label : ""}`
          );
          break;
        }
        default: {
          finalInputs.push(`${v.label}: ${v.value}`);
          break;
        }
      }
    });
  }
  finalInputs.push(`Notes: ${notes || ""}`);
  finalInputs.push(`Photos: ${photoCount}`);
  finalInputs.push(`Date: ${new Date(observedOnUtc).toLocaleString()}`);
  return (
    <div>
      {finalInputs.map((i) => (
        <pre className="m-0" key={i}>
          {i}
        </pre>
      ))}
    </div>
  );
}

ScoutingInputsCell.defaultProps = {
  value: null,
};

ScoutingInputsCell.propTypes = {
  row: PropTypes.shape({
    values: PropTypes.shape({
      photoCount: PropTypes.number,
      notes: PropTypes.string,
      observedOnUtc: PropTypes.string,
    }),
  }).isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({})),
};
