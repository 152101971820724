import { createTheme, ThemeOptions } from "@mui/material/styles";
import * as locales from "@mui/material/locale";
import LinkBehavior from "./LinkBehavior";

export type SupportedLocale = keyof typeof locales;
// $green: #79c80e;
// $warning: #d5e100;
// $dark-blue: #008dcd;
// $info: #00d0eb;
// $dark: #333;
// $light: #f2f2f2;
// $white: #fff;
// $black: #000;
export default function getTheme(
  locale: SupportedLocale,
  mode: "dark" | "light",
  options?: ThemeOptions
) {
  return createTheme({
    typography: {
      fontFamily: "Lato",
    },
    locale,
    palette: {
      mode,
      primary: {
        main: "#008dcd",
        // light: "#008dcd",
        // dark: "#008dcd",
        // contrastText: "#fff"
      },
      success: {
        main: "#79c80e",
      },
      secondary: {
        main: "#C0C6CF",
      },
      // danger: {
      //   main: "#DA5427",
      // },
      warning: {
        main: "#d5e100",
      },
      info: {
        main: "#00d0eb",
      },
      alt: {
        main: "#929292",
      },
    },
    ...locales[locale],
    ...options,
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          noOptions: ({ theme }) => ({
            backgroundColor: theme.palette.mode === "dark" ? "#000" : "#fff",
          }),
          // tag: {
          //   backgroundColor: "#00d0eb",
          // },
          listbox: ({ theme }) => ({
            backgroundColor: theme.palette.mode === "dark" ? "#000" : "#fff",
            ".MuiAutocomplete-option": {
              '&[aria-selected="true"]': {
                backgroundColor: "#00d0eb",
              },
              '&.Mui-focused[aria-selected="true"]': {
                backgroundColor: "#00d0eb",
              },
            },
          }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: ({ theme }) => ({
            ...{
              backgroundColor:
                theme.palette.mode === "light" ? "#f2f2f2" : "#333",
              backgroundImage: "none",
            },
          }),
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: ({ theme }) => ({
            textTransform: "uppercase",
            ".MuiInputBase-root": {
              backgroundColor:
                theme.palette.mode === "light" ? "#f2f2f2" : "#121212",
            },
          }),
        },
        defaultProps: {
          size: "small",
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: ({ theme }) => ({
            ...{
              color: theme.palette.mode === "light" ? "#000" : undefined,
              backgroundColor:
                theme.palette.mode === "light" ? "#f2f2f2" : "#333",
              backgroundImage: "none",
            },
          }),
        },
      },
      MuiLink: {
        defaultProps: {
          component: LinkBehavior, // react-router-link
        },
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior, // react-router-link as button
        },
      },
      ...options?.components,
    },
  });
}
