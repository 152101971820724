import React, { useEffect } from "react";
import { Badge, Form } from "react-bootstrap";
import { Select } from "components";
import { useTranslation } from "react-i18next";
import FormField from "components/Forms/FormField";
import { Control } from "react-hook-form";

const selectComponents = {
  DropdownIndicator: (): null => {
    return null;
  },
  MultiValueRemove: (): null => {
    return null;
  },
  ClearIndicator: (): null => {
    return null;
  },
};

const commonProps = {
  className: "d-none",
  isMulti: true,
  isClearable: false,
  isSearchable: false,
  menuIsOpen: false,
  components: selectComponents,
};

function getOpts(val: string) {
  if (val) {
    const parsed: string[] = val ? JSON.parse(val) : null;
    return parsed?.map((t) => ({
      label: t,
      value: t,
    }));
  }
  return null;
}

export default function FieldAndSubfieldInputs({
  control,
  formState,
  selectedIds,
  setValue,
  show,
}: {
  control: Control;
  formState: {
    isSubmitted: boolean;
  };
  selectedIds: {
    fieldIds: string[];
    subfieldIds: string[];
  };
  setValue: (_id: string, _v: string | null) => void;
  show: boolean;
}) {
  const { t } = useTranslation();
  // set selectedIds input when prop changes
  useEffect(() => {
    setValue(
      "fieldIds",
      selectedIds?.fieldIds ? JSON.stringify(selectedIds.fieldIds) : null
    );
    setValue(
      "subfieldIds",
      selectedIds?.subfieldIds ? JSON.stringify(selectedIds.subfieldIds) : null
    );
  }, [setValue, selectedIds]);

  return (
    <>
      <small className={show ? "" : "d-none"}>
        {t("inventory.operations.useTableToSelect")}
      </small>
      <FormField
        className={show ? "" : "d-none"}
        label={t("inventory.fields.title")}
        name="fieldIds"
        control={control}
        htmlFor="fieldIds-select"
        render={(props: { value: string; label: string }) => {
          const opts = getOpts(props.value);
          return (
            <>
              <Badge variant="primary">{opts?.length || 0}</Badge>
              <Select
                id="fieldIds-select"
                options={opts}
                value={opts}
                {...commonProps}
              />
            </>
          );
        }}
      />
      <FormField
        className={show ? "" : "d-none"}
        label={t("inventory.subfields.title")}
        name="subfieldIds"
        control={control}
        htmlFor="subfieldIds-select"
        render={(props: { value: string; label: string }) => {
          const opts = getOpts(props.value);
          return (
            <>
              <Badge variant="primary">{opts?.length || 0}</Badge>
              <Select
                id="subfieldIds-select"
                options={opts}
                value={opts}
                {...commonProps}
              />
            </>
          );
        }}
      />
      {formState.isSubmitted &&
      !selectedIds?.fieldIds?.length &&
      !selectedIds?.subfieldIds?.length ? (
        <Form.Text className="text-danger">
          {t("inventory.operations.oneFieldSubfieldRequired")}
        </Form.Text>
      ) : null}
    </>
  );
}
