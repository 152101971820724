import React from "react";
import {
  ArrowLeftCircle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Download,
  Edit2,
  Eye,
  Info,
  Loader,
  Plus,
  Trash2,
  ZoomIn,
} from "react-feather";
import { Button } from "react-bootstrap";
import "./icon-btn.scss";
import { useTranslation } from "react-i18next";

interface IconButtonProps {
  className?: string;
  size?: "sm" | "lg";
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  icon:
    | "edit"
    | "eye"
    | "add"
    | "back"
    | "info"
    | "delete"
    | "loader"
    | "zoomIn"
    | "download"
    | "chevronLeft"
    | "chevronRight"
    | "chevronUp"
    | "chevronDown";
  disabled?: boolean;
  wrapper?: React.ComponentType;
  title?: string;
  variant?: string;
  role?: string;
}

export default function IconButton({
  className,
  size = "sm",
  children,
  icon,
  disabled,
  wrapper,
  title: propTitle,
  ...rest
}: IconButtonProps & { to?: string }) {
  const { t } = useTranslation();
  const Wrapper = wrapper || Button;
  let Icon = children;
  let title = "";
  if (!Icon) {
    if (icon) {
      switch (icon) {
        case "edit": {
          title = t("common.edit");
          Icon = <Edit2 size={16} />;
          break;
        }
        case "add": {
          title = t("common.add");
          Icon = <Plus size={16} />;
          break;
        }
        case "eye": {
          Icon = <Eye size={16} />;
          break;
        }
        case "info": {
          Icon = <Info size={16} />;
          break;
        }
        case "delete": {
          title = t("common.delete");
          Icon = <Trash2 size={16} />;
          break;
        }
        case "loader": {
          title = t("common.loadingData");
          Icon = <Loader className="spin" size={16} />;
          break;
        }
        case "zoomIn": {
          Icon = <ZoomIn size={16} />;
          break;
        }
        case "download": {
          title = t("common.download");
          Icon = <Download size={16} />;
          break;
        }
        case "chevronLeft": {
          Icon = <ChevronLeft size={16} />;
          break;
        }
        case "chevronRight": {
          Icon = <ChevronRight size={16} />;
          break;
        }
        case "chevronUp": {
          Icon = <ChevronUp size={16} />;
          break;
        }
        case "chevronDown": {
          Icon = <ChevronDown size={16} />;
          break;
        }
        case "back": {
          Icon = <ArrowLeftCircle size={16} />;
          break;
        }
        default:
          break;
      }
    }
  }
  return (
    <Wrapper
      disabled={disabled}
      className={`btn icon-btn ${size}${className ? ` ${className}` : ""}`}
      size={size}
      title={propTitle || title}
      aria-label={propTitle || title}
      variant=""
      {...rest}
    >
      {Icon}
    </Wrapper>
  );
}
