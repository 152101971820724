import { Button } from "@mui/material";
import React, { useState } from "react";
import { Card, Popover, Overlay, Form } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/Overlay";

function InputConfirmLabel({ text }: { text: string }) {
  return (
    <>
      Please type <b>{text}</b> to confirm
    </>
  );
}

export default function ConfirmAlert({
  alertType = "popover",
  children,
  confirmText = "Yes",
  message = "Are you sure?",
  title = "Confirm",
  cancelText = "No",
  onConfirm,
  onCancel = () => {},
  show,
  target,
  overlayOptions = { placement: "top" },
  requireInputConfirmText,
  requireInputConfirmTextLabel,
}: {
  alertType?: "card" | "popover";
  children?: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  message: React.ReactNode;
  onConfirm: (_e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancel?: () => void;
  overlayOptions?: { placement: Placement };
  requireInputConfirmText?: string;
  requireInputConfirmTextLabel?: React.ReactNode;
  show: boolean;
  target?: HTMLElement | null;
  title?: string;
}) {
  const [verifyText, setVerifyText] = useState("");
  function Buttons() {
    return (
      <div className="float-right my-3">
        {cancelText ? (
          <Button variant="outlined" color="secondary" onClick={onCancel}>
            {cancelText}
          </Button>
        ) : null}{" "}
        <Button
          variant={"contained"}
          disabled={
            requireInputConfirmText && verifyText !== requireInputConfirmText
          }
          onClick={(e) => {
            if (
              requireInputConfirmText &&
              verifyText !== requireInputConfirmText
            ) {
              return;
            }
            onConfirm(e);
            setVerifyText("");
          }}
        >
          {confirmText}
        </Button>
      </div>
    );
  }
  if (alertType === "popover") {
    return (
      <Overlay
        show={show}
        target={target}
        containerPadding={20}
        {...overlayOptions}
      >
        <Popover id="popover-alert">
          <Popover.Title as="h3">{title}</Popover.Title>
          <Popover.Content>
            {message}
            {requireInputConfirmText ? (
              <>
                <Form.Text className="text-danger">
                  {requireInputConfirmTextLabel || (
                    <InputConfirmLabel text={requireInputConfirmText} />
                  )}
                </Form.Text>
                <Form.Control
                  value={verifyText}
                  onChange={(e) => {
                    setVerifyText(e.target.value);
                  }}
                />
              </>
            ) : null}
            <Buttons />
            {children}
          </Popover.Content>
        </Popover>
      </Overlay>
    );
  }
  return (
    <Card>
      <Card.Header>
        <Card.Title>{title}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Card.Text>{message}</Card.Text>
        {children}
      </Card.Body>
      <Card.Footer>
        <Buttons />
      </Card.Footer>
    </Card>
  );
}
