import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import Select from "components/Select";
import DatePicker from "components/DatePicker";
import { FormField } from "components";
import { useFieldsState } from "providers";
import { Control } from "react-hook-form";
import { ICropType, IField, ISelectOption } from "types";
/**
 * Field select list for forms
 */

export function FieldFormField({
  control,
  onChange,
}: {
  control: Control;
  onChange: (_item: IField) => void;
}) {
  const {
    fieldsByFarm,
  }: {
    fieldsByFarm: { label: string; options: IField[] }[];
  } = useFieldsState();
  const { t } = useTranslation();
  return (
    <FormField
      label={t("common.field")}
      name="fieldId"
      control={control}
      htmlFor="field-select"
      render={(props: { value: string; onChange: (_val: string) => void }) => (
        <Select
          id="field-select"
          options={fieldsByFarm ?? []}
          value={fieldsByFarm
            ?.map((f) => f.options)
            ?.flat()
            ?.find((f) => f.value === props.value)}
          onChange={(item) => {
            onChange(item);
            props.onChange(item?.value);
          }}
          isClearable
        />
      )}
    />
  );
}

/**
 * CID input for forms
 */
export function CIDFormField({
  register,
  required,
}: {
  register: () => void;
  required?: boolean;
}) {
  return (
    <FormField label={`ID${required ? " *" : ""}`} htmlFor="cid">
      <Form.Control
        required={required}
        maxLength={255}
        name="cid"
        id="cid"
        ref={register}
      />
    </FormField>
  );
}

/**
 * Datepicker component for bornOnUtc form field
 */
export function BornOnUTCFormField({
  label,
  control,
}: {
  label: string;
  control: Control;
}) {
  return (
    <FormField
      wrapper="div"
      label={label}
      name="bornOnUtc"
      rules={{ required: true }}
      control={control}
      htmlFor="bornOn-date-select"
      render={(props: { value: string; onChange: (_item: Date) => void }) => (
        <DatePicker
          required
          id="bornOn-date-select"
          maxDate={new Date()}
          selected={props.value ? new Date(props.value) : null}
          onChange={(date) => {
            props.onChange(date);
          }}
        />
      )}
    />
  );
}

/**
 * Crop type select for forms, using crops from organization level
 */
export function CropTypeIDFormField({
  control,
  isError,
}: {
  control: Control;
  isError?: boolean;
}) {
  const { cropTypes, cropTypesState } = useOrgState();
  const { t } = useTranslation();
  return (
    <FormField
      label={`${t("common.crop")} *`}
      name="cropTypeId"
      control={control}
      htmlFor="crop-select"
      rules={{ required: true }}
      render={(props: {
        value: string;
        onChange: (_item: ICropType) => void;
      }) => (
        <Select
          isLoading={cropTypesState?.isLoading}
          id="crop-select"
          options={cropTypes || []}
          value={cropTypes?.find((f) => f.value === props.value)}
          onChange={(item) => {
            props.onChange(item?.value);
          }}
          isClearable
        />
      )}
    >
      {isError ? (
        <Form.Text className="text-danger">
          {t("common.requiredField")}
        </Form.Text>
      ) : null}
    </FormField>
  );
}

/**
 * Asset status select for forms
 */
export function AssetStatusIDFormField({
  assetStatusOpts,
  control,
  isError,
}: {
  assetStatusOpts: ISelectOption[];
  control: Control;
  isError?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <FormField
      label={`${t("common.status")} *`}
      name="assetStatusId"
      control={control}
      rules={{ required: true }}
      htmlFor="status-select"
      render={(props: {
        value: string;
        onChange: (_item: ISelectOption) => void;
      }) => (
        <Select
          id="status-select"
          options={assetStatusOpts}
          value={assetStatusOpts?.find((ao) => ao.value === props.value)}
          onChange={(item) => {
            props.onChange(item?.value);
          }}
          isClearable
        />
      )}
    >
      {isError ? (
        <Form.Text className="text-danger">
          {t("common.requiredField")}
        </Form.Text>
      ) : null}
    </FormField>
  );
}

/**
 * Text area input for description form fields
 */
export function DescriptionFormField({ register }: { register: () => void }) {
  const { t } = useTranslation();
  return (
    <FormField label={t("common.notes")} htmlFor="desc-notes">
      <Form.Control
        name="description"
        as="textarea"
        maxLength={255}
        id="desc-notes"
        ref={register}
      />
    </FormField>
  );
}

export function ManualSwitchFormField({
  children,
  id,
  label,
  manualInput,
  onChange,
  useManual,
  wrapper,
}: {
  children: React.ReactNode;
  id: string;
  label: string;
  manualInput: React.ReactNode;
  onChange?: (_e: React.ChangeEvent<HTMLInputElement>) => void;
  useManual?: boolean;
  wrapper?: React.ElementType;
}) {
  const { t } = useTranslation();
  const [useManualInput, setUseManualInput] = useState(useManual ?? false);

  return (
    <FormField label={label} htmlFor={id} wrapper={wrapper}>
      <div className="d-flex align-items-center">
        <div style={{ flexGrow: 2 }}>
          {useManualInput ? manualInput : children}
        </div>
        <Form.Switch
          className="ml-3 text-uppercase"
          id={`${id}-switch`}
          checked={useManualInput}
          label={t("common.manualInput")}
          onChange={(e) => {
            e.stopPropagation();
            setUseManualInput(e.currentTarget.checked);
            onChange && onChange(e);
          }}
        />
      </div>
    </FormField>
  );
}
