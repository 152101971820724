import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { ConfirmAlert } from "lib";
import { useTranslation } from "react-i18next";
import LoadingButton from "components/LoadingButton";

export default function DeleteButton({
  handleDelete,
  isLoading,
  ...rest
}: {
  handleDelete: () => void;
  isLoading: boolean;
}) {
  const { t } = useTranslation();
  const [requestDelete, setRequestDelete] = useState<boolean>(false);
  const nodeRef = useRef();
  return (
    <>
      <span className="ml-auto" ref={nodeRef}>
        <LoadingButton
          isLoading={isLoading}
          className="ml-1"
          variant="outlined"
          color="error"
          onClick={() => {
            setRequestDelete(true);
          }}
          {...rest}
        >
          <div className="d-flex align-items-center">{t("common.delete")}</div>
        </LoadingButton>
      </span>

      <ConfirmAlert
        show={requestDelete}
        target={nodeRef.current}
        alertType="popover"
        title={t("common.confirm")}
        cancelText={t("common.no")}
        confirmText={t("common.yes")}
        message={
          <div className="d-flex flex-column">
            <span>{t("common.areYouSure")}</span>
            <span style={{ fontWeight: "bold" }}>
              *{t("common.cannotUndo")}
            </span>
          </div>
        }
        onConfirm={async () => {
          setRequestDelete(false);
          handleDelete();
        }}
        onCancel={() => {
          setRequestDelete(false);
        }}
      />
    </>
  );
}

DeleteButton.defaultProps = {
  isLoading: false,
};

DeleteButton.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
