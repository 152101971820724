import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers";
import {
  IYieldFilter,
  useYieldFilters,
} from "views/SettingsView/DataFilters/useYieldFilters";
import { usePrevious } from "components";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import {
  useYieldInsightsActions,
  useYieldInsightsYieldFilter,
} from "./useYieldInsightsStore";

type IMinMax = { min: number | string; max: number | string };

function getMinMaxObj(minMax: IMinMax) {
  let d = { ...minMax };
  if (!d.min && d.min !== 0) {
    delete d.min;
  }
  if (!d.max && d.max !== 0) {
    delete d.max;
  }
  if (!d.min && !d.max) {
    d = null;
  }
  return d;
}

export default function MinMaxYieldFilterInput({
  cropTypeId,
  onChange,
}: {
  cropTypeId: string;
  onChange?: (_d?: IMinMax) => void;
}) {
  const prevCropType = usePrevious(cropTypeId);
  const { t } = useTranslation();
  const { org, season } = useOrgState();
  const actions = useYieldInsightsActions();
  const { state: yieldFiltersState } = useYieldFilters({
    org,
    seasonId: season?.id,
  });
  const yieldFilter = useYieldInsightsYieldFilter();

  useEffect(() => {
    if (cropTypeId !== prevCropType) {
      const yieldFilters = yieldFiltersState?.data as IYieldFilter[];
      const cropYieldFilter = yieldFilters?.find(
        (f) => f.cropTypeId === cropTypeId
      );

      const d = {
        min: cropYieldFilter?.yieldMin || "",
        max: cropYieldFilter?.yieldMax || "",
      };
      const yf = getMinMaxObj(d);
      actions.setYieldFilter(yf);
      onChange?.(yf);
    }
  }, [actions, cropTypeId, onChange, prevCropType, yieldFiltersState?.data]);

  return (
    <Stack spacing={2}>
      <TextField
        fullWidth
        label={t("insights.yield.minYield")}
        id="yield-min-input"
        value={yieldFilter?.min ?? ""}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const minMax = {
            min: e.target.value ? Number(e.target.value) : "",
            max: yieldFilter?.max,
          };
          const yf = getMinMaxObj(minMax);
          actions.setYieldFilter(yf);
          onChange?.(yf);
        }}
        type="number"
      />
      <TextField
        fullWidth
        label={t("insights.yield.maxYield")}
        id="yield-max-input"
        value={yieldFilter?.max ?? ""}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const minMax = {
            min: yieldFilter?.min,
            max: e.target.value ? Number(e.target.value) : "",
          };
          const yf = getMinMaxObj(minMax);
          actions.setYieldFilter(yf);
          onChange?.(yf);
        }}
        type="number"
      />
    </Stack>
  );
}
