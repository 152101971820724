import React from "react";
import agLogoDarkText from "../images/agassessor-with-logo-dark.png";
import agLogolightText from "../images/agassessor-with-logo-light.png";
import { useTheme } from "@mui/material";

export default function AuthView({ children }: { children: React.ReactNode }) {
  // const { theme } = useSettingsState();
  const theme = useTheme();
  return (
    <div
      className="d-flex flex-column align-items-center h-100"
      style={{ marginTop: "10rem" }}
    >
      <img
        alt="AgAssessor logo"
        style={{ width: "300px" }}
        className="mb-3"
        src={theme.palette.mode === "light" ? agLogoDarkText : agLogolightText}
      />
      {children}
    </div>
  );
}
