import React from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useOperationsState } from "providers";
import { useTranslation } from "react-i18next";
import { DetailItem } from "components";

export default function TilledOperationDetails() {
  const { t } = useTranslation();
  const { itemId } = useParams<{ itemId: string }>();
  const { currentOperations } = useOperationsState();
  const op = currentOperations?.find((o: { id: string }) => o.id === itemId);

  return (
    <>
      <Row>
        <Col className="d-flex flex-wrap">
          <DetailItem
            title={t("common.startDate")}
            value={
              op?.beginDateUtc
                ? new Date(op?.beginDateUtc).toLocaleDateString()
                : "--"
            }
          />
          <DetailItem
            title={t("common.endDate")}
            value={
              op?.endDateUtc
                ? new Date(op?.endDateUtc).toLocaleDateString()
                : "--"
            }
          />
          <DetailItem
            value={`${op?.depth} ${op?.depthUom}`}
            title={t("common.depth")}
          />
          <DetailItem value={op?.tilledType} title={t("common.type")} />
        </Col>
      </Row>
    </>
  );
}
