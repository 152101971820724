import React from "react";
import SVGIconWrapper from "./IconWrapper";

export default function PlantIcon(props) {
  return (
    <SVGIconWrapper {...props}>
      <svg viewBox="0 0 431 416">
        <path d="M306.83,90A6.86,6.86,0,0,0,301,84.67c-28.37-3.54-54.58-2.71-77.9,2.48-29.69,6.62-55.15,20.37-75.66,40.88a82.28,82.28,0,0,0,0,116.15,84,84,0,0,0,9.84,8.41v57.69a6.88,6.88,0,0,0,13.75,0V260.67a82.29,82.29,0,0,0,92.56-16.49c24.21-24.21,19.2-57.57,17.55-76.29-2.52-28.58-4.7-53.27,22.68-70.59A6.86,6.86,0,0,0,306.83,90ZM157.14,137.75c24.21-24.21,55.67-37.88,93.89-40.87A161.85,161.85,0,0,0,205,131.11a173.52,173.52,0,0,0-47,104.16,68.49,68.49,0,0,1-.84-97.52Zm110.28,31.34c2.16,24.48,4.2,47.6-13.57,65.37a68.51,68.51,0,0,1-82.73,10.78A159.94,159.94,0,0,1,215,140.57a148.57,148.57,0,0,1,42-31.26A114.26,114.26,0,0,1,279,101.14,58.56,58.56,0,0,0,267.94,125c-3.15,14.22-1.81,29.4-.52,44.08Z" />
      </svg>
    </SVGIconWrapper>
  );
}
