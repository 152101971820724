import React from "react";

export default function AssetDetailItem({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) {
  return (
    <div className="d-flex flex-column p-1 mr-3">
      <span
        style={{
          textTransform: "uppercase",
          fontSize: "0.85rem",
          textDecoration: "underline",
        }}
      >
        {title}
      </span>
      <span style={{ textTransform: "uppercase" }}>{value}</span>
    </div>
  );
}
