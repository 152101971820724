import React, { useEffect, useState } from "react";
import { Route, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InventoryProvider } from "providers/InventoryProvider";
import { Fields } from "./Fields";
import { Operations } from "./Operations";
import { Damage } from "./Damage";
import { ProductsView } from "./Products";
import { Assets } from "./Assets";
import { MainLayout, PageColumn, PageLayout } from "../MainLayout";
import InventoryMapView from "./MapView/InventoryMapView";
import { Conservation } from "./Conservation";
import { Subfields } from "./Subfields";
import { Scouting } from "./Scouting";
import { useFieldsState } from "providers/FieldsProvider";
import { useCallback } from "react";
import { IUrlParams } from "types";
import { Map } from "mapbox-gl";
import { FeatureCollection } from "geojson";

export default function InventoryView() {
  const [mapRef, setMapRef] = useState<Map>();
  const [inventoryFtrs, setInventoryFtrs] = useState<FeatureCollection>();
  const [isMapLoading, setIsMapLoading] = useState();
  const [drawFtr, setDrawFtr] = useState<FeatureCollection>();
  const [outsideDrawData, setOutsideDrawData] = useState();
  const { fieldListState, fieldsGeodataState } = useFieldsState();
  const [showMap, setShowMap] = useState(true);
  const [showFieldLabels, setShowFieldLabels] = useState(false);
  const [hideFields, setHideFields] = useState(false);
  const [drawOptions, setDrawOptions] = useState();
  const [useDraw, setUseDraw] = useState(false);
  const [colSizes, setColSizes] = useState([5, 7]);
  const [title, setTitle] = useState();
  const [labelProp, setLabelProp] = useState("");
  const { t } = useTranslation();

  const { editId, itemId } = useParams<IUrlParams>();
  // resize to fix container differences when showing/hiding the map
  useEffect(() => {
    if (mapRef) {
      mapRef.resize();
    }
  }, [mapRef, showMap, colSizes]);

  return (
    <MainLayout>
      <h2>
        {title} {t("inventory.title")}
      </h2>
      <InventoryProvider
        onHideMap={() => {
          setShowMap(false);
        }}
        onShowMap={() => {
          setShowMap(true);
        }}
        onToggleFieldLabels={setShowFieldLabels}
        onShowFields={() => {
          setHideFields(false);
        }}
        onHideFields={() => {
          setHideFields(true);
        }}
        onEnableDraw={() => {
          setUseDraw(true);
        }}
        onDisableDraw={() => {
          setUseDraw(false);
        }}
        setLabelProp={setLabelProp}
        setColSizes={setColSizes}
        setDrawOptions={setDrawOptions}
        fieldsList={fieldListState?.data}
        fieldsGeodata={fieldsGeodataState?.data}
        inventoryMap={mapRef}
        inventoryFtrs={inventoryFtrs}
        drawData={drawFtr}
        setOutsideDrawData={setOutsideDrawData}
        setInventoryFtrs={setInventoryFtrs}
        setIsMapLoading={setIsMapLoading}
        setTitle={setTitle}
      >
        <PageLayout>
          <PageColumn
            lg={!showMap ? 12 : colSizes[0]}
            className="h-100"
            style={{
              minHeight: "500px",
            }}
          >
            <Route
              path={[
                `/:org/:season/inventory/fields/:editId?`,
                `/:org/:season/inventory/fields/add`,
              ]}
            >
              <Fields />
            </Route>
            <Route
              path={[
                `/:org/:season/inventory/subfields/:subfieldType/add`,
                `/:org/:season/inventory/subfields/:subfieldType?/:editId?`,
              ]}
            >
              <Subfields />
            </Route>
            <Route
              path={[
                `/:org/:season/inventory/operations/:type?/items/:itemId?`,
                `/:org/:season/inventory/operations/:type?/add`,
                `/:org/:season/inventory/operations/:type?`,
              ]}
            >
              <Operations />
            </Route>
            <Route path="/:org/:season/inventory/products/:type?">
              <ProductsView />
            </Route>
            <Route
              path={[
                `/:org/:season/inventory/damage/:editId?`,
                `/:org/:season/inventory/damage/add`,
              ]}
            >
              <Damage />
            </Route>
            <Route
              path={[
                `/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId?`,
                `/:org/:season/inventory/assets/:type/:subtype`,
                `/:org/:season/inventory/assets/:type?/add`,
                `/:org/:season/inventory/assets/:type`,
              ]}
            >
              <Assets />
            </Route>
            <Route
              path={[
                `/:org/:season/inventory/conservation/:editId?`,
                `/:org/:season/inventory/conservation/add`,
              ]}
            >
              <Conservation />
            </Route>
            <Route path={[`/:org/:season/inventory/scouting/:observationId?`]}>
              <Scouting />
            </Route>
          </PageColumn>
          <PageColumn
            lg={showMap ? colSizes[1] : 0}
            className={`mt-3 mt-lg-0${!showMap ? " d-none" : ""}`}
            style={{
              minHeight: "500px",
              overflow: "hidden",
              //  transition: 'all 300ms'
            }}
          >
            <div className="h-100 position-relative">
              <InventoryMapView
                labelProp={labelProp}
                hideFields={hideFields}
                showFieldLabels={showFieldLabels}
                // editId={editId || itemId}
                editFeature={
                  useDraw && inventoryFtrs
                    ? inventoryFtrs?.features?.find(
                        (f) => f.id === editId || f.id === itemId
                      )
                    : null
                }
                mapRef={(m) => {
                  setMapRef(m);
                }}
                onDrawChange={useCallback((fc) => {
                  setDrawFtr(fc);
                }, [])}
                outsideDrawData={outsideDrawData}
                draw={useDraw}
                isLoading={isMapLoading}
                inventoryFeatures={inventoryFtrs}
                fields={fieldsGeodataState.data}
                drawOptions={drawOptions}
              />
            </div>
          </PageColumn>
        </PageLayout>
      </InventoryProvider>
    </MainLayout>
  );
}
