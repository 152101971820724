import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";

export default function Tooltip({ children }) {
  return <Card className="p-1">{children}</Card>;
}

Tooltip.defaultProps = {
  children: null,
};

Tooltip.propTypes = {
  children: PropTypes.any,
};
