import React from "react";
import { Route, Link, useParams, useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DataTable, IconButton } from "components";
import { useOperationsState, useOrgState } from "providers";

interface IRow {
  id: string;
  dateUtc: string;
}

export default function OperationRecordsListView() {
  const { t } = useTranslation();
  const { type, itemId } = useParams<{
    type: string;
    itemId: string;
  }>();
  //   const location = useLocation();
  //   const query = useQuery();
  const history = useHistory();
  const { rootUrl } = useOrgState();
  // const { fetchEvents } = useOperationsDispatch();
  const { fetchEventsState } = useOperationsState();

  //   const isAdd = location.pathname.includes('/add');
  //   const assetGroup = query.get('group');
  //   const fieldId = query.get('fieldId');
  const onEditClick = (data: IRow) => {
    history.push(
      `${rootUrl}/inventory/operations/${type}/items/${itemId}/records/${data.id}/edit`
    );
  };

  const recordsCols = [
    {
      Header: "",
      id: "actions",
      isSortable: false,
      accessor: function Cell(row: IRow) {
        return (
          <IconButton
            icon="edit"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              onEditClick(row);
            }}
          />
        );
      },
    },
    {
      Header: t("common.date"),
      id: "dateUtc",
      accessor: (row: IRow) => {
        return row.dateUtc ? new Date(row.dateUtc).toLocaleDateString() : "";
      },
    },
    { Header: t("common.type"), accessor: "template" },
    { Header: t("common.photos"), accessor: "photoCount" },
  ];

  return (
    <>
      <Row>
        <Col className="d-flex">
          <Link
            title={t("common.add")}
            className="ml-auto"
            to={`${rootUrl}/inventory/operations/${type}/items/${itemId}/records/add`}
          >
            <IconButton size="lg" icon="add" className="btn-success" />
          </Link>
        </Col>
      </Row>

      <Route
        path="/:org/:season/inventory/operations/:type/items/:itemId"
        exact
      >
        <Row className="flex-grow-1 overflow-auto">
          <Col className="h-100">
            <div className="px-3 h-100">
              <DataTable
                isFilterable={false}
                tableProps={{ size: "sm" }}
                data={fetchEventsState?.data || []}
                columns={recordsCols}
              />
            </div>
          </Col>
        </Row>
      </Route>
    </>
  );
}
